import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  getApplication,
  changeApplicationState,
  applicationActionStateToInitial,
} from "../../../../actions/application";
import { removeFormAnswerAction } from "../../../../actions/formAnswers";
import { AppState } from "../../../../store";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import moment from "moment";
import CompanyInfo from "./CompanyInfo/CompanyInfo";
import { useTranslation } from "react-i18next";

const ApplicationDetail = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const param: { id: string; secondId: string } = useParams();

  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const application = useSelector(
    (state: AppState) => state.applications.application
  );
  const applications = useSelector(
    (state: AppState) => state.applications.applications
  );
  useEffect(() => {
    dispatch(getApplication(param.secondId));
    return () => {
      dispatch(applicationActionStateToInitial());
      dispatch(removeFormAnswerAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 상태변경 버튼 디자인
  const statusChangeButtonForm = (status: string, action: string) => {
    let title = "";

    if (status === "waiting" && action === "approval") {
      title = t("common.approval");
    } else if (status === "waiting" && action === "reject") {
      title = t("common.reject");
    } else if (
      (status === "approval" || status === "reject") &&
      action === "cancel"
    ) {
      title = t("common.cancel");
    }

    if (action === "delete") {
      title = t("common.delete");
    }

    return title !== "" ? (
      <Button
        onClick={() => {
          stateChangeModalOpen(title, action);
        }}
        style={{
          border: "1px solid #465662",
          backgroundColor: "white",
          color: "#465662",
          margin: "3px",
        }}
        size="small"
      >
        {title}
      </Button>
    ) : null;
  };

  // 상태변경
  const approvalChangeFunc = async (approvalChangeParam: any) => {
    const result: any = await dispatch(
      changeApplicationState(approvalChangeParam)
    );

    return result;
  };

  // 상태 변경 (한명)
  const approvalChangeOneFunc = async (
    changeData: any,
    approval: string,
    message?: string
  ) => {
    if (
      applications &&
      applications?.content.filter((data) => data.approval === "approval")
        .length >= 10
    ) {
      return false;
    }

    const approvalChangeParam = {
      id: changeData.id,
      subEventId: param.id,
      prevApproval: changeData.approval,
      action: approval,
      message: message,
    };

    const result = await approvalChangeFunc(approvalChangeParam);
    if (result) {
      dispatch(getApplication(param.secondId));
    }
  };

  // 승인상태 변경 값
  const [approvalModalData, setApprovalModalData] = React.useState({
    open: false,
    title: "",
    action: "",
    rowData: [],
    message: "",
  });

  // [승인, 거절, 취소, 삭제] 모달 오픈
  const stateChangeModalOpen = (title: string, action: string) => {
    setApprovalModalData({
      ...approvalModalData,
      open: true,
      title: title,
      action: action,
      message: "",
    });
  };

  const stateChangeModalClose = () => {
    setApprovalModalData({
      ...approvalModalData,
      open: false,
      title: "",
      action: "",
      message: "",
    });
  };

  // 거절사유 수정
  const stateChangeMessageChange = (message: string) => {
    setApprovalModalData({
      ...approvalModalData,
      message: message,
    });
  };

  //

  return application !== undefined && application.approval !== "delete" ? (
    // && answerProcessing !== undefined && forms !== undefined
    <>
      <Dialog
        open={approvalModalData.open}
        onClose={stateChangeModalClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ minWidth: 320 }}>
          {approvalModalData.title}하시겠습니까?
        </DialogTitle>
        {approvalModalData.action === "reject" && (
          <DialogContent>
            <DialogContentText>반려사유를 입력해주세요</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="반려사유"
              type="text"
              value={approvalModalData.message}
              onChange={(e: any) => stateChangeMessageChange(e.target.value)}
              fullWidth
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={stateChangeModalClose} color="primary">
            {t("common.close")}
          </Button>
          <Button
            onClick={async () => {
              await approvalChangeOneFunc(
                application,
                approvalModalData.action,
                approvalModalData.message
              );
              await stateChangeModalClose();
            }}
            color="primary"
          >
            {t("common.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              margin: "23px",
              width: "18px",
              height: "18px",
              objectFit: "contain",
            }}
            onClick={() => history.goBack()}
          />
        </Grid>
        <Grid item style={{ padding: "0 0 0 64px", width: "100%" }}>
          <div style={{ height: "32px" }}>
            <b>
              {application.type === "seller"
                ? subEvent?.sgroupName
                : subEvent?.bgroupName}
            </b>{" "}
            {t("common.registerDate")} :{" "}
            {moment(application.createdDate).format("YYYY-MM-DD")}
          </div>
          <div
            style={{
              height: "32px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {t("common.status")}: {application.approval}
            </div>
            <div>
              {["approval", "reject", "cancel", "delete"].map((statusBtn) =>
                statusChangeButtonForm(application.approval!, statusBtn)
              )}
            </div>
          </div>
          {/* 기업정보 component */}
          <CompanyInfo />
        </Grid>
      </Grid>
    </>
  ) : null;
};

export default ApplicationDetail;
