import React, {useCallback, useEffect, useState} from "react";
import {Column} from "material-table";
import moment from "moment";

import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxTable from "../../components/Tables/PxTable";

// material icons
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  getEvents,
  modifyEventApproval,
  // modifyEventApprovalReject,
  modifyEventApprovalDelete,
  changeEventCancel,
} from "../../actions/events";
import {AppState} from "../../store";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
import {createAdvertising} from "../../actions/advertising";
import {confirmAlert} from "react-confirm-alert";
import {confirmModalOptions} from "../../utils/confirmModalOptions";
import styled from "styled-components";
import {approvalText} from "../../types/models/Event";
import {removeLoadingAction, setLoadingAction} from "../../actions/loading";
import {withStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PxSelectBox from "../../components/SelectBox/PxSelectBox";
import {Box, Grid} from "@material-ui/core";

interface RowType {
  evName: string;
  evCreatedDate: string;
  evDate: string;
  approval: string;
  id: string;
  createdUserId: number;
  payment: boolean;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const StyledDiv = styled.div`
  width: 100%;
  & th.MuiTableCell-root,
  td.MuiTableCell-root {
    padding: 0px 0px;
  }
  & td.MuiTableCell-root {
    color: rgba(0, 0, 0, 0.6) !important;
  }
`;

const EventList = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedEventId, setSelectedEventId] = useState<string>();
  const [selectedEventHostId, setSelectedEventHostId] = useState<number>();
  const [approval, setApproval] = useState<string>(" ");
  const dispatch = useDispatch();
  const {events, event} = useSelector((state: AppState) => state.events);

  const [state, setState] = useState<TableStateType>({
    columns: [
      {
        title: "기간",
        field: "evDate",
        cellStyle: {
          width: "200px",
        },
      },
      {
        title: "등록일",
        field: "evCreatedDate",
        cellStyle: {
          width: "150px",
        },
      },
      {
        title: "이벤트명",
        field: "evName",
        cellStyle: {
          width: "calc(100%-800px)",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "상태",
        field: "approval",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
        render: (rowData: any) => {
          const approval: "Y" | "N" | "R" | "W" | "D" = rowData.approval;
          return approvalText[approval];
        },
      },
      {
        title: "",
        field: "approvalBtn",
        cellStyle: {
          width: "300px",
          paddingRight: 0,
        },
        render: rowData => (
          <>
            {(rowData.approval === "R" || rowData.approval === "W" || rowData.approval === "N") && (
              <>
                <PxButton backgroundColor="purple" style={{borderRadius: 5, padding: 7, marginRight: 5}} onClick={e => onClickEventPost(e, rowData.id)}>
                  <ButtonTypo>게시</ButtonTypo>
                </PxButton>
              </>
            )}
            {rowData.approval === "Y" && (
              <PxButton
                backgroundColor="grey"
                style={{
                  borderRadius: 5,
                  padding: 7,
                  marginRight: 5,
                  backgroundColor: "#e4d7f7",
                }}
                onClick={e => onClickEventUnPost(e, rowData.id)}
              >
                <ButtonTypo>미게시</ButtonTypo>
              </PxButton>
            )}
            {["Y", "N", "R", "W"].indexOf(rowData.approval) > -1 && (
              <PxButton backgroundColor="grey" style={{borderRadius: 5, padding: 7}} onClick={e => onClickEventDelete(e, rowData.id)}>
                <ButtonTypo>삭제</ButtonTypo>
              </PxButton>
            )}
          </>
        ),
      },
    ],
    data: [],
  });

  const handleAnctionMenuClick = (event: any, data: RowType | RowType[]) => {
    setAnchorEl(event.currentTarget);
    setSelectedEventHostId((data as RowType).createdUserId);
    setSelectedEventId((data as RowType).id);
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const onClickEventUnPost = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
      event.stopPropagation();
      dispatch(setLoadingAction());
      await dispatch(changeEventCancel(id));
      dispatch(removeLoadingAction());
    },
    [dispatch]
  );

  const onClickEventPost = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
      event.stopPropagation();
      dispatch(setLoadingAction());
      await dispatch(modifyEventApproval(id));
      dispatch(removeLoadingAction());
    },
    [dispatch]
  );

  const onClickEventDelete = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
      event.stopPropagation();
      confirmAlert(
        confirmModalOptions({
          title: "정말 삭세하시겠습니까?",
          click: () => dispatch(modifyEventApprovalDelete(id)),
        })
      );
    },
    [dispatch]
  );

  const onChangeSearchApproval = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const value = event.target.value;
    setApproval(value as string);
  };

  // const onClickEventPaymentSwitch = useCallback(
  //   async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
  //     e.stopPropagation();
  //     dispatch(setLoadingAction());
  //     await dispatch(changeEventPayment(id));
  //     dispatch(removeLoadingAction());
  //   },
  //   [dispatch]
  // );

  useEffect(() => {
    dispatch(getEvents(approval));
  }, [dispatch]);

  useEffect(() => {
    if (events && events.content) {
      const tableData: RowType[] = events!.content.map(ev => {
        const rowData: RowType = {
          evName: ev.name,
          evDate: `${moment(ev.startDate).format("YYYY-MM-DD")} ~ ${moment(ev.endDate).format("YYYY-MM-DD")}`,
          evCreatedDate: moment(ev.createdDate).format("YYYY-MM-DD"),
          approval: ev.approval!,
          id: ev.id!.toString(),
          createdUserId: ev.createdUserId ?? 0,
          payment: ev.payment === "Y" ? true : false,
        };
        return rowData;
      });

      setState({...state, data: tableData});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  useEffect(() => {
    if (event?.id !== undefined) {
      setState((prevState: any) => {
        const data = prevState.data;
        let returnValue: any = [];
        data.map((d: any, idx: number) => {
          let pushAt = true;
          if (d.id === event.id?.toString()) {
            if (event.approval === "D") {
              pushAt = false;
              // data.splice(idx, 1);
            } else {
              d.approval = event.approval;
            }
          }
          pushAt === true && returnValue.push(d);
        });
        return {...prevState, data: returnValue};
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  return (
    <PxGridContainer parent={true}>
      <StyledDiv>
        <PxTable<RowType>
          title={
            <Box width="100%" display="flex" justifyContent="flex-end" paddingRight="20px" height="40px">
              <PxSelectBox value={approval} variant="outlined" onChange={onChangeSearchApproval} displayEmpty name="countryNumber">
                <MenuItem value=" ">전체</MenuItem>
                {Object.keys(searchApproval).map((value: string) => (
                  <MenuItem value={value}>{searchApproval[value as "Y" | "W" | "D"]}</MenuItem>
                ))}
              </PxSelectBox>
            </Box>
          }
          columns={state.columns}
          data={state.data}
          //   onRowClick={(evt, selectedRow) =>
          //     history.push(`/event/detail/${selectedRow?.id}`)
          //   }
          // getPageAction={getEvents}
          getAction={getEvents}
          actionData={approval}
          totalPage={events?.totalPages}
          options={{
            actionsColumnIndex: -1,
            showTitle: false,
            defaultExpanded: false,
            search: true,
          }}
          onRowClick={(event, rowData) => {
            history.push(`/event/detail/${rowData!.id}`);
          }}
          actions={[
            rowData => ({
              icon: () => <MoreHorizIcon />,
              onClick: handleAnctionMenuClick,
            }),
          ]}
        />
      </StyledDiv>
      {/* EventList Action menu */}
      <Menu id="event-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleActionMenuClose}>
        <MenuItem
          onClick={() => {
            history.push(`/event/detail/${selectedEventId}`);
            setAnchorEl(null);
          }}
        >
          <SubHeadingTypo>이벤트 개요보기</SubHeadingTypo>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(createAdvertising(selectedEventId!, "main"));
            setAnchorEl(null);
          }}
        >
          <SubHeadingTypo>메인 광고하기</SubHeadingTypo>
        </MenuItem>
        <MenuItem onClick={handleActionMenuClose}>
          <SubHeadingTypo>한줄 광고하기</SubHeadingTypo>
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(`/member/hosts/${selectedEventHostId}`);
            setAnchorEl(null);
          }}
        >
          <SubHeadingTypo>주최자 보기</SubHeadingTypo>
        </MenuItem>
      </Menu>
    </PxGridContainer>
  );
};

export default EventList;

export const searchApproval = {
  Y: "게시", // 승인
  W: "미게시",
  D: "삭제",
};
