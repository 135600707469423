import { AppActions, AppState } from "../store";
import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Alert from "../types/models/Alert";
import { v4 } from "uuid";
import Error from "../types/models/Error";
import setAuthToken from "./setAuthToken";
import { removeLoading, removeLoadingAction } from "../actions/loading";

// 성공여부 return 값만 받고싶은 경우 action 필요x, dipatch 시킬 경우 action 필요
const postRequest =
  <T>(data: any, path: string, action?: (data: T) => AppActions) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };

    const body = JSON.stringify(data);

    try {
      const res: AxiosResponse<T> = await axios.post(path, body, config);

      if (path === "/api/users/login") {
        setAuthToken(res.headers.authorization, res.headers.userid);
      }
      if (action !== undefined) {
        dispatch(action(res.data));
      }

      return res.data;
    } catch (err) {
      const error: Error = err.response.data;

      let message: string = "";
      switch (err.response.status) {
        case 403:
          message = "접근권한이 없습니다.";
          break;
        case 500:
          message = "접근할 수 없습니다.";
          break;
        default:
          message = error.detail;
      }

      const alert: Alert = {
        alertType: "warning",
        id: v4(),
        msg: message,
      };

      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      if (action === undefined) {
        return false;
      }
    }
  };

const postRequestAndReturn =
  (path: string, data: any) => async (dispatch: Dispatch<AppActions>) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Accept-Language": "ko",
        },
      };

      const body = JSON.stringify(data);

      const res: AxiosResponse = await axios.post(path, body, config);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

// 성공여부 return 값만 받고싶은 경우 action 필요x, dipatch 시킬 경우 action 필요
const putRequest =
  <T>(data: any, path: string, action?: (data: T) => AppActions) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };

    const body = JSON.stringify(data);

    try {
      const res: AxiosResponse<T> = await axios.put(path, body, config);

      if (path === "/api/users/login") {
        setAuthToken(res.headers.authorization, res.headers.userid);
      }
      if (action !== undefined) {
        dispatch(action(res.data));
      }
      return true;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      if (action === undefined) {
        return false;
      }
    }
  };

const getRequest =
  <T>(
    data: any,
    path: string,
    action?: (data: T) => AppActions,
    params?: object
  ) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      params: params,
      data: {},
    };

    try {
      const res: AxiosResponse<T> = await axios.get(path, config);
      if (action) {
        dispatch(action(res.data));
      }
      
      return res.data;
    } catch (err) {
      const error: Error = err.response.data;

      let message: string = "";
      switch (err.response.status) {
        case 403:
          message = "접근권한이 없습니다.";
          break;
        case 500:
          message = "접근할 수 없습니다.";
          break;
        default:
          message = error.detail;
      }
      dispatch(removeLoadingAction());
      const alert: Alert = {
        alertType: "warning",
        id: v4(),
        msg: message,
      };
      if (path.includes("/api/users/") !== false) {
        dispatch(setAlertAction(alert));
        setTimeout(() => {
          dispatch(removeAlertAction(alert.id));
        });
      }
      return false;
    }
  };

const setAlert = (status: number, error: Error, path: string): Alert => {
  let message: string = "";
  switch (status) {
    case 403:
      message = "접근권한이 없습니다.";
      if (path === "/api/users/login") {
        message = "아이디 또는 비밀번호를 확인해주세요.";
      }
      break;
    case 500:
      message = "접근할 수 없습니다.";
      break;
    default:
      message = error.detail;
  }

  const alert: Alert = {
    alertType: "warning",
    id: v4(),
    msg: message,
  };

  return alert;
};

// 받침 체크 (을를이가 체크용)
export const checkBatchimEnding = (word: string) => {
  if (typeof word !== "string" || word.length === 0) return null;

  var lastLetter = word[word.length - 1];

  // 숫자 체크
  var regexp = /^[0-9]*$/;
  if (regexp.test(lastLetter)) {
    var a = ["0", "1", "3", "6", "7", "8"];
    return a.indexOf(lastLetter) > -1;
  } else {
    var uni = lastLetter.charCodeAt(0);
    if (uni < 44032 || uni > 55203) return null;
    return (uni - 44032) % 28 != 0;
  }
};

// 자유양식 질문문구
const typeText: { [key: string]: string } = {
  single: "선택", // 단일선택
  many: "선택", // 복수선택
  short: "입력", // 단문
  long: "입력", // 장문
  file: "첨부", // 파일
  url: "입력", // url
  keyword: "입력", // 키워드
  category: "선택", //카테고리
  star: "선택", // 별점평가
};

// useLocation search param 값 추출
export const locationParamData = (locationSearch: string) => {
  let returnValue: { [key: string]: string } = {};
  const locationSearchData = locationSearch.replace("?", "");
  if (locationSearchData !== "") {
    const splitSearchData: string[] = locationSearchData.split("&");
    splitSearchData.map((searchData) => {
      const searchDataArray = searchData.split("=");
      returnValue[searchDataArray[0]] =
        searchDataArray[1] === "undefined" ? "" : decodeURI(searchDataArray[1]);
    });
  }
  return returnValue;
};

export const freeFormQuestionText = (title: string, type: string) => {
  let returnValue = "";
  returnValue += checkBatchimEnding(title) === false ? "를 " : "을 ";
  returnValue += `${typeText[type]}해 주세요`;
  return returnValue;
};

// 날짜형식 체크 (spring 변수를 Timestamp -> LocalDateTime 으로 변경함에 따라 아래 형식을 따름)
export const isDate = (_date: string) => {
  // 2018-08-01T18:30:00.000Z => new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
  const _regExp1 = new RegExp(
    "^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$"
  );
  // 2018-08-01T18:30 (YYYY-MM-DDTHH:mm)
  const _regExp2 = new RegExp(
    "^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9])?$"
  );
  return _regExp1.test(_date) || _regExp2.test(_date);
};

// 양식폼에서 단일선택이나 복수선택지에서 url 링크 값이 들어있나 확인 후 결과값 리턴
// ex) 카테고리 선택 전 $$여기#https://naver.com$$를 확인 후 선택
const categoryAndCheckboxSelectTextToLinkAndText = (
  text: string,
  link?: boolean
) => {
  if (text.includes("$$")) {
    const firstIndex = text.indexOf("$$");
    const endIndex = text.lastIndexOf("$$");
    const linkText = text.substring(firstIndex + 2, endIndex);
    const splitedLinkText = linkText.split("#");
    const priorText = text.slice(0, firstIndex);
    const endText = text.slice(endIndex + 2);
    if (link === undefined) {
      return {
        priorText: priorText,
        endText: endText,
        link: splitedLinkText[1],
        linkText: splitedLinkText[0],
      };
    } else {
      return priorText + splitedLinkText[0] + endText;
    }
  }
  return text;
};

const getOnlyFileName = (fileName: string, fileType?: string) => {
  let onlyFileName = fileName.split("&&")[0];
  if (fileType) {
    onlyFileName += `.${fileType}`;
  }

  return onlyFileName;
};

export {
  postRequest,
  putRequest,
  getRequest,
  setAlert,
  postRequestAndReturn,
  categoryAndCheckboxSelectTextToLinkAndText,
  getOnlyFileName,
};
