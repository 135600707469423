import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PxButton from "../../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../../components/Typography/ButtonTypo";

import { AppState } from "../../../../../store";
import { useCompanyInfoContext } from "./useCompanyInfoContext";

// 탭 버튼 디자인(기업정보 추가정보 담당자)
const InfoChangeTapButton = styled((props) => {
  const { active, ...rest } = props;
  return <button {...rest} />;
})`
  margin: 20px 14px 15px 0;
  padding: 6.5px 15px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  ${(props: any) => {
    if (props.active === true) {
      return `
          background-color: #616161;
          color: white;
        `;
    } else if (props.active === false) {
      return `
          background-color: white;
          color: #616161;
          border: 1px solid #ccc;
        `;
    }
  }}
`;

const PxStyledButton = styled(PxButton)`
  float: right;
  margin-top: 15px;
`;

const CompanyInfoTopView = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const {
    activeStep,
    setActiveStep,
    setModify,
    modify,
    onClickSubmitButton,
    additionalForm,
    contentForm,
  } = useCompanyInfoContext();

  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  const onClickModifyButton = (e: React.MouseEvent) => {
    setModify(!modify);
  };

  // 회의(presentation) 경우 참가양식이 없을 수 있음, 없을 경우 default tap을 참가자 명함정보로 함
  useEffect(() => {
    if (!contentForm) {
      setActiveStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentForm]);

  return (
    <Fragment>
      <div>
        {contentForm && contentForm.formIds.length !== 0 && (
          <InfoChangeTapButton
            active={activeStep === 0 ? true : false}
            onClick={() => {
              setActiveStep(0);
            }}
          >
            {/* 설명회인지 아닌지에 따라 tab의 이름 다름 */}
            {subEvent?.category === "presentation"
              ? t("applicationDetail.applicationInfo")
              : t("applicationDetail.corporateInfo")}
          </InfoChangeTapButton>
        )}

        {/* 추가양식이 있을경우에만 보임 */}
        {additionalForm && additionalForm.formIds.length !== 0 && (
          <InfoChangeTapButton
            active={activeStep === 1 ? true : false}
            onClick={() => {
              setActiveStep(1);
            }}
          >
            {t("applicationDetail.additionalInfo")}
          </InfoChangeTapButton>
        )}

        {/* 수정상태가 아닐때만 담당자 보임 */}
        {modify === false && (
          <InfoChangeTapButton
            active={activeStep === 2 ? true : false}
            onClick={() => {
              setActiveStep(2);
            }}
          >
            {/* 설명회인지 아닌지에 따라 tab의 이름 다름 */}
            {subEvent?.category === "presentation"
              ? t("applicationDetail.person")
              : t("applicationDetail.person")}
          </InfoChangeTapButton>
        )}

        {modify === false ? (
          activeStep !== 2 ? (
            <PxStyledButton
              padding={"10px 15px"}
              backgroundColor="purple"
              onClick={onClickModifyButton}
            >
              <ButtonTypo>{t("common.modify")}</ButtonTypo>
            </PxStyledButton>
          ) : null
        ) : (
          <>
            <PxStyledButton
              padding={"10px 15px"}
              backgroundColor="grey"
              onClick={onClickModifyButton}
              style={{ marginLeft: 10 }}
            >
              <ButtonTypo>{t("common.cancel")}</ButtonTypo>
            </PxStyledButton>
            <PxStyledButton
              padding={"10px 15px"}
              backgroundColor="purple"
              onClick={onClickSubmitButton}
            >
              <ButtonTypo>{t("common.confirm")}</ButtonTypo>
            </PxStyledButton>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default CompanyInfoTopView;
