import { postRequest, getRequest, putRequest } from "../utils/utils";
import { Interpreter } from "../types/models/Interpreter";
import { AppActions } from "../store";

export const getInterpretersAction = (
  interpreters: Interpreter[]
): AppActions => ({
  type: "GET_INTERPRETERS",
  interpreters: interpreters,
});

export const getInterpreterAction = (interpreter: Interpreter): AppActions => ({
  type: "GET_INTERPRETER",
  interpreter: interpreter,
});

export const createInterpreter = (body: any) => {
  return postRequest(body, "/api/interpreters");
};

export const updateInterpreter = (body: any) => {
  return postRequest(body, "/api/interpreters/update");
};

export const putDeleteInterpreter = (userId: number, subEventId: number) => {
  return putRequest(
    {
      subEventId: subEventId,
      userId: userId,
    },
    "/api/interpreters/delete"
  );
};

export const getInterpreters = (subEventId: string) => {
  return getRequest(null, "/api/interpreters", getInterpretersAction, {
    subEventId: subEventId,
  });
};

export const getInterpreter = (id: string) => {
  return getRequest(null, "/api/interpreters/detail", getInterpreterAction, {
    id: id,
  });
};

// export const getInterpreter = async()
