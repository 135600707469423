import React, { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxGridItem from "../../components/Grid/PxGridItem";
import { useHistory } from "react-router-dom";
import OutlinedTxtField from "../../components/Inputs/OutlinedTxtField";
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import PxButton from "../../components/Buttons/PxButton";
import Container from "@material-ui/core/Container";
import Zoom from "../../types/models/Zoom";
import { createZoom } from "../../actions/zooms";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  navBar: {
    position: "sticky",
    top: 74,
  },
  arrowBackIcon: {
    cursor: "pointer",
    margin: 12,
    width: 24,
    height: 24,
    marginRight: 20,
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      marginBottom: 20,
    },
  },
  companyInfoGridItem: {
    width: "100%",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  subHeadingTypo: {
    margin: "32px 0 8px 0",
    fontWeight: "bold",
  },
}));

const ZoomAdd = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [zoom, setZoom] = useState<Zoom>({
    link: "",
    zoomRoomId: "",
    zoomEmail: "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: string;
      name: string;
    }>
  ) => {
    setZoom({ ...zoom, [e.target.name]: e.target.value.toString() });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const result: any = await dispatch(createZoom(zoom));
    if (result) {
      history.goBack();
    }
  };

  return (
    <>
      <PxGridContainer className={classes.navBar}>
        <PxGridItem>
          <ArrowBackIcon
            className={classes.arrowBackIcon}
            onClick={() => history.goBack()}
          />
        </PxGridItem>
      </PxGridContainer>
      <div>
        <Container maxWidth="md">
          <form onSubmit={onSubmit}>
            <SubHeadingTypo className={classes.subHeadingTypo}>
              Link
            </SubHeadingTypo>
            <OutlinedTxtField
              name="link"
              value={zoom.link}
              fullWidth
              onChange={handleOnChange}
              placeholder="Zoom Meeting 초대링크를 입력해주세요."
            />

            <SubHeadingTypo className={classes.subHeadingTypo}>
              Zoom Room ID
            </SubHeadingTypo>
            <OutlinedTxtField
              name="zoomRoomId"
              value={zoom.zoomRoomId}
              fullWidth
              onChange={handleOnChange}
              placeholder="Zoom Meeting ID 또는 Room ID를 넣어주세요."
            />

            <SubHeadingTypo className={classes.subHeadingTypo}>
              Zoom Email
            </SubHeadingTypo>
            <OutlinedTxtField
              name="zoomEmail"
              value={zoom.zoomEmail}
              fullWidth
              onChange={handleOnChange}
              placeholder="Zoom Meeting을 등록한 이메일 아이디를 입력해주세요."
            />

            <PxGridContainer
              alignContent="space-between"
              spacing={1}
              style={{ justifyContent: "flex-end", marginTop: "32px" }}
            >
              <PxGridItem>
                <PxButton backgroundColor="purple" type="submit">
                  <ButtonTypo>등록</ButtonTypo>
                </PxButton>
              </PxGridItem>
              <PxGridItem>
                <PxButton
                  backgroundColor="grey"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <ButtonTypo>취소</ButtonTypo>
                </PxButton>
              </PxGridItem>
            </PxGridContainer>
          </form>
        </Container>
      </div>
    </>
  );
};

export default ZoomAdd;
