import React, { useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import BlockIcon from "@material-ui/icons/Block";
import MeetingTargetsDialog from "./MeetingTargetsDialog";
import WaitingAcceptMeetingTargetsDialog from "./WaitingAcceptMeetingTargetsDialog";
import {
  MyMeetingContext,
  MyMeetingCheckListType,
  MeetingApplicantDataInterface,
} from "./MeetingDetail";
import styled from "styled-components";

import { confirmAlert } from "react-confirm-alert";

import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { SubEvenPathContext } from "../../../layouts/SubEvent";
import { AppState } from "../../../store";
import {
  applicationMeeting,
  cancelApplicationMeeting,
  changeMeetingState,
  getMyMeetings,
  removeUserImpossibleMeeting,
} from "../../../actions/meeting";
import { ChangeMeetingStateInterface } from "../../../types/models/Meeting";
import { confirmModalOptions } from "../../../utils/confirmModalOptions";

const MeetingCardForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 236px;
  height: 101px;
  border-radius: 3px;
  border: solid 1px #dcdcdc;
  background-color: #ffffff;
`;

const MeetingCardInfo = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #dcdcdc;
`;

const MeetingCardInfoName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.84);
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow-x: scroll;

  // 스크롤바 숨기기
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
const MeetingCardInfoTime = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 204px;
  height: 20px;
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.6);
`;

const MeetingCardActionStatus = styled.div`
  background-color: #eeeeee;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
`;

const MeetingCardActionButton = styled.div`
  background-color: #fafafa;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & svg {
    cursor: pointer;
  }
`;

interface Props {
  id: string;
  date: string;
  startTime: string;
  endTime: string;
  showDate: string;
  showStartTime: string;
  showEndTime: string;
  showHostMeetingTime: string;
  hostCountry: string;
  impossibleAt: string;
  dateIndex: number;
  timeIndex: number;
  meetingStatus: string;
}

const MeetingTimeCard: React.FC<Props> = (props) => {
  const {
    id,
    date,
    startTime,
    endTime,
    showDate,
    showStartTime,
    showEndTime,
    showHostMeetingTime,
    hostCountry,
    impossibleAt,
    dateIndex,
    timeIndex,
    meetingStatus,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string; secondId: string } = useParams();
  const { country } = useSelector((state: AppState) => state.countries);
  // 팝업 변수
  const [open, setOpen] = React.useState(false);
  const { myMeetingCheckList } = useContext(MyMeetingContext);
  const { basicPath } = useContext(SubEvenPathContext);
  const [t] = useTranslation("lang", { useSuspense: false });

  // MeetingDetail 가공할때 같이 안한이유: 몇건 요청이 있는지 파악하기위함
  // 카드 명칭(value: 1.ㅇㅇ에게 미팅 신청중, 2.ㅇ개의 미팅요청이 있습니다)
  const meetingTitle = (list: MyMeetingCheckListType[]) => {
    let returnVal: any = <></>;

    // 본인이 신청했을경우
    if (
      list[0].applicant.toString() === param.secondId &&
      list[0].status === "waiting"
    ) {
      returnVal = ( //신청
        <MeetingCardInfoName
          onClick={() =>
            history.push(`${basicPath}/application/${list[0].acceptor}`)
          }
        >
          [{t("meetingDetail.applied")}]{" "}
          <span style={{ cursor: "pointer" }}>{list[0].acceptorName}</span>
        </MeetingCardInfoName>
      );
    } else if (
      list[0].applicant.toString() === param.secondId &&
      list[0].status === "impossible"
    ) {
      // 불가시간 설정
      returnVal = (
        <MeetingCardInfoName>
          [{t("meetingDetail.unavailableTime")}]
        </MeetingCardInfoName>
      );
    } else if (
      list[0].acceptor.toString() === param.secondId &&
      list[0].status === "waiting"
    ) {
      // 요청건
      returnVal = (
        <MeetingCardInfoName>
          {list.length} {t("meetingDetail.requestExist")}
        </MeetingCardInfoName>
      );
    } else if (list[0].status === "agree") {
      // 미팅 성사
      let targetName: string = "";
      let targetId: string = "";

      if (list[0].acceptor.toString() === param.secondId) {
        targetName = list[0].applicantName;
        targetId = list[0].applicant;
      } else {
        targetName = list[0].acceptorName;
        targetId = list[0].acceptor;
      }

      // http://localhost:3000/subEvent/13/application/63
      returnVal = ( //체결
        <MeetingCardInfoName
          onClick={() => history.push(`${basicPath}/application/${targetId}`)}
        >
          [{t("meetingDetail.conclusioned")}]{" "}
          <span style={{ cursor: "pointer" }}>{targetName}</span>
        </MeetingCardInfoName>
      );
    }
    return returnVal;
  };

  // 미팅 거부 시간 추가
  const addMeetingRejectionTime = async () => {
    const mailPostToggleAt =
      localStorage.getItem("mailPostToggleAt") === null
        ? "false"
        : localStorage.getItem("mailPostToggleAt")!.toString();
    const meetingApplicantData: MeetingApplicantDataInterface = {
      subEventId: param.id, // 서브 이벤트
      applicant: param.secondId, // 신청자(application id)
      acceptor: "", // 수락자(application id)
      meetingId: props.id, // 미팅시간 key;
      status: "impossible", // 상태 [대기: waiting, 동의: agree, 비동의: disagree, 불가설정: impossible]
      date: date,
      startTime: startTime,
      endTime: endTime,
      mailPostToggleAt: mailPostToggleAt,
    };

    const result: any = await dispatch(
      applicationMeeting(meetingApplicantData)
    );
    if (result === true) {
      dispatch(getMyMeetings(param.secondId));
    }
  };

  // 미팅 거부 시간 제거
  const removeUserImpossibleMeetingTime = async (meetingsId: string) => {
    const result: any = await dispatch(removeUserImpossibleMeeting(meetingsId));
    if (result === true) {
      dispatch(getMyMeetings(param.secondId));
    }
  };

  // 미팅 신청 취소
  const cancelApplicationMeetingTime = async (meetingsId: string) => {
    const result: any = await dispatch(cancelApplicationMeeting(meetingsId));
    if (result === true) {
      dispatch(getMyMeetings(param.secondId));
    }
  };

  // 미팅 성사 -> 취소
  const onStateChangeDisagree = async (meetingsId: string) => {
    const mailPostToggleAt =
      localStorage.getItem("mailPostToggleAt") === null
        ? "false"
        : localStorage.getItem("mailPostToggleAt")!.toString();
    // 미팅 신청 데이터
    const changeMeetingStateData: ChangeMeetingStateInterface = {
      subEventId: param.id,
      acceptorId: param.secondId, // 기업 pk;
      meetingId: props.id, // 미팅시간 key;
      meetingsId: meetingsId, // 미팅 pk;
      status: "disagree", // 상태 [대기: waiting, 동의: agree, 비동의: disagree]
      mailPostToggleAt: mailPostToggleAt,
    };
    const result: any = await dispatch(
      changeMeetingState(changeMeetingStateData)
    );
    if (result === true) {
      dispatch(getMyMeetings(param.secondId));
      setOpen(false);
    }
  };

  // 미팅카드 출력 여부
  const openYn = () => {
    let openYn: boolean = false;

    if (country === undefined) {
      // 접속 국가 데이터 체크
      openYn = false;
    } else if (meetingStatus === "") {
      // 미팅상태 선택 안함
      openYn = true;
    } else if (myMeetingCheckList[id] !== undefined) {
      const meeting = myMeetingCheckList[id][0];

      if (
        // 미팅 신청중
        meeting.status === "waiting" &&
        meetingStatus === "waitingApplicant" &&
        meeting.applicant.toString() === param.secondId
      ) {
        openYn = true;
      } else if (
        // 미팅 수락 대기중
        meeting.status === "waiting" &&
        meetingStatus === "waitingAcceptor" &&
        meeting.acceptor.toString() === param.secondId
      ) {
        openYn = true;
      } else if (meeting.status! === meetingStatus) {
        // 대기 or 불가시간
        openYn = true;
      }
    }
    return openYn;
  };

  return openYn() ? (
    <>
      <MeetingCardForm>
        {/* 카드 내용 */}
        <MeetingCardInfo>
          {/* 기업명칭 조건: 주최자 불가시간이 아니면서 매칭이 됏을경우 */}
          {impossibleAt === "N" && myMeetingCheckList[id] !== undefined
            ? meetingTitle(myMeetingCheckList[id])
            : null}
          <MeetingCardInfoTime>
            <span>
              {showStartTime} - {showEndTime}
            </span>
            {/* <div style={{ fontSize: "7px" }}>
              (주최지 시간 {showHostMeetingTime})
            </div> */}
            <Tooltip
              title={`(${hostCountry}) ${showHostMeetingTime}`}
              placement="bottom-end"
            >
              <span
                style={{ fontSize: "12px", color: "gray", cursor: "default" }}
              >
                {t("meetingDetail.hostTime")}
              </span>
            </Tooltip>
          </MeetingCardInfoTime>
        </MeetingCardInfo>

        {/* 버튼 */}
        {impossibleAt === "Y" ? (
          <MeetingCardActionStatus>
            {t("meetingDetail.unavailableTime")}
          </MeetingCardActionStatus>
        ) : myMeetingCheckList[id] !== undefined &&
          myMeetingCheckList[id][0].applicant.toString() === param.secondId &&
          myMeetingCheckList[id][0].status === "impossible" ? (
          <MeetingCardActionButton>
            {/* 미팅 거부 제거*/}
            <BlockIcon
              fontSize="default"
              onClick={() => {
                confirmAlert(
                  confirmModalOptions({
                    title: t(
                      "meetingDetail.doYouWantToCancelMeetingUnavailable?"
                    ), //미팅불가설정 취소
                    click: () =>
                      removeUserImpossibleMeetingTime(
                        myMeetingCheckList[id][0].id
                      ),
                  })
                );
              }}
            />
          </MeetingCardActionButton>
        ) : myMeetingCheckList[id] !== undefined &&
          myMeetingCheckList[id][0].applicant.toString() === param.secondId &&
          myMeetingCheckList[id][0].status === "waiting" ? (
          <MeetingCardActionButton>
            {/* 미팅 신청중, 미팅 신청 취소 버튼 */}
            <BlockIcon
              fontSize="default"
              onClick={() => {
                confirmAlert(
                  confirmModalOptions({
                    title: t(
                      "meetingDetail.doYouWantToCancelMeetingYouAreRequesting?"
                    ), //현재신청중인 미팅 취소
                    click: () =>
                      cancelApplicationMeetingTime(
                        myMeetingCheckList[id][0].id
                      ),
                  })
                );
              }}
            />
          </MeetingCardActionButton>
        ) : myMeetingCheckList[id] !== undefined &&
          myMeetingCheckList[id][0].acceptor.toString() === param.secondId &&
          myMeetingCheckList[id][0].status === "waiting" ? (
          <MeetingCardActionButton>
            {/* 미팅 수락대기중, 미팅 요청자 리스트 open 버튼 */}
            <PlaylistAddCheckIcon
              fontSize="default"
              onClick={() => setOpen(true)}
            />
            {open === true && (
              <WaitingAcceptMeetingTargetsDialog
                open={open}
                setOpen={setOpen}
                id={id}
                date={date}
                startTime={startTime}
                endTime={endTime}
              />
            )}
          </MeetingCardActionButton>
        ) : myMeetingCheckList[id] !== undefined &&
          myMeetingCheckList[id][0].status === "agree" ? (
          <MeetingCardActionButton>
            {/* 미팅 성사 -> 취소 */}
            <BlockIcon
              fontSize="default"
              onClick={() => {
                confirmAlert(
                  confirmModalOptions({
                    title: t(
                      "meetingDetail.areYouSureYouWantToCancelThisMeeting?"
                    ), //체결된 미팅 취소
                    click: () =>
                      onStateChangeDisagree(myMeetingCheckList[id][0].id),
                  })
                );
              }}
            />
          </MeetingCardActionButton>
        ) : (
          <MeetingCardActionButton>
            <AddIcon fontSize="default" onClick={() => setOpen(true)} />
            {open === true && (
              <MeetingTargetsDialog
                open={open}
                setOpen={setOpen}
                id={id}
                date={date}
                startTime={startTime}
                endTime={endTime}
              />
            )}
            {/* 미팅 거부시간 지정 */}
            <BlockIcon
              fontSize="default"
              onClick={() => {
                confirmAlert(
                  confirmModalOptions({
                    title: t("meetingDetail.doYouWantToSetMeetingUnavailable?"),
                    click: addMeetingRejectionTime,
                  })
                );
              }}
            />
          </MeetingCardActionButton>
        )}
      </MeetingCardForm>
    </>
  ) : null;
};

export default MeetingTimeCard;
