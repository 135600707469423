import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHistory, useParams } from "react-router-dom";
import PxButton from "../../components/Buttons/PxButton";
import { AppState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import HostDetailInfo from "./HostDetailInfo";
import User from "../../types/models/User";
import HostDetailApplicants from "./HostDetailApplicants";
import { getMember } from "../../actions/users";

const useStyles = makeStyles((theme) => ({
  navBar: {
    position: "sticky",
    top: 74,
    zIndex: 1,
    transform: "translateY(-10px)",
    backgroundColor: "white",
  },
  arrowBackIcon: {
    cursor: "pointer",
    margin: 12,
    width: 24,
    height: 24,
    marginRight: 20,
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      marginBottom: 20,
    },
  },
  companyInfoGridItem: {
    width: "100%",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
}));
export interface UserType {
  company: string;
  position: string;
  countryNumber: string;
  email: string;
  name: string;
  phoneNumber: string;
  taxInvoiceMail: string;
  businessImageId: number;
  createDate: string;
  id: string;
}

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const users = useSelector((state: AppState) => state.users.hosters);
  const { id }: { id: string } = useParams();
  const [radioBtn, setRadioBtn] = useState<number>(0);
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState<User>({
    company: "",
    email: "",
    countryNumber: "",
    phoneNumber: "",
    position: "",
    name: "",
    createDate: "",
    taxInvoiceMail: "",
    businessImageId: "",
    businessImage: "",
    id: "",
  });

  useEffect(() => {
    if (users && users.length > 0) {
      const user = users!.filter((data) => data.id!.toString() === id)[0];
      if (user) {
        setUserInfo({
          company: user.company!,
          email: user.email,
          countryNumber: user.countryNumber,
          phoneNumber: user.phoneNumber,
          position: user.position!,
          name: user.name,
          taxInvoiceMail: user.taxInvoiceMail ?? "",
          businessImageId: user.businessImageId ?? "",
          createDate: moment(user.createDate!).format("YYYY.MM.DD HH:mm"),
          id: user.id!,
        });
      }
    } else {
      dispatch(getMember("HOST"));
    }
  }, [users, id]);
  return (
    <>
      <Grid container className={classes.navBar}>
        <Grid item>
          <ArrowBackIcon className={classes.arrowBackIcon} onClick={() => history.goBack()} />
        </Grid>
        <Grid item>
          <PxButton
            txtColor={radioBtn === 0 ? "purple" : "black60"}
            padding="14px 24px"
            backgroundColor={radioBtn === 0 ? "sapphire" : "default"}
            style={{ marginRight: 4 }}
            onClick={() => setRadioBtn(0)}
          >
            기본정보
          </PxButton>
        </Grid>

        <Grid item>
          <PxButton
            padding="14px 24px"
            txtColor={radioBtn === 1 ? "purple" : "black60"}
            backgroundColor={radioBtn === 1 ? "sapphire" : "default"}
            onClick={() => setRadioBtn(1)}
          >
            활동내역
          </PxButton>
        </Grid>
      </Grid>
      <div>
        {radioBtn === 0 ? (
          <HostDetailInfo user={userInfo} setUser={setUserInfo} />
        ) : (
          <HostDetailApplicants id={id} />
        )}
      </div>
    </>
  );
};
