import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { removeSubEventAction, getSubEvent } from "../actions/subEvent";
import { useDispatch } from "react-redux";
import FormProvider from "../components/FormProvider/FormProvider";

// router
import { formRoutes } from "../router";

// type
import RouteType from "../types/models/Route";

// material comp
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "640px",
    paddingTop: 40,
  },
}));

const AutonoForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const subEventId = location.pathname.split("/")[3];

  // subEvent를 가져온 후 redux에 저장, form page unmount 시 redux subEvents 초기화
  useEffect(() => {
    dispatch(getSubEvent(parseInt(subEventId)));
    return () => {
      dispatch(removeSubEventAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  return (
    <>
      <Container className={classes.root}>
        <FormProvider>{getRoutes(formRoutes)}</FormProvider>
      </Container>
    </>
  );
};

export default AutonoForm;
