import { AppActions } from "../store";
import { Dispatch } from "react";

// types
import { Form } from "../types/models/Form";

// utils
import { postRequest, getRequest } from "../utils/utils";

export const getFormsAction = (forms: Form): AppActions => ({
  type: "GET_FORMS",
  forms,
});

export const setFormsAction = (forms: Form): AppActions => ({
  type: "SET_FORMS",
  forms,
});

export const removeFormAction = (): AppActions => ({
  type: "REMOVE_FORMS",
});

export const setAddFormsAction = (forms: Form): AppActions => ({
  type: "SET_ADD_FORMS",
  forms,
});

export const getFormAction = (form: Form): AppActions => ({
  type: "GET_FORM",
  form: form,
});

export const getForms = (formId: number) => {
  return getRequest(null, "/api/forms/detail", getFormsAction, { id: formId });
};

export const setForms = (forms: Form) => (dispatch: Dispatch<AppActions>) => {
  dispatch(setFormsAction(forms));
};

export const setAdditionalForms = (forms: Form) => (
  dispatch: Dispatch<AppActions>
) => {
  dispatch(setAddFormsAction(forms));
};

export const getForm = <Form>(formId: string) => {
  return getRequest(null, "/api/forms/detail", getFormAction, {
    id: formId,
  });
};

export const createForm = (form: Form) => {
  return postRequest<Form>(form, "/api/forms/");
};
