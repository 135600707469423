import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    minHeight: 50,
  },
  btnRoot: {
    "&:hover": {
      backgroundColor: "#5f4b8b",
      borderColor: "#5f4b8b",
      boxShadow: "none",
    },
  },
}));

interface PrinterTablProps {
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}

const PrinterTab = ({ selectedTab, setSelectedTab }: PrinterTablProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PxButton
        backgroundColor={selectedTab === 0 ? "purple" : "default"}
        onClick={() => {
          setSelectedTab(0);
        }}
        noBorderRadius={true}
        fullWidth
        classes={{
          root: classes.btnRoot,
        }}
      >
        <ButtonTypo>날짜 조회</ButtonTypo>
      </PxButton>
      <PxButton
        backgroundColor={selectedTab === 1 ? "purple" : "default"}
        onClick={() => setSelectedTab(1)}
        noBorderRadius={true}
        fullWidth
        classes={{
          root: classes.btnRoot,
        }}
      >
        <ButtonTypo>이름 검색</ButtonTypo>
      </PxButton>
    </div>
  );
};

export default PrinterTab;
