import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { AppState } from "../../../store";
import moment from "moment";
import { useTranslation } from "react-i18next";

const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  height: 50px; //36px;
  background-color: #e9ecef;
`;

const Header = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  return subEvent !== undefined &&
    subEvent.mainEvent !== undefined &&
    subEvent.id !== "" ? (
    <HeaderBar>
      <div>
        {subEvent.mainEvent.name}
        <br />
        {subEvent.category === "presentation"
          ? "Conference"
          : subEvent.category}{" "}
        {/* presentation : Conference 모두 변경 / 21.04.19 */}
      </div>
      <div style={{ textAlign: "right" }}>
        {t("subEventHeader.mainPeriod")}:{" "}
        {moment(subEvent.mainEvent.startDate).format("YYYY-MM-DD")} ~{" "}
        {moment(subEvent.mainEvent.endDate).format("YYYY-MM-DD")}
        <br />
        {t("subEventHeader.subPeriod")}:{" "}
        {moment(subEvent.subEventStartDate).format("YYYY-MM-DD")} ~{" "}
        {moment(subEvent.subEventEndDate).format("YYYY-MM-DD")}
      </div>
    </HeaderBar>
  ) : null;
};

export default Header;
