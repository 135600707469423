import AppBar from "@material-ui/core/AppBar";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import PexpoLogo from "../../assets/images/logo.png";
import Box from "@material-ui/core/Box";
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
import PxIconButton from "../../components/Buttons/PxIconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: "white",
    color: "black",
  },
  title: {
    flexGrow: 1,
  },
}));

const PrinterNavbar = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} position="static" elevation={0}>
        <Toolbar>
          <img
            src={PexpoLogo}
            width="79"
            height="26"
            className="d-inline-block align-top"
            alt="Pexpo logo"
          />
          <Box width={10} />
          <SubHeadingTypo className={classes.title} fontweight="bold">
            출입증 프린터
          </SubHeadingTypo>
          {history.location.pathname.includes("/user") && (
            <PxIconButton
              onClick={() => {
                history.goBack();
                // const subEventId = history.location.pathname.split("/")[2];
                // history.replace(`/printer/${subEventId}`);
              }}
            >
              <ExitToAppIcon />
            </PxIconButton>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default PrinterNavbar;
