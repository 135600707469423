import { AppActions, AppState } from "../store";
import axios, { AxiosResponse } from "axios";

// types
import { EventInfo, EventPagination } from "../types/models/Event";
import { Dispatch } from "react";
import { getRequest, setAlert } from "../utils/utils";
import { removeAlertAction, setAlertAction } from "./alerts";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import findIndex from "lodash/findIndex";

export const createEventAction = (event: EventInfo): AppActions => ({
  type: "CREATE_EVENT",
  event,
});

export const getEventsAction = (events: EventPagination): AppActions => ({
  type: "GET_EVENTS",
  events,
});

export const getEventAction = (event: EventInfo): AppActions => ({
  type: "GET_EVENT",
  event,
});

export const modifyEventApprovalAction = (event: EventInfo): AppActions => ({
  type: "GET_EVENT",
  event,
});

export const modifyEvent = (event: EventInfo) => async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(event);

  const path = "/api/events/modify";
  try {
    await axios.put(path, body, config);

    return "success";
  } catch (err) {}
};

export const getHostEvents =
  <Event>(id: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/events?id=${id}`;
    try {
      const res: AxiosResponse = await axios.get(path, config);
      if (res.data) {
        return res.data;
      } else {
        return false;
      }
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = {
        alertType: "warning",
        id: path,
        msg: error.detail,
      };
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

export const getEvents =
  (approval="",page: number = 0, size: number = 10,word='',) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };
    console.log(approval)
    const path = `/api/events/getSearch?page=${page}&size=${size}&word=${word}&approval=${approval}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      dispatch(getEventsAction(res.data));
    } catch (err) {
      const error: Error = err.response.data;

      let message: string = "";
      switch (err.response.status) {
        case 403:
          message = "접근권한이 없습니다.";
          break;
        case 500:
          message = "접근할 수 없습니다.";
          break;
        default:
          message = error.detail;
      }

      const alert: Alert = {
        alertType: "warning",
        id: path,
        msg: message,
      };
      if (path.includes("/api/users/") !== false) {
        dispatch(setAlertAction(alert));
        setTimeout(() => {
          dispatch(removeAlertAction(alert.id));
        });
      }
      return false;
    }
  };

// 이벤트 상태변경(승인요청 -> 승인)
// export const modifyEventApproval = (id: string) => {
//   return getRequest(
//     null,
//     "/api/events/eventApproval",
//     modifyEventApprovalAction,
//     {
//       id: id,
//     }
//   );
// };

// 이벤트 상태변경(승인요청 -> 거절)
export const modifyEventApprovalReject = (id: string) => {
  return getRequest(
    null,
    "/api/events/eventApprovalReject",
    modifyEventApprovalAction,
    {
      id: id,
    }
  );
};

// 이벤트 상태변경([승인요청, 승인, 거절, 삭제] -> 삭제)
export const modifyEventApprovalDelete = (id: string) => {
  return getRequest(
    null,
    "/api/events/eventApprovalDelete",
    modifyEventApprovalAction,
    {
      id: id,
    }
  );
};

export const getEvent = (id: string) => {
  return getRequest(null, "/api/events/detail", getEventAction, { id: id });
};

// 이벤트 상태변경(승인요청 -> 승인)
export const modifyEventApproval =
  (id: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      params: { id: id },
    };

    const path = "/api/events/eventApproval";
    try {
      const res: AxiosResponse<EventInfo> = await axios.get(path, config);

      const events = [...getState().events.events!.content];

      const modifiedIndex = findIndex(events, { id: res.data.id });

      events.splice(modifiedIndex, 1, res.data);
      dispatch(
        getEventsAction({ ...getState().events.events!, content: events! })
      );
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

export const changeEventCancel =
  (id: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };

    const body = JSON.stringify({ id: id });

    const path = "/api/events/hostApprovalToWaiting";
    try {
      const res: AxiosResponse<EventInfo> = await axios.put(path, body, config);

      const events = [...getState().events.events!.content];

      const modifiedIndex = findIndex(events, { id: res.data.id });

      events.splice(modifiedIndex, 1, res.data);

      dispatch(
        getEventsAction({ ...getState().events.events!, content: events! })
      );
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return "error";
    }
  };

export const modifyEventShowLogo = (id: number) => async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const path = "/api/events/showLogo";
  try {
    await axios.put(path, { id: id }, config);

    return true;
  } catch (err) {
    return false;
  }
};
