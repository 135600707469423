import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ExportCSV } from "../../../../components/ExportCSV/ExportCSV";
import HeadingTypo from "../../../../components/Typography/HeadingTypo";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { categoryAndCheckboxSelectTextToLinkAndText } from "../../../../utils/utils";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getPostSurveys } from "../../../../actions/postSurveys";
import { PostSurvey } from "../../../../types/models/PostSurvey";

const groupBy = require("json-groupby");

const SurveyExcel = ({
  classes,
}: {
  classes: Record<"root" | "companyDiv" | "radioForm" | "meetingDiv", string>;
}) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const params: { id: string } = useParams(); // subEventId
  const [data, setData] = useState<{
    // 엑셀 export data
    data: any[];
    fileName: string;
  }>(null!);
  const [filteredData, setFilteredData] = useState<{
    // 라디오 or 체크박스 값에 따라 필터된 excel로 export할 데이터
    buyer: any[];
    seller: any[];
    fileName: string;
  }>(null!);
  const [groupNames, setGroupNames] = useState<{
    // 전체 데이터
    buyer: string;
    seller: string;
  }>();
  // const [radioValue, setRadioValue] = useState<string>("seller"); // 구분 radio value

  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  // 전체 미팅을 가져옴 -> 미팅에 걸린 상담일지와 같이 데이터 가공 후 excel로 export 할 data 생성
  const getSurveys = useCallback(async () => {
    const postSurveyAnswers: PostSurvey[] = await getPostSurveys(params.id);
    const groupedSurveyAnswers: {
      [companyName: string]: PostSurvey[];
    } = groupBy(postSurveyAnswers, ["application.id"]);

    const groupedSurveys = Object.entries(groupedSurveyAnswers).map(
      ([key, value]) => {
        return {
          name: value[0].application.name,
          type: value[0].application.type,
          survey: value,
          managerName: value[0].application.applicants![0].businessCardId!.name,
        };
      }
    );

    const resultData = groupedSurveys.map((data) => {
      const refinedData: any = {}; // 최종 엑셀 데이터
      refinedData["구분"] =
        data.type === "seller" ? subEvent?.sgroupName : subEvent?.bgroupName;
      refinedData["기업명"] = data.name;
      refinedData["담당자"] = data.managerName;

      data.survey!.forEach(async (answer: any) => {
        // 복수선택 답변 일때는 같은 key 값의 value에 해당 답변값(content)를 , 로 붙여줌
        if (
          answer.type === "many" &&
          refinedData[answer.title] !== undefined // 복수선택 값이 처음 들어올때 예외처리
        ) {
          refinedData[answer.title] =
            refinedData[answer.title] + "," + answer.content;
        } else if (answer.type === "file") {
          const filePath =
            answer.content && (await getFile(parseInt(answer.content)));
          refinedData[answer.title] = answer.content ? filePath : "";
        } else if (answer.type === "category") {
          // 카테고리 답변에 url 이 있을 시 url 링크 텍스트 제거
          refinedData[answer.title] =
            categoryAndCheckboxSelectTextToLinkAndText(answer.content!, false);
        } else {
          refinedData[answer.title] = answer.content;
        }
      });

      return refinedData;
    });

    setData({ data: resultData, fileName: "사후설문" });
  }, [params.id, subEvent]);

  useEffect(() => {
    if (subEvent !== undefined) {
      // subEvent에서 설정한 seller 및 buyer naming 변경
      setGroupNames({
        seller: subEvent.sgroupName,
        buyer: subEvent.bgroupName,
      });
      getSurveys();
    }
  }, [subEvent, getSurveys]);

  useEffect(() => {
    if (data !== null && subEvent !== undefined) {
      setFilteredData(null!);
      const seller: any[] = data.data.filter(
        (d) => d["구분"] === subEvent.sgroupName
      );
      const buyer: any[] = data.data.filter(
        (d) => d["구분"] === subEvent.bgroupName
      );
      let fileName = "사후설문";
      setFilteredData({ seller, buyer, fileName: fileName });
    }
  }, [data, subEvent]);

  const getFile = async (id: number) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    try {
      const res = await axios.get(`/api/fileS3?id=${id}`, config);

      return res.data.filePath;
    } catch (err) {
      return "";
    }
  };

  return (
    <div className={classes.meetingDiv}>
      <HeadingTypo fontweight="bold">{t("excel.postSurvey")}</HeadingTypo>

      {filteredData !== null ? (
        <ExportCSV
          sellerData={filteredData.seller}
          buyerData={filteredData.buyer}
          sellerName={groupNames!.seller}
          buyerName={groupNames!.buyer}
          fileName={filteredData.fileName}
        />
      ) : (
        <CircularProgress size={30} />
      )}
    </div>
  );
};

export default SurveyExcel;
