// import React, {useState, useEffect, useCallback} from "react";
// import Container from "@material-ui/core/Container";
// import {ThunkDispatch} from "redux-thunk";
// import {connect, useDispatch, useSelector} from "react-redux";
// import {bindActionCreators} from "redux";
// import {useHistory, useParams} from "react-router-dom";

// // types
// import {SubEventInfo} from "../../types/models/SubEvent";

// // actions
// import {AppActions, AppState} from "../../store";
// import {modifySubEvent, getSubEvent, removeSubEventAction} from "../../actions/subEvent";

// // custom ui comp

// // @material-ui/core
// import Grid from "@material-ui/core/Grid";
// import {TypographyProps} from "@material-ui/core/Typography";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControl from "@material-ui/core/FormControl";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Button from "@material-ui/core/Button";

// import styled from "styled-components";
// import moment from "moment";
// import Event from "../../types/models/Event";
// import Matched from "./Matched";
// // import Mismatch from "./Mismatch";
// import {v4} from "uuid";
// import {gmtTimeAdjustment, parallaxCalculation} from "../../utils/momentTz";
// import {TextField} from "@material-ui/core";
// import {isDate} from "../../utils/utils";

// import {useTranslation} from "react-i18next";
// import Box from "@material-ui/core/Box";
// import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
// import OutlinedTxtField from "../../components/Inputs/OutlinedTxtField";

// // subtitle style
// type TypoProp = TypographyProps;
// const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
//   const {...rest} = props;
//   return <SubHeadingTypo {...rest} />;
// })`
//   margin: 32px 0 8px 0;
//   font-weight: bold;
// `;

// type DisProps = DispatchProps & reduxStateProps;

// const SubEventModify: React.FC<DisProps> = props => {
//   const [t] = useTranslation("lang", {useSuspense: false});
//   const groupBy = require("json-groupby");
//   const {modifySubEvent, event} = props;
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const params: {id: string; secondId: string} = useParams();
//   const subEventDetail = useSelector((state: AppState) => state.subEvents.subEvent);
//   const {country, countries} = useSelector((state: AppState) => state.countries!);

//   const now = moment().format("YYYY-MM-DDTHH:mm");
//   // const [eventState, setEventState] = useState<Event["event"]>(event);
//   const [subEvent, setSubEvent] = useState<SubEventInfo>({
//     id: "",
//     eventId: params.id, // event key
//     matching: "Y", // 매칭 여부 (Y/N)
//     sgroupName: "", // 참가그룹 A
//     bgroupName: "", // 참가그룹 B
//     targetScope: "", // 참가기업 노출범위
//     subEventStartDate: now, // 서브 이벤트 시작날짜
//     subEventEndDate: now, // 서브 이벤트 끝날짜
//     sellRegStartDate: now, // 셀러 참가신청 시작날짜
//     sellRegEndDate: now, // 셀러 참가신청 끝날짜
//     buyRegStartDate: now, // 바이어 참가신청 시작날짜
//     buyRegEndDate: now, // 바이어 참가신청 끝날짜
//     regStartDate: "", // 참가자 참가신청 시작날짜
//     regEndDate: "", // 참가자 참가신청 끝날짜
//     matchStartTime: "09:00", // 매칭(미팅) 시작시간
//     matchEndTime: "09:00", // 매칭(미팅) 종료시간
//     matchReqTime: 0, // 매칭(미팅) 소요시간
//     matchBreakTime: 0, // 매칭(미팅) 휴식시간
//     matchNumPerDay: 0, // 일일 매칭(미팅) 횟수
//     session: "N", // 세션 여부 (Y/N)
//     category: "exhibition", // 구분
//     meetingData: "[]", // 미팅 시간 양식 폼
//     meetingDataJson: [], // 미팅 시간 양식 폼 json
//     // 매치메이킹 없음
//     boothCompany: "N", // 부스업체 여부 (Y/N)
//     boothCompanyStartDate: now, // 부스업체 시작날짜
//     boothCompanyEndDate: now, // 부스업체 끝날짜
//     preRegit: "N", // 사전등록 참관객 여부 (Y/N)
//     preRegitStartDate: now, // 사전등록 시작날짜
//     preRegitEndDate: now, // 사전등록 끝날짜
//     onSiteRegit: "N", // 현장등록 참관객 여부 (Y/N)
//     approval: "Y",
//   });

//   const targetScope = [
//     {code: "", value: t("common.all")},
//     // { code: "others", value: "상대기업(바이어: 셀러만, 셀러: 바이어만)" },
//     {
//       code: "others",
//       value: t("addSubEvent.counterparts"),
//     },
//   ];

//   // 이후 카테고리 메뉴 만들때 등록예정
//   const category = [
//     // { code: "exhibition", value: "전시" },
//     // { code: "conference", value: "회의" },
//     // { code: "meeting", value: "미팅" },
//     {code: "consulting", value: t("addSubEvent.consultation")},
//     {code: "presentation", value: t("addSubEvent.presentation")},
//     {code: "registration", value: t("addSubEvent.registration")},
//   ];

//   // 이벤트 정보 조회 (매칭기간을 이벤트기간에 넘지않도록)
//   useEffect(() => {
//     dispatch(getSubEvent(parseInt(params.secondId)));
//     return () => {
//       dispatch(removeSubEventAction());
//     };
//   }, []);

//   useEffect(() => {
//     if (subEventDetail !== undefined && subEventDetail!.mainEvent !== undefined) {
//       if (
//         subEventDetail.mainEvent?.approval === "D"
//         // || subEventDetail.mainEvent?.approval === "Y"
//       ) {
//         alert("This is the worng address");
//         history.push("/");
//       }

//       setSubEvent({
//         ...subEventDetail,
//         subEventStartDate: parallaxCalculation(subEventDetail.subEventStartDate.substr(0, 16), "", countries![Number(subEventDetail!.mainEvent!.countryCode!)]),
//         subEventEndDate: parallaxCalculation(subEventDetail.subEventEndDate.substr(0, 16), "", countries![Number(subEventDetail!.mainEvent!.countryCode!)]),
//         sellRegStartDate:
//           subEventDetail.sellRegStartDate !== undefined ? parallaxCalculation(subEventDetail.sellRegStartDate.substr(0, 16), "", countries![Number(subEventDetail.mainEvent.countryCode!)]) : "",
//         sellRegEndDate:
//           subEventDetail.sellRegEndDate !== undefined ? parallaxCalculation(subEventDetail.sellRegEndDate.substr(0, 16), "", countries![Number(subEventDetail.mainEvent.countryCode!)]) : "",
//         buyRegStartDate:
//           subEventDetail.buyRegStartDate !== undefined ? parallaxCalculation(subEventDetail.buyRegStartDate.substr(0, 16), "", countries![Number(subEventDetail.mainEvent.countryCode!)]) : "",
//         buyRegEndDate: subEventDetail.buyRegEndDate !== undefined ? parallaxCalculation(subEventDetail.buyRegEndDate.substr(0, 16), "", countries![Number(subEventDetail.mainEvent.countryCode!)]) : "",
//         meetingDataJson: JSON.parse(subEventDetail.meetingData!),
//       });
//     }
//   }, [subEventDetail]);

//   // 미팅 예상 종료 시간 계산
//   useEffect(() => {
//     const matchReqTime = subEvent.matchReqTime as number; // 미팅소요시간
//     const matchBreakTime = subEvent.matchBreakTime as number; // 휴식 횟수
//     const matchNumPerDay = subEvent.matchNumPerDay as number; // 일일 미팅 횟수

//     if (matchReqTime !== 0 && matchNumPerDay !== 0) {
//       const countMinute = matchNumPerDay * matchReqTime + (matchNumPerDay * matchBreakTime - matchBreakTime);

//       // 미팅시작 기준 날짜
//       const defaultDate = `2020-01-01 ${subEvent.matchStartTime}:00`;
//       // 미팅 계산 날짜
//       const endTimeCalculation = moment(defaultDate).add(countMinute, "m");

//       // 위 결과에 따른 문구
//       let matchEndTime = endTimeCalculation.format("HH:mm");

//       setSubEvent({
//         ...subEvent,
//         matchEndTime: matchEndTime,
//       });
//     } else {
//       setSubEvent({...subEvent, matchEndTime: subEvent.matchStartTime});
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [subEvent.matchStartTime, subEvent.matchReqTime, subEvent.matchBreakTime, subEvent.matchNumPerDay]);

//   // 미팅 시간 생성
//   useEffect(() => {
//     if (subEvent.matchEndTime !== undefined) {
//       createMeetingTimeForm();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [subEvent.matchEndTime, subEvent.subEventStartDate, subEvent.subEventEndDate]);

//   // 미팅 불가시간 셋팅(id 포함 하기위해 함수 분리)
//   const meetingTimeSetting = (subEventDateString: string) => {
//     const matchReqTime = subEvent.matchReqTime as number;
//     const matchBreakTime = subEvent.matchBreakTime as number;
//     const matchNumPerDay = subEvent.matchNumPerDay as number;

//     let meetingTimeList: any = []; // 필요데이터 {시작시간, 종료시간, 불가여부}

//     // 날짜별 매칭시간 기준 값
//     let impossibleStartTime = moment(`2020-01-01 ${subEvent.matchStartTime}:00`);

//     // 이전 미팅시간 가져오기
//     let impossibleArray: any;
//     if (subEvent !== undefined && subEvent.meetingData !== undefined) {
//       const meetingDataArray: any[] = JSON.parse(subEvent?.meetingData || "");
//       const impossibleY: any = [];
//       //@ts-ignore
//       meetingDataArray.forEach(meetingData => {
//         meetingData.time.forEach((time: any) => {
//           time.date = meetingData.date;
//           impossibleY.push(time);
//         });
//       });
//       impossibleArray = groupBy(impossibleY, ["impossibleAt"]);
//     }

//     // 날짜별 매칭시간 조회 (시작시간, 종료시간, 불가여부)
//     Array.from({length: matchNumPerDay}, (x, i) => {
//       const startTime = impossibleStartTime.format("HH:mm");
//       const endTime = impossibleStartTime.add(matchReqTime, "m").format("HH:mm");
//       let impossibleAt = "N";

//       // 동일시간 기존 데이터가 불가시간일 경우(수정일경우에만 해당)
//       if (impossibleArray !== undefined && impossibleArray["Y"]?.length > 0) {
//         impossibleArray["Y"].map((impossibleCheckData: any) => {
//           if (impossibleCheckData.startTime === startTime && impossibleCheckData.endTime === endTime && impossibleCheckData.date === subEventDateString) {
//             impossibleAt = "Y";
//           }
//         });
//       }

//       meetingTimeList.push({
//         id: v4(),
//         startTime: startTime,
//         endTime: endTime,
//         impossibleAt: impossibleAt,
//       });

//       impossibleStartTime = impossibleStartTime.add(matchBreakTime, "m");
//     });

//     return meetingTimeList;
//   };

//   // 미팅 데이터 폼 생성
//   const createMeetingTimeForm = useCallback(() => {
//     let meetingDataJson: any = [];
//     // 자정이 넘어갔을경우 계산안함
//     if (subEvent.matchEndTime!.indexOf(":") > 0) {
//       // 서브 이벤트 기간 일수 차
//       const subDateCount = moment(subEvent.subEventEndDate, "YYYY-MM-DD").diff(moment(subEvent.subEventStartDate, "YYYY-MM-DD"), "day") + 1;

//       // 미팅날찌 기준 값
//       let subEventDate = moment(subEvent.subEventStartDate);

//       // 날짜, 미팅시간 가공
//       Array.from({length: subDateCount}, (x, i) => {
//         const subEventDateString = subEventDate.format("YYYY-MM-DD");

//         // 미팅 시간 추가
//         const meetingTimeList: any = meetingTimeSetting(subEventDateString);

//         meetingDataJson.push({
//           date: subEventDateString,
//           time: meetingTimeList,
//         });
//         subEventDate = subEventDate.add(1, "days");
//       });
//     }
//     setSubEvent({
//       ...subEvent,
//       meetingData: JSON.stringify(meetingDataJson),
//       meetingDataJson: meetingDataJson,
//     });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [subEvent.matchEndTime, subEvent.subEventStartDate, subEvent.subEventEndDate]);

//   type impossibleAt = "Y" & "N";
//   const onChangeMeetingTime = (impossibleAt: impossibleAt, dateIndex: number, timeIndex: number) => {
//     setSubEvent(prevState => {
//       let prevMeetingData = prevState.meetingDataJson;
//       if (prevMeetingData !== undefined) {
//         // prevMeetingData[dateIndex].time[timeIndex].impossibleAt = impossibleAt, Object.assign 안돼서 아래방법 사용
//         const newTimeObject = {
//           ...prevMeetingData[dateIndex].time![timeIndex],
//           impossibleAt: impossibleAt,
//         };

//         const newTimeArray: any = [...prevMeetingData[dateIndex].time!.slice(0, timeIndex), newTimeObject, ...prevMeetingData[dateIndex].time!.slice(timeIndex + 1)];

//         prevMeetingData[dateIndex].time = newTimeArray;
//       }
//       return {
//         ...prevState,
//         meetingDataJson: prevMeetingData,
//         meetingData: JSON.stringify(prevMeetingData),
//       };
//     });
//   };

//   // 서브 이벤트 시작일
//   const handleSubEventStartDateChange = (value: string) => {
//     if (false === isDate(value)) return false;

//     const timeDiff = moment(value, "YYYY-MM-DDTHH:mm").diff(
//       moment(parallaxCalculation(subEventDetail!.mainEvent!.startDate, "", countries![Number(subEventDetail!.mainEvent!.countryCode!)]), "YYYY-MM-DDTHH:mm"),
//       "minute"
//     );

//     if (timeDiff < 0) return false;

//     const startDate = value;
//     let endDate = subEvent.subEventEndDate;

//     const returnWorkingPeriod = gmtTimeAdjustment(startDate, endDate, "start");

//     setSubEvent({
//       ...subEvent,
//       subEventStartDate: returnWorkingPeriod.startTime,
//       subEventEndDate: returnWorkingPeriod.endTime,
//     });
//   };

//   // 서브 이벤트 종료일
//   const handleSubEndDateChange = (value: string) => {
//     if (false === isDate(value)) return false;

//     const timeDiff = moment(value, "YYYY-MM-DDTHH:mm").diff(
//       moment(parallaxCalculation(subEventDetail!.mainEvent!.endDate, "", countries![Number(subEventDetail!.mainEvent!.countryCode!)]), "YYYY-MM-DDTHH:mm"),
//       "minute"
//     );

//     if (timeDiff > 0) return false;

//     let startDate = subEvent.subEventStartDate;
//     const endDate = value;

//     const returnWorkingPeriod = gmtTimeAdjustment(startDate, endDate, "end");

//     setSubEvent({
//       ...subEvent,
//       subEventStartDate: returnWorkingPeriod.startTime,
//       subEventEndDate: returnWorkingPeriod.endTime,
//     });
//   };

//   // 이벤트 내용 변경
//   const handleOnChange = (
//     e: React.ChangeEvent<{
//       value: unknown;
//       name: string;
//     }>
//   ) => {
//     const name = e.target.name;
//     const value = e.target.value;
//     console.log(name, "name");
//     console.log(value, "value");
//     setSubEvent(prevState => {
//       if (name && name === "category") {
//         switch (e.target.value) {
//           case "presentation": {
//             return {
//               ...prevState,
//               sgroupName: t("addSubEvent.attendee"),
//               bgroupName: t("addSubEvent.presenter"),
//               matching: "N",
//               [name as string]: e.target.value as string,
//             };
//           }
//           case "consulting": {
//             return {
//               ...prevState,
//               sgroupName: t("addSubEvent.seller"),
//               bgroupName: t("addSubEvent.buyer"),
//               matching: "Y",
//               [name as string]: e.target.value as string,
//             };
//           }
//           case "meeting": {
//             return {
//               ...prevState,
//               sgroupName: t("addSubEvent.seller"),
//               bgroupName: t("addSubEvent.buyer"),
//               matching: "Y",
//               [name as string]: e.target.value as string,
//             };
//           }
//           case "registration": {
//             return {
//               ...prevState,
//               sgroupName: t("addSubEvent.seller"),
//               bgroupName: t("addSubEvent.buyer"),
//               matching: "N",
//               [e.target.name as string]: e.target.value as string,
//             };
//           }
//           default: {
//             return {
//               ...prevState,
//               [name as string]: e.target.value as string,
//             };
//           }
//         }
//       } else {
//         return {
//           ...prevState,
//           [name as string]: value as string,
//         };
//       }
//     });
//   };

//   // 참가그룹A,B 참가신청 기간 조정
//   const handleGroupDateChange = (
//     e: React.ChangeEvent<{
//       value: string;
//       name?: string | undefined;
//     }>
//   ) => {
//     e.preventDefault();

//     if (false === isDate(e.target.value)) return false;

//     const name = e.target.name;
//     const type = name?.includes("sellReg") ? "seller" : "buyer";

//     let returnWorkingPeriod;
//     if (name?.includes("StartDate")) {
//       const startDate = e.target.value;
//       let endDate;
//       if (name.includes("sellReg")) {
//         endDate = subEvent.sellRegEndDate;
//       } else {
//         endDate = subEvent.buyRegEndDate;
//       }
//       returnWorkingPeriod = gmtTimeAdjustment(startDate, endDate, "start");
//     } else {
//       const endDate = e.target.value;
//       let startDate;
//       if (name?.includes("buyReg")) {
//         startDate = subEvent.buyRegStartDate;
//       } else {
//         startDate = subEvent.sellRegStartDate;
//       }
//       returnWorkingPeriod = gmtTimeAdjustment(startDate, endDate, "end");
//     }

//     if (type === "seller") {
//       setSubEvent({
//         ...subEvent,
//         sellRegStartDate: returnWorkingPeriod.startTime,
//         sellRegEndDate: returnWorkingPeriod.endTime,
//       });
//     } else {
//       setSubEvent({
//         ...subEvent,
//         buyRegStartDate: returnWorkingPeriod.startTime,
//         buyRegEndDate: returnWorkingPeriod.endTime,
//       });
//     }
//   };

//   // 이벤트 수정
//   const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();

//     // 서브 이벤트 기간 날짜 가공
//     const saveEventStartDate = parallaxCalculation(subEvent.subEventStartDate, countries![Number(subEventDetail!.mainEvent!.countryCode!)], "");
//     const saveEventEndDate = parallaxCalculation(subEvent.subEventEndDate, countries![Number(subEventDetail!.mainEvent!.countryCode!)], "");

//     let saveSellRegStartDate = "";
//     let saveSellRegEndDate = "";
//     let saveBuyRegStartDate = "";
//     let saveBuyRegEndDate = "";

//     // 셀러 온라인 참가 기간 날짜 가공
//     saveSellRegStartDate = subEvent.sellRegStartDate !== undefined ? parallaxCalculation(subEvent.sellRegStartDate, countries![Number(subEventDetail!.mainEvent!.countryCode!)], "") : "";
//     saveSellRegEndDate = subEvent.sellRegEndDate !== undefined ? parallaxCalculation(subEvent.sellRegEndDate, countries![Number(subEventDetail!.mainEvent!.countryCode!)], "") : "";

//     // 바이어 온라인 참가 기간 날짜 가공
//     saveBuyRegStartDate = subEvent.buyRegStartDate !== undefined ? parallaxCalculation(subEvent.buyRegStartDate, countries![Number(subEventDetail!.mainEvent!.countryCode!)], "") : "";
//     saveBuyRegEndDate = subEvent.buyRegEndDate !== undefined ? parallaxCalculation(subEvent.buyRegEndDate, countries![Number(subEventDetail!.mainEvent!.countryCode!)], "") : "";

//     let param = Object.assign(subEvent, {
//       subEventStartDate: saveEventStartDate,
//       subEventEndDate: saveEventEndDate,
//       sellRegStartDate: saveSellRegStartDate,
//       sellRegEndDate: saveSellRegEndDate,
//       buyRegStartDate: saveBuyRegStartDate,
//       buyRegEndDate: saveBuyRegEndDate,
//     });

//     const subEventRes = await modifySubEvent(param);
//     if (subEventRes) history.push(`/event/detail/${params.id}`);
//   };

//   return subEvent.id !== "" ? (
//     <Container maxWidth="lg" style={{paddingTop: "60px", paddingBottom: "60px"}}>
//       <form onSubmit={onSubmit}>
//         <SubTitle>{t("addSubEvent.subEventType")}</SubTitle>
//         <Grid container direction="row">
//           <Grid sm={12} md={12} lg={12}>
//             {/* 양식데이터가 변경되지 않아 변경 금지 */}
//             <FormControl disabled component="fieldset">
//               <RadioGroup aria-label="category" name="category" row value={subEvent.category} onChange={handleOnChange}>
//                 {category != null && category.map((gb, index) => <FormControlLabel key={`category-${index}`} value={gb.code} control={<Radio />} label={gb.value} />)}
//               </RadioGroup>
//             </FormControl>
//           </Grid>
//         </Grid>

//         <SubTitle>{t("addSubEvent.nameOfParicipatingGroup")}</SubTitle>
//         <Grid container direction="row">
//           <Grid sm={6} md={6} lg={6} style={{paddingRight: 10}}>
//             <SubHeadingTypo>{`${t("addSubEvent.participantA")}${subEvent.category === "presentation" ? `(${t("addSubEvent.attendee")})` : `(${t("addSubEvent.seller")})`}`}</SubHeadingTypo>
//             <OutlinedTxtField name="sgroupName" value={subEvent.sgroupName} fullWidth onChange={handleOnChange} placeholder={t("addSubEvent.participantA")} />
//           </Grid>
//           <Grid sm={6} md={6} lg={6} style={{paddingRight: 10}}>
//             <SubHeadingTypo>{`${t("addSubEvent.participantB")}${subEvent.category === "presentation" ? `(${t("addSubEvent.presenter")})` : `(${t("addSubEvent.buyer")})`}`}</SubHeadingTypo>
//             <OutlinedTxtField name="bgroupName" value={subEvent.bgroupName} fullWidth onChange={handleOnChange} placeholder={t("addSubEvent.participantB")} />
//           </Grid>
//         </Grid>

//         {subEvent.matching === "Y" && (
//           <>
//             <SubTitle>{t("addSubEvent.exposureRange")}</SubTitle>
//             <SubHeadingTypo>{t("addSubEvent.theTargetSetting")}</SubHeadingTypo>
//             <Grid container direction="row">
//               <Grid sm={12} md={12} lg={12}>
//                 <FormControl component="fieldset">
//                   <RadioGroup aria-label="targetScope" name="targetScope" row value={subEvent.targetScope} onChange={handleOnChange}>
//                     {targetScope != null && targetScope.map((ts, index) => <FormControlLabel key={`targetScope-${index}`} value={ts.code} control={<Radio />} label={ts.value} />)}
//                   </RadioGroup>
//                 </FormControl>
//               </Grid>
//             </Grid>
//           </>
//         )}

//         <SubTitle>{t("addSubEvent.subEventPeriod")}</SubTitle>
//         <Grid style={{display: "flex", alignItems: "center"}}>
//           <TextField
//             variant="outlined"
//             id="datetime-local"
//             label={t("addSubEvent.start")}
//             type="datetime-local"
//             value={subEvent.subEventStartDate}
//             name="showStartDate"
//             disabled={subEvent.mainEvent?.approval === "Y"}
//             onChange={e => handleSubEventStartDateChange(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             inputProps={{
//               style: {padding: "10.5px 0 10.5px 10px", margin: 0},
//             }}
//             InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
//           />
//           <div style={{padding: "0 10px"}}>-</div>
//           <TextField
//             variant="outlined"
//             id="datetime-local"
//             label={t("addSubEvent.end")}
//             type="datetime-local"
//             value={subEvent.subEventEndDate}
//             name="showEndDate"
//             disabled={subEvent.mainEvent?.approval === "Y"}
//             onChange={e => handleSubEndDateChange(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             inputProps={{
//               style: {padding: "10.5px 0 10.5px 10px", margin: 0},
//             }}
//             InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
//           />
//         </Grid>

//         {/* 참가그룹 A, B 참가신청 기간 */}
//         {subEvent.category !== "registration" && (
//           <div>
//             <SubTitle>
//               {/*셀러*/}
//               {subEvent.sgroupName} {t("addSubEvent.sApplyPeriod")}
//             </SubTitle>
//             <Grid style={{display: "flex", alignItems: "center"}}>
//               <TextField
//                 variant="outlined"
//                 id="datetime-local"
//                 label={`${subEvent.sgroupName} ${t("addSubEvent.start")}`} // 셀러
//                 type="datetime-local"
//                 value={subEvent.sellRegStartDate}
//                 name="sellRegStartDate"
//                 onChange={handleGroupDateChange}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 inputProps={{
//                   style: {padding: "10.5px 0 10.5px 10px", margin: 0},
//                 }}
//                 InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
//               />
//               <div style={{padding: "0 10px"}}>-</div>
//               <TextField
//                 variant="outlined"
//                 id="datetime-local"
//                 label={`${subEvent.sgroupName} ${t("addSubEvent.end")}`} // 셀러
//                 type="datetime-local"
//                 value={subEvent.sellRegEndDate}
//                 name="sellRegEndDate"
//                 onChange={handleGroupDateChange}
//                 // onChange={(e) => handleSellRegEndDateChange(e.target.value)}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 inputProps={{
//                   style: {padding: "10.5px 0 10.5px 10px", margin: 0},
//                 }}
//                 InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
//               />
//             </Grid>

//             <SubTitle>
//               {subEvent.bgroupName} {t("addSubEvent.bApplyPeriod")}
//             </SubTitle>
//             {/* 바이어 */}
//             <Grid style={{display: "flex", alignItems: "center"}}>
//               <TextField
//                 variant="outlined"
//                 id="datetime-local"
//                 label={`${subEvent.bgroupName} ${t("addSubEvent.start")}`} // 바이어
//                 type="datetime-local"
//                 value={subEvent.buyRegStartDate}
//                 name="buyRegStartDate"
//                 onChange={handleGroupDateChange}
//                 // onChange={(e) => handleBuyRegStartDateChange(e.target.value)}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 inputProps={{
//                   style: {padding: "10.5px 0 10.5px 10px", margin: 0},
//                 }}
//                 InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
//               />
//               <div style={{padding: "0 10px"}}>-</div>
//               <TextField
//                 variant="outlined"
//                 id="datetime-local"
//                 label={`${subEvent.bgroupName} ${t("addSubEvent.end")}`} //바이어
//                 type="datetime-local"
//                 value={subEvent.buyRegEndDate}
//                 name="buyRegEndDate"
//                 onChange={handleGroupDateChange}
//                 // onChange={(e) => handleBuyRegEndDateChange(e.target.value)}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 inputProps={{
//                   style: {padding: "10.5px 0 10.5px 10px", margin: 0},
//                 }}
//                 InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
//               />
//             </Grid>
//           </div>
//         )}
//         {subEvent.matching === "Y" ? (
//           <Matched subEvent={subEvent} setSubEvent={setSubEvent} handleOnChange={handleOnChange} onChangeMeetingTime={onChangeMeetingTime} disabled={subEvent.mainEvent?.approval === "Y"} />
//         ) : (
//           <></>
//           // subEvent.category !== "presentation" && (
//           //   <Mismatch
//           //     subEvent={subEvent}
//           //     setSubEvent={setSubEvent}
//           //     handleOnChange={handleOnChange}
//           //   />
//           // )
//         )}
//         <Box height={20} />
//         <Button
//           type="submit"
//           style={{
//             boxShadow: "none",
//             backgroundColor: "#5f4b8b",
//             color: "white",
//             padding: "9px 16px",
//           }}
//         >
//           {t("common.save")}
//         </Button>
//         <Button
//           style={{
//             boxShadow: "none",
//             border: "none",
//             backgroundColor: "transparent",
//             padding: "9px 16px",
//             marginLeft: 10,
//           }}
//           onClick={() => history.goBack()}
//         >
//           {t("common.cancel")}
//         </Button>
//       </form>
//     </Container>
//   ) : null;
// };

// interface DispatchProps {
//   modifySubEvent: (subEvent: SubEventInfo) => any;
// }

// interface reduxStateProps {
//   event: Event["event"];
// }

// const mapStateToProps = (state: AppState): reduxStateProps => ({
//   event: state.events.event,
// });

// const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
//   modifySubEvent: bindActionCreators(modifySubEvent, dispatch),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(SubEventModify);

import React, {useState, useEffect, useCallback} from "react";
import Container from "@material-ui/core/Container";
import {ThunkDispatch} from "redux-thunk";
import {connect, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {useHistory, useParams} from "react-router-dom";

// types
import {SubEventInfo} from "../../types/models/SubEvent";

// actions
import {AppActions, AppState} from "../../store";
import {modifySubEvent, getSubEvent, removeSubEventAction} from "../../actions/subEvent";

// custom ui comp

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import {TypographyProps} from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";

import styled from "styled-components";
import moment from "moment";
import Event from "../../types/models/Event";
import Matched from "./Matched";
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";

// import Mismatch from "./Mismatch";
import uuid from "uuid";
import {gmtTimeAdjustment, parallaxCalculation} from "../../utils/momentTz";
import {TextField} from "@material-ui/core";
import {isDate} from "../../utils/utils";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import {getMeetings} from "../../actions/meeting";
import {newAlert} from "../../actions/alerts";
import OutlinedTxtField from "../../components/Inputs/OutlinedTxtField";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const {...rest} = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

type DisProps = DispatchProps & reduxStateProps;

const SubEventModify: React.FC<DisProps> = props => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const groupBy = require("json-groupby");
  const {modifySubEvent, event} = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const params: {id: string; secondId: string} = useParams();
  const subEventDetail = useSelector((state: AppState) => state.subEvents.subEvent);
  const countries = useSelector((state: AppState) => state.countries.getCountries);
  const [disabledTime, setDisabledTime] = useState({});
  const now = moment().format("YYYY-MM-DDTHH:mm");
  // const [eventState, setEventState] = useState<Event["event"]>(event);
  const [subEvent, setSubEvent] = useState<SubEventInfo>({
    id: "",
    eventId: params.id, // event key
    matching: "Y", // 매칭 여부 (Y/N)
    sgroupName: "", // 참가그룹 A
    bgroupName: "", // 참가그룹 B
    targetScope: "", // 참가기업 노출범위
    subEventStartDate: now, // 서브 이벤트 시작날짜
    subEventEndDate: now, // 서브 이벤트 끝날짜
    sellRegStartDate: now, // 셀러 참가신청 시작날짜
    sellRegEndDate: now, // 셀러 참가신청 끝날짜
    buyRegStartDate: now, // 바이어 참가신청 시작날짜
    buyRegEndDate: now, // 바이어 참가신청 끝날짜
    regStartDate: "", // 참가자 참가신청 시작날짜
    regEndDate: "", // 참가자 참가신청 끝날짜
    matchStartTime: "09:00", // 매칭(미팅) 시작시간
    matchEndTime: "09:00", // 매칭(미팅) 종료시간
    matchReqTime: 0, // 매칭(미팅) 소요시간
    matchBreakTime: 0, // 매칭(미팅) 휴식시간
    matchNumPerDay: 0, // 일일 매칭(미팅) 횟수
    session: "N", // 세션 여부 (Y/N)
    category: "exhibition", // 구분
    meetingData: "[]", // 미팅 시간 양식 폼
    meetingDataJson: [], // 미팅 시간 양식 폼 json
    // 매치메이킹 없음
    boothCompany: "N", // 부스업체 여부 (Y/N)
    boothCompanyStartDate: now, // 부스업체 시작날짜
    boothCompanyEndDate: now, // 부스업체 끝날짜
    preRegit: "N", // 사전등록 참관객 여부 (Y/N)
    preRegitStartDate: now, // 사전등록 시작날짜
    preRegitEndDate: now, // 사전등록 끝날짜
    onSiteRegit: "N", // 현장등록 참관객 여부 (Y/N)
    approval: "Y",
  });

  const targetScope = [
    {code: "", value: t("common.all")},
    // { code: "others", value: "상대기업(바이어: 셀러만, 셀러: 바이어만)" },
    {
      code: "others",
      value: t("addSubEvent.counterparts"),
    },
  ];

  // 이후 카테고리 메뉴 만들때 등록예정
  const category = [
    // { code: "exhibition", value: "전시" },
    // { code: "conference", value: "회의" },
    // { code: "meeting", value: "미팅" },
    {code: "consulting", value: t("addSubEvent.consultation")},
    {code: "presentation", value: t("addSubEvent.presentation")},
    {code: "registration", value: t("addSubEvent.registration")},
  ];

  // 이벤트 정보 조회 (매칭기간을 이벤트기간에 넘지않도록)
  useEffect(() => {
    dispatch(getSubEvent(parseInt(params.secondId)));
    dispatch(getMeetings(params.secondId));
    return () => {
      dispatch(removeSubEventAction());
    };
  }, []);

  useEffect(() => {
    if (subEventDetail !== undefined && subEventDetail!.mainEvent !== undefined) {
      if (
        subEventDetail.mainEvent?.approval === "D"
        // || subEventDetail.mainEvent?.approval === "Y"
      ) {
        alert("This is the worng address");
        history.push("/");
      }
      const countryCode = subEventDetail!.mainEvent!.countryCode!;
      subEventDetail.impossibleDateAndTime && setDisabledTime(JSON.parse(subEventDetail.impossibleDateAndTime));

      setSubEvent({
        ...subEventDetail,
        subEventStartDate: parallaxCalculation(subEventDetail.subEventStartDate.substr(0, 16), "", countries![countryCode] as any),
        subEventEndDate: parallaxCalculation(subEventDetail.subEventEndDate.substr(0, 16), "", countries![countryCode] as any),
        sellRegStartDate: subEventDetail.sellRegStartDate !== undefined ? parallaxCalculation(subEventDetail.sellRegStartDate.substr(0, 16), "", countries![countryCode] as any) : "",
        sellRegEndDate: subEventDetail.sellRegEndDate !== undefined ? parallaxCalculation(subEventDetail.sellRegEndDate.substr(0, 16), "", countries![countryCode] as any) : "",
        buyRegStartDate: subEventDetail.buyRegStartDate !== undefined ? parallaxCalculation(subEventDetail.buyRegStartDate.substr(0, 16), "", countries![countryCode] as any) : "",
        buyRegEndDate: subEventDetail.buyRegEndDate !== undefined ? parallaxCalculation(subEventDetail.buyRegEndDate.substr(0, 16), "", countries![countryCode] as any) : "",
        meetingDataJson: JSON.parse(subEventDetail.meetingData!),
      });
    }
  }, [subEventDetail]);

  // 미팅 예상 종료 시간 계산
  useEffect(() => {
    const matchReqTime = subEvent.matchReqTime as number; // 미팅소요시간
    const matchBreakTime = subEvent.matchBreakTime as number; // 휴식 횟수
    const matchNumPerDay = subEvent.matchNumPerDay as number; // 일일 미팅 횟수

    if (matchReqTime !== 0 && matchNumPerDay !== 0) {
      const countMinute = matchNumPerDay * matchReqTime + (matchNumPerDay * matchBreakTime - matchBreakTime);

      // 미팅시작 기준 날짜
      const defaultDate = `2020-01-01 ${subEvent.matchStartTime}:00`;
      // 미팅 계산 날짜
      const endTimeCalculation = moment(defaultDate).add(countMinute, "m");

      // 위 결과에 따른 문구
      let matchEndTime = endTimeCalculation.format("HH:mm");

      setSubEvent({
        ...subEvent,
        matchEndTime: matchEndTime,
      });
    } else {
      setSubEvent({...subEvent, matchEndTime: subEvent.matchStartTime});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent.matchStartTime, subEvent.matchReqTime, subEvent.matchBreakTime, subEvent.matchNumPerDay]);

  // 미팅 데이터 폼 생성
  // const createMeetingTimeForm = useCallback(() => {
  //   let meetingDataJson: any = [];
  //   // 자정이 넘어갔을경우 계산안함
  //   if (subEvent.matchEndTime!.indexOf(":") > 0) {
  //     // 서브 이벤트 기간 일수 차
  //     const subDateCount =
  //       moment(subEvent.subEventEndDate, "YYYY-MM-DD").diff(
  //         moment(subEvent.subEventStartDate, "YYYY-MM-DD"),
  //         "day"
  //       ) + 1;

  //     // 미팅날찌 기준 값
  //     let subEventDate = moment(subEvent.subEventStartDate);

  //     // 날짜, 미팅시간 가공
  //     Array.from({ length: subDateCount }, (x, i) => {
  //       const subEventDateString = subEventDate.format("YYYY-MM-DD");

  //       // 미팅 시간 추가
  //       const meetingTimeList: any = meetingTimeSetting(subEventDateString);

  //       meetingDataJson.push({
  //         date: subEventDateString,
  //         time: meetingTimeList,
  //       });
  //       subEventDate = subEventDate.add(1, "days");
  //     });
  //   }
  //   setSubEvent({
  //     ...subEvent,
  //     meetingData: JSON.stringify(meetingDataJson),
  //     meetingDataJson: meetingDataJson,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [subEvent.matchEndTime, subEvent.subEventStartDate, subEvent.subEventEndDate]);

  type impossibleAt = "Y" & "N";
  const onChangeMeetingTime = (impossibleAt: impossibleAt, dateIndex: number, timeIndex: number) => {
    setSubEvent(prevState => {
      let prevMeetingData = prevState.meetingDataJson;
      if (prevMeetingData !== undefined) {
        // prevMeetingData[dateIndex].time[timeIndex].impossibleAt = impossibleAt, Object.assign 안돼서 아래방법 사용
        const newTimeObject = {
          ...prevMeetingData[dateIndex].time![timeIndex],
          impossibleAt: impossibleAt,
        };

        const newTimeArray: any = [...prevMeetingData[dateIndex].time!.slice(0, timeIndex), newTimeObject, ...prevMeetingData[dateIndex].time!.slice(timeIndex + 1)];

        prevMeetingData[dateIndex].time = newTimeArray;
      }
      return {
        ...prevState,
        meetingDataJson: prevMeetingData,
        meetingData: JSON.stringify(prevMeetingData),
      };
    });
  };

  // 서브 이벤트 시작일
  const handleSubEventStartDateChange = (value: string) => {
    if (false === isDate(value)) return false;

    const timeDiff = moment(value, "YYYY-MM-DDTHH:mm").diff(
      moment(parallaxCalculation(subEventDetail!.mainEvent!.startDate, "", countries![subEventDetail!.mainEvent!.countryCode!] as any), "YYYY-MM-DDTHH:mm"),
      "minute"
    );
    if (timeDiff < 0) {
      dispatch(newAlert("서브이벤트 기간은 이벤트기간내에서 선택 가능합니다.", subEventDetail!.id!, "warning"));
      return false;
    }

    const startDate = value;
    let endDate = subEvent.subEventEndDate;

    const returnWorkingPeriod = gmtTimeAdjustment(startDate, endDate, "start");

    setSubEvent({
      ...subEvent,
      subEventStartDate: returnWorkingPeriod.startTime,
      subEventEndDate: returnWorkingPeriod.endTime,
    });
  };

  // 서브 이벤트 종료일
  const handleSubEndDateChange = (value: string) => {
    if (false === isDate(value)) return false;

    const timeDiff = moment(value, "YYYY-MM-DDTHH:mm").diff(
      moment(parallaxCalculation(subEventDetail!.mainEvent!.endDate, "", countries![subEventDetail!.mainEvent!.countryCode!] as any), "YYYY-MM-DDTHH:mm"),
      "minute"
    );
    if (timeDiff > 0) {
      dispatch(newAlert("서브이벤트 기간은 이벤트기간내에서 선택 가능합니다.", subEventDetail!.id!, "warning"));
      return false;
    }

    let startDate = subEvent.subEventStartDate;
    const endDate = value;
    const returnWorkingPeriod = gmtTimeAdjustment(startDate, endDate, "end");
    setSubEvent({
      ...subEvent,
      subEventStartDate: returnWorkingPeriod.startTime,
      subEventEndDate: returnWorkingPeriod.endTime,
    });
  };

  // 이벤트 내용 변경
  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name: string;
    }>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    setSubEvent(prevState => {
      if (name && name === "category") {
        switch (e.target.value) {
          case "presentation": {
            return {
              ...prevState,
              sgroupName: t("addSubEvent.attendee"),
              bgroupName: t("addSubEvent.presenter"),
              matching: "N",
              [name as string]: e.target.value as string,
            };
          }
          case "consulting": {
            return {
              ...prevState,
              sgroupName: t("addSubEvent.seller"),
              bgroupName: t("addSubEvent.buyer"),
              matching: "Y",
              [name as string]: e.target.value as string,
            };
          }
          case "meeting": {
            return {
              ...prevState,
              sgroupName: t("addSubEvent.seller"),
              bgroupName: t("addSubEvent.buyer"),
              matching: "Y",
              [name as string]: e.target.value as string,
            };
          }
          case "registration": {
            return {
              ...prevState,
              sgroupName: t("addSubEvent.seller"),
              bgroupName: t("addSubEvent.buyer"),
              matching: "N",
              [e.target.name as string]: e.target.value as string,
            };
          }
          default: {
            return {
              ...prevState,
              [name as string]: e.target.value as string,
            };
          }
        }
      } else {
        return {
          ...prevState,
          [name as string]: value as string,
        };
      }
    });
  };

  // 참가그룹A,B 참가신청 기간 조정
  const handleGroupDateChange = (
    e: React.ChangeEvent<{
      value: string;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();

    if (false === isDate(e.target.value)) return false;

    const name = e.target.name;
    const type = name?.includes("sellReg") ? "seller" : "buyer";

    let returnWorkingPeriod;
    if (name?.includes("StartDate")) {
      const startDate = e.target.value;
      let endDate;
      if (name.includes("sellReg")) {
        endDate = subEvent.sellRegEndDate;
      } else {
        endDate = subEvent.buyRegEndDate;
      }
      returnWorkingPeriod = gmtTimeAdjustment(startDate, endDate, "start");
    } else {
      const endDate = e.target.value;
      let startDate;
      if (name?.includes("buyReg")) {
        startDate = subEvent.buyRegStartDate;
      } else {
        startDate = subEvent.sellRegStartDate;
      }
      returnWorkingPeriod = gmtTimeAdjustment(startDate, endDate, "end");
    }

    if (type === "seller") {
      setSubEvent({
        ...subEvent,
        sellRegStartDate: returnWorkingPeriod.startTime,
        sellRegEndDate: returnWorkingPeriod.endTime,
      });
    } else {
      setSubEvent({
        ...subEvent,
        buyRegStartDate: returnWorkingPeriod.startTime,
        buyRegEndDate: returnWorkingPeriod.endTime,
      });
    }
  };

  // 이벤트 수정
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // 서브 이벤트 기간 날짜 가공
    const saveEventStartDate = parallaxCalculation(subEvent.subEventStartDate, countries![subEventDetail!.mainEvent!.countryCode!] as any, "");
    const saveEventEndDate = parallaxCalculation(subEvent.subEventEndDate, countries![subEventDetail!.mainEvent!.countryCode!] as any, "");

    let saveSellRegStartDate = "";
    let saveSellRegEndDate = "";
    let saveBuyRegStartDate = "";
    let saveBuyRegEndDate = "";
    // if (subEvent.matching === "Y") {
    // 셀러 온라인 참가 기간 날짜 가공
    saveSellRegStartDate = subEvent.sellRegStartDate !== undefined ? parallaxCalculation(subEvent.sellRegStartDate, countries![subEventDetail!.mainEvent!.countryCode!] as any, "") : "";
    saveSellRegEndDate = subEvent.sellRegEndDate !== undefined ? parallaxCalculation(subEvent.sellRegEndDate, countries![subEventDetail!.mainEvent!.countryCode!] as any, "") : "";

    // 바이어 온라인 참가 기간 날짜 가공
    saveBuyRegStartDate = subEvent.buyRegStartDate !== undefined ? parallaxCalculation(subEvent.buyRegStartDate, countries![subEventDetail!.mainEvent!.countryCode!] as any, "") : "";
    saveBuyRegEndDate = subEvent.buyRegEndDate !== undefined ? parallaxCalculation(subEvent.buyRegEndDate, countries![subEventDetail!.mainEvent!.countryCode!] as any, "") : "";
    // }

    const data: SubEventInfo = {
      ...subEvent,
      impossibleDateAndTime: JSON.stringify(disabledTime),
    };
    let param = Object.assign(data, {
      subEventStartDate: saveEventStartDate,
      subEventEndDate: saveEventEndDate,
      sellRegStartDate: saveSellRegStartDate,
      sellRegEndDate: saveSellRegEndDate,
      buyRegStartDate: saveBuyRegStartDate,
      buyRegEndDate: saveBuyRegEndDate,
    });

    const subEventRes = await modifySubEvent(param);
    if (subEventRes) history.push(`/event/detail/${params.id}`);
  };

  return subEvent.id !== "" ? (
    <Container maxWidth="lg" style={{paddingTop: "60px", paddingBottom: "60px"}}>
      <form onSubmit={onSubmit}>
        <SubTitle>{t("addSubEvent.subEventType")}</SubTitle>
        <Grid container direction="row">
          <Grid sm={12} md={12} lg={12}>
            {/* 양식데이터가 변경되지 않아 변경 금지 */}
            <FormControl disabled component="fieldset">
              <RadioGroup aria-label="category" name="category" row value={subEvent.category} onChange={handleOnChange}>
                {category != null && category.map((gb, index) => <FormControlLabel key={`category-${index}`} value={gb.code} control={<Radio />} label={gb.value} />)}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <SubTitle>{t("addSubEvent.nameOfParicipatingGroup")}</SubTitle>
        <Grid container direction="row">
          <Grid sm={6} md={6} lg={6} style={{paddingRight: 10}}>
            <SubHeadingTypo>{`${t("addSubEvent.participantA")}${subEvent.category === "presentation" ? `(${t("addSubEvent.attendee")})` : `(${t("addSubEvent.seller")})`}`}</SubHeadingTypo>
            <OutlinedTxtField name="sgroupName" value={subEvent.sgroupName} fullWidth onChange={handleOnChange} placeholder={t("addSubEvent.participantA")} />
          </Grid>
          <Grid sm={6} md={6} lg={6} style={{paddingRight: 10}}>
            <SubHeadingTypo>{`${t("addSubEvent.participantB")}${subEvent.category === "presentation" ? `(${t("addSubEvent.presenter")})` : `(${t("addSubEvent.buyer")})`}`}</SubHeadingTypo>
            <OutlinedTxtField name="bgroupName" value={subEvent.bgroupName} fullWidth onChange={handleOnChange} placeholder={t("addSubEvent.participantB")} />
          </Grid>
        </Grid>

        {subEvent.matching === "Y" && (
          <>
            <SubTitle>{t("addSubEvent.exposureRange")}</SubTitle>
            <SubHeadingTypo>{t("addSubEvent.theTargetSetting")}</SubHeadingTypo>
            <Grid container direction="row">
              <Grid sm={12} md={12} lg={12}>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="targetScope" name="targetScope" row value={subEvent.targetScope} onChange={handleOnChange}>
                    {targetScope != null && targetScope.map((ts, index) => <FormControlLabel key={`targetScope-${index}`} value={ts.code} control={<Radio />} label={ts.value} />)}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}

        <SubTitle>{t("addSubEvent.subEventPeriod")}</SubTitle>
        <Grid style={{display: "flex", alignItems: "center"}}>
          <TextField
            variant="outlined"
            id="datetime-local"
            label={t("addSubEvent.start")}
            type="datetime-local"
            value={subEvent.subEventStartDate}
            name="showStartDate"
            disabled={subEvent.mainEvent?.approval === "Y"}
            onChange={e => handleSubEventStartDateChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {padding: "10.5px 0 10.5px 10px", margin: 0},
            }}
            InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
          />
          <div style={{padding: "0 10px"}}>-</div>
          <TextField
            variant="outlined"
            id="datetime-local"
            label={t("addSubEvent.end")}
            type="datetime-local"
            value={subEvent.subEventEndDate}
            name="showEndDate"
            disabled={subEvent.mainEvent?.approval === "Y"}
            onChange={e => handleSubEndDateChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {padding: "10.5px 0 10.5px 10px", margin: 0},
            }}
            InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
          />
        </Grid>

        {/* 참가그룹 A, B 참가신청 기간 */}
        {subEvent.category !== "registration" && (
          <div>
            <SubTitle>
              {/*셀러*/}
              {subEvent.sgroupName} {t("addSubEvent.sApplyPeriod")}
            </SubTitle>
            <Grid style={{display: "flex", alignItems: "center"}}>
              <TextField
                variant="outlined"
                id="datetime-local"
                label={`${subEvent.sgroupName} ${t("addSubEvent.start")}`} // 셀러
                type="datetime-local"
                value={subEvent.sellRegStartDate}
                name="sellRegStartDate"
                onChange={handleGroupDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {padding: "10.5px 0 10.5px 10px", margin: 0},
                }}
                InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
              />
              <div style={{padding: "0 10px"}}>-</div>
              <TextField
                variant="outlined"
                id="datetime-local"
                label={`${subEvent.sgroupName} ${t("addSubEvent.end")}`} // 셀러
                type="datetime-local"
                value={subEvent.sellRegEndDate}
                name="sellRegEndDate"
                onChange={handleGroupDateChange}
                // onChange={(e) => handleSellRegEndDateChange(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {padding: "10.5px 0 10.5px 10px", margin: 0},
                }}
                InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
              />
            </Grid>

            <SubTitle>
              {subEvent.bgroupName} {t("addSubEvent.bApplyPeriod")}
            </SubTitle>
            {/* 바이어 */}
            <Grid style={{display: "flex", alignItems: "center"}}>
              <TextField
                variant="outlined"
                id="datetime-local"
                label={`${subEvent.bgroupName} ${t("addSubEvent.start")}`} // 바이어
                type="datetime-local"
                value={subEvent.buyRegStartDate}
                name="buyRegStartDate"
                onChange={handleGroupDateChange}
                // onChange={(e) => handleBuyRegStartDateChange(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {padding: "10.5px 0 10.5px 10px", margin: 0},
                }}
                InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
              />
              <div style={{padding: "0 10px"}}>-</div>
              <TextField
                variant="outlined"
                id="datetime-local"
                label={`${subEvent.bgroupName} ${t("addSubEvent.end")}`} //바이어
                type="datetime-local"
                value={subEvent.buyRegEndDate}
                name="buyRegEndDate"
                onChange={handleGroupDateChange}
                // onChange={(e) => handleBuyRegEndDateChange(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {padding: "10.5px 0 10.5px 10px", margin: 0},
                }}
                InputProps={{style: {padding: "0 10px 0 0", margin: 0}}} // readOnly: true
              />
            </Grid>
          </div>
        )}
        {subEvent.matching === "Y" ? (
          <Matched
            subEvent={subEvent}
            event={event}
            setSubEvent={setSubEvent}
            disabledTime={disabledTime}
            setDisabledTime={setDisabledTime}
            handleOnChange={handleOnChange}
            onChangeMeetingTime={onChangeMeetingTime}
            disabled={subEvent.mainEvent?.approval === "Y"}
          />
        ) : (
          <></>
          // subEvent.category !== "presentation" && (
          //   <Mismatch
          //     subEvent={subEvent}
          //     setSubEvent={setSubEvent}
          //     handleOnChange={handleOnChange}
          //   />
          // )
        )}
        <Box height={20} />
        <Button
          type="submit"
          style={{
            boxShadow: "none",
            backgroundColor: "#5f4b8b",
            color: "white",
            padding: "9px 16px",
          }}
        >
          {t("common.save")}
        </Button>
        <Button
          style={{
            boxShadow: "none",
            border: "none",
            backgroundColor: "transparent",
            padding: "9px 16px",
            marginLeft: 10,
          }}
          onClick={() => history.goBack()}
        >
          {t("common.cancel")}
        </Button>
      </form>
    </Container>
  ) : null;
};

interface DispatchProps {
  modifySubEvent: (subEvent: SubEventInfo) => any;
}

interface reduxStateProps {
  event: Event["event"];
}

const mapStateToProps = (state: AppState): reduxStateProps => ({
  event: state.events.event,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>): DispatchProps => ({
  modifySubEvent: bindActionCreators(modifySubEvent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubEventModify);
