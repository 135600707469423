import {Box, Button, MenuItem, Select, TextField} from "@material-ui/core";
import {makeStyles, createStyles} from "@material-ui/styles";
import React, {useEffect, useState} from "react";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxTable, {StyledDiv} from "../../components/Tables/PxTable";
import {Column} from "material-table";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setTableInfoLoadingAction} from "../../actions/tableInfo";
// import { RowType } from "../UserRegit/SpreadSheet";

const useStyles = makeStyles(() =>
  createStyles({
    keywordWrap: {
      display: "flex",
      justifyContent: "space-between",
      padding: "2.5rem",
    },
    keywordInput: {
      width: "calc(100% - 20rem)",
    },
    keywordSaveBtn: {
      width: "7.5rem",
    },
    inputSelect: {
      width: "7.5rem",
    },
  })
);

interface RowType {
  id: number;
  keyword: string;
  eventList: eventListItem[];
}

interface eventListItem {
  id: number;
  eventTitle: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const KeywordDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const [inputType, setInputType] = useState<string>("search");
  const [inputValue, setInputValue] = useState<string>();
  const dispatch = useDispatch();

  const [state, setState] = useState<TableStateType>({
    columns: [
      {
        title: "no",
        field: "id",
        cellStyle: {
          width: "150px",
        },
      },
      {
        title: "키워드",
        field: "keyword",
        cellStyle: {
          width: "300px",
        },
      },
      {
        title: "등록된 이벤트 리스트",
        field: "eventList",
        cellStyle: {
          width: "300px",
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    (async () => {
      getKeywordList("");
    })();
  }, []);

  const getKeywordList = async (keyword: any) => {
    const res = await axios.get<RowType[]>("/api/keyword/keywordEvent", {params: {keyword: keyword}});

    const tableData: RowType[] = res.data.map(item => {
      const rowData: RowType = {
        id: item.id,
        keyword: item.keyword,
        eventList: item.eventList,
      };

      return rowData;
    });

    setState({...state, data: tableData});
    dispatch(setTableInfoLoadingAction(false));
  };

  const inputTypeHandle = (event: React.ChangeEvent<{value: unknown}>) => {
    const selectValue = event.target.value as string;
    setInputType(selectValue);
  };

  const keywordOnChange = (event: React.ChangeEvent<{value: unknown}>) => {
    const changeValue = event.target.value as string;
    setInputValue(changeValue);
  };
  const keywordKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == "Enter") {
      buttonOnClick();
    }
  };

  const buttonOnClick = async () => {
    // console.log(`keywordList : `, keywordArray);
    if (inputType === "save") {
      const keywordArray = inputValue?.split(",");
      const res = await axios.post("/api/keyword/adminRegist", {keyword: keywordArray});
      if (res.status == 200) {
        alert("등록되었습니다.");
        getKeywordList("");
      } else {
        alert("이미 등록된 키워드입니다.");
      }
    } else {
      getKeywordList(inputValue);
    }
    setInputValue("");
  };

  //   useEffect(() => {}, []);
  return (
    <>
      <Box className={classes.keywordWrap}>
        <Select defaultValue={inputType} className={classes.inputSelect} value={inputType} onChange={e => inputTypeHandle(e)}>
          <MenuItem value="search">검색</MenuItem>
          <MenuItem value="save">저장</MenuItem>
        </Select>
        <TextField variant="outlined" value={inputValue} label="키워드를 입력해주세요" className={classes.keywordInput} onKeyPress={keywordKeyDown} onChange={keywordOnChange} />

        <Button variant="contained" className={classes.keywordSaveBtn} onClick={buttonOnClick}>
          저장
        </Button>
      </Box>

      <PxGridContainer parent={true}>
        {/* <StyledDiv> */}
        <PxTable<RowType>
          title={<h1>키워드</h1>}
          columns={state.columns}
          data={state.data}
          onRowClick={(event, rowData) => {
            history.push(`/keyword/detail/${rowData?.id}`);
          }}
        />
        {/* </StyledDiv> */}
      </PxGridContainer>
    </>
  );
};

export default KeywordDetail;
