import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import React, { CSSProperties } from "react";

interface PxSwitchButtonProps {
  checked?: boolean;
  label?: string | JSX.Element;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
}
type Prop = PxSwitchButtonProps & SwitchProps;

const PxPurpleSwitch = withStyles({
  switchBase: {
    label: { marginBottom: 0 },
    "&$checked": {
      color: "rgb(81, 45, 168)",
    },
    "&$checked + $track": {
      backgroundColor: "rgb(81, 45, 168)",
    },
  },

  checked: {},
  track: {},
})(Switch);

const PxSwitch: React.FC<Prop> = (props) => {
  const { disabled, checked, onChange, label, className, style } = props;
  return (
    <FormControlLabel
      style={style}
      disabled={disabled}
      className={className}
      control={<PxPurpleSwitch checked={checked} onChange={onChange} name="checkedA" />}
      label={label}
    />
  );
};

export default PxSwitch;
