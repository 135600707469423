import Country from "../types/models/Country";
import { SubEventInfo } from "../types/models/SubEvent";
import { parallaxCalculation } from "./momentTz";
import moment from "moment";

export const currentCountryTime = (
  subEvent: SubEventInfo,
  date:
    | "subEventStartDate"
    | "subEventEndDate"
    | "mainStartDate"
    | "mainEndDate"
) => {
  // const countryCode = subEvent?.mainEvent?.countryCode ?? "KR";
  // const { country, countries } = selector((state: AppState) => state.countries);
  let time;
  if (date === "mainStartDate") {
    time = subEvent!.mainEvent!.startDate;
  } else if (date === "mainEndDate") {
    time = subEvent!.mainEvent!.endDate;
  } else {
    time = subEvent[date];
  }
  const subEventStartTime = moment(time).format("YYYY-MM-DDTHH:mm");

  return subEventStartTime as string;
};
