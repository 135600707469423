import React, { useEffect } from "react";
import PexpoLogo from "../../assets/images/logo.png";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TitleTypo from "../../components/Typography/TitleTypo";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import PxSelectBox from "../../components/SelectBox/PxSelectBox";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { getPrinterAction, getPrinters } from "../../actions/printers";
import { AppState } from "../../store";
import Container from "@material-ui/core/Container";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import { removeAlertAction, setAlertAction } from "../../actions/alerts";
import Alert from "../../types/models/Alert";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection: "column",
  },
  container: {
    padding: "0px 70px 150px 70px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    "& p": {
      transform: "translateY(10%)",
      marginLeft: 15,
      fontSize: 27,
    },
  },
  formControl: {
    backgroundColor: "white",
  },
}));

const PrinterSelect = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { printers, printer } = useSelector(
    (state: AppState) => state.printers
  );

  useEffect(() => {
    dispatch(getPrinters());
  }, [dispatch]);

  const onPrinterOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (printers) {
      dispatch(
        getPrinterAction(
          printers.find((printer) => printer.id === event.target.value)!
        )
      );
    }
  };

  const onClickStart = () => {
    if (printer) {
      history.push("/printer/eventList");
    } else {
      const alert: Alert = {
        id: "noPrinterAlert",
        msg: "프린터를 선택해주세요.",
        alertType: "warning",
      };
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="sm">
        <div className={classes.title}>
          <img
            src={PexpoLogo}
            width="126"
            height="42"
            className="d-inline-block align-top"
            alt="Pexpo logo"
          />
          <TitleTypo>출입증 프린터</TitleTypo>
        </div>
        <Box height={60} />
        {printers && printers.length > 0 && (
          <FormControl
            className={classes.formControl}
            variant="outlined"
            fullWidth
          >
            <PxSelectBox value={printer?.id ?? ""} onChange={onPrinterOnChange}>
              {printers.map((printer) => (
                <MenuItem value={printer.id}>{printer.name}</MenuItem>
              ))}
            </PxSelectBox>
          </FormControl>
        )}
        <Box height={10} />
        <PxButton
          backgroundColor="purple"
          fullWidth
          padding="15px"
          onClick={onClickStart}
        >
          <ButtonTypo>시작하기</ButtonTypo>
        </PxButton>
      </Container>
    </div>
  );
};

export default PrinterSelect;
