import axios, { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { AppActions } from "../store";
import Alert from "../types/models/Alert";
import { setAlert } from "../utils/utils";
import { removeAlertAction, setAlertAction } from "./alerts";
import { setPrinterLoadingAction } from "./printers";

export interface RegitDeskApplicants {
  id: string;
  businessCardId: string;
  name: string;
  position: string;
  company: string;
  countryNumber: string;
  phoneNumber: string;
  type: string;
  email: string;
  eventName: string;
}

interface DrawDeviceFontParamsType {
  text: string;
  x: number;
  y: number;
  fontType: string;
  widthEnlarge: number;
  heightEnlarge: number;
  rotation: number;
  invert: number;
  bold: number;
  alignment: number;
}

interface DrawTrueTypeFontParamsType {
  text: string;
  x: number;
  y: number;
  fontname: string;
  fontsize: number;
  rotation: number;
  italic: number;
  bold: number;
  underline: number;
  compression: number;
}

interface DrawVectorFontParamType {
  text: string;
  x: number;
  y: number;
  fontType: string;
  fontWidth: number;
  fontHeight: number;
  bold: 0 | 1;
  invert: 0 | 1;
  italic: 0 | 1;
  rotation: 0 | 1 | 2 | 3; // 0: 회전안함, 1: 90도, 2: 180도, 3: 270도
  alignment: 0 | 1 | 2; // 0: 왼쪽정렬, 1: 오른쪽정렬, 2: 중앙정렬
  rtol: boolean; // true: right to left, false: left to right 문자열 인쇄방향
}

interface DrawBlockParamsType {
  startHorizontal: number;
  startVertical: number;
  endHorizontal: number;
  endVertical: number;
  option: string;
  thickness: number;
}

var paperWidth = 920;
var label_data = { id: 0, functions: {} };
var label_func: any = {};
var incLabelNum = 0;

export var printResult: Alert;

function clearBuffer() {
  const _a = { clearBuffer: [] };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;

  return JSON.stringify(label_data);
}

function printBuffer() {
  switch (arguments.length) {
    case 0:
      const a = { printBuffer: [] };
      label_func["func" + incLabelNum] = a;
      incLabelNum++;
      break;
    case 1:
      const b = { printBuffer: [arguments[0]] };
      label_func["func" + incLabelNum] = b;
      incLabelNum++;
      break;
  }
}

function setWidth(width: number) {
  var _a = { setWidth: [width] };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

function setSpeed(speed: 0 | 1 | 2 | 3 | 4 | 5 | 6) {
  var _a = { setSpeed: [speed] };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

function setDensity(density: number) {
  // 0~20
  var _a = { setDensity: [density] };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

function drawDeviceFont({
  text,
  x,
  y,
  fontType,
  widthEnlarge,
  heightEnlarge,
  rotation,
  invert,
  bold,
  alignment,
}: DrawDeviceFontParamsType) {
  var _a = {
    drawDeviceFont: [
      text,
      x,
      y,
      fontType,
      widthEnlarge,
      heightEnlarge,
      rotation,
      invert,
      bold,
      alignment,
    ],
  };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

function drawVectorFont({
  text,
  x,
  y,
  fontType,
  fontWidth,
  fontHeight,
  bold,
  invert,
  italic,
  rotation,
  alignment,
  rtol,
}: DrawVectorFontParamType) {
  var _a = {
    drawVectorFont: [
      text,
      x,
      y,
      fontType,
      fontWidth,
      fontHeight,
      bold,
      invert,
      italic,
      rotation,
      alignment,
      rtol,
    ],
  };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

function drawBlock({
  startHorizontal,
  startVertical,
  endHorizontal,
  endVertical,
  option,
  thickness,
}: DrawBlockParamsType) {
  var _a = {
    drawBlock: [
      startHorizontal,
      startVertical,
      endHorizontal,
      endVertical,
      option,
      thickness,
    ],
  };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

interface DrawQrCodePrams {
  data: string;
  x: number;
  y: number;
  model: number;
  eccLevel: number;
  size: number;
  rotation: number;
}

function drawQRCode({
  data,
  x,
  y,
  model,
  eccLevel,
  size,
  rotation,
}: DrawQrCodePrams) {
  var _a = { drawQRCode: [data, x, y, model, eccLevel, size, rotation] };
  label_func["func" + incLabelNum] = _a;
  incLabelNum++;
}

function getLabelData() {
  label_data.functions = label_func;
  label_func = {};
  incLabelNum = 0;

  return JSON.stringify(label_data);
}

function makeResultInquiryData(
  requestId: string,
  responseId: string,
  timeout: number
) {
  return (
    '{"RequestID":' +
    requestId +
    ',"ResponseID":"' +
    responseId +
    '","Timeout":' +
    timeout +
    "}"
  );
}

export const getApplicantByPhoneNum = (
  subEventId: string,
  phoneNum: string
) => async (dispatch: Dispatch<AppActions>) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      params: { subEventId: subEventId, phoneNum: phoneNum },
      data: {},
    };

    const res: AxiosResponse<RegitDeskApplicants> = await axios.get(
      "/api/applicants/deskPrintUser",
      config
    );

    return res.data;
  } catch (err) {
    const error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, "printDeskError");
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const requestPrint = (
  serverURL: string,
  strPrinterName: string,
  strSubmit: string,
  dispatch: Dispatch<any>
) => {
  const requestURL = serverURL + strPrinterName;
  const xmlHttpReq = new XMLHttpRequest();

  if (xmlHttpReq === undefined) {
    return;
  }

  xmlHttpReq.open("POST", requestURL, true);

  xmlHttpReq.setRequestHeader(
    "Content-Type",
    "application/x-www-form-urlencoded"
  );
  xmlHttpReq.send(strSubmit);

  xmlHttpReq.onreadystatechange = function () {
    if (xmlHttpReq.readyState === 4 && xmlHttpReq.status === 200) {
      dispatch(setPrinterLoadingAction(true));
      const res = JSON.parse(xmlHttpReq.responseText);
      const ret = res.Result;
      if (ret.search("ready") >= 0 || ret.search("progress") >= 0) {
        checkResult(
          serverURL,
          "POST",
          strPrinterName,
          res.RequestID,
          res.ResponseID,
          dispatch
        );
      } else if (ret.search("duplicated") >= 0) {
        const alert: Alert = {
          id: "printerSuccess",
          msg: "명찰 출력에 성공하였습니다. 잠시만 기다려주세요.",
          alertType: "warning",
        };
        dispatch(setPrinterLoadingAction(false, alert));
      }
    } else if (xmlHttpReq.readyState === 4 && xmlHttpReq.status === 404) {
      const alert: Alert = {
        id: "printerNotFound",
        msg: "프린터를 찾을 수 없습니다. 프린터 연결을 확인해주세요.",
        alertType: "warning",
      };
      dispatch(setPrinterLoadingAction(false, alert));
    } else if (xmlHttpReq.readyState === 4) {
      const alert: Alert = {
        id: "printerServerNotFound",
        msg: "프린터 서버를 찾을 수 없습니다.",
        alertType: "warning",
      };
      dispatch(setPrinterLoadingAction(false, alert));
    }
  };
};

function calcTextXLocation(text: string, width: number) {
  let textWidthSize = 0;
  const spaceTxt = text.match(/ /g);
  const engTxt = text.match(/[a-zA-Z]/g);
  const korTxt = text.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g);
  const numTxt = text.match(/[0-9]/g);

  if (korTxt !== null) {
    textWidthSize += korTxt.length * width;
  }

  if (engTxt !== null) {
    textWidthSize += engTxt.length * width;
  }

  if (numTxt !== null) {
    textWidthSize += numTxt.length * (width - 20);
  }

  if (spaceTxt !== null) {
    textWidthSize += spaceTxt.length * (width - 55);
  }

  let xLocation;

  console.log(textWidthSize, "textWidthSize");

  if (textWidthSize > 900) {
    xLocation = 0;
  } else {
    xLocation = (paperWidth - textWidthSize) / 2;
  }

  return xLocation;
}

function calcTextWidth(text: string, enlarge: number) {
  let textWidthDotSize = 0;
  const spaceTxt = text.match(/ /g);
  const engTxt = text.match(/[a-zA-Z]/g);
  const korTxt = text.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g);
  const numTxt = text.match(/[0-9]/g);

  if (korTxt !== null) {
    textWidthDotSize += korTxt.length * 16 * enlarge; // 한글 dots 16 x 16
  }

  if (engTxt !== null) {
    textWidthDotSize += engTxt.length * 8 * enlarge; // 영문 dots 8 x 8
  }

  if (numTxt !== null) {
    textWidthDotSize += numTxt.length * 8 * enlarge; // 숫자 dots 8 x 8
  }

  if (spaceTxt !== null) {
    textWidthDotSize += spaceTxt.length * 1 * enlarge; // 스페이스 dots 1 x 1
  }

  const xLocation = (paperWidth - textWidthDotSize) / 2;

  return xLocation;
}

interface PrintLabelParamsType {
  applicantId: number;
  eventName: string;
  subEventName?: string;
  companyName?: string;
  position?: string;
  name: string;
  type: string;
  dispatch: Dispatch<any>;
  ipAddress: string;
  printerName: string;
}

export function printLabel({
  applicantId,
  eventName,
  subEventName,
  companyName,
  position,
  name,
  type,
  dispatch,
  ipAddress,
  printerName,
}: PrintLabelParamsType) {
  clearBuffer();
  setWidth(paperWidth);
  setSpeed(4);
  setDensity(20);

  if (subEventName) {
    drawVectorFont({
      text: subEventName,
      x: 430, //subEventTxtX,
      y: 140,
      fontType: "K",
      fontWidth: 60,
      fontHeight: 60,
      bold: 0, // 바탕전환
      alignment: 2,
      invert: 0,
      italic: 0,
      rotation: 0,
      rtol: false,
    });
  }

  // // 이벤트 이름
  drawVectorFont({
    text: eventName, // 최대 15자
    x: 430,
    y: subEventName ? 200 : 180,
    fontType: "K",
    fontWidth: 60,
    fontHeight: 60,
    bold: 0,
    alignment: 2,
    invert: 0,
    italic: 0,
    rotation: 0,
    rtol: false,
  });

  // // 회사이름
  drawVectorFont({
    text: companyName ?? "",
    x: 430,
    y: 450,
    fontType: "K",
    fontWidth: 65,
    fontHeight: 65,
    bold: 1,
    alignment: 2,
    invert: 0,
    italic: 0,
    rotation: 0,
    rtol: false,
  });

  // // 이름
  drawVectorFont({
    text: name,
    x: 430,
    y: 550,
    fontType: "K",
    fontWidth: 80,
    fontHeight: 80,
    bold: 1,
    alignment: 2,
    invert: 0,
    italic: 0,
    rotation: 0,
    rtol: false,
  });

  // 직함
  drawVectorFont({
    text: position ?? "",
    x: 430,
    y: 670,
    fontType: "K",
    fontWidth: 60,
    fontHeight: 60,
    bold: 0,
    alignment: 2,
    invert: 0,
    italic: 0,
    rotation: 0,
    rtol: false,
  });

  drawQRCode({
    data: JSON.stringify({ id: applicantId }),
    x: 800,
    y: 750,
    model: 0,
    eccLevel: 0,
    size: 5,
    rotation: 1,
  });

  // 타입 (seller or buyer etc)
  drawVectorFont({
    text: type,
    x: 430,
    y: 880,
    fontType: "K",
    fontWidth: 80,
    fontHeight: 80,
    bold: 0,
    alignment: 2,
    invert: 0,
    italic: 0,
    rotation: 0,
    rtol: false,
  });

  // if (companyName) {
  //   const companyTxtX = calcTextWidth(companyName, companyTxtEnlarge);
  //   // 회사이름
  //   drawDeviceFont({
  //     text: companyName,
  //     x: companyTxtX,
  //     y: 400,
  //     fontType: "K",
  //     widthEnlarge: companyTxtEnlarge,
  //     heightEnlarge: companyTxtEnlarge,
  //     rotation: 0,
  //     invert: 0,
  //     bold: 1,
  //     alignment: 0,
  //   });
  // }

  // if (position) {
  //   const positionTxtX = calcTextWidth(position, nameTxtEnlarge);
  //   // 직함
  //   drawDeviceFont({
  //     text: position,
  //     x: positionTxtX,
  //     y: companyName ? 500 : 400,
  //     fontType: "a",
  //     widthEnlarge: nameTxtEnlarge,
  //     heightEnlarge: nameTxtEnlarge,
  //     rotation: 0,
  //     invert: 0,
  //     bold: 1,
  //     alignment: 0,
  //   });
  // }

  // drawDeviceFont({
  //   text: eventName,
  //   x: eventTxtX,
  //   y: 200,
  //   fontType: "a",
  //   widthEnlarge: 1, //eventTxtEnlarge,
  //   heightEnlarge: 1, //eventTxtEnlarge,
  //   rotation: 0,
  //   invert: 0,
  //   bold: 1,
  //   alignment: 0,
  // });

  // 이름
  // drawDeviceFont({
  //   text: name,
  //   x: nameTxtX,
  //   y:
  //     companyName && position === undefined
  //       ? 400
  //       : companyName === undefined && position
  //       ? 500
  //       : 600,
  //   fontType: "a",
  //   widthEnlarge: nameTxtEnlarge,
  //   heightEnlarge: nameTxtEnlarge,
  //   rotation: 0,
  //   invert: 0,
  //   bold: 1,
  //   alignment: 0,
  // });

  // drawBlock({
  //   startHorizontal: 20,
  //   startVertical: 20,
  //   endHorizontal: 800,
  //   endVertical: 800,
  //   option: "B",
  //   thickness: 5,
  // });

  printBuffer();

  const strSubmit = getLabelData();

  requestPrint(
    `http://${ipAddress}:18080/mPrintServer/`,
    printerName,
    strSubmit,
    dispatch
  );
}

function checkResult(
  serverURL: string,
  method: string,
  strPrinterName: string,
  requestId: string,
  responseId: string,
  dispatch: Dispatch<any>
) {
  //_callback
  const requestURL = serverURL + strPrinterName + "/checkStatus";

  const xmlHttpCheck = new XMLHttpRequest();

  const inquiryData = makeResultInquiryData(requestId, responseId, 30);

  xmlHttpCheck.open(method, requestURL, true);
  xmlHttpCheck.setRequestHeader(
    "Content-Type",
    "application/x-www-form-urlencoded"
  );
  xmlHttpCheck.send(inquiryData);
  xmlHttpCheck.timeout = 5000;

  xmlHttpCheck.onreadystatechange = function () {
    dispatch(setPrinterLoadingAction(true));
    if (xmlHttpCheck.readyState === 4 && xmlHttpCheck.status === 200) {
      const res = JSON.parse(xmlHttpCheck.responseText);
      const ret = res.Result;

      if (ret.search("ready") >= 0 || ret.search("progress") >= 0) {
        checkResult(
          serverURL,
          method,
          strPrinterName,
          requestId,
          responseId,
          dispatch
        );
      } else {
        const alert: Alert = {
          id: "printerSuccess",
          msg: "명찰 출력에 성공하였습니다. 잠시만 기다려주세요.",
          alertType: "success",
        };

        dispatch(setPrinterLoadingAction(false, alert));
      }
    } else if (xmlHttpCheck.readyState === 4 && xmlHttpCheck.status === 404) {
      const alert: Alert = {
        id: "printerNotFound",
        msg: "프린터를 찾을 수 없습니다. 프린터 연결을 확인해주세요.",
        alertType: "warning",
      };
      dispatch(setPrinterLoadingAction(false, alert));
    } else if (xmlHttpCheck.readyState === 4) {
      const alert: Alert = {
        id: "printerServerNotFound",
        msg: "프린터 서버를 찾을 수 없습니다.",
        alertType: "warning",
      };
      dispatch(setPrinterLoadingAction(false, alert));
    }
  };
}
