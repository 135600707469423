import React, { ReactNode } from "react";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import ButtonTypo from "../Typography/ButtonTypo";

interface PxRadioGroupProps {
  onchange?:
    | ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void)
    | undefined;
  value: string;
  titleComp?: ReactNode;
  radiogroupname: string;
  data: RadioData[]; // radio button data
  row?: boolean;
  disabled?: boolean;
}

export interface RadioData {
  value: string;
  label: string;
}

const PxRadioGroup: React.FC<PxRadioGroupProps> = (props) => {
  const { titleComp, onchange, value, radiogroupname, data, row, disabled } =
    props;
  return (
    <FormControl disabled={disabled}>
      {titleComp !== undefined && titleComp}
      <RadioGroup
        name={radiogroupname}
        value={value}
        onChange={onchange}
        row={row}
      >
        {data.map((radio, index) => (
          <FormControlLabel
            key={index}
            value={radio.value}
            control={<Radio />}
            label={<ButtonTypo>{radio.label}</ButtonTypo>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default PxRadioGroup;
