import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

//custom ui
import PxOutlinedTxtField from "../../components/Inputs/PxOutlinedTxtField";
import PxOutlinedFormControl from "../../components/Forms/PxOutlinedFormControl";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxGridItem from "../../components/Grid/PxGridItem";
import PxSelectBox from "../../components/SelectBox/PxSelectBox";
import PxButton from "../../components/Buttons/PxButton";

//material ui
import { TypographyProps } from "@material-ui/core/Typography";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";

//utils , redux
import { countryPhoneNumberCode } from "../../utils/constants";
// import { removeLoading, setLoading } from "../../actions/loading";
// import { updateUserInfo } from "../../actions/users";
import Alert from "../../types/models/Alert";
import { removeAlertAction, setAlertAction } from "../../actions/alerts";

import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
import CaptionTypo from "../../components/Typography/CaptionTypo";
import { UserType } from "./UserDetail";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { updateUserInfo, updateUserPwd } from "../../actions/users";
import { removeLoading, setLoading } from "../../actions/loading";
import TitleTypo from "../../components/Typography/TitleTypo";

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  titleTypo: {
    display: "inline-block",
    verticalAlign: "text-top",
  },
  managerAddInfoGridItem: {
    maxWidth: 552,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  countryCodeSelect: {
    color: "rgba(0,0,0,0.6)",
    display: "flex",
  },
  countryCodeInput: {
    color: "rgba(0,0,0,0.6)",
    padding: 10,
  },
  phoneNumberInput: {
    paddingLeft: 4,
  },
  input: {
    color: "rgba(0,0,0,0.6)",
    padding: 10,
  },
}));

const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  min-height: 635px;
  max-height: 100%;
  display: flex;
  margin-bottom: 70px;
  justify-content: center;
`;

const Content = styled.div`
  width: 328px;
  height: 100%;
  flex-direction: column;
`;

const Email = styled.div`
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  background-color: #f6f7fa;
  color: rgba(0, 0, 0, 0.38);
  margin-bottom: 20px;
`;

type TypoProp = TypographyProps;

const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 20px 0 8px 0;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
`;

export default ({
  userInfo,
  setUserInfo,
}: {
  userInfo: UserType;
  setUserInfo: any;
}) => {
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const cellPhoneRegExp = new RegExp("^\\d+$"); // 핸드폰 번호 입력 정규식 (숫자만 입력가능)
  const passwordRegExp = new RegExp("^[a-z0-9]*$");
  const koreanRegExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글확인 정규식
  const ShowNewPassword = () => {
    setNewValue({ ...newValues, showPassword: !newValues.showPassword });
  };

  const [newValues, setNewValue] = useState<{
    password: string;
    showPassword: boolean;
  }>({
    password: "",
    showPassword: false,
  });
  const [userPassword, setUserPassword] = useState<{
    id: string;
    newPassword: string;
  }>({
    id: userInfo.id,
    newPassword: "",
  });
  const alert = (type: "warning" | "success", message: string) => {
    const onAlert: Alert = { alertType: type, id: "fdsafds", msg: message };
    dispatch(setAlertAction(onAlert));
    setTimeout(() => {
      dispatch(removeAlertAction(onAlert.id));
    });
  };

  const submitInfo = async () => {
    dispatch(setLoading());
    if (userInfo.name === "") {
      alert("warning", t("regularExpression.nameEmpty"));
      return;
    } else if (userInfo.company === "") {
      alert("warning", t("regularExpression.companyEmpty"));
      return;
    } else if (userInfo.company.length > 40) {
      alert("warning", t("regularExpression.company"));
      return;
    } else if (userInfo.phoneNumber === "") {
      alert("warning", t("regularExpression.phoneNumberEmpty"));
      return;
    } else if (userInfo.phoneNumber.length > 20) {
      alert("warning", t("regularExpression.phoneNumber"));
      return;
    } else {
      const result: any = await dispatch(updateUserInfo(userInfo));
      if (result === true) {
        alert("success", "변경되었습니다.");
      } else {
        alert("warning", "다시시도해주세요.");
      }
    }
    dispatch(removeLoading());
  };

  const sumbitPassword = async () => {
    dispatch(setLoading());
    if (
      userPassword.newPassword === "" ||
      passwordRegExp.test(userPassword.newPassword) === false
    ) {
      alert("warning", t("regularExpression.password"));
    } else if (userInfo.id === "") {
      alert("warning", "다시 시도해주세요");
    } else {
      const result: any = await dispatch(
        updateUserPwd({
          id: userInfo.id!,
          newPassword: userPassword.newPassword,
        })
      );
      if (result === true) {
        alert("success", "비밀번호가 변경되었습니다.");
        setUserPassword({ ...userPassword, newPassword: "" });
      }
    }
    dispatch(removeLoading());
  };
  return (
    <>
      {userInfo && (
        <Container>
          <Content>
            <SubTitle>이메일(ID)</SubTitle>
            <Email>{userInfo.email}</Email>
            {/* 이름 */}
            <MuiThemeProvider theme={theme}>
              <SubTitle>이름</SubTitle>
              <PxOutlinedTxtField
                name="name"
                inputProps={{
                  className: classes.input,
                }}
                value={userInfo.name}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, name: e.target.value })
                }
                fullWidth
                inputpadding={10}
              />
              {/* 기업명 */}
              <SubTitle>기업명</SubTitle>
              <PxOutlinedTxtField
                name="company"
                inputProps={{
                  className: classes.input,
                }}
                value={userInfo.company}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, company: e.target.value })
                }
                fullWidth
                // onChange={handleOnChange}
                inputpadding={10}
                placeholder="기업명을 입력해주세요"
              />
              {/* 직함 */}
              <SubTitle>직함</SubTitle>
              <PxOutlinedTxtField
                name="position"
                inputProps={{
                  className: classes.input,
                }}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, position: e.target.value })
                }
                fullWidth
                value={userInfo.position}
                inputpadding={10}
              />

              {/* 휴대번호 */}
              <SubTitle>휴대번호</SubTitle>
              <PxOutlinedFormControl fullWidth>
                <PxGridContainer direction="row">
                  <PxGridItem xs={4} md={4}>
                    <PxSelectBox
                      native
                      className={classes.countryCodeSelect}
                      displayEmpty
                      inputProps={{
                        className: classes.countryCodeInput,
                        name: "countryNumber",
                      }}
                      defaultValue={userInfo.countryNumber}
                    >
                      <option value="" disabled>
                        {i18n.language === "ko" ? "국가코드" : "Country Code"}
                      </option>
                      {countryPhoneNumberCode.map((countryCode) => (
                        <option value={countryCode.code}>
                          {i18n.language === "ko"
                            ? countryCode.country
                            : countryCode.countryEn}
                        </option>
                      ))}
                    </PxSelectBox>
                  </PxGridItem>
                  <PxGridItem
                    md={8}
                    xs={8}
                    className={classes.phoneNumberInput}
                  >
                    <PxOutlinedTxtField
                      name="phoneNumber"
                      fullWidth
                      inputProps={{
                        className: classes.input,
                      }}
                      inputpadding={10}
                      value={userInfo.phoneNumber}
                      onChange={(e) => {
                        const isValid = cellPhoneRegExp.test(
                          e.target.value as string
                        );
                        if (isValid || (e.target.value as string) === "") {
                          setUserInfo({
                            ...userInfo,
                            phoneNumber: e.target.value,
                          });
                        }
                      }}
                      placeholder="-없이 입력해주세요"
                    />
                    {/* 휴대번호 */}
                  </PxGridItem>
                </PxGridContainer>
              </PxOutlinedFormControl>
            </MuiThemeProvider>
            <PxButton
              fullWidth
              backgroundColor="bluePurple"
              txtColor="white"
              style={{ marginTop: 20, marginBottom: 20 }}
              onClick={() => submitInfo()}
            >
              변경하기
            </PxButton>
            {/* <Scession>{t("myPage.secession")}</Scession> */}
            ----------------------------------------------------
            <TitleTypo fontSize={20} fontWeight={500} style={{ marginTop: 15 }}>
              비밀번호 변경하기
            </TitleTypo>
            <FormControl fullWidth variant="outlined" style={{ marginTop: 20 }}>
              <InputLabel
                htmlFor="outlined-adornment-password"
                style={{ backgroundColor: "white" }}
              >
                새로운 비밀번호를 입력해주세요
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={newValues.showPassword ? "text" : "password"}
                style={{ imeMode: "disabled" }}
                value={userPassword.newPassword}
                onChange={(e) => {
                  const isValid = koreanRegExp.test(e.target.value as string);
                  if (!isValid) {
                    setUserPassword({
                      ...userPassword,
                      newPassword: e.target.value,
                    });
                  } else {
                    alert("warning", "패스워드에 한글은 입력할 수 없습니다.");
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={ShowNewPassword}
                      edge="end"
                    >
                      {newValues.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            {userPassword.newPassword!.length > 0 &&
              !userPassword.newPassword!.match(/[a-z]/) && (
                <div style={{ color: "red" }}>
                  영어 소문자가 보함되어야 합니다.
                </div>
              )}
            {userPassword.newPassword!.length > 0 &&
              !userPassword.newPassword!.match(/[0-9]/) && (
                <div style={{ color: "red" }}>숫자가 포함되어야 합니다.</div>
              )}
            <PxButton
              fullWidth
              backgroundColor="bluePurple"
              txtColor="white"
              style={{ marginTop: 20 }}
              onClick={() => sumbitPassword()}
            >
              <ButtonTypo txtcolor="white">비밀번호 변경</ButtonTypo>
            </PxButton>
            <CaptionTypo style={{ marginTop: 60 }}>
              가입일시 {userInfo.createDate}
            </CaptionTypo>
          </Content>
        </Container>
      )}
    </>
  );
};
