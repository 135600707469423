import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import UserActionTypes from "./types/actions/UserActions";
import AlertActionTypes from "./types/actions/AlertAction";
import ProgressActionTypes from "./types/actions/ProgressAction";
import EventActionTypes from "./types/actions/EventAction";
import AdvertisingActionTypes from "./types/actions/AdvertisingAction";
import SubEventActionTypes from "./types/actions/SubEventAction";
import FileActionTypes from "./types/actions/FileAction";
import FormActionTypes from "./types/actions/FormAction";
import LoadingActionTypes from "./types/actions/LoadingAction";
import FormAnswerActionTypes from "./types/actions/FormAnswerAction";
import CountryActionTypes from "./types/actions/CountryAction";

import userReducer from "./reducers/users";
import alertReducer from "./reducers/alerts";
import progressReducer from "./reducers/progresses";
import eventReducer from "./reducers/events";
import advertisingReducer from "./reducers/advertising";
import loadingReducer from "./reducers/loadings";
import subEventReducer from "./reducers/subEvents";
import fileReducer from "./reducers/files";
import formReducer from "./reducers/forms";
import formAnswerReducer from "./reducers/formAnswers";
import countryReducer from "./reducers/countries";
import zoomReducer from "./reducers/zooms";
import ZoomActionTypes from "./types/actions/ZoomAction";
import TableInfoActionTypes from "./types/actions/TableInfoAction";
import tableInfoReducer from "./reducers/tableInfo";
import PrinterSubEventActionTypes from "./types/actions/PrinterAction";
import printerReducer from "./reducers/printers";
import OnSiteUserActionTypes from "./types/actions/OnSiteUserAction";
import onSiteUserReducer from "./reducers/onSiteUsers";
import ApplicationActionTypes from "./types/actions/ApplicationAction";
import applicationReducer from "./reducers/applications";
import dialogReducer from "./reducers/dialogs";
import DialogActionTypes from "./types/actions/DialogAction";
import MeetingHistoryActionTypes from "./types/actions/MeetingHistoryAction";
import meetingHistoryReducer from "./reducers/meetingHistories";
import NoticeActionTypes from "./types/actions/NoticeAction";
import noticeReducer from "./reducers/notices";
import mailReducer from "./reducers/mails";
import MailActionTypes from "./types/actions/MailAction";
import SmsActionTypes from "./types/actions/SmsAction";
import smssReducer from "./reducers/smss";
import meetingReducer from "./reducers/meetings";
import MeetingActionTypes from "./types/actions/MeetingAction";
import counselReducer from "./reducers/counsels";
import CounselActionTypes from "./types/actions/CounselAction";
import WebinarActionTypes from "./types/actions/WebinarAction";
import webinarReducer from "./reducers/webinars";
import InterpreterActionTypes from "./types/actions/InterpreterAction";
import interpreterReducer from "./reducers/interpreters";

const initialState = {};

// reducer
export const rootReducer = combineReducers({
  users: userReducer,
  alerts: alertReducer,
  progresses: progressReducer,
  events: eventReducer,
  advertisings: advertisingReducer,
  subEvents: subEventReducer,
  files: fileReducer,
  forms: formReducer,
  formAnswers: formAnswerReducer,
  tableInfo: tableInfoReducer,
  loadings: loadingReducer,
  countries: countryReducer,
  zooms: zoomReducer,
  printers: printerReducer,
  onSiteUsers: onSiteUserReducer,
  applications: applicationReducer,
  dialogs: dialogReducer,
  meetingHistories: meetingHistoryReducer,
  notices: noticeReducer,
  mails: mailReducer,
  smss: smssReducer,
  meetings: meetingReducer,
  counsels: counselReducer,
  webinars: webinarReducer,
  interpreters: interpreterReducer,
});

const middleWare = [thunk as ThunkMiddleware<AppState, AppActions>];

export type AppState = ReturnType<typeof rootReducer>;

// actiontypes
export type AppActions =
  | UserActionTypes
  | AlertActionTypes
  | ProgressActionTypes
  | EventActionTypes
  | AdvertisingActionTypes
  | SubEventActionTypes
  | FileActionTypes
  | FormAnswerActionTypes
  | FormActionTypes
  | TableInfoActionTypes
  | LoadingActionTypes
  | CountryActionTypes
  | ZoomActionTypes
  | PrinterSubEventActionTypes
  | OnSiteUserActionTypes
  | ApplicationActionTypes
  | DialogActionTypes
  | MeetingHistoryActionTypes
  | NoticeActionTypes
  | MailActionTypes
  | SmsActionTypes
  | MeetingActionTypes
  | CounselActionTypes
  | WebinarActionTypes
  | InterpreterActionTypes;

export const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleWare))
);

export default store;
