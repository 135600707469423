import React, { SetStateAction, useCallback } from "react";
import { Grid, Input } from "react-spreadsheet-grid";
import set from "lodash/set";

export interface RowType {
  name: string;
  phoneNum: string;
  eventName: string;
  type: string;
  companyName: string;
  position: string;
}

interface SpreadSheetProps {
  rowData: RowType[];
  setRowData: React.Dispatch<SetStateAction<RowType[]>>;
}

const SpreadSheet = ({ rowData, setRowData }: SpreadSheetProps) => {
  const onFieldChange = useCallback(
    (rowId: string, field: string) => (value: string) => {
      //   Find the row that is being changed
      const row: any = rowData.find(({ phoneNum }) => phoneNum === rowId);

      //   Change a value of a field
      set(row, field, value);

      setRowData(([] as RowType[]).concat(rowData));
    },
    [rowData, setRowData]
  );

  const initColumns = useCallback(
    () => [
      {
        id: "name",
        title: "이름(name)",
        width: 10,
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.name}
              focus={focus}
              onChange={onFieldChange(row.phoneNum, "name")}
            />
          );
        },
      },
      {
        id: "phoneNum",
        title: "전화번호(phoneNum)",
        width: 15,
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.phoneNum}
              focus={focus}
              onChange={onFieldChange(row.phoneNum, "phoneNum")}
            />
          );
        },
      },
      {
        id: "eventName",
        title: "이벤트이름(eventName)",
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.eventName}
              focus={focus}
              onChange={onFieldChange(row.phoneNum, "eventName")}
            />
          );
        },
      },
      {
        id: "type",
        title: "타입(type)",
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.type}
              focus={focus}
              onChange={onFieldChange(row.phoneNum, "type")}
            />
          );
        },
        width: 10,
      },
      {
        id: "companyName",
        title: "회사이름(companyName)",
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.companyName}
              focus={focus}
              onChange={onFieldChange(row.phoneNum, "companyName")}
            />
          );
        },
        width: 20,
      },
      {
        id: "position",
        title: "직책(position)",
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.position}
              focus={focus}
              onChange={onFieldChange(row.phoneNum, "taxInvoiceMail")}
            />
          );
        },
        width: 10,
      },
    ],
    [onFieldChange]
  );

  return (
    <>
      <Grid
        columns={initColumns()}
        rows={rowData}
        getRowKey={(row: RowType) => row.phoneNum}
      />
    </>
  );
};

export default SpreadSheet;
