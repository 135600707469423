import React, { useEffect } from "react";
import { Column, MTableToolbar } from "material-table";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PxTable from "../../components/Tables/PxTable";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import { AppState } from "../../store";
import { removeLoadingAction, setLoadingAction } from "../../actions/loading";
import {
  deletePrinters,
  getPrinters,
  resetPrinterAction,
} from "../../actions/printers";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PxIconButton from "../../components/Buttons/PxIconButton";
import PrintIcon from "@material-ui/icons/Print";
import { setTableInfoLoadingAction } from "../../actions/tableInfo";

interface RowType {
  id?: string;
  name: string;
  eventName: string;
  ipAddress: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const useStyles = makeStyles(() => ({
  tableToolbarRoot: {
    flexGrow: 1,
    minHeight: 48,
  },
}));

const StyledDiv = styled.div`
  margin-top: 20px;
  width: 100%;
  & th.MuiTableCell-root,
  td.MuiTableCell-root {
    padding: 0px 0px;
  }
  & td.MuiTableCell-root {
    color: rgba(0, 0, 0, 0.6) !important;
  }
`;

const PrinterList = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const printers = useSelector((state: AppState) => state.printers.printers);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: "프린터",
        field: "name",
        cellStyle: {
          width: "150px",
        },
      },
      {
        title: "이벤트",
        field: "eventName",
        cellStyle: {
          width: "calc(100%-650px)",
        },
      },
      {
        title: "기간",
        field: "eventDate",
        cellStyle: {
          width: "200px",
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    dispatch(getPrinters());
    return () => {
      dispatch(resetPrinterAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (printers) {
      const tableData = printers.map((printer) => ({
        id: printer.id,
        name: printer.name,
        eventName: printer.eventName,
        ipAddress: printer.ipAddress,
      }));

      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: tableData };
      });
      dispatch(setTableInfoLoadingAction(false));
    }
  }, [printers]);

  const onClickPrinterDelete = async (evt: any, data: any) => {
    dispatch(setLoadingAction());
    const printerIds = data.map((printer: RowType) => printer.id);
    await deletePrinters(printerIds);
    await dispatch(getPrinters());
    dispatch(removeLoadingAction());
  };

  return (
    <StyledDiv>
      <PxTable<RowType>
        columns={state.columns}
        data={state.data}
        options={{
          selection: true,
          actionsColumnIndex: 0,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          searchFieldAlignment: "left",
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MTableToolbar
                {...props}
                classes={{
                  root: classes.tableToolbarRoot,
                }}
              />
              <PxIconButton
                onClick={() => {
                  window.open("/printer", "_blank");
                }}
              >
                <PrintIcon />
              </PxIconButton>
              <PxButton
                backgroundColor="purple"
                onClick={() => {
                  history.push("/printers/add");
                }}
                style={{ margin: 10 }}
              >
                <ButtonTypo>프린터 추가</ButtonTypo>
              </PxButton>
            </div>
          ),
        }}
        onRowClick={(event, rowData) => {
          history.push(`/printers/modify/${rowData!.id}`);
        }}
        actions={[
          {
            tooltip: "",
            icon: () => (
              <span style={{ fontSize: 14, color: "red" }}>삭제</span>
            ),
            onClick: onClickPrinterDelete,
          },
        ]}
      />
    </StyledDiv>
  );
};

export default PrinterList;
