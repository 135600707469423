import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getPrinterSubEvent } from "../../actions/printers";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import HeadlineTypo from "../../components/Typography/HeadlineTypo";
import { AppState } from "../../store";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
import moment from "moment";
import PxButton from "../../components/Buttons/PxButton";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    "& .item": {
      flex: 1,
      "& p": {
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
        textAlign: "center",
      },
    },
    "& .button": {
      flex: 1,
      marginBottom: 20,
      "& .MuiButton-label": {
        justifyContent: "start",
      },
      "& p": {
        fontSize: 30,
        position: "relative",
        top: "50%",
        textAlign: "start",
      },
    },
    paddingTop: 15,
  },
}));

const PrinterEvent = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ subEventId: string }>();

  const { subEvent, onSiteRegit } = useSelector(
    (state: AppState) => state.printers
  );

  useEffect(() => {
    if (subEvent === undefined && params.subEventId) {
      dispatch(getPrinterSubEvent(params.subEventId));
    }
  }, [dispatch, params.subEventId, subEvent]);

  return (
    <div className={classes.root}>
      <div className="item">
        <HeadlineTypo>{subEvent?.mainEvent.name ?? ""} </HeadlineTypo>
        <SubHeadingTypo txtcolor="secondary">
          {subEvent
            ? `${moment(subEvent.subEventStartDate).format(
                "YYYY.MM.DD"
              )} - ${moment(subEvent.subEventEndDate).format("YYYY.MM.DD")}`
            : ""}
        </SubHeadingTypo>
      </div>
      <PxButton
        fullWidth
        className="button"
        backgroundColor="purple"
        onClick={() => {
          history.push(`/printer/${subEvent?.id}/user`);
        }}
      >
        <PxGridContainer direction="column">
          <HeadlineTypo gutterBottom>참가신청 조회</HeadlineTypo>
          <HeadlineTypo style={{ color: "rgb(255 255 255 / 80%)" }}>
            출입증 발급
          </HeadlineTypo>
        </PxGridContainer>
      </PxButton>
      {onSiteRegit === "none" ? (
        <div className="button"></div>
      ) : (
        <PxButton
          fullWidth
          className="button"
          backgroundColor="blueGrey"
          onClick={() => {
            history.push(`/printer/${params.subEventId}/onSiteUser`);
          }}
        >
          <PxGridContainer direction="column">
            <HeadlineTypo gutterBottom>현장등록</HeadlineTypo>
            <HeadlineTypo style={{ color: "rgb(255 255 255 / 80%)" }}>
              출입증 발급
            </HeadlineTypo>
          </PxGridContainer>
        </PxButton>
      )}
    </div>
  );
};

export default PrinterEvent;
