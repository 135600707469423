import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, match } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column, MTableToolbar } from "material-table";

// actions
import { getMails } from "../../../../actions/mail";

// custom ui comp
import PxTable from "../../../../components/Tables/PxTable";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";

interface RowType {
  id: string;
  code: string;
  title: string;
  // content: string;
  createdDate: string;
  createdUserId: string;
  subEventId: string;
  toMail: string;
  status: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const MailList = ({ match }: { match: match }) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const param: { id: string } = useParams();
  const mails = useSelector((state: AppState) => state.mails.mails);
  const sendgrid = useSelector((state: AppState) => state.mails.sendGridMails);
  const dispatch = useDispatch();
  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("mailList.recipient"),
        field: "toMail",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("common.title"),
        field: "title",
        cellStyle: {
          width: "calc(100%-500px)",
          paddingRight: 0,
          maxWidth: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      // {
      //   title: "결과",
      //   field: "code",
      //   cellStyle: { width: "100px", paddingRight: 0 },
      // },
      // {
      //   title: t("common.content"),
      //   field: "content",
      //   cellStyle: {
      //     paddingRight: 0,
      //     maxWidth: "200px",
      //     whiteSpace: "nowrap",
      //     overflow: "hidden",
      //     textOverflow: "ellipsis",
      //   },
      // },
      {
        title: t("mailList.sentDate"),
        field: "createdDate",
        cellStyle: { width: "120px", paddingRight: 0 },
      },
      {
        title: "status",
        field: "status",
        cellStyle: { width: "120px", paddingRight: 0 },
      },
    ],
    data: [],
  });

  // const callGetMails = useCallback(async () => {
  //   await dispatch(getMails(param.id));
  //   if (!mails || mails.content.length <= 0)
  //     dispatch(setTableInfoLoadingAction(false));
  // }, [dispatch, param.id]);

  // useEffect(() => {
  //   callGetMails();
  // }, [callGetMails]);

  useEffect(() => {
    let tableData: RowType[] = [];
    if (mails && Array.isArray(mails.content) && mails.content.length > 0) {
      mails.content.map((mail) => {
        const sendGridMessage =
          sendgrid &&
          sendgrid.content.find(
            (m) => m.msg_id && m.msg_id.split(".")[0] === mail.msgId
          );
        const rowData: RowType = {
          title: mail.title,
          id: mail.id || "",
          code: mail.code || "",
          createdDate: moment(mail.createdDate).format("YYYY-MM-DD") || "",
          createdUserId: mail.createdUserId || "",
          subEventId: mail.subEventId || "",
          toMail: mail.toMail || "",
          status: sendGridMessage
            ? sendGridMessage?.opens_count !== 0
              ? "open"
              : sendGridMessage.status
            : "process",
        };
        tableData.push(rowData);
        dispatch(setTableInfoLoadingAction(false));
      });
    }
    setState((prevCreateDate) => {
      return { ...prevCreateDate, data: tableData };
    });
  }, [mails]);

  return (
    <>
      <PxTable<RowType>
        components={{
          Toolbar: (props) => {
            return (
              <PxGridContainer direction="column">
                {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        title=""
        columns={state.columns}
        actionData={param.id}
        totalPage={mails?.totalPages}
        getAction={getMails}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          history.push(`${match.url}/${selectedRow?.id}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
    </>
  );
};

export default MailList;
