import React from "react";
import PxButton from "../Buttons/PxButton";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import ButtonTypo from "../Typography/ButtonTypo";
import { useTranslation } from "react-i18next";

interface ExportCSVProps {
  sellerData: any[];
  buyerData: any[];
  fileName: string;
  marges?: any[];
  sellerName: string;
  buyerName: string;
}

export const ExportCSV = ({
  sellerData,
  buyerData,
  fileName,
  marges,
  sellerName,
  buyerName,
}: ExportCSVProps) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";

  const exportToCSV = (
    sellerData: any[],
    buyerData: any[],
    name: string,
    sellerName: string,
    buyerName: string,
    marges?: any[]
  ) => {
    const sellerWs = XLSX.utils.json_to_sheet(sellerData);
    const buyerWs = XLSX.utils.json_to_sheet(buyerData);

    const wb = {
      Sheets: {
        [`${sellerName}`]: {
          ...sellerWs,
          "!merges": marges,
        },
        [`${buyerName}`]: {
          ...buyerWs,
          "!merges": marges,
        },
      },
      SheetNames: [`${sellerName}`, `${buyerName}`],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <PxButton
      backgroundColor="purple"
      onClick={(e) =>
        exportToCSV(
          sellerData,
          buyerData,
          fileName,
          sellerName,
          buyerName,
          marges
        )
      }
    >
      <ButtonTypo>{t("excel.download(xlsx)")}</ButtonTypo>
    </PxButton>
  );
};
//
