import React, { useEffect, useState } from "react";
import { Column } from "material-table";
import moment from "moment";

import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxTable from "../../components/Tables/PxTable";

// material icons
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getEvents, modifyEventApproval, getEventsAction } from "../../actions/events";
import { AppState } from "../../store";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
import {
  createAdvertising,
  getAdvertisings,
  modifyAdvertisingOpen,
} from "../../actions/advertising";

interface RowType {
  adId: string;
  adCreatedDate: string;
  adType: string;
  adOpen: string;
  evName: string;
  evCreatedDate: string;
  evDate: string;
  evId: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const AdvertisingList = () => {
  const history = useHistory();
  const { advertisings, advertising } = useSelector((state: AppState) => state.advertisings);

  const dispatch = useDispatch();

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: "기간",
        field: "evDate",
        cellStyle: {
          width: "210px",
        },
      },
      {
        title: "이벤트",
        field: "evName",
        cellStyle: {
          width: "calc(100%-310px)",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "",
        field: "adOpen",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
        render: (rowData) =>
          rowData.adOpen === "N" || rowData.adOpen === undefined ? (
            <PxButton
              backgroundColor="purple"
              style={{ borderRadius: 5, padding: 7 }}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                dispatch(modifyAdvertisingOpen(rowData.adId));
              }}
            >
              <ButtonTypo>광고하기</ButtonTypo>
            </PxButton>
          ) : (
            <PxButton
              backgroundColor="grey"
              style={{ borderRadius: 5, padding: 7 }}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                dispatch(modifyAdvertisingOpen(rowData.adId));
              }}
            >
              <ButtonTypo>광고중</ButtonTypo>
            </PxButton>
          ),
      },
    ],
    data: [],
  });

  useEffect(() => {
    dispatch(getAdvertisings());
  }, [dispatch]);

  useEffect(() => {
    if (advertisings.length !== 0) {
      const tableData: RowType[] = advertisings.map((ad) => {
        const rowData: RowType = {
          adOpen: ad.open,
          adId: ad.id,
          adCreatedDate: moment(ad.createdDate).format("YYYY-MM-DD"),
          adType: ad.type,
          evName: ad.event.name,
          evDate: `${moment(ad.event.startDate).format("YYYY-MM-DD")} ~ ${moment(
            ad.event.endDate
          ).format("YYYY-MM-DD")}`,
          evCreatedDate: moment(ad.event.createdDate).format("YYYY-MM-DD"),
          evId: ad.event.id!.toString(),
        };
        return rowData;
      });

      setState({ ...state, data: tableData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertisings]);

  useEffect(() => {
    if (advertising?.id !== undefined) {
      const data = state.data;
      data.forEach((d) => {
        if (d.adId === advertising.id) {
          d.adOpen = advertising.open;
        }
      });
      setState({ ...state, data: data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertising]);

  return (
    <PxGridContainer parent={true}>
      <PxTable<RowType>
        columns={state.columns}
        data={state.data}
        //   onRowClick={(evt, selectedRow) =>
        //     history.push(`/event/detail/${selectedRow?.id}`)
        //   }
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: true,
        }}
      />
    </PxGridContainer>
  );
};

export default AdvertisingList;
