import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

// types
import { DraggableItemComp } from "../../../types/models/AutonoForm";

// custom Component
import AutonomousForm from "./AutonomousForm";
import PxSelectFormContent from "./PxSelectFormContent";

// material comps
import Paper from "@material-ui/core/Paper";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import MenuItem from "@material-ui/core/MenuItem";
import PxSelectBox from "../../../components/SelectBox/PxSelectBox";
import PxGridItem from "../../../components/Grid/PxGridItem";
import PxIconButton from "../../../components/Buttons/PxIconButton";
import PxInputBase from "../../../components/Inputs/PxInputBase";
import PxButton from "../../../components/Buttons/PxButton";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// material icons
import MenuIcon from "@material-ui/icons/Menu";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddIcon from "@material-ui/icons/Add";
import AdjustIcon from "@material-ui/icons/Adjust";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ShortTextIcon from "@material-ui/icons/ShortText";
import NotesIcon from "@material-ui/icons/Notes";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import LinkIcon from "@material-ui/icons/Link";
import StarIcon from "@material-ui/icons/Star";
import ButtonTypo from "../../../components/Typography/ButtonTypo";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import PxOutlinedTxtField from "../../../components/Inputs/PxOutlinedTxtField";

const StyledFormLabelControl = styled(FormControlLabel)`
  & .MuiTypography-body1 {
    font-size: 13px;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
  },
  contentRoot: {
    margin: "20px 10px",
  },
  addButton: {
    marginLeft: 20,
    marginBottom: 20,
  },
}));

// 전체 쟈율양식 폼
const PxAutonomousForm: React.FC<DraggableItemComp> = (props) => {
  const classes = useStyles();
  const {
    elevation,
    id,
    index,
    draghandle,
    setSelected,
    formCopy,
    formDelete,
    selectContentFormAdd,
    selectContentFormDelete,
    formData,
    initialData,
    setInitialData,
  } = props;
  const location = useLocation();

  // 자율양식 타입 셀렉트박스 onChange
  const handleOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>
  ) => {
    event.persist();
    if (formData !== undefined)
      setInitialData((prevState) => {
        // const formData = prevState.formData;
        Object.assign(prevState.formData[id], {
          [event.target.name as string]: event.target.value as string,
        });
        return { ...prevState };
      });
  };

  const checkBoxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const name = event.target.name as string;
    const value = checked ? "Y" : "N";
    if (formData !== undefined)
      setInitialData((prevState) => {
        const formData = prevState.formData;
        Object.assign(formData[id], {
          [name]: value,
        });
        return { ...prevState, formData };
      });
  };

  const keywordOptionsOnChange = (
    event: React.ChangeEvent<{}>,
    value: string[]
  ) => {
    if (formData) {
      setInitialData((prevState) => {
        const formData = prevState.formData;
        Object.assign(formData[id], {
          keywordOptions: value,
        });
        return { ...prevState, formData };
      });
    }
  };

  return (
    <Paper
      elevation={elevation}
      className={classes.root}
      onClick={() => {
        setSelected(id);
      }}
    >
      <PxGridContainer>
        {/* 자율양식 헤더 */}
        {elevation === 10 && formData?.selectable !== false && (
          <PxGridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ backgroundColor: "#eeeeee" }}
          >
            <PxGridItem className={"pl-2"} {...draghandle}>
              <MenuIcon />
            </PxGridItem>
            {/* 자율양식 셀렉트 */}
            <PxGridItem md={9} xs={8}>
              <PxSelectBox
                value={formData!.type}
                onChange={handleOnChange}
                displayEmpty
                name="type"
                fullWidth
                style={{ backgroundColor: "white", paddingLeft: 10 }}
              >
                <MenuItem value={"single"}>
                  <PxGridContainer direction="row">
                    <AdjustIcon fontSize="small" />
                    <ButtonTypo className="pl-2">단일선택</ButtonTypo>
                  </PxGridContainer>
                </MenuItem>
                <MenuItem value={"many"}>
                  <PxGridContainer direction="row">
                    <CheckBoxIcon fontSize="small" />
                    <ButtonTypo className="pl-2">복수선택</ButtonTypo>
                  </PxGridContainer>
                </MenuItem>
                <MenuItem value={"short"}>
                  <PxGridContainer direction="row">
                    <ShortTextIcon fontSize="small" />
                    <ButtonTypo className="pl-2">단문입력</ButtonTypo>
                  </PxGridContainer>
                </MenuItem>
                <MenuItem value={"long"}>
                  <PxGridContainer direction="row">
                    <NotesIcon fontSize="small" />
                    <ButtonTypo className="pl-2">장문입력</ButtonTypo>
                  </PxGridContainer>
                </MenuItem>
                {location.pathname.includes("CounselForm") && (
                  <MenuItem value={"star"}>
                    <PxGridContainer direction="row">
                      <StarIcon fontSize="small" />
                      <ButtonTypo className="pl-2">별점평가</ButtonTypo>
                    </PxGridContainer>
                  </MenuItem>
                )}
                {!location.pathname.includes("CounselForm") && (
                  <MenuItem value={"file"}>
                    <PxGridContainer direction="row">
                      <AttachFileIcon fontSize="small" />
                      <ButtonTypo className="pl-2">파일첨부</ButtonTypo>
                    </PxGridContainer>
                  </MenuItem>
                )}
                <MenuItem value={"url"}>
                  <PxGridContainer direction="row">
                    <LinkIcon fontSize="small" />
                    <ButtonTypo className="pl-2">URL 링크</ButtonTypo>
                  </PxGridContainer>
                </MenuItem>
              </PxSelectBox>
            </PxGridItem>
            {/* 폼 복사하기, 삭제하기 버튼 */}
            <PxGridItem>
              <PxIconButton
                onClick={() => {
                  formDelete !== undefined && formDelete(id);
                }}
              >
                <DeleteIcon />
              </PxIconButton>
              <PxIconButton
                onClick={() => {
                  formCopy !== undefined && formCopy(id, index);
                }}
              >
                <FileCopyIcon />
              </PxIconButton>
            </PxGridItem>
          </PxGridContainer>
        )}
        {/* 자율양식 본문 */}
        <PxGridContainer className={classes.contentRoot} direction="column">
          <PxGridItem style={{ fontWeight: "bold" }}>
            질문:{" "}
            <PxInputBase
              name="title"
              value={formData!.title}
              placeholder="제목"
              fontsize={15}
              readOnly={formData?.selectable === false ? true : false}
              fontweight="bold"
              autoComplete="off"
              onChange={handleOnChange}
            />
          </PxGridItem>
          {formData!.explainCheck === "Y" && (
            <PxGridItem>
              <PxInputBase
                name="explain"
                value={formData!.explain}
                fullWidth
                placeholder="설명을 입력해주세요."
                fontsize={14}
                fontweight="bold"
                autoComplete="off"
                onChange={handleOnChange}
              />
            </PxGridItem>
          )}
          {/* 답변 항목 만들기 */}
          {(formData!.type === "many" ||
            formData!.type === "single" ||
            formData!.type === "category") && (
            <AutonomousForm
              colunmId="columnId-2"
              selectContent={true}
              formId={id} // 전체 폼의 id
              initialData={formData?.selectContent}
              setInitialData={setInitialData}
              draggableComp={
                <PxSelectFormContent
                  selectContentFormDelete={selectContentFormDelete}
                  setInitialData={setInitialData}
                  initialData={initialData}
                />
              }
            />
          )}
          {/* 키워드 옵션 */}
          {formData?.keywordOptions && formData?.type === "keyword" && (
            <Autocomplete
              id="keywordOptions"
              options={formData.keywordOptions ?? []}
              freeSolo
              multiple
              defaultValue={formData.keywordOptions ?? []}
              onChange={keywordOptionsOnChange}
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <PxOutlinedTxtField
                  {...params}
                  inputProps={{ ...params.inputProps, style: { padding: 0 } }}
                  fullWidth
                  placeholder={"옵션으로 사용할 키워드를 입력해주세요."}
                  fontsize={14}
                />
              )}
            />
          )}
        </PxGridContainer>
        {/* 추가하기 버튼 */}
        {(formData!.type === "many" ||
          formData!.type === "single" ||
          formData!.type === "category") && (
          <PxGridContainer className={classes.addButton}>
            <PxButton
              backgroundColor="purple"
              startIcon={<AddIcon style={{ color: "white" }} />}
              onClick={() => {
                selectContentFormAdd !== undefined && selectContentFormAdd(id);
              }}
            >
              <ButtonTypo>추가하기</ButtonTypo>
            </PxButton>
          </PxGridContainer>
        )}

        <Divider light style={{ width: "100%" }} />

        {/* 체크박스 */}
        {elevation === 10 && formData?.optionable !== false && (
          <PxGridContainer className="pt-2 pl-3">
            <FormGroup row>
              <StyledFormLabelControl
                control={
                  <Checkbox
                    color="primary"
                    checked={formData!.explainCheck === "Y" ? true : false}
                    onChange={checkBoxOnChange}
                    name="explainCheck"
                  />
                }
                label="설명"
              />
              <StyledFormLabelControl
                control={
                  <Checkbox
                    color="primary"
                    checked={formData!.secretCheck === "Y" ? true : false}
                    onChange={checkBoxOnChange}
                    name="secretCheck"
                  />
                }
                label="비공개 정보"
              />
              <StyledFormLabelControl
                control={
                  <Checkbox
                    color="primary"
                    checked={formData!.requireCheck === "Y" ? true : false}
                    onChange={checkBoxOnChange}
                    name="requireCheck"
                  />
                }
                label="필수"
              />
            </FormGroup>
          </PxGridContainer>
        )}
      </PxGridContainer>
    </Paper>
  );
};

export default PxAutonomousForm;
