import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import WooriIcon from "../../../assets/icons/woori-copy.svg";
import Button from "@material-ui/core/Button";
import TextField, { OutlinedTextFieldProps } from "@material-ui/core/TextField";
import { SubEventInfo } from "../../../types/models/SubEvent";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import Body1Typo from "../../../components/Typography/Body1Typo";
import PxGridItem from "../../../components/Grid/PxGridItem";
import Body3Typo from "../../../components/Typography/Body3Typo";
import CaptionTypo from "../../../components/Typography/CaptionTypo";
import HeadingTypo from "../../../components/Typography/HeadingTypo";
import OutlinedTxtField from "../../../components/Inputs/OutlinedTxtField";
import SubHeadingTypo from "../../../components/Typography/SubHeadingTypo";
import SuperHeadTypo from "../../../components/Typography/SuperHeadTypo";
import PxIconButton from "../../../components/Buttons/PxIconButton";
import ImageDialogs from "../../../components/Dialog/ImageDialogs";

export const Divider = styled.div`
  height: 1px;
  margin-bottom: 20px;
  background-color: rgb(220, 222, 225);
`;

interface Props {
  title: string;
  children: ReactNode;
  subEvent: SubEventInfo;
  createdDate?: string;
  modifiedDate?: string;
}

export default ({
  title,
  children,
  subEvent,
  createdDate,
  modifiedDate,
}: Props) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Container>
      <PxGridContainer justify="space-between">
        <Box display="flex">
          <PxIconButton hoverNone={true} onClick={() => history.goBack()}>
            <KeyboardArrowLeftIcon />
          </PxIconButton>
          <SuperHeadTypo fontweight="bold">{title}</SuperHeadTypo>
        </Box>
        {createdDate && (
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Body1Typo fontSize="17px" txtcolor="black0.6">
              작성일 :&nbsp;{moment(createdDate).format("yyyy-MM-DD HH:mm")}
            </Body1Typo>
            {modifiedDate && (
              <Body1Typo fontSize="17px" txtcolor="black0.6">
                최근 수정일 :&nbsp;
                {moment(modifiedDate).format("yyyy-MM-DD HH:mm")}
              </Body1Typo>
            )}
          </Box>
        )}
      </PxGridContainer>

      {/* 대이벤트 정보배너 ui*/}
      <PxGridItem fullWidth>
        <Card
          className={classes.cardRoot}
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.goBack();
          }}
        >
          <CardMedia
            component="img"
            className={classes.cardImage}
            image={
              subEvent?.mainEvent?.image
                ? subEvent?.mainEvent.image
                : subEvent?.mainEvent?.mainImageFile.filePath
            }
            alt={subEvent?.mainEvent?.name + "Main Image"}
          />
          <div className={classes.cardDetails}>
            <CardContent>
              <SubHeadingTypo fontweight="bold">
                {subEvent?.mainEvent?.name}
              </SubHeadingTypo>
              <Body1Typo txtcolor="secondary">
                {`${moment(subEvent?.mainEvent?.startDate).format(
                  "yyyy-MM-DD"
                )} ~ ${moment(subEvent?.mainEvent?.endDate).format(
                  "yyyy-MM-DD"
                )}`}
              </Body1Typo>
            </CardContent>
          </div>
        </Card>
      </PxGridItem>
      <Box height={40} />
      {children}
    </Container>
  );
};

interface ItemProps {
  title: string;
  subTitle: string | ReactNode;
  input: ReactNode;
  justify?:
    | "space-around"
    | "space-between"
    | "space-evenly"
    | "center"
    | "flex-end"
    | "flex-start";
  subTitleColor?:
    | "secondary"
    | "black0.6"
    | "rgba(0,0,0,0.87)"
    | "purple"
    | "purple700"
    | "azure";
  fontWeight?: number;
  caption?: ReactNode;
}
export const Item = ({
  title,
  subTitle,
  input,
  justify,
  subTitleColor,
  fontWeight,
  caption,
}: ItemProps) => {
  return (
    <PxGridContainer
      justify={justify || "space-between"}
      style={{ paddingBottom: 22 }}
    >
      <Box>
        <Body1Typo fontSize="20px" fontWeight="500">
          {title}
        </Body1Typo>
        <Body3Typo
          fontWeight={fontWeight}
          txtcolor={subTitleColor ?? "black0.6"}
        >
          {subTitle}
        </Body3Typo>
      </Box>
      <Box
        width={justify ? "100%" : 94}
        justifyContent={justify ? "space-between" : "center"}
        alignItems="center"
        display="flex"
      >
        {input}
      </Box>
      {caption}
    </PxGridContainer>
  );
};

interface PriceProps {
  totalPrice: number;
  deposit?: number;
  amount: number;
}
export const Price = ({ totalPrice, deposit, amount }: PriceProps) => {
  const classes = useItemStyles();
  return (
    <Box marginBottom="32px">
      <Box className={classes.price}>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Body3Typo fontWeight={500}>합계</Body3Typo>
          <Body3Typo>
            ₩ {totalPrice.toString()}
            {/* {totalPrice.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} */}
          </Body3Typo>
        </Box>
        {deposit && (
          <>
            <Box
              width="100%"
              height="1px"
              marginY="17px"
              style={{ backgroundColor: "rgb(220, 222, 225)" }}
            />
            <Box width="100%" display="flex" justifyContent="space-between">
              <Body3Typo>상담회 보증금</Body3Typo>
              <Body3Typo>- ₩ {deposit.toLocaleString("ko-KR")}</Body3Typo>
            </Box>
          </>
        )}
      </Box>
      <Amount amount={amount} />
      <CaptionTypo>※ 잔금은 이벤트 종료 후 2일 이내 입금요망</CaptionTypo>
    </Box>
  );
};

export const Amount = ({ amount }: { amount: number }) => {
  const classes = useItemStyles();
  return (
    <Box width="100%" className={classes.amount}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <HeadingTypo fontweight="bold" txtcolor="purple">
          입금하실 금액
        </HeadingTypo>
        <Body3Typo fontWeight={800} txtcolor="purple">
          ₩ {amount.toLocaleString("ko-KR")}
        </Body3Typo>
      </Box>
    </Box>
  );
};

export const PexpoAccount = () => {
  return (
    <Box marginBottom="32px">
      <Body1Typo fontSize="20px" fontWeight="500">
        입금하실 계좌
      </Body1Typo>
      <Body1Typo fontSize="15px" txtcolor="black0.6">
        입금시 이벤트 이름을 간단히 적어주시기 바랍니다.{" "}
      </Body1Typo>
      <Box
        borderRadius="12px"
        padding="23px 32px"
        bgcolor="rgb(251, 251, 251)"
        display="flex"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <img src={WooriIcon} />
          &nbsp;&nbsp;&nbsp;1005—403-281147
        </Box>
        <Body3Typo>주식회사 킨터치</Body3Typo>
      </Box>
    </Box>
  );
};

interface TaxbillProps extends Partial<OutlinedTextFieldProps> {
  nameInput: string;
  name: string;
  subTitle: string | ReactNode;
  value: string;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onChangeInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Taxbill = ({
  onChangeInput,
  name,
  subTitle,
  onChange,
  nameInput,
  value,
  ...props
}: TaxbillProps) => {
  const classes = useItemStyles();
  return (
    <Box marginBottom="32px">
      {" "}
      <Body1Typo fontSize="20px" fontWeight="500">
        세금계산서 발행메일
      </Body1Typo>
      <OutlinedTxtField
        inputMode="email"
        fullWidth
        className={classes.taxBill}
        name={name}
        onChange={onChange}
        value={value}
        inputProps={{ style: { textAlign: "center", padding: "12px 14px" } }}
        placeholder="ex) kinterch@pexpo.io"
        FormHelperTextProps={{ style: { color: "rgba(255,0,0,0.6)" } }}
        {...props}
      />
      <Box marginTop="32px">
        <Item
          title="사업자등록증"
          subTitleColor={subTitle === "없음" ? "black0.6" : "purple700"}
          subTitle={subTitle}
          input={
            onChangeInput ? (
              <LoadFileInput
                name={nameInput}
                onChange={onChangeInput}
                accept="application/pdf, image/jpeg, image/png"
              />
            ) : (
              <></>
            )
          }
        />
      </Box>
    </Box>
  );
};

export const CalendarSelector = ({
  name,
  value,
  onChange,
  disabled,
}: {
  name: string;
  value?: any;
  disabled?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) => {
  const classes = useItemStyles();
  return (
    <TextField
      id="date"
      variant="outlined"
      type="date"
      defaultValue="2020-05-24"
      name={name}
      disabled={disabled}
      value={value}
      onChange={onChange}
      inputProps={{ style: { textAlign: "center" } }}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

interface OutlinedTxtFieldProps extends Partial<OutlinedTextFieldProps> {}
export const InputNumber = (props: OutlinedTxtFieldProps) => {
  const { ...rest } = props;
  const classes = useItemStyles();
  return (
    <TextField
      variant="outlined"
      fullWidth
      className={classes.inputNumber}
      inputProps={{ style: { textAlign: "center", padding: 10 } }}
      {...rest}
    />
  );
};

export const LoadFileInput = ({
  name,
  onChange,
  accept,
}: {
  accept: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Button
      variant="contained"
      component="label"
      style={{
        boxShadow: "none",
        backgroundColor: "#f1f2f5",
        padding: "6px 19px 10px",
        color: "rgba(0, 0, 0, 0.6)",
      }}
    >
      추가
      <input
        type="file"
        onChange={onChange}
        name={name}
        style={{ display: "none" }}
        accept={accept}
      />
    </Button>
  );
};

export const DialogImgBtn = ({
  title,
  link,
}: {
  title: string;
  link: string;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open === true ? <ImageDialogs imgLink={link} setOpen={setOpen} /> : null}
      <UnderLineTypo onClick={() => setOpen(!open)}>{title}</UnderLineTypo>
    </>
  );
};

const UnderLineTypo = styled.span`
  font-size: 16px;
  text-decoration: underline;
  color: rgb(81, 45, 168) !important;
  cursor: pointer;
`;

const Container = styled.div`
  width: 638px;
  margin-top: 52px;
  margin-bottom: 125px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: 640,
    flexDirection: "column",
    margin: "auto",
  },
  title: {
    textAlign: "center",
  },
  cardRoot: {
    display: "flex",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      margin: "0px 20px",
    },
  },
  cardImage: {
    width: 200,
    maxHeight: 145,
    objectFit: "fill",
    [theme.breakpoints.down("xs")]: {
      width: 120,
      maxHeight: 110,
    },
  },
  cardDetails: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
  },
  formRoot: {
    paddingTop: 30,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 20px",
    },
  },
  businessCardRoot: {
    padding: 20,
    margin: "20px 100px 40px 100px",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0px 40px 0px",
      padding: 5,
    },
  },
}));

const useItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      width: 286,
      borderRadius: 0,
      //   border: "solid 1px rgba(0, 0, 0, 0.12)",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 0,
        "&:before": {
          content: "' '",
          position: "absolute",
          left: 0,
          width: 230,
          height: "100%",
          backgroundColor: "transparent",
          border: "solid 1px rgba(0, 0, 0, 0.12)",
        },
      },
      "&::before": {
        content: "' '",
        position: "absolute",
        right: 0,
        width: 56,
        height: "100%",
        border: "solid 1px rgb(241, 242, 245)",
        backgroundColor: "rgb(241, 242, 245)",
      },
      //   "&:before": {
      //     content: "' '",
      //     position: "absolute",
      //     left: 0,
      //     width: 230,
      //     height: "100%",
      //     backgroundColor: "transparent",
      //     border: "solid 1px rgba(0, 0, 0, 0.12)",
      //   },
    },
    inputNumber: {
      "& .MuiOutlinedInput-root": {
        borderRadius: 0,
      },
    },
    price: {
      padding: "16px 20px 17px",
      border: "solid 1px rgba(0, 0, 0, 0.12)",
    },
    amount: {
      padding: "26px 20px 29px",
      border: "solid 4px rgb(81, 45, 168)",
      marginBottom: 12,
    },
    taxBill: {
      borderRadius: 5,
      fontSize: 17,
      color: "rgba(0, 0, 0, 0.87)",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgb(151, 151, 151)",
      },
    },
  })
);
