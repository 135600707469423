import React, { useState } from "react";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import EventListByDate from "./eventListByDate";
import EventListByName from "./eventListByName";
import PrinterTab from "./printerTab";

const PrinterDetail = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <>
      <PrinterTab selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <PxGridContainer>
        {selectedTab === 0 && <EventListByDate />}
        {selectedTab === 1 && <EventListByName />}
      </PxGridContainer>
    </>
  );
};

export default PrinterDetail;
