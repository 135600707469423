import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// types
import SubHeadingTypo from "../Typography/SubHeadingTypo";
import { CountryInfo } from "../../types/models/Country";

// actions
import { modifyCountry, getCountry } from "../../actions/country";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import OutlinedTxtField from "../Inputs/OutlinedTxtField";
import PxButton from "../Buttons/PxButton";
import ButtonTypo from "../Typography/ButtonTypo";

import styled from "styled-components";
import { AppState } from "../../store";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const CountryModify = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const [country, setCountry] = useState<CountryInfo>({
    id: "",
    code: "",
    koName: "",
    enName: "",
    timezone: "",
    i18code: "",
  });
  const countryDetail = useSelector((state: AppState) => state.countries.country);

  useEffect(() => {
    dispatch(getCountry(params.id));
  }, []);

  useEffect(() => {
    if (countryDetail !== undefined) {
      setCountry({
        id: countryDetail.id,
        code: countryDetail.code,
        koName: countryDetail.koName,
        enName: countryDetail.enName,
        timezone: countryDetail.timezone,
        i18code: countryDetail.i18code,
      });
    }
  }, [countryDetail]);

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    let name: string = e.target.name as string;
    let value: string = e.target.value as string;

    setCountry({ ...country, [name]: value });
  };

  // 국가 수정
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const countryRes: any = await dispatch(modifyCountry(country));
    if (countryRes === "success") history.goBack();
  };

  return (
    <Grid container>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px" }}>
        <form onSubmit={onSubmit}>
          <SubTitle>국가코드(2자리)</SubTitle>
          <OutlinedTxtField
            name="code"
            value={country.code}
            fullWidth
            onChange={handleOnChange}
            placeholder="코드를 입력해주세요"
          />
          <SubHeadingTypo style={{ color: "gray" }}>
            사용자 or 주최자 페이지의 개발자모드(크롬기준 F12)에서 "{">>>>>"} 현재 접속국가입니다"
            문구 아래 countryCode 값 2글자
            <br />
            예상결과는 <a href="https://extreme-ip-lookup.com/json">내 접속국가 정보</a>에서
            확인가능
          </SubHeadingTypo>

          <SubTitle>국가명(국문)</SubTitle>
          <OutlinedTxtField
            name="koName"
            value={country.koName}
            fullWidth
            onChange={handleOnChange}
            placeholder="국가명(국문)을 입력해주세요"
          />
          <SubTitle>국가명(영문)</SubTitle>
          <OutlinedTxtField
            name="enName"
            value={country.enName}
            fullWidth
            onChange={handleOnChange}
            placeholder="국가명(영문)을 입력해주세요"
          />
          <SubTitle>타임존</SubTitle>
          <OutlinedTxtField
            name="timezone"
            value={country.timezone}
            fullWidth
            onChange={handleOnChange}
            placeholder="타임존을 입력해주세요"
          />
          <SubHeadingTypo style={{ color: "gray" }}>
            <a href="https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a">
              타임존 목록
            </a>
            에서 확인
            <br />
            moment에서 지원하지 않는 국가일경우 Etc/GMT 시간을 직접 지정해야함
            <br />
            직접등록시 Etc/GMT 시간 반전으로 넣어야함 예）Etc/GMT+9 → Etc/GMT-9{" "}
            <a href="https://momentjs.com/timezone/docs/#/zone-object/offset/">관련문서</a>
          </SubHeadingTypo>

          <SubTitle>국가(번역)</SubTitle>
          <OutlinedTxtField
            name="i18code"
            value={country.i18code}
            fullWidth
            onChange={handleOnChange}
            placeholder="국가(번역)을 입력해주세요"
          />

          <Grid
            container
            alignContent="space-between"
            spacing={1}
            style={{ margin: "32px 0 8px 0" }}
          >
            <Grid item>
              <PxButton backgroundColor="purple" type="submit">
                <ButtonTypo>국가 수정하기</ButtonTypo>
              </PxButton>
            </Grid>
            <Grid item>
              <PxButton
                backgroundColor="grey"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ButtonTypo>취소</ButtonTypo>
              </PxButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default CountryModify;
