import FormAnswerActionType from "../types/actions/FormAnswerAction";
import { FormAnswer } from "../types/models/FormAnswer";

interface FormAnswerReducerType {
  formAnswers: FormAnswer[];
}

const formAnswerDefaultState: FormAnswerReducerType = {
  formAnswers: [],
};

const formAnswerReducer = (
  state = formAnswerDefaultState,
  action: FormAnswerActionType
): FormAnswerReducerType => {
  switch (action.type) {
    case "GET_FORM_ANSWERS":
      return {
        ...state,
        formAnswers: action.formAnswers,
      };
    case "REMOVE_FORM_ANSWER":
      return formAnswerDefaultState;
    default:
      return formAnswerDefaultState;
  }
};

export default formAnswerReducer;
