import React from "react";
import Container from "@material-ui/core/Container";
import AdvertisingList from "../views/Advertising/AdvertisingList";

const Advertising = () => {
  return (
    <Container>
      <AdvertisingList />
    </Container>
  );
};

export default Advertising;
