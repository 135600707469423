import File from "../types/models/File";
import { AppActions, AppState } from "../store";
import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Alert from "../types/models/Alert";
import { v4 } from "uuid";
import Error from "../types/models/Error";
import { setProgressAction } from "./progresses";

// SignedRequest for image Upload to AWS S3
export const createFileAction = (file: File): AppActions => ({
  type: "FILE_UPLOAD",
  file,
});

export const resetFileAction = (): AppActions => ({
  type: "FILE_RESET",
});

// 파일등록
export const getSignedRequest =
  (
    file: File,
    fileName: string,
    fileType: string,
    folderPath: string,
    fileSize: number,
    gubun: string
  ) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const reg = /(jpg|jpeg|png|gif|bmp)$/;

    if (gubun === "mainImage" && !fileType.toLowerCase().match(reg)) {
      const alert: Alert = {
        alertType: "warning",
        id: v4(),
        msg: "이미지 파일을 등록해주세요",
      };
      dispatch(setAlertAction(alert));
      return;
    }

    if (gubun === "bannerImage" && !fileType.match(reg)) {
      const alert: Alert = {
        alertType: "warning",
        id: v4(),
        msg: "이미지 파일을 등록해주세요",
      };
      dispatch(setAlertAction(alert));
      return;
    }

    if (fileSize > 10 * 1024 * 1024) {
      const alert: Alert = {
        alertType: "warning",
        id: v4(),
        msg: "파일 사이즈가 10mb를 넘지 않아야합니다.",
      };
      dispatch(setAlertAction(alert));
      return;
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.append("file", file as any);
    formData.append("fileName", fileName as string);
    formData.append("fileType", fileType as string);
    formData.append("folderPath", folderPath as string);
    formData.append("fileSize", fileSize.toString() as string);

    // dispatch(setProgressAction({ open: "true" }));
    try {
      const res: AxiosResponse<any> = await axios.post(
        "/api/fileS3",
        formData,
        config
      );
      Object.assign(res.data, { gubun: gubun });
      dispatch(createFileAction(res.data));
      // dispatch(setProgressAction({ open: "false" }));
      return res;
    } catch (err) {
      // dispatch(setProgressAction({ open: "false" }));
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

// 파일 리덕스 초기화
export const fileStateToInitial =
  () => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(resetFileAction());
  };

const setAlert = (status: number, error: Error): Alert => {
  const alert: Alert = {
    alertType: "warning",
    id: v4(),
    msg: error.detail,
  };

  if (status === 403) {
    alert.msg = "권한이 없습니다.";
  }

  return alert;
};

export const getFiles =
  (ids: number[]) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/fileS3/getFiles?ids=${ids}`;
    try {
      const res: AxiosResponse = await axios.get(path, config);
      return res.data;
    } catch (err) {
      return undefined;
    }
  };

export const getFile =
  (ids: number) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/fileS3/getFiles?ids=${ids}`;
    try {
      const res: AxiosResponse = await axios.get(path, config);
      return res.data;
    } catch (err) {
      return undefined;
    }
  };

// 에디터 파일등록
export const getEditorImageRequest =
  (
    file: File,
    fileName: string,
    fileType: string,
    folderPath: string,
    fileSize: number,
    gubun: string,
    accept?: string
  ) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const reg = /(jpg|jpeg|png|gif|bmp)$/;
    if (!fileType.toLowerCase().match(reg)) {
      const alert: Alert = {
        alertType: "warning",
        id: v4(),
        msg: "이미지 파일은 jpg,jpeg,png 파일만 가능합니다.",
      };
      dispatch(setAlertAction(alert));
      return;
    }

    if (fileSize > 10 * 1024 * 1024) {
      const alert: Alert = {
        alertType: "warning",
        id: v4(),
        msg: "파일 사이즈가 10mb를 넘지 않아야합니다.",
      };
      dispatch(setAlertAction(alert));
      return;
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.append("file", file as any, fileName);
    formData.append("fileName", fileName as string);
    formData.append("fileType", fileType as string);
    formData.append("folderPath", folderPath as string);
    formData.append("fileSize", fileSize.toString() as string);

    dispatch(setProgressAction({ open: "true" }));
    try {
      const res: AxiosResponse<any> = await axios.post(
        "/api/fileS3",
        formData,
        config
      );
      Object.assign(res.data, { gubun: gubun });
      dispatch(setProgressAction({ open: "false" }));
      return res;
    } catch (err) {
      dispatch(setProgressAction({ open: "false" }));
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };
