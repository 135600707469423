import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { AppState } from "../../store";
import { Column, MTableToolbar } from "material-table";
import styled from "styled-components";
// actions
import { getCountries } from "../../actions/country";

// custom ui comp
import PxTable from "../../components/Tables/PxTable";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import PxGridContainer from "../Grid/PxGridContainer";
import { setTableInfoLoadingAction } from "../../actions/tableInfo";

const StyledDiv = styled.div`
  width: 100%;
  padding: 0px 30px;
`;

interface RowType {
  countryTitle: string;
  countryCreatedDate: string;
  countryUpdatedDate: string;
  countryentId: string;
  countryId: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const CountryList: React.FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const countries = useSelector((state: AppState) => state.countries.countries);

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      // {
      //   title: "제목",
      //   field: "countryTitle",
      //   cellStyle: {
      //     width: "calc(100%-600px)",
      //     paddingRight: 0,
      //     maxWidth: "500px",
      //     whiteSpace: "nowrap",
      //     overflow: "hidden",
      //     textOverflow: "ellipsis",
      //   },
      // },
      {
        title: "코드",
        field: "countryCode",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: "국가명(국문)",
        field: "countryKoName",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: "국가명(영문)",
        field: "countryEnName",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: "타임존",
        field: "countryTimezone",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: "국가(번역)",
        field: "countryI18Code",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
    ],
    data: [],
  });

  useEffect(() => {
    let tableData: any = [];
    if (Array.isArray(countries) && countries.length > 0) {
      countries.map((country) => {
        const rowData = {
          countryCode: country.code,
          countryKoName: country.koName,
          countryEnName: country.enName,
          countryTimezone: country.timezone,
          countryI18Code: country.i18code,
          // countryCreatedDate: moment(country.createdDate).format("YYYY-MM-DD"),
          // countryUpdatedDate: moment(country.modifiedDate).format("YYYY-MM-DD"),
          countryId: country.id,
        };
        tableData.push(rowData);
      });
    }
    setState((prevCreateDate) => {
      return { ...prevCreateDate, data: tableData };
    });
    dispatch(setTableInfoLoadingAction(false));
  }, [countries]);

  return (
    <>
      <div style={{ textAlign: "right", margin: "10px" }}>
        <PxButton
          backgroundColor="purple"
          onClick={() => history.push(`${match.url}/add`)}
        >
          <ButtonTypo>국가 추가</ButtonTypo>
        </PxButton>
      </div>
      <StyledDiv>
        <PxTable<RowType>
          components={{
            Toolbar: (props) => {
              return (
                <PxGridContainer direction="column">
                  {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                  <MTableToolbar {...props} />
                </PxGridContainer>
              );
            },
          }}
          title="Basic Selection Preview"
          columns={state.columns}
          data={state.data}
          onRowClick={(evt, selectedRow) => {
            history.push(`${match.url}/${selectedRow?.countryId}`);
          }}
          // material-table 속성: https://material-table.com/#/docs/features/search
          options={{
            toolbar: true,
            actionsColumnIndex: -1,
            pageSize: 10,
            showTitle: false,
            defaultExpanded: false,
            search: true,
            selection: false,
            searchFieldAlignment: "right",
            exportButton: false, // csv 다운
          }}
        />
      </StyledDiv>
    </>
  );
};

export default CountryList;
