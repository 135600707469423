import React, { useEffect, useMemo, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import PxTable from "../../components/Tables/PxTable";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "material-table";
import {
  getPrinterSubEventsByDate,
  getPrinterSubEventsByName,
} from "../../actions/printers";
import { AppState } from "../../store";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
import PxGridItem from "../../components/Grid/PxGridItem";
import CaptionTypo from "../../components/Typography/CaptionTypo";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import debounce from "lodash/debounce";
import { useHistory } from "react-router";

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

interface RowType {
  subEventId: string;
  date: string;
  name: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flexGrow: 1,
      padding: "15px 40px",
      backgroundColor: "#f5f5f5",
      border: "1px solid",
      borderColor: "#bdbdbd",
      marginBottom: 15,
    },
  })
);

const EventListByName = () => {
  const classes = useStyles();
  const history = useHistory();
  const nowDate = moment().format("YYYY-MM-DD");
  const dispatch = useDispatch();

  const subEvents = useSelector((state: AppState) => state.printers.subEvents);

  const [searchEventName, setSearchEventName] = useState<string>("");
  const [tableState, setTableState] = useState<TableStateType>();

  useEffect(() => {
    dispatch(getPrinterSubEventsByDate(nowDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEvents = useMemo(
    () =>
      debounce(async (eventName: string) => {
        await dispatch(getPrinterSubEventsByName(eventName));
      }, 500),
    [dispatch]
  );

  const handleTxtChange = async (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    setSearchEventName(e.target.value as string);
  };

  useEffect(() => {
    if (searchEventName.length >= 1) {
      getEvents(searchEventName);
    }
  }, [getEvents, searchEventName]);

  useEffect(() => {
    if (subEvents) {
      const tableData: RowType[] = subEvents.map((subEvent) => ({
        subEventId: subEvent.id,
        name: subEvent.mainEvent.name,
        date: `${moment(subEvent.subEventStartDate).format(
          "YYYY.MM.DD"
        )} - ${moment(subEvent.subEventEndDate).format("YYYY.MM.DD")}`,
      }));

      setTableState({
        columns: [
          {
            field: "name",
            render: (rowData) => (
              <PxGridContainer direction="column">
                <SubHeadingTypo fontweight="bold">
                  {rowData.name}
                </SubHeadingTypo>
                <PxGridContainer direction="row" spacing={1}>
                  <PxGridItem>
                    <CaptionTypo txtcolor="grey">{rowData.date}</CaptionTypo>
                  </PxGridItem>
                </PxGridContainer>
              </PxGridContainer>
            ),
          },
        ],
        data: tableData,
      });
    }
  }, [subEvents]);

  return (
    <>
      <form className={classes.container} noValidate>
        <TextField
          id="text"
          type="text"
          defaultValue=""
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleTxtChange}
        />
      </form>
      <PxTable<RowType>
        columns={tableState?.columns ?? []}
        data={tableState?.data ?? []}
        onRowClick={(e, rowData) => {
          history.push(`/printer/${rowData?.subEventId}/test`);
        }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          header: false,
          defaultExpanded: false,
          search: false,
          toolbar: false,
          rowStyle: {
            borderBottom: "none",
          },
        }}
      />
    </>
  );
};

export default EventListByName;
