import React from "react";
import styled from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface SubHeadingTypoProps {
  fontweight?: string;
  txtcolor?: "rgba(0,0,0,0.87)" | "purple" | "secondary";
}

type Prop = SubHeadingTypoProps & TypographyProps;

const SubHeadingTypo: React.FC<Prop> = styled((props: Prop) => {
  const { txtcolor, fontweight, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: 15px;
  font-family: NotoSansCJKkr;
  font-weight: ${(props) => props.fontweight ?? "normal"};
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.4px;
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    }
    if (props.txtcolor === "secondary") {
      return "#6d7278";
    }
    return props.txtcolor;
  }};
`;

SubHeadingTypo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default SubHeadingTypo;
