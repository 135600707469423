import React from "react";
import CompanyInfoAdditional from "./CompanyInfoAdditional";
import CompanyInfoContent from "./CompanyInfoContent";
import CompanyManagerInfo from "./CompanyManagerInfo";
import { useCompanyInfoContext } from "./useCompanyInfoContext";

const CompanyInfoView = () => {
  const { activeStep } = useCompanyInfoContext();

  // activeStep 0: 기업정보, 1: 추가정보, 2: 담당자
  return activeStep === 0 ? (
    <CompanyInfoContent />
  ) : activeStep === 1 ? (
    <CompanyInfoAdditional />
  ) : (
    <CompanyManagerInfo />
  );
};

export default CompanyInfoView;
