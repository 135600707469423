import { AppActions, AppState } from "../store";
import Dialog from "../types/models/Dialog";
import { Dispatch } from "react";

export const setDialogAction = (
  dialog: Dialog["materialDialog"]
): AppActions => ({
  type: "SET_DIALOG",
  dialog: dialog,
});

export const setPxDialogAction = (dialog: Dialog["pxDialog"]): AppActions => ({
  type: "SET_PXDIALOG",
  dialog: dialog,
});

export const setDialog =
  (title: string, content: string[]) =>
  (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const dialog: Dialog["materialDialog"] = {
      open: "true",
      title: title,
      content: content,
    };
    dispatch(setDialogAction(dialog));
  };

export const closeDialog =
  (e: React.MouseEvent<HTMLElement, MouseEvent>) =>
  (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(
      setPxDialogAction({
        open: false,
        width: "",
        children: "",
        isBackgroundColor: true,
        footer: "",
        footerJustifyContent: "flex-end",
      })
    );
  };
