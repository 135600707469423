import { Dispatch } from "redux";
import { AppActions } from "../store";

export const setLoadingAction = (): AppActions => ({
  type: "SET_LOADING",
});

export const removeLoadingAction = (): AppActions => ({
  type: "REMOVE_LOADING",
});

export const setBackDropLoadingAction = (): AppActions => ({
  type: "SET_BACKDROP_LOADING",
});

export const removeBackDropLoadingAction = (): AppActions => ({
  type: "REMOVE_BACKDROP_LOADING",
});


export const setLoading = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(setLoadingAction());
};

export const removeLoading = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(removeLoadingAction());
};
