import { AppActions, AppState } from "../store";
import axios, { AxiosResponse } from "axios";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import { getRequest, postRequest, putRequest } from "../utils/utils";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { setAlert } from "../utils/utils";

// types
import { SubEventInfo } from "../types/models/SubEvent";
import { Dispatch } from "react";

export const getSubEventAction = (subEvent: SubEventInfo): AppActions => ({
  type: "GET_SUB_EVENT",
  subEvent: subEvent,
});

export const removeSubEventAction = (): AppActions => ({
  type: "REMOVE_SUB_EVENT",
});

export const getSubEventsAction = (subEvents: SubEventInfo[]): AppActions => ({
  type: "GET_SUB_EVENTS",
  subEvents,
});

export const modifyMeetingAvailableAction = (): AppActions => ({
  type: "MODIFY_MEETING_AVAILABLE",
});

export const getSubEvent = (subEventId: number) => {
  return getRequest(subEventId, `/api/subEvents/detail`, getSubEventAction, {
    id: subEventId,
  });
};

export const removeSubEvent = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(removeSubEventAction());
};

export const createSubEvent = (subEvent: SubEventInfo) => {
  if (subEvent.matching === "Y") {
    return postRequest(subEvent, "/api/subEvents/matching");
  }
  return postRequest(subEvent, "/api/subEvents/mismatch");
};

export const modifySubEvent = (subEvent: SubEventInfo) => {
  if (subEvent.matching === "Y") {
    return putRequest(subEvent, "/api/subEvents/matching");
  }
  return putRequest(subEvent, "/api/subEvents/mismatch");
};

export const getSubEvents =
  <SubEvent>(eventId: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/subEvents?eventId=${eventId}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      dispatch(getSubEventsAction(res.data));
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

// 이벤트 상태변경([승인요청, 승인, 거절, 삭제] -> 삭제)
export const modifySubEventApprovalDelete =
  (id: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      params: { id: id },
      data: {},
    };

    const path = "/api/subEvents/approvalDelete";
    try {
      var res: any = await axios.get(path, config);

      return "success";
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return "error";
    }
  };

export const changeSubEventPayment =
  (id: string, payment: "N" | "Y") =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };

    const body = JSON.stringify({ id: id, type: payment });

    const path = "/api/subEvents/payment";
    try {
      const res: AxiosResponse<SubEventInfo> = await axios.put(
        path,
        body,
        config
      );
      const subEvents = getState().subEvents.subEvents.map((data) =>
        data.id === res.data.id
          ? {
              ...data,
              payment: res.data.payment,
              paymentApprovedDate: res.data.paymentApprovedDate,
            }
          : data
      );

      dispatch(getSubEventsAction(subEvents));
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

export const getSubEventsByMainEventId = (mainEventId: number) => {
  return getRequest(null, "/api/subEvents/mainEvent", undefined, {
    mainEventId: mainEventId,
  });
};

export const modifySubEventMeetingAvailable = (data: { id: string }) => {
  return putRequest(
    data,
    "/api/subEvents/meetingAvailable",
    modifyMeetingAvailableAction
  );
};
