import Container from "@material-ui/core/Container";
import React, { useEffect, useMemo, useState } from "react";
import OutlinedTxtField from "../../components/Inputs/OutlinedTxtField";
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
import TitleTypo from "../../components/Typography/TitleTypo";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Printer } from "../../types/models/Printer";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxGridItem from "../../components/Grid/PxGridItem";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import { useHistory } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import {
  createPrinter,
  getPrinterSubEventsByName,
} from "../../actions/printers";
import { AppState } from "../../store";
import moment from "moment";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 20,
  },
  titleTypo: {
    textAlign: "center",
  },
  subHeadingTypo: {
    margin: "10px 0 8px 0",
    fontWeight: "bold",
  },
  buttonContainer: {
    justifyContent: "center",
    marginTop: "32px",
  },
}));

const PrinterAdd = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  // const printerSubEvents = useSelector(
  //   (state: AppState) => state.printers.subEvents
  // );

  // const [autoutoCompleteOptions, setOptions] = useState<
  //   { eventName: string; eventDate: string }[]
  // >([]);
  const [printer, setPrinter] = useState<Printer>({
    name: "",
    eventName: "",
    subEventName: "",
    ipAddress: "",
  });

  // useEffect(() => {
  //   if (printerSubEvents && printerSubEvents?.length !== 0) {
  //     setOptions(
  //       printerSubEvents.map((subEvent) => ({
  //         eventName: subEvent.mainEvent.name,
  //         eventDate: `${moment(subEvent.subEventStartDate).format(
  //           "YYYY-MM-DD"
  //         )} ~ ${moment(subEvent.subEventEndDate).format("YYYY-MM-DD")}`,
  //       }))
  //     );
  //   }
  // }, [printerSubEvents]);

  const getEvents = useMemo(
    () =>
      debounce(async (eventName: string) => {
        if (eventName !== "") {
          await dispatch(getPrinterSubEventsByName(eventName));
        }
      }, 500),
    [dispatch]
  );

  // const autoCompleteOnChange = (
  //   event: any,
  //   newValue: { eventName: string; eventDate: string } | null
  // ) => {
  //   setPrinter({
  //     ...printer,
  //     eventName: newValue!.eventName,
  //   });
  // };

  const textFieldOnChange = (
    e: React.ChangeEvent<{
      value: string;
      name: string;
    }>
  ) => {
    setPrinter({
      ...printer,
      [e.target.name as string]: e.target.value as string,
    });
    // if (e.target.name === "eventName") {
    //   getEvents(e.target.value);
    // } else {
    //   setPrinter({
    //     ...printer,
    //     [e.target.name as string]: e.target.value as string,
    //   });
    // }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res: any = await dispatch(createPrinter(printer));

    if (res) {
      history.goBack();
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <TitleTypo className={classes.titleTypo}>프린터 추가</TitleTypo>
      <form onSubmit={onSubmit}>
        <SubHeadingTypo className={classes.subHeadingTypo}>
          프린터 이름
        </SubHeadingTypo>
        <OutlinedTxtField
          name="name"
          value={printer.name}
          fullWidth
          onChange={textFieldOnChange}
          placeholder="프린터 이름을 입력해주세요."
        />
        <SubHeadingTypo className={classes.subHeadingTypo}>
          아이피 주소
        </SubHeadingTypo>
        <OutlinedTxtField
          name="ipAddress"
          value={printer.ipAddress}
          fullWidth
          onChange={textFieldOnChange}
          placeholder="프린터 아이피 주소를 입력해주세요."
        />
        <SubHeadingTypo className={classes.subHeadingTypo}>
          이벤트 이름
        </SubHeadingTypo>
        <OutlinedTxtField
          name="eventName"
          value={printer.eventName}
          fullWidth
          onChange={textFieldOnChange}
          placeholder="이벤트 이름을 입력해주세요."
        />
        <SubHeadingTypo className={classes.subHeadingTypo}>
          서브 이벤트 이름
        </SubHeadingTypo>
        <OutlinedTxtField
          name="subEventName"
          value={printer.subEventName}
          fullWidth
          onChange={textFieldOnChange}
          placeholder="서브이벤트 이름을 입력해주세요."
        />
        {/* <Autocomplete
          options={autoutoCompleteOptions}
          getOptionLabel={(option) => option.eventName}
          fullWidth
          onChange={autoCompleteOnChange}
          renderInput={(params) => (
            <OutlinedTxtField
              {...params}
              name="eventName"
              onChange={textFieldOnChange}
              InputProps={{
                ...params.InputProps,
                style: { padding: 3 },
              }}
              placeholder="이벤트 이름을 입력 후 목록에서 이벤트를 선택해주세요."
            />
          )}
        /> */}

        <PxGridContainer
          alignContent="space-between"
          spacing={1}
          className={classes.buttonContainer}
        >
          <PxGridItem>
            <PxButton
              backgroundColor="default"
              padding="10px 40px"
              onClick={() => {
                history.goBack();
              }}
            >
              <ButtonTypo>취소</ButtonTypo>
            </PxButton>
          </PxGridItem>
          <PxGridItem>
            <PxButton
              backgroundColor="purple"
              type="submit"
              padding="10px 40px"
            >
              <ButtonTypo>완료</ButtonTypo>
            </PxButton>
          </PxGridItem>
        </PxGridContainer>
      </form>
      <div></div>
    </Container>
  );
};

export default PrinterAdd;
