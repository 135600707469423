import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, RouteComponentProps } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column, MTableToolbar } from "material-table";

// actions
import { getInterpreters } from "../../../../actions/interpreter";

// custom ui comp
import PxButton from "../../../../components/Buttons/PxButton";

import moment from "moment";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import { useTranslation } from "react-i18next";
import ButtonTypo from "../../../../components/Typography/ButtonTypo";
import PxTable from "../../../../components/Tables/PxTable";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";

interface RowType {
  interpreterEmailId: string;
  interpreterName: string;
  // interpreterEmail: string;
  interpreterPhoneNumber: string;
  interpreterCreatedDate: string;
  interpreterId: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const InterpreterList: React.FC<RouteComponentProps> = ({ match }) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string } = useParams();
  const [loading, setLoading] = useState<boolean>(true);

  const { interpreters } = useSelector((state: AppState) => state.interpreters);

  const callGetInterpreters = useCallback(async () => {
    await dispatch(getInterpreters(param.id));
    dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  useEffect(() => {
    callGetInterpreters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetInterpreters]);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("interpreterAdd.email(id)"),
        field: "interpreterEmailId",
        // cellStyle: {
        //   width: "calc(100%-600px)",
        //   paddingRight: 0,
        //   maxWidth: "500px",
        //   whiteSpace: "nowrap",
        //   overflow: "hidden",
        //   textOverflow: "ellipsis",
        // },
      },
      {
        title: t("common.name"),
        field: "interpreterName",
      },
      // {
      //   title: "이메일",
      //   field: "interpreterEmail",
      // },
      {
        title: t("common.phone"),
        field: "interpreterPhoneNumber",
      },
      {
        title: t("common.registerDate"),
        field: "interpreterCreatedDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    let tableData: RowType[] = [];
    if (Array.isArray(interpreters) && interpreters.length > 0) {
      interpreters.forEach((interpreter) => {
        const rowData = {
          interpreterEmailId: interpreter.userId.email,
          interpreterName: interpreter.name,
          // interpreterEmail: interpreter.email,
          interpreterPhoneNumber: interpreter.phoneNumber,
          interpreterCreatedDate: moment(interpreter.createdDate).format(
            "YYYY-MM-DD"
          ),
          interpreterId: interpreter.id,
        };
        tableData.push(rowData);
      });
    }
    setState((prevCreateDate) => {
      return { ...prevCreateDate, data: tableData };
    });
    setLoading(false);
  }, [interpreters]);

  return (
    <>
      <div style={{ textAlign: "right", margin: "10px" }}>
        <PxButton
          backgroundColor="purple"
          onClick={() => history.push(`${match.url}/add`)}
        >
          <ButtonTypo>{t("common.add")}</ButtonTypo>
        </PxButton>
      </div>

      <PxTable<RowType>
        components={{
          Toolbar: (props) => {
            return (
              <PxGridContainer direction="column">
                {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        columns={state.columns}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          history.push(`${match.url}/${selectedRow?.interpreterId}`);
        }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
        isLoading={loading}
      />
    </>
  );
};

export default InterpreterList;
