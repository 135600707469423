import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../store";
import { getForm } from "../../../../../actions/forms";
import { FormData } from "../../../../../types/models/AutonoForm";
import { ApplicationFormAnswers } from "../../../../../types/models/Application";
import find from "lodash/find";
import { updateApplicationAnswers } from "../../../../../actions/application";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../../../actions/loading";
import filter from "lodash/filter";
import reject from "lodash/reject";
import { setAlertAction } from "../../../../../actions/alerts";
import { useParams } from "react-router-dom";

// import cloneDeep from "lodash/cloneDeep";  // object deepclone 시 사용

interface CompanyInfoContextType {
  additionalForm?: FormData;
  contentForm?: FormData;
  contentApplicationFormAnswers: ApplicationFormAnswers[];
  additionalApplicationFormAnswers: ApplicationFormAnswers[];
  activeStep: number;
  modify: boolean;
  formId?: number;
  modifiedApplicationFormAnswers: ApplicationFormAnswers[];
  setModify: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  modifyContentAnswers: (
    applicationFormAnswers: ApplicationFormAnswers,
    content: string,
    whtAdditional: boolean,
    additionOrder: number | undefined
  ) => void;
  modifyManyContentAnswers: (
    uuid: string,
    additionOrder: number | undefined,
    whtAdditional: boolean,
    newApplicationManyFormAnswers: ApplicationFormAnswers[]
  ) => void;
  onClickSubmitButton: (e: React.MouseEvent) => void;
  onClickAdditionalAnswerAdd: (e: React.MouseEvent) => void;
  onClickAdditionalAnswerDelete: (additionOrder: number) => void;
}

export const CompanyInfoContext = createContext<CompanyInfoContextType>(null!);

interface CompanyInfoProviderProps {
  children: ReactNode;
}

const CompanyInfoProvider = ({ children }: CompanyInfoProviderProps) => {
  const dispatch = useDispatch();
  const [additionalForm, setAdditionalForm] = useState<FormData>();
  const [contentForm, setContentForm] = useState<FormData>();
  const [modify, setModify] = useState<boolean>(false); // 수정상태 on/off
  const param: { id: string; secondId: string } = useParams();

  const { applicationFormAnswers, id, type } = useSelector(
    (state: AppState) => state.applications.application!
  ); // 서브이벤트 신청서

  // 일반정보 참가신청서 답변
  const [contentApplicationFormAnswers, setContentApplicationFormAnswers] =
    useState<ApplicationFormAnswers[]>([]);
  // 추가정보 참가신청서 답변
  const [
    additionalApplicationFormAnswers,
    setAdditionalApplicationFormAnswers,
  ] = useState<ApplicationFormAnswers[]>([]);
  // 수정된 참가신청서 답변
  const [modifiedApplicationFormAnswers, setModifiedApplicationFormAnswers] =
    useState<ApplicationFormAnswers[]>([]);

  const forms = useSelector((state: AppState) => state.forms);
  const [activeStep, setActiveStep] = useState<number>(0);

  useEffect(() => {
    if (applicationFormAnswers.length > 0) {
      // 답변목록중 하나를 선택해서 formId값으로 저장된 form 데이터를 가져옴
      dispatch(getForm(applicationFormAnswers[0].formId.toString()));

      const contentAnswers: ApplicationFormAnswers[] = [];
      const additionalAnswers: ApplicationFormAnswers[] = [];

      applicationFormAnswers.forEach((answer) => {
        // 반복정보 답변인지 일반답변인지 구분
        if (answer.repeatInfo === "Y") {
          additionalAnswers.push(answer);
        } else {
          contentAnswers.push(answer);
        }
      });

      // 일반정보 답변 set
      setContentApplicationFormAnswers(contentAnswers);

      // 반복정보 답변 set
      setAdditionalApplicationFormAnswers(additionalAnswers);
    }
  }, [applicationFormAnswers, dispatch]);

  // 기존 답변(applicationFormAnswers)값과 답변 수정(modifiedApplicationFormAnswers)값이 같은 주소를 참조하면 안됨
  useEffect(() => {
    if (modify === true && applicationFormAnswers.length !== 0) {
      // 기존 applicationFormAnswers값과 다른 참조를 가진 collectio이 필요하므로 collection의 깊은 복사(deepClone)을 해줌
      const newData = [...applicationFormAnswers];
      const clonedApplicationFormAnswers = newData.map((answer) => {
        const returnData = { ...answer };
        return returnData;
      });

      // 전체 신청서 답변을 수정된 formAnswers값으로 지정
      setModifiedApplicationFormAnswers(clonedApplicationFormAnswers);
    }
  }, [modify, applicationFormAnswers]);

  // 신청서 자율양식 form set
  useEffect(() => {
    if (forms.id !== undefined) {
      const additionalForm: FormData = JSON.parse(forms.additional!);
      const contentForm: FormData = JSON.parse(forms.content!);
      setAdditionalForm(additionalForm); // 추가정보 양식
      setContentForm(contentForm); // 일반정보 양식
    }
  }, [forms]);

  // 일반 신청서 답변 수정
  const modifyContentAnswers = (
    applicationAnswer: ApplicationFormAnswers,
    content: string,
    whtAdditional: boolean,
    additionOrder: number | undefined
  ) => {
    // 기존 답변값(answer)을 찾는다. (추가정보 답변 or 일반 답변에 따라 가져오는 기존 답변을 가져온는 방식이 다름)
    // 추가답변들은 하나의 form을 가지고 여러 추가답변들을 생성하므로 uuid 값이 겹칠 수 있기에 additionOrder 조건도 넣어줌
    let answer;
    if (whtAdditional === true) {
      answer = find(modifiedApplicationFormAnswers, {
        uuid: applicationAnswer.uuid,
        additionOrder: additionOrder,
      });
    } else {
      answer = find(modifiedApplicationFormAnswers, {
        id: applicationAnswer.id,
      });
    }

    // 어떤 답변 데이터인지에 따라서 구분
    switch (applicationAnswer.type) {
      // 단일선택 || 카테고리 => 기본로직은 단문, 장문, url과 같으나 'answerUuid' 값이 더 필요함
      case "category": {
        const splitRadioValue = content.split("[S]");
        if (answer !== undefined) {
          answer.answerUuid = splitRadioValue[0];
          answer.content = splitRadioValue[1];
        }
        break;
      }
      case "single": {
        const splitRadioValue = content.split("[S]");
        if (answer !== undefined) {
          answer.answerUuid = splitRadioValue[0];
          answer.content = splitRadioValue[1];
        }
        break;
      }
      default:
        if (answer !== undefined) {
          answer.content = content;
        }
    }
  };

  // 복수선택 답변 수정
  // 복수선택 답변은 다른 답변값들과 달리 (다른 답변들은 content에 "" or undefined 값이 있음)
  // 만약 사용자가 답변 선택을 안했을 경우, 답변 값이 존재하지 않기 때문에 새로운 답변 값을 넣어줘야함
  // 기존 값이 있을 경우와 없을 경우를 판단하기 어려워 새로운 답변의 uuid 값으로 기존 답변을 지워주고 새로운 답변만 넣어주는 방식
  const modifyManyContentAnswers = (
    uuid: string, // 질문의 고유id
    additionOrder: number | undefined, // 추가답변일 경우, 반복순서
    whtAdditional: boolean, // 추가답변 or 일반답변 구분
    newApplicationManyFormAnswers: ApplicationFormAnswers[] // 새로 추가될 복수선택 답변
  ) => {
    let filteredAnswer;
    if (whtAdditional === true) {
      // 선택한 답변 외 모든 답변데이터를 지움 uuid(고유 질문id값), additionOrder(답변순서)
      filteredAnswer = reject(modifiedApplicationFormAnswers, {
        uuid: uuid,
        additionOrder: additionOrder,
      });
    } else {
      filteredAnswer = reject(modifiedApplicationFormAnswers, {
        uuid: uuid,
      });
    }

    setModifiedApplicationFormAnswers([
      ...filteredAnswer,
      ...newApplicationManyFormAnswers,
    ]);
  };

  // 추가정보 답변 추가 버튼 onClick method
  const onClickAdditionalAnswerAdd = useCallback(
    (e: React.MouseEvent) => {
      // 수정할 답변의 반복정보 답변값만 가지고 옴
      const modifiedAddFormAnswers = filter(modifiedApplicationFormAnswers, {
        repeatInfo: "Y",
      });

      const newAdditionalAnswer: ApplicationFormAnswers[] = [];
      if (additionalForm !== undefined && forms.id !== undefined) {
        additionalForm.formIds.forEach((formId) => {
          // 새로운 추가정보 답변을 만들어줌
          const newAnswer: ApplicationFormAnswers = {
            additionOrder:
              modifiedAddFormAnswers.length === 0
                ? 0
                : modifiedAddFormAnswers[modifiedAddFormAnswers.length - 1]
                    .additionOrder! + 1, // 새로운 추가정보의 반복정보 순서
            answerUuid: undefined,
            content: "",
            formId: Number(forms.id!),
            id: undefined,
            title: additionalForm.formData[formId].title!,
            type: additionalForm.formData[formId].type!,
            uuid: additionalForm.formData[formId].id,
            repeatInfo: "Y",
          };

          newAdditionalAnswer.push(newAnswer);
        });

        setModifiedApplicationFormAnswers([
          ...modifiedApplicationFormAnswers,
          ...newAdditionalAnswer,
        ]);
      }
    },

    [additionalForm, modifiedApplicationFormAnswers, forms.id]
  );

  // 추가정보 삭제 버튼 onClick method
  const onClickAdditionalAnswerDelete = useCallback(
    (additionOrder: number) => {
      const deletedAddFormAnswers: ApplicationFormAnswers[] = [];
      if (modifiedApplicationFormAnswers.length !== 0) {
        modifiedApplicationFormAnswers.forEach((answer) => {
          // 반복정보가 아닌 답변
          if (answer.repeatInfo === "N") {
            deletedAddFormAnswers.push(answer);
          }
          // 반복정보이면서 삭제할 반복정보 순서(additionOrder)와 값이 다른 답변
          else if (
            answer.additionOrder !== additionOrder &&
            answer.repeatInfo === "Y"
          ) {
            deletedAddFormAnswers.push(answer);
          }
        });
      }

      // // 추가정보 삭제 시 additionOrder(순서)값도 -1을 해줘야함 (순서가 0 or 1 경우 제외)
      deletedAddFormAnswers.forEach((answer) => {
        if (
          answer.repeatInfo === "Y" &&
          answer.additionOrder !== 0 &&
          answer.additionOrder !== 1
        ) {
          answer.additionOrder = answer.additionOrder! - 1;
        }
      });

      setModifiedApplicationFormAnswers([...deletedAddFormAnswers]);
    },
    [modifiedApplicationFormAnswers]
  );

  // 수정확인 버튼 OnClick method
  const onClickSubmitButton = async (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setLoadingAction());

    const finalModifiedApplicationFormAnswers = reject(
      modifiedApplicationFormAnswers,
      {
        type: "many",
        answerUuid: undefined,
      }
    );

    let validationCheck; // validation 결과
    let alert: any; // validation 실패 시 보여줄 알림

    // 프론트 필수양식 validation check
    contentForm!.formIds.forEach((formId, index) => {
      const formData = contentForm!.formData[formId];
      if (formData.requireCheck === "Y") {
        if (formData.type === "many") {
          const result = find(finalModifiedApplicationFormAnswers, {
            uuid: formId,
            type: "many",
          });
          if (result === undefined) {
            validationCheck = false;
            alert = {
              id: "formRequired",
              msg: "필수값을 입력(선택)해주세요.",
              alertType: "warning",
            };
            return;
          }
        } else {
          const result = find(finalModifiedApplicationFormAnswers, {
            uuid: formId,
          });
          if (
            result?.content === undefined ||
            result?.content === "" ||
            result?.content === null
          ) {
            validationCheck = false;
            alert = {
              id: "formRequired",
              msg: "필수값을 입력(선택)해주세요.",
              alertType: "warning",
            };
            return;
          }
        }
      } else {
        const result = find(finalModifiedApplicationFormAnswers, {
          uuid: formId,
        });
        if (
          result?.content === undefined ||
          result?.content === "" ||
          result?.content === null
        ) {
          if (index === 0) {
            alert = {
              id: "formCompanyNameRequired",
              msg: "기업명을 입력해주세요.",
              alertType: "warning",
            };
            validationCheck = false;
            return;
          } else if (index === 1) {
            alert = {
              id: "formCategoryRequired",
              msg: "카테고리를 선택해주세요.",
              alertType: "warning",
            };
            validationCheck = false;
            return;
          } else if (index === 2) {
            alert = {
              id: "formKeywordRequired",
              msg: "키워드를 입력해주세요.",
              alertType: "warning",
            };
            validationCheck = false;
            return;
          } else if (index === 3 && type === "buyer") {
            alert = {
              id: "formCategoryRequired",
              msg: "필수값을 입력(선택)해주세요.",
              alertType: "warning",
            };
            validationCheck = false;
            return;
          }
        }
        validationCheck = true;
      }
    });

    if (validationCheck === false) {
      dispatch(removeLoadingAction());
      return dispatch(setAlertAction(alert));
    }

    const result: any = await dispatch(
      updateApplicationAnswers(id!, finalModifiedApplicationFormAnswers)
    );
    dispatch(removeLoadingAction());

    // 업데이트 성공 시 수정상태 변경 true -> false
    if (result) {
      setModify(false);
    }
  };

  return (
    <CompanyInfoContext.Provider
      value={{
        additionalForm: additionalForm,
        contentForm: contentForm,
        contentApplicationFormAnswers: contentApplicationFormAnswers,
        additionalApplicationFormAnswers: additionalApplicationFormAnswers,
        activeStep: activeStep,
        modify: modify,
        formId: Number(forms.id),
        modifiedApplicationFormAnswers: modifiedApplicationFormAnswers,
        setModify: setModify,
        setActiveStep: setActiveStep,
        modifyContentAnswers: modifyContentAnswers,
        modifyManyContentAnswers: modifyManyContentAnswers,
        onClickSubmitButton: onClickSubmitButton,
        onClickAdditionalAnswerAdd: onClickAdditionalAnswerAdd,
        onClickAdditionalAnswerDelete: onClickAdditionalAnswerDelete,
      }}
    >
      {children}
    </CompanyInfoContext.Provider>
  );
};

export default CompanyInfoProvider;
