import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
// import { setAlertAction, removeAlertAction } from "../actions/alerts";
import Country, {
  GetCountryApiInfo,
  GetCountryInfo,
  CountryInfo,
} from "../types/models/Country";
// import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
// import { setAlert } from "../utils/utils";
import { connectionCountry, countryTzCheck } from "../utils/momentTz";

export const createCountryAction = (country: CountryInfo): AppActions => ({
  type: "CREATE_COUNTRY",
  country,
});

export const getCountriesAction = (countries: CountryInfo[]): AppActions => ({
  type: "GET_COUNTRIES",
  countries,
});

export const getCountryAction = (country: CountryInfo): AppActions => ({
  type: "GET_COUNTRY",
  country,
});

export const setCountryAction = (
  getCountry: GetCountryApiInfo
): AppActions => ({
  type: "SET_COUNTRY",
  getCountry,
});

export const setCountriesAction = (
  getCountries: GetCountryInfo
): AppActions => ({
  type: "SET_COUNTRIES",
  getCountries,
});

export const createCountry = (country: CountryInfo) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(country);

  const path = `/api/countries`;
  try {
    var res: AxiosResponse<Event> = await axios.post(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    // const alert: Alert = setAlert(err.response.status, error, path);
    // dispatch(setAlertAction(alert));
    // setTimeout(() => {
    //   dispatch(removeAlertAction(alert.id));
    // });
    return "error";
  }
};

export const modifyCountry = (country: CountryInfo) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };

  const body = JSON.stringify(country);

  const path = `/api/countries/modify`;
  try {
    var res: AxiosResponse<Event> = await axios.put(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    // const alert: Alert = setAlert(err.response.status, error, path);
    // dispatch(setAlertAction(alert));
    // setTimeout(() => {
    //   dispatch(removeAlertAction(alert.id));
    // });
    return "error";
  }
};

export const getCountries = <Country>() => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/countries`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getCountriesAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    // const alert: Alert = setAlert(err.response.status, error, path);
    // dispatch(setAlertAction(alert));
    // setTimeout(() => {
    //   dispatch(removeAlertAction(alert.id));
    // });
  }
};

export const getCountry = <Country>(countryId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/countries/detail?countryId=${countryId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getCountryAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    // const alert: Alert = setAlert(err.response.status, error, path);
    // dispatch(setAlertAction(alert));
    // setTimeout(() => {
    //   dispatch(removeAlertAction(alert.id));
    // });
  }
};

export const setCountry = <GetCountry>() => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  // 현재 접속위치
  let countryData = await connectionCountry();
  countryData["thisCountryTz"] = countryTzCheck(countryData.country.toString());

  // 관리자가 등록한 국가 목록 조회
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/countries`;
  try {
    var res: AxiosResponse = await axios.get(path, config);

    const countriesArray = res.data;
    if (countriesArray.length > 0) {
      let setCountries: any;
      countriesArray.map((country: GetCountryInfo) => {
        if (setCountries === undefined) {
          setCountries = {
            [country.code.toString()]: {
              koName: country.koName,
              enName: country.enName,
              timezone: country.timezone,
              // i18code: country.i18code, // 언어 따로 지정
            },
          };
        } else {
          Object.assign(setCountries, {
            [country.code.toString()]: {
              koName: country.koName,
              enName: country.enName,
              timezone: country.timezone,
              // i18code: country.i18code,
            },
          });
        }
      });
      dispatch(setCountriesAction(setCountries));
    }

    dispatch(setCountryAction(countryData));

    return true;
  } catch (err) {
    const error: Error = err.response.data;
  }
};

// 국가 선택
export const choiceCountry = <GetCountryApiInfo>(
  key: string,
  country: string,
  countryMomentCode: string
) => async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
  let countryData = await connectionCountry();

  countryData["country"] = country;
  countryData["countryCode"] = key;
  countryData["thisCountryTz"] = countryMomentCode;

  dispatch(setCountryAction(countryData));
  return true;
};
