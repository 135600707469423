import { AppActions } from "../store";
import Zoom from "../types/models/Zoom";
import { getRequest, postRequest } from "../utils/utils";
import axios from "axios";

export const createZoomAction = (): AppActions => ({
  type: "CREATE_ZOOM",
});

export const getZoomsAction = (zooms: Zoom[]): AppActions => ({
  type: "GET_ZOOMS",
  zooms: zooms,
});

export const getZoomAction = (zoom: Zoom): AppActions => ({
  type: "GET_ZOOM",
  zoom: zoom,
});

export const createZoom = (zoom: Zoom) => {
  return postRequest(zoom, "/api/zoomLink");
};

export const getZooms = () => {
  return getRequest(null, "/api/zoomLink", getZoomsAction);
};

export const getZoom = (id: string) => {
  return getRequest(null, `/api/zoomLink/${id}`, getZoomAction);
};

export const deleteZooms = async (zoomLinkIds: string[]) => {
  try {
    const body = JSON.stringify(zoomLinkIds);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: body, // get 호출시 필요
    };

    const res = await axios.delete("/api/zoomLink", config);

    return res.data;
  } catch (err) {
    return false;
  }
};

export const unUseZooms = async (zoomLinkIds: string[]) => {
  try {
    const body = JSON.stringify(zoomLinkIds);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: null,
    };

    const res = await axios.post("/api/zoomLink/unUse", body, config);

    return res.data;
  } catch (err) {
    return false;
  }
};
