import React from "react";
import Banner from "./Banner";
import GroupIcon from "../../../../assets/icons/sharp-groups.svg";
import { SubEventInfo } from "../../../../types/models/SubEvent";

export default ({ subEvent }: { subEvent: SubEventInfo }) => {
  return (
    <Banner
      bgColor="#af52de"
      IconNode={<img src={GroupIcon} />}
      estimateLink={`/estimate/subEvent/${subEvent.id}/conference`}
      quoationLink={`/estimate/subEvent/${subEvent.id}/conferenceQuoation`}
      actionBtnTypo="최종견적"
      title={<>회의</>}
      subEvent={subEvent}
      body={
        subEvent.mainEvent &&
        subEvent.mainEvent.managers && (
          <table style={{ marginTop: 18 }}>
            <tr>
              <td>담당자</td>
              <td>{subEvent.mainEvent.managers[0].name}</td>{" "}
            </tr>
            <tr>
              <td style={{ paddingRight: 17 }}>전화번호</td>
              <td>{`(${subEvent.mainEvent.managers[0].countryNumber}) ${subEvent.mainEvent.managers[0].phone}`}</td>{" "}
            </tr>
            <tr>
              <td>이메일</td>
              <td>{subEvent.mainEvent.managers[0].email}</td>{" "}
            </tr>
          </table>
        )
      }
    />
  );
};
