import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import xlsx from "xlsx";
import { makeStyles } from "@material-ui/core/styles";
import TitleTypo from "../components/Typography/TitleTypo";
import PxGridContainer from "../components/Grid/PxGridContainer";
import PxBackDrop from "../components/BackDrop/PxBackDrop";

import FileSaver from "file-saver";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
  },
  uploadButton: {
    boxShadow: "none",
    backgroundColor: "#eee",
    maxWidth: 300,
    padding: "5px 10px",
  },
  regitButton: {
    boxShadow: "none",
    backgroundColor: "#5f4b8b",
    padding: "5px 10px",
    color: "white",
    marginTop: 10,
  },
  input: {
    display: "none",
  },
  spanTitle: {
    color: "blue",
  },
  spanImportant: {
    color: "red",
  },
  resultDialog: {
    minWidth: 150,
  },
  failedUserList: {
    maxHeight: 300,
    overflow: "scroll",
  },
}));

const FileConversion = () => {
  const classes = useStyles();
  const csvToJson = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target.files;
    if (files !== null) {
      const f = files![0];
      const reader = new FileReader();

      reader.onload = function (e) {
        const data = e.target!.result;
        const readedData = xlsx.read(data, { type: "binary" });
        let sheetNames =
          readedData.SheetNames.length === 1
            ? readedData.SheetNames[0]
            : prompt(
                "변환할 시트를 입력해주세요  \n" +
                  readedData.SheetNames.map((name) => " \n" + `${name}`),
                readedData.SheetNames[0]
              );
        if (!readedData.SheetNames.some((name) => name === sheetNames) && !sheetNames) {
          sheetNames = readedData.SheetNames[0]!;
        }
        const sheetData = readedData.Sheets[sheetNames || readedData.SheetNames[0]];
        const row = parseInt(sheetData["!ref"]?.split(":")[1].slice(1) || "");
        const langArr: Array<{ char: string; langague: string }> = [];

        const lastRow: number =
          (sheetData["!ref"]?.split(":")[1].charAt(0).charCodeAt(0) || 68) - 1;
        for (let n = 67; n <= lastRow; n++) {
          const char = String.fromCharCode(n);
          langArr.push({
            char,
            langague: sheetData[`${char}1`].v,
          });
        }
        let selectLang = prompt(
          "아래 언어중 하나를 입력해주세요 \n" + langArr.map((a) => " \n" + `${a.langague}`),
          langArr[langArr.length - 1].langague
        );
        let check = langArr.findIndex((d) => d.langague === selectLang);
        if (check == -1) {
          alert("해당하는 언어가 없습니다.");
          return false;
        }

        let key: string = sheetData[`A1`].v;
        const json: any = new Object();
        for (let i = 2; i < row; i++) {
          if (sheetData[`A${i}`]) {
            key = sheetData[`A${i}`].v.trim();
            json[key] = new Object();
          }
          if (sheetData[`B${i}`])
            json[key][sheetData[`B${i}`].v] =
              sheetData[`${langArr[check].char}${i}`]?.v.trim() || "";
        }
        console.log(json);
        const fileName = prompt("파일 이름을 입력해주세요 ex) en, ko, jp, ...");
        const blob = new Blob([JSON.stringify(json)], { type: "text/csv;charset=utf-8,%EF%BB%BF" });
        FileSaver.saveAs(blob, `${fileName}.json`);
      };
      reader.readAsBinaryString(f);
    }
  };

  return (
    <Container className={classes.container}>
      <PxBackDrop />

      <PxGridContainer justify="center" direction="column" alignItems="center">
        <TitleTypo>파일 변환</TitleTypo>

        <Button variant="contained" component="label" className={classes.uploadButton}>
          json으로 변환
          <input type="file" name="file1" onChange={csvToJson} className={classes.input} />
        </Button>
      </PxGridContainer>
    </Container>
  );
};

export default FileConversion;
