import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { AppState } from "../../store";
import { Column } from "material-table";
import moment from "moment";
import {
  getSubEvents,
  modifySubEventApprovalDelete,
} from "../../actions/subEvent";

// custom ui comp
import GridContainer from "../../components/Grid/PxGridContainer";
import PxTable from "../../components/Tables/PxTable";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";
// import PxSwitch from "../../components/switch/PxSwitch";
import { removeLoadingAction, setLoadingAction } from "../../actions/loading";
import { changeSubEventPayment } from "../../actions/subEvent";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { setTableInfoLoadingAction } from "../../actions/tableInfo";

const PurpleSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "rgb(81, 45, 168)",
    },
    "&$checked + $track": {
      backgroundColor: "rgb(81, 45, 168)",
    },
  },
  checked: {},
  track: {},
})(Switch);

interface RowType {
  subEvCategory: string;
  subEvCreatedDate: string;
  subEvDate: string;
  subEvUpdatedDate: string;
  subEvId: string;
  subEvMatching: string; // suvEvent matching Y or N 확인, 어떤 양식을 보여줄지 확인하기 위함
  subEvPayment: boolean;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const SubEventList = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params: { id: string } = useParams();

  const subEvents = useSelector((state: AppState) => state.subEvents.subEvents);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: "이벤트 타입",
        field: "subEvCategory",
        cellStyle: {
          width: "calc(100%-600px)",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "결제",
        field: "payment",
        cellStyle: { width: "100px", paddingRight: 0 },
        render: (rowData) => {
          return (
            <PurpleSwitch
              checked={rowData.subEvPayment}
              onClick={(e) => {
                onClickEventPaymentSwitch(
                  e,
                  rowData.subEvPayment,
                  rowData.subEvId
                );
              }}
              name="paymentSwitch"
            />
          );
        },
      },
      {
        title: "",
        field: "subEvSetting",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
        render: (rowData) => (
          <PxButton
            backgroundColor="grey"
            style={{ borderRadius: 20, padding: 5 }}
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.stopPropagation();
              history.push(
                `${location.pathname}/SubEventModify/${rowData.subEvId}`
              );
            }}
          >
            <ButtonTypo>설정</ButtonTypo>
          </PxButton>
        ),
      },
      {
        title: "",
        field: "subEvFormSetting",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
        render: (rowData) => (
          <PxButton
            backgroundColor="purple"
            padding="5px"
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.stopPropagation();
              rowData.subEvMatching === "Y"
                ? history.push(`/form/subEvent/${rowData.subEvId}/match`)
                : history.push(`/form/subEvent/${rowData.subEvId}/noMatch`);
            }}
          >
            <ButtonTypo>양식</ButtonTypo>
          </PxButton>
        ),
      },
      {
        title: "등록",
        field: "subEvCreatedDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
      {
        title: "기간",
        field: "subEvDate",
        cellStyle: {
          width: "200px",
          paddingRight: 0,
        },
      },
      {
        title: "수정일",
        field: "subEvUpdatedDate",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
      {
        title: "",
        field: "subEvFormSetting",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
        render: (rowData) => (
          <>
            <PxButton
              backgroundColor="grey"
              style={{ borderRadius: 5, padding: 7 }}
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.stopPropagation();
                dispatch(modifySubEventApprovalDelete(rowData.subEvId));
              }}
            >
              <ButtonTypo>삭제</ButtonTypo>
            </PxButton>
          </>
        ),
      },
    ],
    data: [],
  });

  useEffect(() => {
    const tableData: RowType[] = [];
    if (Array.isArray(subEvents) && subEvents.length > 0) {
      subEvents.forEach((subEv) => {
        if (subEv.approval !== "D") {
          const rowData: any = {
            subEvCategory:
              subEv.category === "presentation" ? "conference" : subEv.category, //presentation : Conference 모두 변경 / 21.04.19
            subEvDate: `${moment(subEv.subEventStartDate).format(
              "YYYY-MM-DD"
            )} - ${moment(subEv.subEventEndDate).format("YYYY-MM-DD")}`,
            subEvCreatedDate: moment(subEv.createdDate).format("YYYY-MM-DD"),
            subEvUpdatedDate: moment(subEv.modifiedDate).format("YYYY-MM-DD"),
            subEvId: subEv.id,
            subEvMatching: subEv.matching,
            subEvPayment: subEv.payment === "Y" ? true : false,
          };

          tableData.push(rowData);
        }
      });
    }
    setState((prevCreateDate) => {
      return { ...prevCreateDate, data: tableData };
    });
    dispatch(setTableInfoLoadingAction(false));
  }, [subEvents]);

  const onClickEventPaymentSwitch = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, checked, id) => {
      e.stopPropagation();
      dispatch(setLoadingAction());
      await dispatch(changeSubEventPayment(id, !checked ? "Y" : "N"));
      dispatch(removeLoadingAction());
    },
    [dispatch]
  );

  return (
    <>
      <GridContainer parent={true}>
        <PxTable<RowType>
          columns={state.columns}
          data={state.data}
          onRowClick={(evt, selectedRow) => {
            if (selectedRow?.subEvCategory === "registration") {
              history.push(
                `/subEvent/${selectedRow?.subEvId}/regitApplicationList`
              );
            } else {
              history.push(`/subEvent/${selectedRow?.subEvId}/application`);
            }
          }}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            showTitle: false,
            defaultExpanded: false,
            search: false,
            toolbar: false,
          }}
        />
      </GridContainer>
    </>
  );
};

export default SubEventList;
