import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {isArray, mergeWith} from "lodash";

// custom ui comp
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";

// @material-ui/core
import {TypographyProps} from "@material-ui/core/Typography";
import styled, {css} from "styled-components";
import moment from "moment";
import ImpossibleTimeCard from "./ImpossibleTimeCard";
import {Box} from "@material-ui/core";
import {parallaxCalculation} from "../../utils/momentTz";
import {CountryInfo, GetCountryInfo} from "../../types/models/Country";
import {MeetingInfo} from "../../types/models/Meeting";
import {useTranslation} from "react-i18next";
import {SubEventInfo} from "../../types/models/SubEvent";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import Event from "../../types/models/Event";
import Tooltip from "@material-ui/core/Tooltip";
import RepeatSetting from "./Calendar/RepeatSetting";
import PxCalendar from "./Calendar/PxCalendar";

interface ImpossibleTimeProps {
  event: Event["event"];
  subEvent: SubEventInfo;
  disabledTime: object;
  setDisabledTime: React.Dispatch<React.SetStateAction<Object>>;
}

export default ({event, subEvent, disabledTime, setDisabledTime}: ImpossibleTimeProps) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const meetings = useSelector((state: AppState) => state.meetings.meetings);
  const countries = useSelector((state: AppState) => state.countries.getCountries!);
  const [currentDateMeetings, setCurrentDateMeetings] = useState<MeetingInfo[]>([]);
  const [repeatSettingValue, setRepeatSetting] = useState<"default" | "week" | "multiple">("default");
  const [selectedWeeks, setSelectedWeeks] = useState<number[]>([]);
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [dayRef, setDayRef] = useState<React.MutableRefObject<HTMLDivElement>[]>([]); // {current:undefind}

  const weeks = [t("calendar.SUN"), t("calendar.MON"), t("calendar.TUE"), t("calendar.WED"), t("calendar.THU"), t("calendar.FRI"), t("calendar.SAT")];
  useEffect(() => {
    if (dayRef.length > 0 && meetings && subEvent.id) {
      let filterMeetings: MeetingInfo[] = [];
      dayRef.forEach(ref => {
        const date = parallaxCalculation(ref.current.id, countries![event!.countryCode! as string] as any, "", "YYYY-MM-DD");
        filterMeetings.push(...meetings.filter(meeting => meeting.date == date));
      });

      setCurrentDateMeetings([...filterMeetings]);
    }
  }, [dayRef, meetings]);

  const defaultDisabeld = useCallback(
    (key: string, impossibleAt: "Y" | "N") => {
      // 기본선택일때
      let newDisabledTime: any = disabledTime;
      const [year, month, day] = parallaxCalculation(dayRef[0].current.id, countries![event!.countryCode! as string] as any, "", "YYYY-MM-DD").split("-");
      const value = day.concat("_", key);

      newDisabledTime.hasOwnProperty(year) // 해당 연도 체크
        ? newDisabledTime[year].hasOwnProperty(month) // 해당 월 체크
          ? newDisabledTime[year][month].some((d: string) => d === value)
            ? (newDisabledTime[year][month] = [
                ...newDisabledTime[year][month].filter((d: string) => d !== value), //해당 일이 이미 있을때 (불가시간 해제)
              ])
            : (newDisabledTime[year][month] = [...newDisabledTime[year][month], value])
          : (newDisabledTime[year] = {...newDisabledTime[year], [month]: [value]}) // 해당 월이 없을때
        : (newDisabledTime = {[year]: {[month]: [value]}}); //해당 연도가 없을때
      const newData = {...disabledTime, ...newDisabledTime};
      setDisabledTime(newData);
    },
    [dayRef, disabledTime]
  );

  const weekDisabled = useCallback(
    // 요일 반복 선택 일때
    (key: string, impossibleAt: "Y" | "N") => {
      let newDisabledTime: any = disabledTime;
      const startDate = moment(subEvent.subEventStartDate);
      const endDate = moment(subEvent.subEventEndDate);
      // 불가시간
      selectedWeeks.forEach(w => {
        //선택된 요일만큼

        for (let i = Math.abs(startDate.diff(endDate, "weeks")) + 1; -1 < i; i--) {
          const date = startDate.clone().day(i * 7 + w);
          const [year, month, day] = date.format("YYYY-MM-DD").split("-");
          if (!date.isBetween(startDate, endDate, undefined, "[]") || (meetings?.some(meeting => date.format("YYYY-MM-DD") === meeting.date && meeting.startTime === key) && subEvent.id)) continue; // 이벤트 날짜가 아니거나 미팅이 잡혀있으면 건너뛰기

          const value = day.concat("_", key);
          if (impossibleAt === "Y") {
            //삭제
            newDisabledTime.hasOwnProperty(year) &&
              newDisabledTime[year].hasOwnProperty(month) &&
              newDisabledTime[year][month].some((d: string) => d === value) &&
              (newDisabledTime[year][month] = newDisabledTime[year][month].filter((d: string) => d !== value)); //해당 일이 이미 있을때 (불가시간 해제)
          } else {
            //추가
            newDisabledTime = mergeWith(newDisabledTime, {[year]: {[month]: [value]}}, customizer);
          }
        }
      });

      selectedTimes.some(t => t === key) //선택된시간들 배열 추가/삭제
        ? setSelectedTimes(selectedTimes.filter(t => t !== key))
        : setSelectedTimes([...selectedTimes, key]);
      setDisabledTime({...newDisabledTime});
    },
    [selectedWeeks, selectedTimes, meetings, subEvent.subEventEndDate, subEvent.subEventStartDate, subEvent.id, disabledTime]
  );

  const multipleDisabled = useCallback(
    (key: string, impossibleAt: "Y" | "N") => {
      let newDisabledTime: any = disabledTime;

      // 다중 날짜 선택일때
      // dayRef.[0] 의 불가시간 설정 가져와 구현
      dayRef.forEach(ref => {
        const [year, month, day] = parallaxCalculation(ref.current.id, countries![event!.countryCode! as string] as any, "", "YYYY-MM-DD").split("-");
        if (
          //미팅에 있으면 건너뛰기
          currentDateMeetings.some(meeting => meeting.date === `${year}-${month}-${day}` && meeting.startTime === key)
        )
          return;
        const value = day.concat("_", key);
        if (impossibleAt === "Y") {
          //삭제 //해당 일이 이미 있을때 (불가시간 해제)
          newDisabledTime.hasOwnProperty(year) &&
            newDisabledTime[year].hasOwnProperty(month) &&
            newDisabledTime[year][month].some((d: string) => d === value) &&
            (newDisabledTime[year][month] = newDisabledTime[year][month].filter((d: string) => d !== value));
        } else {
          //추가
          newDisabledTime = mergeWith(
            newDisabledTime,
            {[year]: {[month]: [value]}},
            customizer //array 검사
          );
        }
      });
      setDisabledTime({...newDisabledTime});
    },
    [dayRef, currentDateMeetings, disabledTime]
  );

  const clicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const id = parseInt(event.currentTarget.id);
    setSelectedWeeks(selectedWeeks?.some(w => w === id) ? selectedWeeks.filter(w => w !== id) : [...selectedWeeks, id]);
  };

  const ImpossibleTimeCardGeneragator = useCallback(
    (
      matchStartTime: string, // 매칭(미팅) 시작시간
      matchReqTime: number, // 매칭(미팅) 소요시간
      matchBreakTime: number, // 매칭(미팅) 휴식시간)),
      matchNumPerDay: number,
      onChangeMeetingTime: any,
      disabledTime: any,
      countries: GetCountryInfo | undefined,
      countryCode: string | undefined,
      selectedTimes: string[]
    ) => {
      //시작 시간부터 끝나는 시간까지
      let meetingTimeList: ReactNode[] = []; // 필요데이터 {시작시간, 종료시간, 불가여부}
      // 날짜별 매칭시간 기준 값
      let impossibleStartTime = moment(`2020-01-01 ${matchStartTime}:00`);
      // 날짜별 매칭시간 조회 (시작시간, 종료시간, 불가여부)
      Array.from({length: matchNumPerDay || 0}, (x, i) => {
        const startTime = impossibleStartTime.format("HH:mm");
        const endTime = impossibleStartTime.add(matchReqTime, "m").format("HH:mm");
        meetingTimeList.push(
          <ImpossibleTimeCard
            key={`metting-time-card-${i}`}
            startTime={startTime}
            endTime={endTime}
            impossibleAt={
              repeatSettingValue === "week"
                ? selectedTimes.some(t => startTime === t)
                  ? "Y"
                  : "N"
                : dayRef.length > 0 && disabledTime
                ? checkImpossibleAt(disabledTime, dayRef, startTime, countries!, countryCode!)
                : "N"
            }
            disabledClick={currentDateMeetings?.some(meeting => meeting.date === dayRef[0]?.current.id && meeting.startTime === startTime)}
            dateIndex={i}
            timeIndex={i}
            onChangeMeetingTime={onChangeMeetingTime}
          ></ImpossibleTimeCard>
        );

        impossibleStartTime = impossibleStartTime.add(matchBreakTime, "m");
      });

      return meetingTimeList;
    },
    [repeatSettingValue, selectedTimes, dayRef, repeatSettingValue, currentDateMeetings]
  );

  useEffect(() => {
    setSelectedWeeks([]);
    setDayRef([]);
  }, [repeatSettingValue]);

  // 기본으로 설정되어있을때
  return (
    <>
      <SubTitle>{t("addSubEvent.meetingUnavailable")}</SubTitle>
      <SubHeadingTypo>{t("addSubEvent.checkMeetingUnavailableTime")}</SubHeadingTypo>
      <RepeatSetting value={repeatSettingValue} setValue={setRepeatSetting} />
      <Box display="flex" paddingBottom="20px">
        {weeks.map((w, index) => (
          <WeekItem checked={selectedWeeks.some(w => w === index)} id={index.toString()} onClick={clicked} disabled={repeatSettingValue !== "week"}>
            {w}
          </WeekItem>
        ))}
      </Box>
      <Box display="flex" flexDirection="row" height="400px">
        <Box width="50%" position="relative">
          <PxCalendar subEventInfo={subEvent} dayRef={dayRef} setDayRef={setDayRef} disabledTime={disabledTime} repeatSettingValue={repeatSettingValue} width="50%" />
          {repeatSettingValue === "week" && <TipPannel>{t("matched.selectTimeAfterWeek")}</TipPannel>}
        </Box>

        <MeetingFormTime>
          {subEvent.matchStartTime &&
            subEvent.matchReqTime &&
            subEvent.matchNumPerDay &&
            ImpossibleTimeCardGeneragator(
              subEvent.matchStartTime,
              subEvent.matchReqTime,
              subEvent.matchBreakTime || 0,
              subEvent.matchNumPerDay,
              repeatSettingValue === "default" ? defaultDisabeld : repeatSettingValue === "multiple" ? multipleDisabled : weekDisabled,
              disabledTime,
              countries!,
              event?.countryCode,
              selectedTimes
            )}
          {repeatSettingValue !== "week" && dayRef.length <= 0 && <TipPannel>{t("matched.selectDate")}</TipPannel>}
          {repeatSettingValue === "week" && selectedWeeks.length <= 0 && <TipPannel> {t("matched.selectWeek")}</TipPannel>}
        </MeetingFormTime>
      </Box>
      <Box display="flex" paddingTop="15px" flexWrap="wrap">
        {repeatSettingValue === "multiple" &&
          dayRef.length > 0 &&
          dayRef.map((w, index) => (
            <Tooltip key={index} title={w.current.id} placement="bottom">
              <WeekItem checked={true} id={`I_${w.current.id}`}>
                {`${w.current.id.split("-")[1]}.${w.current.id.split("-")[2]}`}
              </WeekItem>
            </Tooltip>
          ))}
      </Box>
    </>
  );
};

function checkImpossibleAt(disabledTime: any, dayRef: React.MutableRefObject<HTMLDivElement>[], startTime: string, countries: GetCountryInfo, countryCode: string): "N" | "Y" {
  if (dayRef.length > 0) {
    try {
      const [year, month, day] = parallaxCalculation(dayRef[0].current.id, countries![countryCode! as string] as any, "", "YYYY-MM-DD").split("-");
      const value = day.concat("_", startTime);

      return disabledTime.hasOwnProperty(year) && disabledTime[year].hasOwnProperty(month) && disabledTime[year][month].some((d: string) => d === value) ? "Y" : "N";
    } catch (error) {
      return "N";
    }
  }
  return "N";
}

const TipPannel = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.76);
  font-size: 1rem;
  font-weight: 500;
  color: white;
`;

type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const {...rest} = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const MeetingFormTime = styled.div`
  display: grid;
  position: relative;
  width: 50%;
  overflow-x: hidden;
  overflow-y: auto;
  gap: 7px;
  align-content: start;
  grid-template-columns: repeat(4, 1fr);
  margin: 10px 15px 10px;
`;

const WeekItem = styled.button<{checked: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  margin-right: 5px;
  height: 40px;
  margin-bottom: 5px;
  border: none;
  border-radius: 50%;
  ${props => {
    if (props.checked) {
      return css`
        background-color: #7e57c2;
        color: white;
      `;
    }
  }}
`;

function customizer(objValue: any, srcValue: any) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}
