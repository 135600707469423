export const GOOGLE_API_KEY = "AIzaSyBuW6VRgBMg5xnu0-Xvpv2pC1D1vgtXdjg";
export const EVENT_MAIN_IMAGES = [
  "https://pexpo2.s3.ap-northeast-2.amazonaws.com/event/560_conference_t3.png",
  "https://pexpo2.s3.ap-northeast-2.amazonaws.com/event/560_consultation_t3.png",
  "https://pexpo2.s3.ap-northeast-2.amazonaws.com/event/560_online_conference_t3.png",
  "https://pexpo2.s3.ap-northeast-2.amazonaws.com/event/560_online_consultation_t3.png",
];
export const countryPhoneNumberCode: {
  country: string;
  code: number;
  countryEn: string;
}[] = [
  { country: "가나", code: 233, countryEn: "Ghana" },
  { country: "가봉", code: 241, countryEn: "Gabon" },
  { country: "가이아나", code: 592, countryEn: "Guyana" },
  { country: "감비아", code: 220, countryEn: "Gambia" },
  { country: "과들루프", code: 590, countryEn: "Guadeloupe" },
  { country: "과테말라", code: 502, countryEn: "Guatemala" },
  { country: "괌", code: 1671, countryEn: "Guam" },
  { country: "그레나다", code: 1473, countryEn: "Wagner grenadines" },
  { country: "그리스", code: 30, countryEn: "Greece" },
  { country: "그린란드", code: 299, countryEn: "Greenland" },
  { country: "기니", code: 224, countryEn: "Guinea" },
  { country: "기니비사우", code: 245, countryEn: "Guinea-Bissau" },
  { country: "나미비아", code: 264, countryEn: "Namibia" },
  { country: "나우루", code: 674, countryEn: "Nauru" },
  { country: "나이지리아", code: 234, countryEn: "Nigeria" },
  { country: "남수단", code: 211, countryEn: "South Sudan" },
  {
    country: "남아프리카 공화국",
    code: 27,
    countryEn: "Republic of South Africa",
  },
  { country: "네덜란드", code: 31, countryEn: "Netherlands" },
  {
    country: "네덜란드령 카리브, 퀴라소",
    code: 599,
    countryEn: "Dutch Caribbean, Curacao",
  },
  { country: "네팔", code: 977, countryEn: "Nepal" },
  { country: "노르웨이", code: 47, countryEn: "Norway" },
  { country: "누벨칼레도니", code: 687, countryEn: "Nouvelle Caledonia" },
  { country: "뉴질랜드", code: 64, countryEn: "New Zealand" },
  { country: "니우에", code: 683, countryEn: "Niue" },
  { country: "니제르", code: 227, countryEn: "Niger" },
  { country: "니카라과", code: 505, countryEn: "Nicaragua" },
  { country: "대만", code: 886, countryEn: "Taiwan" },
  { country: "대한민국", code: 82, countryEn: "Republic of Korea" },
  { country: "덴마크", code: 45, countryEn: "Denmark" },
  { country: "도미니카 공화국", code: 1809, countryEn: "Dominican Republic" },
  { country: "도미니카 연방", code: 1767, countryEn: "Dominican Federation" },
  { country: "독일", code: 49, countryEn: "Germany" },
  { country: "동티모르", code: 670, countryEn: "East Timor" },
  {
    country: "디에고 가르시아 섬",
    code: 246,
    countryEn: "Diego Garcia Island",
  },
  { country: "라오스", code: 856, countryEn: "Laos" },
  { country: "라이베리아", code: 231, countryEn: "Liberia" },
  { country: "라트비아", code: 371, countryEn: "Latvia" },
  { country: "러시아, 카자흐스탄", code: 7, countryEn: "Russia, Kazakhstan" },
  { country: "레바논", code: 961, countryEn: "Lebanon" },
  { country: "레소토", code: 266, countryEn: "Lesotho" },
  { country: "레위니옹", code: 262, countryEn: "Reunion" },
  { country: "루마니아", code: 40, countryEn: "Romania" },
  { country: "룩셈부르크", code: 352, countryEn: "Luxembourg" },
  { country: "르완다", code: 250, countryEn: "Rwanda" },
  { country: "리비아", code: 218, countryEn: "Libya" },
  { country: "리투아니아", code: 370, countryEn: "Lithuania" },
  { country: "리히텐슈타인", code: 423, countryEn: "Liechtenstein" },
  { country: "마다가스카르", code: 261, countryEn: "Madagascar" },
  { country: "마르티니크", code: 596, countryEn: "Martinique" },
  { country: "마셜 제도", code: 692, countryEn: "Marshall Islands" },
  { country: "마카오", code: 853, countryEn: "Macau" },
  {
    country: "마케도니아 공화국",
    code: 389,
    countryEn: "Republic of Macedonia",
  },
  { country: "말라위", code: 265, countryEn: "Malawi" },
  { country: "말레이시아", code: 60, countryEn: "Malaysia" },
  { country: "말리", code: 223, countryEn: "Mali" },
  { country: "멕시코", code: 52, countryEn: "Mexico" },
  { country: "모나코", code: 377, countryEn: "Monaco" },
  {
    country: "모로코, 서사하라",
    code: 212,
    countryEn: "Morocco, Western Sahara",
  },
  { country: "모리셔스", code: 230, countryEn: "Mauritius" },
  { country: "모리타니", code: 222, countryEn: "Mauritania" },
  { country: "모잠비크", code: 258, countryEn: "Mozambique" },
  { country: "몬테네그로", code: 382, countryEn: "Montenegro" },
  { country: "몬트세랫", code: 1664, countryEn: "Montserrat" },
  { country: "몰도바", code: 373, countryEn: "Moldova" },
  { country: "몰디브", code: 960, countryEn: "Maldive Islands" },
  { country: "몰타", code: 356, countryEn: "Malta" },
  { country: "몽골", code: 976, countryEn: "Mongolia" },
  { country: "미국령 버진아일랜드", code: 1340, countryEn: "U.S. Virgin" },
  { country: "미얀마(버마)", code: 95, countryEn: "Myanmar (Burma)" },
  {
    country: "미크로네시아 연방",
    code: 691,
    countryEn: "Federation of Micronesia",
  },
  { country: "바누아투", code: 678, countryEn: "Vanuatu" },
  { country: "바레인", code: 973, countryEn: "Bahrain" },
  { country: "바베이도스", code: 1246, countryEn: "Barbados" },
  { country: "바하마", code: 1242, countryEn: "Bahamas" },
  { country: "방글라데시", code: 880, countryEn: "Bangladesh" },
  { country: "버뮤다", code: 1441, countryEn: "Bermuda shorts" },
  { country: "베냉", code: 229, countryEn: "Benin" },
  { country: "베네수엘라", code: 58, countryEn: "Venezuela" },
  { country: "베트남", code: 84, countryEn: "Vietnam" },
  { country: "벨기에", code: 32, countryEn: "Belgium" },
  { country: "벨라루스", code: 375, countryEn: "Belarus" },
  { country: "벨리즈", code: 501, countryEn: "Belize" },
  {
    country: "보스니아 헤르체고비나",
    code: 387,
    countryEn: "Bosnia and Herzegovina",
  },
  { country: "보츠와나", code: 267, countryEn: "Botswana" },
  { country: "볼리비아", code: 591, countryEn: "Bolivia" },
  { country: "부룬디", code: 257, countryEn: "Burundi" },
  { country: "부르키나파소", code: 226, countryEn: "Burkina Faso" },
  { country: "부탄", code: 975, countryEn: "butane" },
  {
    country: "북마리아나 제도",
    code: 1670,
    countryEn: "Northern Mariana Islands",
  },
  { country: "불가리아", code: 359, countryEn: "Bulgaria" },
  { country: "브라질", code: 55, countryEn: "Brazil" },
  { country: "브루나이", code: 673, countryEn: "Brunei" },
  { country: "사모아", code: 685, countryEn: "Samoa" },
  { country: "사우디아라비아", code: 966, countryEn: "Saudi Arabia" },
  { country: "산마리노", code: 378, countryEn: "San Marino" },
  { country: "상투메 프린시페", code: 239, countryEn: "Sao Tome and Principe" },
  { country: "생피에르 미클롱", code: 508, countryEn: "Saint Pierre Miquelon" },
  { country: "세네갈", code: 221, countryEn: "Senegal" },
  { country: "세르비아", code: 381, countryEn: "Serbia" },
  { country: "세이셸", code: 248, countryEn: "Seychelles" },
  { country: "세인트루시아", code: 1758, countryEn: "Saint Lucia" },
  {
    country: "세인트빈센트 그레나딘",
    code: 1784,
    countryEn: "Saint Vincent and the",
  },
  { country: "세인트헬레나", code: 290, countryEn: "St Helena" },
  {
    country: "소말리아, 소말릴란드",
    code: 252,
    countryEn: "Somalia, Somaliland",
  },
  { country: "솔로몬 제도", code: 677, countryEn: "Solomon Islands" },
  { country: "수단", code: 249, countryEn: "Way" },
  { country: "수리남", code: 597, countryEn: "surname" },
  { country: "스리랑카", code: 94, countryEn: "Sri Lanka" },
  { country: "스와질란드", code: 268, countryEn: "Swaziland" },
  { country: "스웨덴", code: 46, countryEn: "Sweden" },
  { country: "스위스", code: 41, countryEn: "Swiss" },
  { country: "스페인", code: 34, countryEn: "Spain" },
  { country: "슬로바키아", code: 421, countryEn: "Slovakia" },
  { country: "슬로베니아", code: 386, countryEn: "Slovenian" },
  { country: "시리아", code: 963, countryEn: "Syria" },
  { country: "시에라리온", code: 232, countryEn: "Sierra Leone" },
  { country: "싱가포르", code: 65, countryEn: "Singapore" },
  { country: "아랍에미리트", code: 971, countryEn: "United Arab Emirates" },
  { country: "아루바", code: 297, countryEn: "Aruba" },
  { country: "아르메니아", code: 374, countryEn: "Armenia" },
  { country: "아르헨티나", code: 54, countryEn: "Argentina" },
  { country: "아메리칸사모아", code: 1684, countryEn: "American Samoa" },
  { country: "아이슬란드", code: 354, countryEn: "Iceland" },
  { country: "아이티", code: 509, countryEn: "Haiti" },
  { country: "아일랜드", code: 353, countryEn: "Ireland" },
  { country: "아제르바이잔", code: 994, countryEn: "Azerbaijan" },
  { country: "아프가니스탄", code: 93, countryEn: "Afghanistan" },
  { country: "안도라", code: 376, countryEn: "Andorra" },
  { country: "알바니아", code: 355, countryEn: "Albania" },
  { country: "알제리", code: 213, countryEn: "Algeria" },
  { country: "앙골라", code: 244, countryEn: "Angola" },
  { country: "앤티가 바부다", code: 1268, countryEn: "Antigua and Barbuda" },
  { country: "앵귈라", code: 1264, countryEn: "Anguilla" },
  { country: "어센션 섬", code: 247, countryEn: "Ascension Island" },
  { country: "에리트레아", code: 291, countryEn: "Eritrea" },
  { country: "에스토니아", code: 372, countryEn: "Estonia" },
  { country: "에콰도르", code: 593, countryEn: "Ecuador" },
  { country: "에티오피아", code: 251, countryEn: "Ethiopia" },
  { country: "엘살바도르", code: 503, countryEn: "El Salvador" },
  { country: "영국", code: 44, countryEn: "England" },
  {
    country: "영국령 버진아일랜드",
    code: 1284,
    countryEn: "British Virgin Islands",
  },
  { country: "예멘", code: 967, countryEn: "Yemen" },
  { country: "오만", code: 968, countryEn: "Oman" },
  { country: "오스트레일리아", code: 61, countryEn: "Australia" },
  {
    country: "오스트레일리아의 외부 영토",
    code: 672,
    countryEn: "Australia's outer territories",
  },
  { country: "오스트리아", code: 43, countryEn: "Austria" },
  { country: "온두라스", code: 504, countryEn: "Honduras" },
  { country: "왈리스 퓌튀나", code: 681, countryEn: "Wallis and Futuna" },
  { country: "요르단", code: 962, countryEn: "Jordan" },
  { country: "우간다", code: 256, countryEn: "Uganda" },
  { country: "우루과이", code: 598, countryEn: "Uruguay" },
  { country: "우즈베키스탄", code: 998, countryEn: "Uzbekistan" },
  { country: "우크라이나", code: 380, countryEn: "Ukraine" },
  { country: "이란", code: 98, countryEn: "Iran" },
  { country: "이라크", code: 964, countryEn: "Iraq" },
  { country: "이스라엘", code: 972, countryEn: "Israel" },
  { country: "이집트", code: 20, countryEn: "Egypt" },
  {
    country: "이탈리아, 바티칸 시국",
    code: 39,
    countryEn: "Italy, Vatican City",
  },
  { country: "인도", code: 91, countryEn: "India" },
  { country: "인도네시아", code: 62, countryEn: "Indonesia" },
  { country: "일본", code: 81, countryEn: "Japan" },
  { country: "자메이카", code: 1876, countryEn: "Jamaica" },
  { country: "잠비아", code: 260, countryEn: "Zambia" },
  { country: "적도 기니", code: 240, countryEn: "Equatorial Guinea" },
  {
    country: "조선민주주의인민공화국",
    code: 850,
    countryEn: "The Democratic People's",
  },
  { country: "조지아", code: 995, countryEn: "Georgia" },
  {
    country: "중앙아프리카 공화국",
    code: 236,
    countryEn: "Central African Republic",
  },
  { country: "중화인민공화국", code: 86, countryEn: "China" },
  { country: "지부티", code: 253, countryEn: "Djibouti" },
  { country: "지브롤터", code: 350, countryEn: "Gibraltar" },
  { country: "짐바브웨", code: 263, countryEn: "Zimbabwe" },
  { country: "차드", code: 235, countryEn: "Chad" },
  { country: "체코", code: 420, countryEn: "Czech Republic" },
  { country: "칠레", code: 56, countryEn: "Chile" },
  { country: "카메룬", code: 237, countryEn: "Cameroon" },
  { country: "카보베르데", code: 238, countryEn: "Cape Verde" },
  { country: "카타르", code: 974, countryEn: "catarrh" },
  { country: "캄보디아", code: 855, countryEn: "Cambodia" },
  { country: "캐나다", code: 1, countryEn: "Canada" },
  { country: "케냐", code: 254, countryEn: "Kenya" },
  { country: "케이맨 제도", code: 1345, countryEn: "Cayman Islands" },
  { country: "코모로, 마요트", code: 269, countryEn: "Comoros, Mayotte" },
  { country: "코소보 공화국", code: 383, countryEn: "Republic of Kosovo" },
  { country: "코스타리카", code: 506, countryEn: "Costa Rica" },
  { country: "코트디부아르", code: 225, countryEn: "Ivory Coast" },
  { country: "콜롬비아", code: 57, countryEn: "Columbia" },
  { country: "콩고 공화국", code: 242, countryEn: "Republic of the Congo" },
  {
    country: "콩고 민주 공화국",
    code: 243,
    countryEn: "Democratic Republic of the",
  },
  { country: "쿠바", code: 53, countryEn: "Cuba" },
  { country: "쿠웨이트", code: 965, countryEn: "Kuwait" },
  { country: "쿡 제도", code: 682, countryEn: "Cook islands" },
  { country: "크로아티아", code: 385, countryEn: "Croatia" },
  { country: "키르기스스탄", code: 996, countryEn: "Kyrgyzstan" },
  { country: "키리바시", code: 686, countryEn: "Kiribati" },
  { country: "키프로스", code: 357, countryEn: "Cyprus" },
  { country: "타이", code: 66, countryEn: "tie" },
  { country: "타지키스탄", code: 992, countryEn: "Tajikistan" },
  { country: "탄자니아", code: 255, countryEn: "Tanzania" },
  { country: "터키", code: 90, countryEn: "Turkey" },
  { country: "토고", code: 228, countryEn: "Togo" },
  { country: "토켈라우", code: 690, countryEn: "Tokelau" },
  { country: "통가", code: 676, countryEn: "Tonga" },
  { country: "투르크메니스탄", code: 993, countryEn: "Turkmenistan" },
  { country: "투발루", code: 688, countryEn: "Tuvalu" },
  { country: "튀니지", code: 216, countryEn: "Tunisia" },
  { country: "파나마", code: 507, countryEn: "Panama" },
  { country: "파라과이", code: 595, countryEn: "Paraguay" },
  { country: "파키스탄", code: 92, countryEn: "Pakistan" },
  { country: "파푸아 뉴기니", code: 675, countryEn: "Papua New Guinea" },
  { country: "팔라우", code: 680, countryEn: "Palau" },
  { country: "팔레스타인", code: 970, countryEn: "Palestine" },
  { country: "페로 제도", code: 298, countryEn: "Faroe Islands" },
  { country: "페루", code: 51, countryEn: "Peru" },
  { country: "포르투갈", code: 351, countryEn: "Portugal" },
  { country: "포클랜드 제도", code: 500, countryEn: "Falkland Islands" },
  { country: "폴란드", code: 48, countryEn: "Poland" },
  { country: "푸에르토리코", code: 1787, countryEn: "Puerto Rico" },
  { country: "프랑스", code: 33, countryEn: "France" },
  { country: "프랑스령 기아나", code: 594, countryEn: "French Guiana" },
  { country: "프랑스령 폴리네시아", code: 689, countryEn: "French Polynesia" },
  { country: "피지", code: 679, countryEn: "sebum" },
  { country: "핀란드", code: 358, countryEn: "Finland" },
  { country: "필리핀", code: 63, countryEn: "Philippines" },
  { country: "헝가리", code: 36, countryEn: "Hungary" },
  { country: "홍콩", code: 852, countryEn: "Hong Kong" },
];
