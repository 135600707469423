import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  copySubEventApplicationsToOtherSubEvent,
  getSelectedSubEvApplications,
} from "../../../../actions/application";
import { getSubEventsByMainEventId } from "../../../../actions/subEvent";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import { AppState } from "../../../../store";

import { makeStyles } from "@material-ui/core/styles";

import { Column, MTableToolbar } from "material-table";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import { useParams } from "react-router";
import { setProgressAction } from "../../../../actions/progresses";

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import SubHeadingTypo from "../../../../components/Typography/SubHeadingTypo";
import PxOutlinedFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typography/ButtonTypo";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import CaptionTypo from "../../../../components/Typography/CaptionTypo";
import PxTable from "../../../../components/Tables/PxTable";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    alignItems: "center",
    padding: 20,
  },
  outlinedFormControl: { width: 200 },
  tableTitle: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
  },
  stateChangeBtnText: { fontSize: "14px" },
  addResultDialogContent: {
    border: "1px solid grey",
    textAlign: "center",
    padding: 10,
    minHeight: 200,
  },
}));

interface RowType {
  applicationApproval: string;
  applicationId: string;
  applicationName: string;
  applicationManager: string;
  applicationType: string;
  // type: "seller" | "buyer";
  note?: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

interface AddApplicationDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddApplicationDialog = ({ open, setOpen }: AddApplicationDialogProps) => {
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const [selectedSubEv, setSelectedSubEv] = useState(""); // 선택된 subEvent 셀럭터 value 값 for Ui
  const [selectedSubEvent, setSelectedSubEvent] =
    useState<{
      // 선택된 subEvent
      id: string; // subEvent id
      category: string; // subEvent category
      bgroupName: string; // buyer 명칭
      sgroupName: string; // seller 명칭
    }>();
  const [subEventList, setSubEventList] = useState<
    {
      id: string; // subEvent id
      category: string; // subEvent category
      bgroupName: string; // buyer 명칭
      sgroupName: string; // seller 명칭
    }[]
  >([]);
  const [tableActionData, setTableActionData] =
    useState<{ subEventId: string; type: string }>();
  const [type, setType] = useState(""); // table 참가자 type sorting
  const [selectedAplcType, setSelectedAplcType] = useState<string>("");
  const [addResult, setAddResult] =
    useState<{
      succeedApplications: string[];
      failedApplications: RowType[];
    }>();
  const [addResultDialogOpen, setAddResultDialogOpen] =
    useState<boolean>(false);
  const [selectAplcTypeDialogOpen, setSelectAplcTypeDialogOpen] =
    useState<boolean>(false);
  const [willAddApplications, setWillAddApplications] = useState<RowType[]>([]);

  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const selectedSubEventApplications = useSelector(
    (state: AppState) => state.applications.selectedEventApplications
  );

  const [tableData, setTableData] = React.useState<TableStateType>({
    columns: [
      {
        title: t("applicationList.group"),
        field: "applicationType",
        cellStyle: {
          width: "150px",
          paddingRight: 0,
          maxWidth: 140,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("applicationList.corporateName"),
        field: "applicationName",
        cellStyle: {
          paddingRight: 0,
          width: "cal(100%-820px)",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("applicationList.manager"),
        field: "applicationManager",
        cellStyle: {
          paddingRight: 0,
          width: "cal(100%-820px)",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("applicationList.note"),
        field: "note",
        cellStyle: {
          width: "120px",
          textAlign: "inherit",
          paddingRight: 0,
        },
      },
    ],
    data: [],
  });

  const handleDialogClose = () => {
    setOpen(false);
  };

  // 선택한 서브이벤트 참가자리스트를 tableData로 set
  useEffect(() => {
    if (selectedSubEventApplications && selectedSubEvent) {
      dispatch(setTableInfoLoadingAction(true));

      const tableData = selectedSubEventApplications.content.map(
        (application) => ({
          applicationApproval: application.approval!,
          applicationId: application.id!,
          applicationName: application.name,
          applicationManager: application.applicants
            ? application.applicants[0].businessCardId!.name
            : "",
          applicationType:
            application.type === "seller"
              ? selectedSubEvent!.sgroupName
              : selectedSubEvent!.bgroupName,
          note: application.note ?? "",
          type: application.type as string,
        })
      );

      setTableData((prevCreateDate) => {
        return { ...prevCreateDate, data: tableData };
      });

      dispatch(setTableInfoLoadingAction(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubEvent, selectedSubEventApplications]);

  // 서브이벤트 셀렉터 subEvent 리스트api 콜
  useEffect(() => {
    const getSubEventList = async () => {
      if (subEvent) {
        const subEvents: any = await dispatch(
          getSubEventsByMainEventId(subEvent.mainEvent!.id!)
        );

        const subEvListExceptCurrentSubEv = subEvents.filter(
          (ev: {
            id: string; // subEvent id
            category: string; // subEvent category
          }) => ev.id !== subEvent.id
        );

        setSubEventList(subEvListExceptCurrentSubEv);
      }
    };

    getSubEventList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent]);

  // 서브이벤트 셀렉터 onChangeEvent
  const onChangeSelectedSubEv = useCallback(
    (
      e: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      setSelectedSubEv(e.target.value as string);
      setTableActionData({ subEventId: e.target.value as string, type: "" });
      setSelectedSubEvent(
        subEventList.filter((s) => s.id === (e.target.value as string))[0]
      );
    },
    [subEventList]
  );

  // 참가자 가져오기 버튼클릭 이후 가져올 참가자유형 셀렉터 onChangeEvent
  const onChangeSelectedAplcType = useCallback(
    (
      e: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      setSelectedAplcType(e.target.value as string);
    },
    []
  );

  const onClickAddApplication = async (evt: any, data: RowType | RowType[]) => {
    setWillAddApplications(data as RowType[]);

    setSelectAplcTypeDialogOpen(true);
  };

  const onClickAddApplicationConfirm = async () => {
    const willCopyApplicationIds: string[] = (
      willAddApplications as RowType[]
    ).map((row) => row.applicationId);

    dispatch(setProgressAction({ open: "true" }));
    const succeedApplications: any = await dispatch(
      copySubEventApplicationsToOtherSubEvent({
        subEventId: params.id,
        type: selectedAplcType,
        applicationIds: willCopyApplicationIds,
      })
    );
    dispatch(setProgressAction({ open: "false" }));

    // 등록 성공한 회사이름 리스트
    const succeedApplicationNames: string[] = succeedApplications
      ? succeedApplications.map((aplc: any) => aplc.name)
      : [];

    // 등록 실패한 회사이름 리스트 (이미 등록된 회사)
    const failedApplications = (willAddApplications as RowType[]).filter(
      (d) => {
        return succeedApplicationNames.indexOf(d.applicationName) < 0;
      }
    );

    setAddResult({
      succeedApplications: succeedApplicationNames,
      failedApplications: failedApplications,
    });

    setOpen(false);
    setSelectAplcTypeDialogOpen(false);
    setAddResultDialogOpen(true);
  };

  const onChangeSearchParam = useCallback(
    (
      e: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      setType(e.target.value as string);
      setTableActionData({
        subEventId: selectedSubEvent!.id,
        type: e.target.value as string,
      });
    },
    [selectedSubEvent]
  );

  const onCloseAddResultDialog = () => {
    setAddResultDialogOpen(false);
  };

  const onCloseSelectAddAplcTypeDialog = () => {
    setSelectAplcTypeDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={selectAplcTypeDialogOpen}
        onClose={onCloseSelectAddAplcTypeDialog}
      >
        <DialogContent>
          <SubHeadingTypo>참가자 유형선택</SubHeadingTypo>
          <PxOutlinedFormControl className={classes.outlinedFormControl}>
            <PxSelectBox
              value={selectedAplcType}
              onChange={onChangeSelectedAplcType}
              displayEmpty
              input={<OutlinedInput margin="dense" />}
            >
              <MenuItem key={subEvent?.sgroupName} value={"seller"}>
                {subEvent?.sgroupName}
              </MenuItem>
              <MenuItem key={subEvent?.bgroupName} value={"buyer"}>
                {subEvent?.bgroupName}
              </MenuItem>
            </PxSelectBox>
          </PxOutlinedFormControl>
        </DialogContent>
        <DialogActions>
          <PxButton onClick={onCloseSelectAddAplcTypeDialog}>
            <ButtonTypo>취소</ButtonTypo>
          </PxButton>
          <PxButton
            backgroundColor="purple"
            onClick={onClickAddApplicationConfirm}
          >
            <ButtonTypo>확인</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        open={addResultDialogOpen}
        onClose={onCloseAddResultDialog}
      >
        <DialogContent>
          <PxGridContainer direction="row">
            <PxGridItem className={classes.addResultDialogContent}>
              <SubHeadingTypo>{"< 참가자 등록성공 >"}</SubHeadingTypo>
              <Box height={10} />
              {addResult?.succeedApplications.map((r) => (
                <CaptionTypo>{`${r}`}</CaptionTypo>
              ))}
            </PxGridItem>
            <Box width={10} />
            <PxGridItem className={classes.addResultDialogContent}>
              <SubHeadingTypo>{"< 이미 등록된 참가자 >"}</SubHeadingTypo>
              <Box height={10} />
              {addResult?.failedApplications.map((r) => (
                <CaptionTypo>{`${r.applicationName}`}</CaptionTypo>
              ))}
            </PxGridItem>
          </PxGridContainer>
        </DialogContent>
        <DialogActions>
          <PxButton backgroundColor="purple" onClick={onCloseAddResultDialog}>
            <ButtonTypo>확인</ButtonTypo>
          </PxButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleDialogClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <div>
          <CaptionTypo>서브이벤트</CaptionTypo>
          <PxOutlinedFormControl className={classes.outlinedFormControl}>
            <PxSelectBox
              value={selectedSubEv}
              onChange={onChangeSelectedSubEv}
              displayEmpty
              input={<OutlinedInput margin="dense" />}
            >
              {subEventList.map((subEv) => (
                <MenuItem key={subEv.id} value={subEv.id}>
                  {subEv.category}
                </MenuItem>
              ))}
            </PxSelectBox>
          </PxOutlinedFormControl>
        </div>
        <PxTable<RowType>
          dialog={true}
          components={{
            Toolbar: (props) => {
              return (
                <PxGridContainer direction="column">
                  <MTableToolbar {...props} />
                </PxGridContainer>
              );
            },
          }}
          title={
            <div className={classes.tableTitle}>
              <PxOutlinedFormControl className={classes.outlinedFormControl}>
                <PxSelectBox
                  value={type}
                  onChange={onChangeSearchParam}
                  displayEmpty
                  input={<OutlinedInput margin="dense" />}
                >
                  <MenuItem value={""}>{t("applicationList.all")}</MenuItem>
                  <MenuItem value={"seller"}>
                    {selectedSubEvent?.sgroupName}
                  </MenuItem>
                  <MenuItem value={"buyer"}>
                    {selectedSubEvent?.bgroupName}
                  </MenuItem>
                </PxSelectBox>
              </PxOutlinedFormControl>
              <Box width={10} />
            </div>
          }
          getAction={
            selectedSubEvent ? getSelectedSubEvApplications : undefined
          }
          totalPage={selectedSubEventApplications?.totalPages}
          actionData={tableActionData}
          columns={tableData.columns}
          data={tableData.data}
          options={{
            toolbar: true,
            actionsColumnIndex: -1,
            showTitle: true,
            defaultExpanded: false,
            search: true,
            selection: true,
            searchFieldAlignment: "right",
            exportButton: false, // csv 다운
          }}
          actions={[
            {
              tooltip: "",
              icon: () => (
                <span className={classes.stateChangeBtnText}>가져오기</span>
              ),
              onClick: onClickAddApplication,
            },
          ]}
        />
      </Dialog>
    </>
  );
};

export default AddApplicationDialog;
