export default interface Event {
  event?: EventInfo;
  events?: EventPagination;
}

export interface EventInfo {
  id?: number;
  createdUserId?: number;
  approval?: string; // 승인여부
  name: string; // 이벤트 이름
  startDate: string; // 이벤트 기간
  endDate: string; // 이벤트 기간
  image: string; // 이벤트 이미지
  countryCode?: string; // 주최지
  locationCheck: boolean; // 이벤트 장소 여부 체크 (등록수정에서만 사용)
  location: string; // 이벤트 장소
  locationDetail: string; // 이벤트 장소 상세
  lat?: string; // 위도
  lng?: string; // 경도
  content: string; // 이벤트 개요
  homePageCheck: boolean; // 이벤트 웹사이트 체크
  homePage: string; // 이벤트 웹사이트
  atchFile?: Array<any> | undefined; // 첨부파일
  atchFile1?: number;
  atchFile2?: number;
  atchFile3?: number;
  atchFile4?: number;
  atchFile5?: number;
  mainImageFile?: any;
  mainImage?: number;
  bannerImageFile?: any;
  bannerImage?: number;
  digitalMap?: string; // 디지털맵
  managers?: Array<{
    id: string;
    name: string;
    email: string;
    phone: string;
    countryNumber: string;
  }>; // 담당자
  createdDate?: string; // 최초등록일
  modifiedDate?: string; // 최종수정일
  payment?: "Y" | "N"; // 결제여부
  showLogo?: "Y" | "N"; // 로고숨김 여부
}

export const approvalText = {
  Y: "게시", // 승인
  W: "미게시", // 대기
  R: "미게시", //승인요청
  N: "미게시", // 승인거절
  D: "삭제",
};

export interface EventPagination {
  content: EventInfo[];
  pageable?: any;
  last?: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export const eventCategoryArray = [
  "B2B",
  "B2C",
  "machinery",
  "medical",
  "power plan",
  "startup",
  "employment",
  "investment",
  "environment",
  "association",
  "society",
  "beauty",
  "entrepreneur",
  "IT",
  "textile",
  "MICE",
  "tourism",
  "education", "etc"
]
