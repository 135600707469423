import React, { useEffect, useState } from "react";
import axios from "axios";
import FileSaver from "file-saver";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import PxButton from "../Buttons/PxButton";
import ButtonTypo from "../Typography/ButtonTypo";

interface ShowFileProps {
  fileId: number;
  marginBottom?: number;
}

const ShowFile = ({ fileId, marginBottom }: ShowFileProps) => {
  const [fileState, setFileState] = useState<any>();
  useEffect(() => {
    getFile(fileId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  const getFile = async (id: number) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const res = await axios.get(`/api/fileS3?id=${id}`, config);
    setFileState(res.data);
  };

  const imgChk = (fileType: string, filePath: string) => {
    var reg = /(jpg|jpeg|png|gif|bmp)$/;

    if (fileType.match(reg)) {
      return (
        <>
          <img src={filePath} style={{ width: "100%" }} />
          <br />
        </>
      );
    }
  };

  return fileState !== undefined ? (
    <Grid sm={12} md={12} lg={12} style={{ marginBottom: marginBottom ?? "20px" }}>
      {imgChk(fileState.fileType, fileState.filePath)}
      <PxButton
        backgroundColor="grey"
        fullWidth
        onClick={() => {
          FileSaver.saveAs(fileState.filePath, fileState.fileName);
        }}
      >
        <ButtonTypo>{fileState.fileName}</ButtonTypo>
      </PxButton>
    </Grid>
  ) : null;
};

export default ShowFile;
