import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  background-color: white;
  padding: 15px;
`;

export default ({
  imgLink,
  setOpen,
}: {
  imgLink: string;
  setOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  return (
    <Container onClick={() => setOpen(false)}>
      <Image src={imgLink} />
    </Container>
  );
};
