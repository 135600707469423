import React, { useEffect } from "react";
import { Column } from "material-table";

// material icons
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PxTable from "../../components/Tables/PxTable";
import moment from "moment";
import { debounce } from "lodash";
import { removeLoading, setLoading } from "../../actions/loading";
import { getHostEvents } from "../../actions/events";
import { approvalText } from "../../types/models/Event";
import { setTableInfoLoadingAction } from "../../actions/tableInfo";

interface RowType {
  id: string;
  createDate: string;
  eventDate: string;
  name: string;
  payment: string;
  approval: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

export default ({ id }: { id: string }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoading());
    loadData();
  }, [id]);
  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: "신청일시",
        field: "createDate",
        cellStyle: {
          width: "130px",
        },
      },
      {
        title: "이벤트명",
        field: "name",
        cellStyle: {
          width: "calc(100% - 710px)",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "기간",
        field: "eventDate",
        cellStyle: {
          width: "230px",
        },
      },

      {
        title: "결제",
        field: "payment",
        cellStyle: {
          width: "60px",
        },
      },
      {
        title: "상태",
        field: "approval",
        cellStyle: {
          width: "60px",
        },
        render: (rowData: any) => {
          const approval: "Y" | "N" | "R" | "W" | "D" = rowData.approval;
          return approvalText[approval];
        },
      },
    ],
    data: [],
  });

  const loadData = debounce(async () => {
    const result: any = await dispatch(getHostEvents(id));
    if (result) {
      const tableData: RowType[] = result.content.map((data: any) => {
        const rowData: RowType = {
          name: data.name,
          createDate: moment(data.createDate).format("YYYY-MM-DD HH:mm").toString(),
          id: data.id!,
          payment: "무료",
          approval: data.approval,
          eventDate: `${moment(data.startDate).format("YYYY-MM-DD HH:mm")} ~ ${moment(
            data.endDate
          ).format("YYYY-MM-DD")} `,
        };
        return rowData;
      });
      setState({ ...state, data: tableData });
      console.log(tableData);
    }
    dispatch(setTableInfoLoadingAction(false));
    dispatch(removeLoading());
  }, 500);

  return (
    <PxTable<RowType>
      columns={state.columns}
      data={state.data}
      title=""
      options={{
        selection: false,
        actionsColumnIndex: 0,
        pageSize: 10,
        showTitle: true,
        defaultExpanded: false,
        search: true,
      }}
      onRowClick={(event, rowData) => {
        history.push(`/event/detail/${rowData!.id}`);
      }}
    />
  );
};
