import React, { useEffect } from "react";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// types
import { FormData } from "../../../types/models/AutonoForm";
import { Form } from "../../../types/models/Form";

// actions
import { AppState } from "../../../store";
import { setForms, createForm } from "../../../actions/forms";

// custom comp
import PxAutonoForm from "./PxAutonomousForm";

// material comp
import AutonomousForm from "./AutonomousForm";
import PxButton from "../../../components/Buttons/PxButton";
import ButtonTypo from "../../../components/Typography/ButtonTypo";
import PxGridContainer from "../../../components/Grid/PxGridContainer";

// material icons
import AddIcon from "@material-ui/icons/Add";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../actions/loading";

interface AutoFormProps {
  initialFormData: Form | FormData; // default form data
}

type Props = AutoFormProps;

const AutoForm: React.FC<Props> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formData } = useSelector((state: AppState) => state.forms);
  const { subEvent } = useSelector((state: AppState) => state.subEvents);
  const [initData, setInitialData] = React.useState<FormData>({
    formData: {},
    formIds: [],
  });

  // 바이어 참가양식 등록 시 셀러의 카테고리 및 키워드를 가져오는 api call
  const getSellerPartiForm = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      params: {
        id: subEvent?.sellerPartiForm,
      },
      data: {},
    };
    dispatch(setLoadingAction());
    try {
      const result = await axios.get("/api/forms/detail", config);

      const sellerFormData: FormData = JSON.parse(result.data.content);

      const sellerCategoryForm =
        sellerFormData.formData[sellerFormData.formIds[1]];
      const sellerKeywordForm =
        sellerFormData.formData[sellerFormData.formIds[2]];

      setInitialData((prevState) => {
        const formData = prevState.formData;
        formData[prevState.formIds[1]].requireCheck = "N";
        formData[prevState.formIds[1]].explainCheck =
          sellerCategoryForm.explainCheck;
        formData[prevState.formIds[1]].explain = sellerCategoryForm.explain;
        formData[prevState.formIds[1]].selectContent =
          sellerCategoryForm.selectContent;
        formData[prevState.formIds[2]].keywordOptions =
          sellerKeywordForm.keywordOptions;

        return { ...prevState, formData };
      });
    } catch (err) {
      history.goBack();
    }
    dispatch(removeLoadingAction());
  };

  // 바이어의 카테고리를 셀러의 카테고리양식과 똑같이 맞춰줌
  useEffect(() => {
    if (
      subEvent !== undefined &&
      subEvent.sellerPartiForm !== 0 &&
      (props.initialFormData as Form).type === "BYP" &&
      formData!.formIds.length === 0
    ) {
      getSellerPartiForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent]);

  useEffect(() => {
    if (formData !== undefined) {
      if (formData!.formIds.length === 0) {
        setInitialData((props.initialFormData! as Form).formData!);
      } else {
        setInitialData(formData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  // 양식추가하기 버튼 onClick function
  const formAddButtonOnClick = () => {
    // 기본 자율양식 form 데이터
    const newFormId = `form-${v4()}`;
    const newForm = {
      [newFormId]: {
        id: newFormId,
        index: 0,
        type: "single",
        title: "",
        explain: "",
        secretCheck: "N",
        explainCheck: "N",
        requireCheck: "N",
        selectContent: {
          formData: {
            [newFormId]: {
              id: newFormId,
              explain: "",
              index: 0,
            },
          },
          formIds: [newFormId],
        },
      },
    };
    setInitialData((prevState) => {
      const formData = Object.assign(prevState.formData, newForm);
      const formIds = prevState.formIds;
      formIds.push(newFormId);
      return { ...prevState, formData, formIds };
    });
  };

  // 양식 복사 onClick fucntion
  const formCopyButtonOnClick = (formId: string, index: number) => {
    const newFormId = `form-${v4()}`;
    // 복사할 폼과 같은 폼 생성
    const newForm = {
      [newFormId]: {
        ...initData.formData[formId],
        id: newFormId,
        selectContent: {
          formData: {},
          formIds: [] as string[],
        },
      },
    };

    // 단일 및 복수 선택지 내용을 복사하기 위한 변수
    const selectContent: FormData = {
      formData: {},
      formIds: [],
    };
    // 전체 form에 selectForm의 key값과 id 값만 변경해서 넣어줌
    for (let [, value] of Object.entries(
      initData.formData[formId].selectContent!.formData
    )) {
      const selectFormId = `form-${v4()}`;
      Object.assign(selectContent.formData, {
        [selectFormId]: {
          id: selectFormId,
          explain: value.explain,
          index: value.index,
        },
      });
    }

    // select Form의 index값에 따라 정렬 후 formIds 값에 넣어줌
    const selectFormOrderByIndex = Object.values(selectContent.formData).sort(
      (a, b) => a.index! - b.index!
    );
    const formIds = selectFormOrderByIndex.map((value) => {
      return value.id;
    });
    selectContent.formIds = formIds;

    // select content object값 갱신
    Object.assign(newForm[newFormId].selectContent, selectContent);

    // 생성된 폼을 formData에 추가하고 formIds 배열에 기존폼 index 옆에 추가
    setInitialData((prevState) => {
      const formData = Object.assign(initData.formData, newForm);
      const formIds = prevState.formIds;
      formIds.splice(index + 1, 0, newFormId);
      return { ...prevState, formData, formIds };
    });
  };

  // 양식 삭제 onClick function
  const formDeleteButtonOnClick = (selectId: string) => {
    // const newData = initData.formData.filter((value) => value.id !== selectId);
    delete initData.formData[selectId]; // delete object property
    setInitialData((prevState) => {
      // delete formId in formIds array
      const formIds = prevState.formIds.filter((value) => value !== selectId);
      return { ...prevState, formIds };
    });
  };
  // 복수/단수 선택 양식 추가 onClick function
  const selectContentFormAdd = (formId: string) => {
    const newFormId = `form-${v4()}`;
    const newSelectForm = {
      [newFormId]: {
        id: newFormId,
        explain: "",
        index: initData.formData[formId].selectContent?.formIds.length,
      },
    };

    setInitialData((prevState) => {
      const formData = prevState.formData;
      Object.assign(formData[formId].selectContent?.formData, newSelectForm);
      formData[formId].selectContent?.formIds.push(newFormId);
      return { ...prevState, formData };
    });
  };

  // 복수/단수 선택 폼 삭제 메소드
  const selectContentFormDelete = (formId: string, selectFormId: string) => {
    setInitialData((prevState) => {
      const formData = prevState.formData;
      delete formData[formId].selectContent?.formData[selectFormId];
      const formIds = formData[formId].selectContent?.formIds.filter(
        (value) => value !== selectFormId
      );
      formData[formId].selectContent!.formIds = formIds!;
      return { ...prevState, formData };
    });
  };

  // 미리보기 버튼 onclick function, redux에 forms data 저장 후 미리보기 화면이동
  const previewOnClick = () => {
    const form: Form = {
      formData: initData,
    };

    dispatch(setForms(form));
    history.push("/form/parti/preview");
  };

  // 완료 버튼 or 다음 버튼 onClick function
  const onSubmit = async () => {
    switch ((props.initialFormData as Form).type) {
      case "SP": {
        const form: Form = {
          ...props.initialFormData,
          formData: initData,
        };
        dispatch(setForms(form));
        history.push(
          `/form/subEvent/${
            (props.initialFormData as Form).subEventId
          }/match/addSellerPartiForm`
        );
        break;
      }
      case "BYP": {
        const form: Form = {
          ...props.initialFormData,
          formData: initData,
        };
        dispatch(setForms(form));
        history.push(
          `/form/subEvent/${
            (props.initialFormData as Form).subEventId
          }/match/addBuyerPartiForm`
        );
        break;
      }
      default: {
        const form: Form = {
          type: (props.initialFormData as Form).type,
          subEventId: (props.initialFormData as Form).subEventId,
          content: JSON.stringify(initData),
        };

        const result: any = await dispatch(createForm(form));
        if (result === true) {
          history.goBack();
        }
      }
    }
  };

  return (
    <>
      <AutonomousForm
        colunmId="columId-1"
        initialData={initData}
        setInitialData={setInitialData}
        draggableComp={
          <PxAutonoForm
            id=""
            index={0}
            elevation={0}
            setSelected={() => {}}
            draghandle={undefined}
            formCopy={formCopyButtonOnClick}
            formDelete={formDeleteButtonOnClick}
            initialData={initData}
            setInitialData={setInitialData}
            selectContentFormAdd={selectContentFormAdd}
            selectContentFormDelete={selectContentFormDelete}
          />
        }
      />
      <PxButton
        fullWidth
        backgroundColor="grey"
        startIcon={<AddIcon />}
        className="mt-3"
        onClick={formAddButtonOnClick}
      >
        <ButtonTypo>양식 추가하기</ButtonTypo>
      </PxButton>
      <PxGridContainer justify="space-between" style={{ marginTop: 100 }}>
        <PxButton
          padding="10px 60px 10px 60px"
          onClick={() => history.goBack()}
        >
          <ButtonTypo>취소</ButtonTypo>
        </PxButton>
        {/* 완성된 양식 미리보기 버튼 */}
        <PxButton
          backgroundColor="grey"
          onClick={previewOnClick}
          padding="10px 50px 10px 50px"
        >
          <ButtonTypo>미리보기</ButtonTypo>
        </PxButton>
        {/* 자율양식 완료 or 다음 버튼 (어떤 양식 만들기냐에 따라 다음 페이지가 있을 수 있고 없을 수 있음)*/}
        <PxButton
          backgroundColor="purple"
          padding="10px 60px 10px 60px"
          onClick={onSubmit}
        >
          <ButtonTypo>
            {/* 셀러참가양식, 바이어 참가양식 만들기 페이지에서만 추가양식 페이지로 이동 하기 때문에 구분*/}
            {(props.initialFormData as Form).type === "SP" ||
            (props.initialFormData as Form).type === "BYP"
              ? "다음"
              : "완료"}
            {/* {history.location.pathname.includes("noMatch") ? "완료" : "다음"} */}
          </ButtonTypo>
        </PxButton>
      </PxGridContainer>
    </>
  );
};

export default AutoForm;
