import React, { useState, useEffect, useMemo } from "react";
import { v4 } from "uuid";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { getForms } from "../../../../actions/forms";
import axios from "axios";

// types
import { Form } from "../../../../types/models/Form";

// 자율양식 form component
import AutoForm from "../../autonomous/AutoForm";

import useFormContext from "../../../../components/FormProvider/useFormContext";

const BuyerPartiForm = () => {
  const { initialLoad, setInitialLoad } = useFormContext();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>(); // subEvent id
  const { subEvent } = useSelector((state: AppState) => state.subEvents);
  const forms = useSelector((state: AppState) => state.forms);
  const [defaultData, setDefaultData] = useState<Form>();

  // 이미 등록한 양식이 있으면 db에서 불러옴
  useEffect(() => {
    if (
      subEvent?.buyerPartiForm !== undefined &&
      subEvent.buyerPartiForm !== 0 &&
      initialLoad
    ) {
      dispatch(getForms(subEvent.buyerPartiForm));
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent]);

  // db에서 불러온 양식을 가공하여 자율양식 form으로 만들어줌
  useEffect(() => {
    if (forms.content !== "" && forms.content !== undefined) {
      const data: Form = {
        id: forms.id,
        type: forms.type,
        content: "",
        subEventId: params.id,
      };
      setDefaultData({
        ...data,
        formData: JSON.parse(forms.content!),
        additionalFormData: JSON.parse(forms.additional!),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const makeDefaultFormData = () => {
    const map: Form = {
      type: "BYP",
      formData: { formData: {}, formIds: [] },
      content: "",
      subEventId: params.id,
    };
    const data = [
      { title: "기업명", type: "short" },
      { title: "관심 카테고리", type: "category" },
      { title: "키워드", type: "keyword" },
      { title: "카테고리", type: "single" },
    ].map((v) => {
      const formId = `form-${v4()}`;
      map.formData!.formIds.push(formId);
      return {
        [formId]: {
          id: formId,
          index: 0,
          type: v.type,
          selectable: false,
          optionable: false,
          title: v.title,
          explain: "",
          secretCheck: "N",
          explainCheck: "N",
          requireCheck: "N",
          selectContent: {
            formData: {
              [formId]: {
                id: formId,
                explain: "",
                index: 0,
              },
            },
            formIds: [formId],
          },
          content: "",
        },
      };
    });
    Object.assign(map.formData!.formData, ...data);
    return map;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialData = useMemo(() => makeDefaultFormData(), [params.id]);

  return (
    <>
      {defaultData !== undefined && <AutoForm initialFormData={defaultData} />}
      {defaultData === undefined && <AutoForm initialFormData={initialData} />}
    </>
  );
};

export default BuyerPartiForm;
