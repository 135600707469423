import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, RouteComponentProps } from "react-router-dom";
import { Column } from "material-table";
import {
  Chip,
  Grid,
  MenuItem,
  OutlinedInput,
  TypographyProps,
} from "@material-ui/core";
import styled from "styled-components";
// actions
import { createMail, getMailTargets } from "../../../../actions/mail";
import { AppState } from "../../../../store";

// custom ui comp
import PxTable from "../../../../components/Tables/PxTable";
import SubHeadingTypo from "../../../../components/Typography/SubHeadingTypo";
import OutlinedTxtField from "../../../../components/Inputs/OutlinedTxtField";
import { MailInfo, MailRecipient } from "../../../../types/models/Mail";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typography/ButtonTypo";
import { setDialog } from "../../../../actions/dialogs";
import { confirmAlert } from "react-confirm-alert";
import { confirmModalOptions } from "../../../../utils/confirmModalOptions";
import PxOutlinedFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import { useTranslation } from "react-i18next";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../../actions/loading";
import Body1Typo from "../../../../components/Typography/Body1Typo";
import { getSubEvent } from "../../../../actions/subEvent";
import { makeStyles } from "@material-ui/core/styles";
import PxEditor from "../../../../components/Editor/PxEditor";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const Folder = styled.div<{ open: boolean }>`
  overflow: hidden;
  display: ${(props) => (props.open ? "none" : "block")};
  /* transition: all 0.3s; */
`;

const FolderBtn = styled.span`
  float: right;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
`;

interface RowType {
  applicantId: string;
  approval: string;
  category: string;
  company: string;
  email: string;
  keyword: string;
  method: string;
  name: string;
  type: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const MailAdd: React.FC<RouteComponentProps> = ({ match }) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string } = useParams();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const mailTargets = useSelector((state: AppState) => state.mails.mailTargets);
  const user = useSelector((state: AppState) => state.users);
  const [folder, setfolder] = useState<boolean>(false);
  const [selectParam, setSelectParam] = useState("");
  const ref = useRef(null);
  const classes = useStyles();
  const [mail, setMail] = useState<MailInfo>({
    subEventId: param.id,
    senderName: user.company,
    recipient: [],
    title: "",
    content: "",
  });

  useEffect(() => {
    setMail({ ...mail, senderName: user.company });
  }, [user]);
  useEffect(() => {
    callMailTargets();
  }, []);

  const callMailTargets = useCallback(async () => {
    await dispatch(getMailTargets(param.id));
    dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  const callSubEvent = useCallback(async () => {
    dispatch(setTableInfoLoadingAction(true));
    await dispatch(getSubEvent(parseInt(param.id)));
    dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("mailAdd.group"),
        field: "type",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("common.email"),
        field: "email",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("common.company"),
        field: "company",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("common.name"),
        field: "name",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("mailAdd.category"),
        field: "category",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("mailAdd.keyword"),
        field: "keyword",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("mailAdd.approvalStatus"),
        field: "approval",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
    ],
    data: [],
  });
  useEffect(() => {
    let tableData: RowType[] = [];
    if (Array.isArray(mailTargets) && mailTargets.length > 0 && subEvent) {
      mailTargets.map((mailTarget) => {
        const rowData: RowType = {
          approval: mailTarget.approval || "",
          category: mailTarget.category || "",
          company: mailTarget.company || "",
          email: mailTarget.email || "",
          keyword: mailTarget.keyword || "",
          method: mailTarget.method || "",
          name: mailTarget.name || "",
          type:
            mailTarget.type === "seller"
              ? subEvent.sgroupName
              : subEvent.bgroupName,
          applicantId: mailTarget.usersId || "",
        };
        tableData.push(rowData);
      });
    } else {
      if (!subEvent) {
        callSubEvent();
      }
    }
    setState((prevCreateDate) => {
      return { ...prevCreateDate, data: tableData };
    });
  }, [mailTargets, subEvent]);

  useEffect(() => {
    let tableData: RowType[] = [];
    if (Array.isArray(mailTargets) && mailTargets.length > 0 && subEvent) {
      mailTargets
        .filter((data) => data.type?.includes(selectParam))
        .map((mailTarget) => {
          const rowData: RowType = {
            approval: mailTarget.approval || "",
            category: mailTarget.category || "",
            company: mailTarget.company || "",
            email: mailTarget.email || "",
            keyword: mailTarget.keyword || "",
            method: mailTarget.method || "",
            name: mailTarget.name || "",
            type:
              mailTarget.type === "seller"
                ? subEvent.sgroupName
                : subEvent.bgroupName,
            applicantId: mailTarget.usersId || "",
          };
          tableData.push(rowData);
        });
    }
    setState((prevCreateDate) => {
      return { ...prevCreateDate, data: tableData };
    });
  }, [selectParam]);

  // 메일 대상 선택
  const changeTarget = (changeData: RowType[]) => {
    let targetArray: MailRecipient[] = [];
    changeData.map((data: RowType) => {
      targetArray.push({
        email: data.email,
        company: data.company,
        name: data.name,
        recipientId: data.applicantId,
      });
    });
    setMail({ ...mail, recipient: targetArray });
  };

  // 이벤트 컨텐츠 내용(에디터) 변경
  const handleContentChange = (value: string) => {
    //태그 넣어보기
    setMail({
      ...mail,
      content: value as string,
    });
  };

  // 메일 내용 변경
  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    setMail({
      ...mail,
      [e.target.name as string]: e.target.value as string,
    });
  };

  // 메일 발송
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMail({ ...mail, senderName: mail.senderName?.trim() });
    confirmAlert(
      confirmModalOptions({
        title: "메일을 발송하시겠습니까??",
        click: async () => {
          dispatch(setLoadingAction());
          const mailRes = await dispatch(createMail(mail));
          dispatch(removeLoadingAction());
          if (mailRes.toString() === "success") {
            dispatch(setDialog(`메일 발송`, ["메일이 발송되었습니다."]));

            history.push(`/subEvent/${param.id}/mail`);
          }
        },
      })
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <SubTitle>
        {t("mailAdd.list")}
        <FolderBtn onClick={() => setfolder(!folder)}>
          {folder ? (
            <>
              {t("mailAdd.open")} <ArrowDropDownIcon />
            </>
          ) : (
            <>
              {t("mailAdd.fold")} <ArrowDropUpIcon />
            </>
          )}
        </FolderBtn>
      </SubTitle>

      <Folder open={folder}>
        <Grid
          container
          direction="row"
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            minHeight: "60px",
          }}
        >
          <PxTable<RowType>
            // components={{
            //   Toolbar: (props) => {
            //     return (
            //       <PxGridContainer direction="column">
            //         {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
            //         <MTableToolbar {...props} />
            //       </PxGridContainer>
            //     );
            //   },
            // }}
            title={
              <div
                style={{
                  width: "100%",

                  margin: 5,
                }}
              >
                <PxOutlinedFormControl>
                  <PxSelectBox
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px",
                    }}
                    value={selectParam}
                    onChange={(e) => setSelectParam(e.target.value as string)}
                    displayEmpty
                    input={<OutlinedInput margin="dense" />}
                  >
                    <MenuItem value={""}>{t("mailAdd.all")}</MenuItem>
                    <MenuItem value={"seller"}>
                      {subEvent ? subEvent.sgroupName : "SELLER"}
                    </MenuItem>
                    <MenuItem value={"buyer"}>
                      {subEvent ? subEvent.bgroupName : "BUYER"}
                    </MenuItem>
                  </PxSelectBox>
                </PxOutlinedFormControl>
              </div>
            }
            columns={state.columns}
            data={state.data}
            // material-table 속성: https://material-table.com/#/docs/features/search
            options={{
              toolbar: true,
              actionsColumnIndex: -1,
              paging: false,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "300px",
              // pageSize: 10,
              showTitle: true,
              defaultExpanded: false,
              search: true,
              selection: true,
              searchFieldAlignment: "right",
              exportButton: false, // csv 다운
            }}
            onSelectionChange={(rows) => changeTarget(rows)}
          />
        </Grid>
      </Folder>

      <SubTitle></SubTitle>
      <SubTitle>{t("mailAdd.senderName(alias)")}</SubTitle>

      <Grid container direction="row">
        <OutlinedTxtField
          fullWidth
          defaultValue={user.company}
          onChange={handleOnChange}
          name="senderName"
          value={mail.senderName}
          placeholder={t("mailAdd.entername(alias)")}
        />
      </Grid>
      <SubTitle>{t("mailAdd.recipient")}</SubTitle>
      <Grid
        container
        direction="row"
        style={{
          padding: "10px",
          minHeight: "60px",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        }}
      >
        {mail.recipient !== undefined && mail.recipient.length > 0 ? (
          mail.recipient.map((targetId: any, idx) => (
            <Chip
              key={`email-${idx}`}
              // avatar={<Avatar>M</Avatar>}
              label={targetId.email}
              // clickable
              color="primary"
              //onDelete={handleDelete}
              // deleteIcon={<DoneIcon />}
              variant="outlined"
              style={{ margin: "3px 10px 3px 0" }}
            />
          ))
        ) : (
          <div style={{ alignSelf: "center", color: "#888" }}>
            {t("mailAdd.selectItFromTheList")}
          </div>
        )}
      </Grid>
      <SubTitle> {t("common.title")}</SubTitle>
      <Grid container direction="row">
        <OutlinedTxtField
          fullWidth
          onChange={handleOnChange}
          name="title"
          value={mail.title}
          placeholder={t("mailAdd.typeTheTitle")}
        />
      </Grid>
      <SubTitle> {t("common.content")}</SubTitle>
      <Body1Typo>{t("mailAdd.contentTip")}</Body1Typo>
      <PxEditor
        value={mail.content}
        onChange={handleContentChange}
        folderPath="event/"
        placeholder={t("mailAdd.typeTheContent")}
      />
      <Grid container alignContent="space-between" spacing={1}>
        <Grid item>
          <PxButton backgroundColor="bluePurple" type="submit">
            <ButtonTypo txtcolor="white">{t("mailList.send")}</ButtonTypo>
          </PxButton>
        </Grid>
        <Grid item>
          <PxButton backgroundColor="grey" onClick={() => history.goBack()}>
            <ButtonTypo>{t("common.cancel")}</ButtonTypo>
          </PxButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default MailAdd;

const useStyles = makeStyles((theme) => ({
  quill: {
    display: "inline-block",
    width: "100%",
    height: "100%",
    "& .ql-editor": {
      minHeight: 200,
      maxHeight: 800,
      height: "auto",
    },
  },
}));
