import React, { useEffect, useState } from "react";
import Estimate, {
  CalendarSelector,
  InputNumber,
  Item,
  Divider,
  Price,
  PexpoAccount,
  Taxbill,
  LoadFileInput,
  DialogImgBtn,
} from "./Estimate";
import { fileStateToInitial, getFile, getSignedRequest } from "../../../actions/files";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import moment from "moment";
import { useHistory } from "react-router-dom";
import PxSwitch from "../../../components/switch/PxSwitch";
import PxButton from "../../../components/Buttons/PxButton";
import { createEstimate, modifyEstimate } from "../../../actions/estimates";
import File from "../../../types/models/File";
import { setAlertMessage } from "../../../actions/alerts";

export interface EstimateProps {
  subEventId: number;
  maxUsers: number;
  type: "estimate" | "quoation";
  numberOfPrinter: number;
  printStartDate: string;
  printEndDate: string;
  numberOfPassPocket: number;
  customDesign: boolean;
  customRolls: number;
  customDesignImg: any;
  supporters: number;
  supportStartDate: string;
  supportEndDate: string;
  totalPrice: number;
  deposit: number;
  finalPrice: number;
  taxBillEmail: string;
  businessRegistration: any;
}

export default () => {
  const dispatch = useDispatch();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent); // layout 페이지에서 subEvent data load
  const user = useSelector((state: AppState) => state.users);
  const files = useSelector((state: AppState) => state.files);
  const [isModify, setIsModify] = useState(false);
  const [filesName, setFilesName] = useState({
    customDesign: { name: "", path: "" },
    businessRegistration: { name: "", path: "" },
  });
  const now = moment().format("YYYY-MM-DD");
  const history = useHistory();
  const [quoationData, setQuoationData] = useState<EstimateProps>({
    subEventId: 0,
    maxUsers: 0,
    type: "quoation", // estiamte : 예상견적 , admin : 최종견적
    numberOfPrinter: 0,
    printStartDate: now,
    printEndDate: now,
    numberOfPassPocket: 0,
    customDesign: false,
    customRolls: 0,
    customDesignImg: 0,
    supporters: 1,
    supportStartDate: now,
    supportEndDate: now,
    totalPrice: 0,
    deposit: 500000,
    finalPrice: 0,
    taxBillEmail: user.taxInvoiceMail || "",
    businessRegistration: parseFloat(user.businessImageId || ""),
  });

  useEffect(() => {
    if (user && user.taxInvoiceMail && user.businessImageId) {
      setQuoationData({
        ...quoationData,
        taxBillEmail: user.taxInvoiceMail,
        businessRegistration: parseFloat(user.businessImageId),
      });
    }
  }, [user]);

  useEffect(() => {
    if (subEvent && subEvent.estimates && subEvent.estimates.length > 0) {
      let estimate = subEvent.estimates.find((data) => data.type === "estimate")!;
      if (subEvent.estimates.some((data) => data.type === "quoation")) {
        estimate = subEvent.estimates.find((data) => data.type === "quoation")!;
        setIsModify(true);
      }
      setQuoationData({
        ...quoationData,
        subEventId: estimate.subEventId,
        maxUsers: estimate.maxUsers,
        type: "quoation",
        numberOfPrinter: estimate.numberOfPrinter || 0,
        printStartDate: estimate.printStartDate || now,
        printEndDate: estimate.printEndDate || now,
        numberOfPassPocket: estimate.numberOfPassPocket || 0,
        customDesign: estimate.customDesign,
        customRolls: estimate.customRolls || 0,
        customDesignImg: estimate.customDesignImg ? estimate.customDesignImg.id : 0,
        supporters: estimate.supporters || 0,
        supportStartDate: estimate.supportStartDate || now,
        supportEndDate: estimate.supportEndDate || now,
        totalPrice: estimate.totalPrice,
        deposit: estimate.deposit,
        finalPrice: estimate.totalPrice - estimate.deposit,
        taxBillEmail: estimate.taxBillEmail,
        businessRegistration: estimate.businessRegistration.id,
      });
      setFilesName({
        customDesign: {
          name: estimate.customDesignImg ? estimate.customDesignImg.fileName : "",
          path: estimate.customDesignImg ? estimate.customDesignImg.filePath : "",
        },
        businessRegistration: {
          name: estimate.businessRegistration.fileName,
          path: estimate.businessRegistration.filePath,
        },
      });
    }
  }, [subEvent]);

  //데이터 입력
  const onChagne = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = event.currentTarget.name;

    //숫자 입력
    if (
      (name === "maxUsers" ||
        name === "numberOfPrinter" ||
        name === "numberOfPassPocket" ||
        name === "customRolls" ||
        name === "supporters") &&
      (!isNaN(Number(event.currentTarget.value)) || event.currentTarget.value === "")
    ) {
      //숫자만 입력가능하게 replace
      const value =
        event.currentTarget.value === ""
          ? 0
          : parseInt(event.currentTarget.value.replace(/[^0-9]/g, ""));

      // 날짜 카운트 , 같은 날짜 값 = 1
      const printDateCount =
        moment(quoationData.printEndDate, "YYYY-MM-DD").diff(
          moment(quoationData.printStartDate, "YYYY-MM-DD"),
          "day"
        ) + 1;
      const supportDateCount =
        moment(quoationData.supportEndDate, "YYYY-MM-DD").diff(
          moment(quoationData.supportStartDate, "YYYY-MM-DD"),
          "day"
        ) + 1;

      const data: CaulatorProps[] = [
        { num: name === "maxUsers" ? value : quoationData.maxUsers, price: 7700 },
        {
          num: (name === "numberOfPrinter" ? value : quoationData.numberOfPrinter) * printDateCount,
          price: 110000,
        },
        {
          num: name === "numberOfPassPocket" ? value : quoationData.numberOfPassPocket,
          price: 1500,
        },
        {
          num: (name === "supporters" ? value : quoationData.supporters) * supportDateCount,
          price: 110000,
        },
        { num: name === "customRolls" ? value : quoationData.customRolls, price: 50000 },
      ];
      const totalPrice = caulator(data);
      setQuoationData({
        ...quoationData,
        [name]: value,
        totalPrice,
        finalPrice: totalPrice - quoationData.deposit,
      });
    }
    // 날짜 입력
    if (
      name === "printStartDate" ||
      name === "printEndDate" ||
      name === "supportStartDate" ||
      name === "supportEndDate"
    ) {
      const value = event.currentTarget.value;
      const printDateCount =
        moment(name === "printEndDate" ? value : quoationData.printEndDate, "YYYY-MM-DD").diff(
          moment(name === "printStartDate" ? value : quoationData.printStartDate, "YYYY-MM-DD"),
          "day"
        ) + 1;
      const supportDateCount =
        moment(name === "supportEndDate" ? value : quoationData.supportEndDate, "YYYY-MM-DD").diff(
          moment(name === "supportStartDate" ? value : quoationData.supportStartDate, "YYYY-MM-DD"),
          "day"
        ) + 1;

      const data: CaulatorProps[] = [
        { num: quoationData.maxUsers, price: 7700 },
        {
          num: quoationData.numberOfPrinter * printDateCount,
          price: 110000,
        },
        {
          num: quoationData.numberOfPassPocket,
          price: 1500,
        },
        {
          num: quoationData.supporters * supportDateCount,
          price: 110000,
        },
        { num: quoationData.customRolls, price: 50000 },
      ];
      const totalPrice = caulator(data);
      setQuoationData({
        ...quoationData,
        [name]: event.currentTarget.value,
        totalPrice,
        finalPrice: totalPrice - quoationData.deposit,
      });
    }
  };

  //파일 등록 // 디스패치로 -> 파일검사 -> s3등록 -> 결과 fileSeletor 에서 추출후 파일이름 보여주고 id 값 estimate에 입력
  const handleImageAdd = (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();
    const file = e.target.files[0];
    const originalFileName = file.name.split(".");
    originalFileName.splice(-1, 1);
    const timestamp = new Date().getTime();
    const fileType = file.type.split("/")[1];
    const fileName = originalFileName.join() + "&&" + timestamp + "." + fileType;
    const folderPath = e.currentTarget.name === "customDesign" ? "subEvent/passDesign/" : "auth/";
    const fileSize = file.size;
    const gubun = e.currentTarget.name as string; // 여러파일등록시 어떤 파일을 등록했는지 구분

    dispatch(getSignedRequest(file, fileName, fileType, folderPath, fileSize, gubun));
  };
  // //파일 등록결과
  // useEffect(() => {
  //   if (files.id !== "") {
  //     files.gubun === "businessRegistration"
  //       ? setFilesName({
  //           ...filesName,
  //           businessRegistration: { name: files.fileName, path: files.filePath },
  //         })
  //       : setFilesName({
  //           ...filesName,
  //           customDesign: { name: files.fileName, path: files.filePath },
  //         });
  //     files.gubun === "businessRegistration"
  //       ? setQuoationData({ ...quoationData, businessRegistration: parseFloat(files.id) })
  //       : setQuoationData({ ...quoationData, customDesignImg: parseFloat(files.id) });
  //   }
  //   // 리덕스 초기화
  //   dispatch(fileStateToInitial());
  // }, [files]);

  const submitBtn = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    if (!subEvent || !subEvent.id) {
      return dispatch(setAlertMessage("새로고침 후 다시 시도해 주세요"));
    }
    const result: any = isModify
      ? await dispatch(modifyEstimate({ ...quoationData }))
      : await dispatch(createEstimate({ ...quoationData, subEventId: parseFloat(subEvent.id) }));
    if (result) {
      history.goBack();
    }
  };

  return (
    <Estimate title="상담회 최종 견적서" subEvent={subEvent!}>
      <Item
        title="승인된 참가자 수"
        subTitle="1인당 ₩7,700"
        input={
          <InputNumber
            name="maxUsers"
            defaultValue={0}
            value={quoationData.maxUsers}
            onChange={onChagne}
          />
        }
      />
      <Divider />
      <Item
        title="무선 출입증 프린터"
        subTitle="1대당 ₩110,000"
        input={
          <InputNumber
            name="numberOfPrinter"
            value={quoationData.numberOfPrinter}
            onChange={onChagne}
          />
        }
      />
      <Item
        title="대여 기간"
        subTitle="시작일 하루전 설치 및 테스트"
        justify="flex-start"
        input={
          <>
            <CalendarSelector
              name="printStartDate"
              value={quoationData.printStartDate}
              onChange={onChagne}
            />{" "}
            -{" "}
            <CalendarSelector
              name="printEndDate"
              value={quoationData.printEndDate}
              onChange={onChagne}
            />
          </>
        }
      />
      <Divider />
      <Item
        title="출입증 목걸이"
        subTitle={
          <>
            1개당 ₩1,500 [
            <DialogImgBtn
              title="출입증 기본 디자인 보기"
              link="https://pexpo2.s3.ap-northeast-2.amazonaws.com/images/name_badge.png"
            />
            ]
          </>
        }
        input={
          <InputNumber
            name="numberOfPassPocket"
            value={quoationData.numberOfPassPocket}
            onChange={onChagne}
          />
        }
      />
      <Divider />
      <Item
        title="커스텀 출입증 디자인"
        subTitle="10 x 13(cm) 사이즈로 제작"
        input={
          <PxSwitch
            checked={quoationData.customDesign}
            onChange={() =>
              setQuoationData({
                ...quoationData,
                customDesign: !quoationData.customDesign,
                customRolls: !quoationData.customDesign ? 10 : 0,
              })
            }
            label=""
          />
        }
      />

      {quoationData && quoationData.customDesign && (
        <>
          <Item
            title="디자인 시안"
            subTitle={
              filesName.customDesign && filesName.customDesign.name !== "" ? (
                <DialogImgBtn
                  title={filesName.customDesign.name}
                  link={filesName.customDesign.path}
                />
              ) : (
                "없음"
              )
            }
            input={
              <LoadFileInput
                name="customDesignImg"
                onChange={handleImageAdd}
                accept="image/jpeg, image/png"
              />
            }
          />
          <Item
            title="주문 수량"
            subTitle="1롤당 ₩50,000 / 1롤당 250장 인쇄 / 최소 주문수량 10롤"
            input={
              <InputNumber
                name="customRolls"
                value={quoationData.customRolls}
                onChange={onChagne}
              />
            }
          />
        </>
      )}

      <Item
        title="기술지원"
        subTitle="1인당 ₩110,000 / 최소 1명 필수"
        input={
          <InputNumber name="supporters" value={quoationData.supporters} onChange={onChagne} />
        }
      />
      <Item
        title="지원 기간"
        subTitle="시작일 하루전 오리엔테이션 포함"
        justify="flex-start"
        input={
          <>
            <CalendarSelector
              name="supportStartDate"
              value={quoationData.supportStartDate}
              onChange={onChagne}
            />{" "}
            -
            <CalendarSelector
              name="supportEndDate"
              value={quoationData.supportEndDate}
              onChange={onChagne}
            />
          </>
        }
      />
      <Price
        totalPrice={quoationData.totalPrice}
        deposit={quoationData.deposit}
        amount={quoationData.finalPrice}
      />
      <PexpoAccount />

      <Taxbill
        name="taxBillEmail"
        onChange={onChagne}
        value={quoationData.taxBillEmail}
        subTitle={
          filesName.businessRegistration && filesName.businessRegistration.name !== "" ? (
            <DialogImgBtn
              title={filesName.businessRegistration.name}
              link={filesName.businessRegistration.path}
            />
          ) : (
            "없음"
          )
        }
        nameInput="businessRegistration"
        onChangeInput={handleImageAdd}
      />

      <PxButton
        boxshadow={false}
        fullWidth
        backgroundColor="#512da8"
        noBorderRadius={true}
        txtColor="white"
        onClick={submitBtn}
      >
        저장하기
      </PxButton>
    </Estimate>
  );
};

//합계 계산기
interface CaulatorProps {
  num: number; //개수
  price: number; //개당 가격
}

function caulator(props: CaulatorProps[]) {
  let amount: number = 0;
  props.forEach((item) => {
    amount += item.num * item.price;
  });
  return amount;
}
