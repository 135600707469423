import React, { useEffect } from "react";
import { Switch, RouteComponentProps, Route } from "react-router-dom";

// router

// type
import RouteType from "../types/models/Route";

// material comp
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { getSubEvent } from "../actions/subEvent";
import { EstimatesRoutes } from "../router";

const Estimate: React.FC<RouteComponentProps> = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubEvent(parseFloat(window.location.pathname.split("/")[3])));
  }, []);

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      const path = prop.layout + prop.path;
      if (prop.path.indexOf("/consulting") > -1) {
        return <Route path={path} component={prop.component} key={key} />;
      } else if (prop.path.indexOf("/conferenceQuoation") > -1) {
        return <Route path={path} component={prop.component} key={key} />;
      } else if (prop.path.indexOf("/conference") > -1) {
        return <Route path={path} component={prop.component} key={key} />;
      } else if (prop.path.indexOf("/consultingQuoation")) {
        return <Route path={path} component={prop.component} key={key} />;
      }
    });
  };
  return (
    <Grid container direction="row" justify="center">
      <Switch>{getRoutes(EstimatesRoutes)}</Switch>
    </Grid>
  );
};

export default Estimate;
