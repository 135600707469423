import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import MeetingTimeCard from "./MeetingTimeCard";

import styled from "styled-components";

import { useTranslation } from "react-i18next";

import moment from "moment";
import { AppState } from "../../../store";
import { MeetingData, MeetingTimeInfo } from "../../../types/models/SubEvent";
import { parallaxCalculation } from "../../../utils/momentTz";
import Grid from "@material-ui/core/Grid";
import PxOutlinedFormControl from "../../../components/Forms/PxOutlinedFormControl";
import InputLabel from "@material-ui/core/InputLabel";
import PxSelectBox from "../../../components/SelectBox/PxSelectBox";
import MenuItem from "@material-ui/core/MenuItem";
import {
  GetCountryApiInfo,
  GetCountryInfo,
} from "../../../types/models/Country";

const MeetingForm = styled.div`
  width: 100%;
  overflow: scroll;
  display: grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
`;
const MeetingFormData = styled.div`
  width: 189px;
  height: 24px;
  font-size: 15px;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.6);
`;
const MeetingFormTime = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const MeetingTimeForm = ({ date }: { date: moment.Moment }) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  // const meeting = useSelector((state: AppState) => state.meetings.meeting);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [meetingData, setMeetingData] = useState<MeetingData[]>();

  const [meetingStatus, setMeetingStatus] = useState(""); // 대기: waiting, 동의: agree, 불가시간: impossible
  const { getCountry, getCountries } = useSelector(
    (state: AppState) => state.countries!
  );

  useEffect(() => {
    if (subEvent !== undefined && subEvent.meetingData !== "") {
      const countryCode = subEvent.mainEvent?.countryCode;
      const subEventStartDate = parallaxCalculation(
        `${subEvent.subEventStartDate.split("T")[0]}T${
          subEvent.matchStartTime
        }`,
        getCountries![countryCode! as string] as any,
        getCountries![getCountry!.countryCode!] as any
      );
      //현지시간
      let localStartOfWeek = date
        .clone()
        .startOf("week")
        .isSameOrAfter(subEventStartDate)
        ? date.clone().startOf("week")
        : moment(subEventStartDate); // subEventStartDate(현지날짜 변경되어야함) 보다 전날이면 안됨
      const endOfWeek = date
        .clone()
        .endOf("week")
        .isSameOrBefore(subEvent.subEventEndDate)
        ? date.clone().endOf("week")
        : moment(subEvent.subEventEndDate); // subEventEndDate(현지날짜 변경되엉야함) 보다 이후면 안됨

      //서버시간
      let serverTimeStartOfWeek = moment(
        parallaxCalculation(
          localStartOfWeek.format("YYYY-MM-DDTHH:ss"),
          getCountries![getCountry!.countryCode!] as any,
          ""
        )
      );
      // 나라별 시간을 따로 보여주기위해 showDate, showStartTime, showEndTime 추가
      let newTimeMeeting: MeetingData = {};
      let newTimeMeetingArr: MeetingData[] = [];
      // 최대 7일치 만들기
      for (
        let d =
          endOfWeek.diff(localStartOfWeek, "days") + 1 >= 8
            ? 7
            : endOfWeek.diff(localStartOfWeek, "days") + 1;
        d > 0;
        d--
      ) {
        const newTImes = [
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(-1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            getCountries!,
            countryCode!,
            getCountry!,
            subEvent.impossibleDateAndTime
          ),
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            getCountries!,
            countryCode!,
            getCountry!,
            subEvent.impossibleDateAndTime
          ),
          ...timeArrGenerator(
            subEvent.subEventStartDate,
            subEvent.subEventEndDate,
            serverTimeStartOfWeek.add(1, "days").format("YYYY-MM-DD"),
            subEvent.matchStartTime!,
            subEvent.matchReqTime!,
            subEvent.matchBreakTime!,
            subEvent.matchNumPerDay!,
            getCountries!,
            countryCode!,
            getCountry!,
            subEvent.impossibleDateAndTime
          ),
        ];

        newTimeMeeting = {
          date: serverTimeStartOfWeek.add(-1, "days").format("YYYY-MM-DD"), //한국시간
          showDate: localStartOfWeek.format("YYYY-MM-DD"), // 현지시간
          time: newTImes.filter(
            (d) => d.showDate == localStartOfWeek.format("YYYY-MM-DD")
          ),
        };
        localStartOfWeek.add(1, "days");
        serverTimeStartOfWeek.add(1, "days");
        newTimeMeetingArr.push(newTimeMeeting);
      }
      setMeetingData(newTimeMeetingArr);

      // setMeetingData(newMeetings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return meetingData !== undefined ? (
    <>
      {/* 미팅 상태 필터 */}
      <div style={{ marginBottom: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PxOutlinedFormControl fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                {t("common.status")}
              </InputLabel>
              <PxSelectBox
                name="category"
                label={t("common.status")}
                value={meetingStatus}
                // value={filter.category || ""}
                onChange={(e: any) => setMeetingStatus(e.target.value)}
                displayEmpty
              >
                <MenuItem value={""}>{t("common.status")}</MenuItem>
                <MenuItem value={"waitingApplicant"}>
                  {t("meetingDetail.requesting")}
                </MenuItem>
                <MenuItem value={"waitingAcceptor"}>
                  {t("meetingDetail.awaiting")}
                </MenuItem>
                <MenuItem value={"agree"}>
                  {t("meetingDetail.meetingConcluded")}
                </MenuItem>
                <MenuItem value={"impossible"}>
                  {t("meetingDetail.setUnavailable")}
                </MenuItem>
              </PxSelectBox>
            </PxOutlinedFormControl>
          </Grid>
        </Grid>
      </div>

      <MeetingForm>
        {Array.isArray(meetingData) &&
          meetingData.map((meeting: any, dateIndex: number) => (
            <div key={`metting-card-${dateIndex}`}>
              <MeetingFormData>{meeting.showDate}</MeetingFormData>
              <MeetingFormTime>
                {meeting.time.map((tm: any, timeIndex: number) => (
                  <>
                    <MeetingTimeCard
                      key={`metting-time-card-${timeIndex}`}
                      id={`${tm.date}${tm.startTime}${tm.endTime}`}
                      date={tm.date}
                      startTime={tm.startTime}
                      endTime={tm.endTime}
                      showDate={meeting.showDate}
                      showStartTime={tm.showStartTime}
                      showEndTime={tm.showEndTime}
                      hostCountry={
                        getCountries![subEvent!.mainEvent!.countryCode!].koName!
                      }
                      showHostMeetingTime={tm.showHostMeetingTime}
                      impossibleAt={tm.impossibleAt}
                      dateIndex={dateIndex}
                      timeIndex={timeIndex}
                      meetingStatus={meetingStatus}
                    />
                  </>
                ))}
              </MeetingFormTime>
            </div>
          ))}
      </MeetingForm>
    </>
  ) : null;
};

export default MeetingTimeForm;

function timeArrGenerator(
  subEventStartDate: string,
  subEventEndDate: string,
  startDate: string,
  startTime: string,
  reqTime: number,
  breakTime: number,
  numPerDay: number,
  countries: GetCountryInfo,
  countryCode: string,
  country: GetCountryApiInfo,
  impossibleDateAndTime: string | undefined
): MeetingTimeInfo[] {
  // timecard 만들어주는 함수 -----
  // 서브이벤트 시작날
  // 서브이벤트 시작시간
  // 서브이벤트 끝나는날
  // 미팅 소요시간
  // 미팅 쉬는시간
  // 미팅 횟수
  // --------------------------
  // subEvent , countrycode 변경될때마다 업데이트

  let meetingTimeList: MeetingTimeInfo[] = []; // 필요데이터 {시작시간, 종료시간, 불가여부}
  // 날짜별 매칭시간 기준 값
  let impossibleStartTime = moment(`${startDate.split("T")[0]} ${startTime}`);

  if (impossibleStartTime.isBefore(subEventStartDate.split("T")[0]))
    return meetingTimeList;
  if (
    impossibleStartTime.isAfter(`${subEventEndDate.split("T")[0]}T${startTime}`)
  )
    return meetingTimeList;
  const disabledDateAndTime = impossibleDateAndTime
    ? JSON.parse(impossibleDateAndTime)
    : {}; //{년 : {월 : [day_time]}}
  let localStartTime = moment(
    parallaxCalculation(
      impossibleStartTime.format("YYYY-MM-DDTHH:mm:ss"),
      countries![countryCode as string] as any,
      countries![country!.countryCode!] as any
    )
  );
  // 날짜별 매칭시간 조회 (시작시간, 종료시간, 불가여부)
  Array.from({ length: numPerDay || 0 }, (x, i) => {
    meetingTimeList.push({
      startTime: impossibleStartTime.format("HH:mm"),
      impossibleAt: checkDisabledTime(impossibleStartTime, disabledDateAndTime), // 주최자 지정 불가시간
      endTime: impossibleStartTime.add(reqTime, "m").format("HH:mm"),
      date: impossibleStartTime.format("YYYY-MM-DD"),
      showDate: localStartTime.format("YYYY-MM-DD"),
      showStartTime: localStartTime.format("HH:mm"),
      showEndTime: localStartTime.add(reqTime, "m").format("HH:mm"),
    });
    localStartTime.add(breakTime, "m");
    impossibleStartTime = impossibleStartTime.add(breakTime, "m");
  });

  return meetingTimeList;
}

function checkDisabledTime(
  serverTime: moment.Moment,
  disabledDateAndTime: any
): "N" | "Y" {
  const [year, month, day] = serverTime.format("YYYY-MM-DD").split("-");
  if (
    disabledDateAndTime.hasOwnProperty(year) &&
    disabledDateAndTime[year].hasOwnProperty(month) &&
    disabledDateAndTime[year][month].length > 0 &&
    disabledDateAndTime[year][month].some(
      (d: string) => d == `${day}_${serverTime.format("HH:mm")}`
    )
  ) {
    return "Y";
  }
  return "N";
}
