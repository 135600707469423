import React from "react";

// material
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ButtonTypo from "../Typography/ButtonTypo";

interface PxCheckBoxProps {
  label: string | JSX.Element;
  name?: string;
  checked?: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PxCheckBox: React.FC<PxCheckBoxProps> = (props) => {
  const { label, name, checked, onChange, value, disabled } = props;
  return (
    <FormControlLabel
      name={name}
      disabled={disabled}
      control={<Checkbox checked={checked} onChange={onChange} />}
      label={<ButtonTypo>{label}</ButtonTypo>}
      value={value}
    />
  );
};

export default PxCheckBox;
