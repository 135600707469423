import ZoomActionTypes from "../types/actions/ZoomAction";
import Zoom from "../types/models/Zoom";

interface ZoomsReduxType {
  zoom?: Zoom;
  zooms?: Zoom[];
  loading: boolean;
}

const zoomDefaultState: ZoomsReduxType = {
  loading: true,
};

const zoomReducer = (
  state = zoomDefaultState,
  action: ZoomActionTypes
): ZoomsReduxType => {
  switch (action.type) {
    case "CREATE_ZOOM":
      return { ...state };
    case "GET_ZOOM":
      return { ...state, zoom: action.zoom };
    case "GET_ZOOMS":
      return { ...state, zooms: action.zooms };
    default:
      return state;
  }
};

export default zoomReducer;
