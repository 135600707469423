import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "../actions/alerts";
import {
  MeetingHistoryInfo,
  MeetingHistoryPagination,
} from "../types/models/MeetingHistory";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { setAlert } from "../utils/utils";

export const createMeetingHistoryAction = (
  meetingHistory: MeetingHistoryInfo
): AppActions => ({
  type: "CREATE_MEETING_HISTORY",
  meetingHistory,
});

export const getMeetingHistoriesAction = (
  meetingHistories: MeetingHistoryPagination
): AppActions => ({
  type: "GET_MEETING_HISTORIES",
  meetingHistories,
});

export const getMeetingHistoryAction = (
  meetingHistory: MeetingHistoryInfo
): AppActions => ({
  type: "GET_MEETING_HISTORY",
  meetingHistory,
});

export const createMeetingHistory =
  (meetingHistory: MeetingHistoryInfo) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
    };

    const body = JSON.stringify(meetingHistory);

    const path = `/api/meetingHistories`;
    try {
      var res: AxiosResponse<Event> = await axios.post(path, body, config);

      return "success";
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
      return "error";
    }
  };

export const getMeetingHistories =
  <MeetingHistory>(
    subEventId: string,
    page: number = 0,
    pageSize: number = 10,
    search: string = ""
  ) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/meetingHistories?subEventId=${subEventId}&searchWord=${search}&page=${page}&size=${pageSize}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      dispatch(getMeetingHistoriesAction(res.data));
      return res.data;
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };

export const getMeetingHistory =
  <MeetingHistory>(meetingHistoryId: string) =>
  async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    const path = `/api/meetingHistories/detail?meetingHistoryId=${meetingHistoryId}`;
    try {
      var res: AxiosResponse = await axios.get(path, config);
      dispatch(getMeetingHistoryAction(res.data));
    } catch (err) {
      const error: Error = err.response.data;
      const alert: Alert = setAlert(err.response.status, error, path);
      dispatch(setAlertAction(alert));
      setTimeout(() => {
        dispatch(removeAlertAction(alert.id));
      });
    }
  };
