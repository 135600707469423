import React, { useEffect, useState, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../../../store";
import throttle from "lodash/throttle";
import findIndex from "lodash/findIndex";
import axios from "axios";

// types
import { SubEvenPathContext } from "../../../../layouts/SubEvent";

// @material-ui
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Autocomplete from "@material-ui/lab/Autocomplete";

// custom ui comp

import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";

// icons
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { createInterpreter } from "../../../../actions/interpreter";
import { useTranslation } from "react-i18next";
import SubHeadingTypo from "../../../../components/Typography/SubHeadingTypo";
import OutlinedTxtField from "../../../../components/Inputs/OutlinedTxtField";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typography/ButtonTypo";

// User type for autocomplete User list
interface UserType {
  id: string;
  emailId: string;
  email: string;
  name: string;
  phoneNumber: string;
}

// meeting type for autocomplete Meeting list
interface MeetingType {
  id: string;
  applicantName: string;
  acceptorName: string;
  meetingId: string;
  endTime: string;
  startTime: string;
}

// subtitle style
type TypoProp = TypographyProps;

const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const InterpreterAdd = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const { subEventId } = useContext(SubEvenPathContext);
  const { interpreters } = useSelector((state: AppState) => state.interpreters);

  // 선택된 유저id
  const [userInfo, setUserInfo] = useState<UserType>({
    id: "",
    emailId: "",
    phoneNumber: "",
    email: "",
    name: "",
  });

  // 선택된 미팅 id 리스트
  const [meetingInfo, setMeetingInfo] = useState<MeetingType[]>([]);

  // user search autocomplete input
  const [userEmailInput, setUserEmailInput] = useState<string>("");
  // 검색한 user 리스트
  const [userList, setUserList] = useState<UserType[]>([]);

  // meeting search autocomplete input
  const [meetingInput, setMeetingInput] = useState<string>("");
  // 검색한 meeting 리스트
  const [meetingList, setMeetingList] = useState<MeetingType[]>([]);

  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    let name: string = e.target.name as string;
    let value: string = e.target.value as string;

    setUserInfo({ ...userInfo, [name]: value });
  };

  const handelUserEmailIdOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();
    setUserEmailInput(e.target.value as string);
  };

  const handelMeetingInfoOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();
    setMeetingInput(e.target.value as string);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data: any = {
      name: userInfo.name,
      email: userInfo.email,
      phoneNumber: userInfo.phoneNumber,
      meetingIds: meetingInfo,
      userId: userInfo.id,
      subEventId: subEventId,
    };

    const result: any = await dispatch(createInterpreter(data));
    if (result) {
      history.goBack();
    }
  };

  const getUserList = React.useMemo(
    () =>
      throttle(async (input: string) => {
        const result = await axios.get("/api/users/list", {
          params: { email: input },
        });
        const userListData: UserType[] = result.data.map((user: UserType) => {
          return {
            id: user.id,
            name: user.name,
            emailId: user.email,
            email: user.email,
            phoneNumber: user.phoneNumber,
          };
        });

        const check: { emailId: string }[] = interpreters.map(
          (interpreter) => ({
            emailId: interpreter.userId.email,
          })
        );

        // 이미 등록된 통역사 리스트 제거
        const data = userListData.filter((user) => {
          if (findIndex(check, { emailId: user.emailId }) === -1) {
            return true;
          } else {
            return false;
          }
        });

        setUserList(data);
      }, 1500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getMeetingList = useMemo(
    () =>
      throttle(async (input: string) => {
        const result = await axios.get("/api/meetings/interpreterMeetingList", {
          params: { subEventId: subEventId, companyName: input },
        });
        setMeetingList(result.data);
      }, 1500),
    [subEventId]
  );

  useEffect(() => {
    if (userEmailInput.length > 2) {
      getUserList(userEmailInput);
    }
  }, [userEmailInput, getUserList]);

  useEffect(() => {
    if (meetingInput.length > 2) {
      getMeetingList(meetingInput);
    }
  }, [meetingInput, getMeetingList]);

  return (
    <Grid container>
      <Grid item md={12}>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px" }} md={12}>
        <form onSubmit={onSubmit}>
          <SubTitle>{t("interpreterAdd.email(id)")}</SubTitle>
          <Autocomplete
            options={userList}
            getOptionLabel={(option) => option.emailId}
            getOptionSelected={(option, value) => {
              return option.emailId === value.emailId;
            }}
            onChange={(event, value) => {
              if (value !== null) {
                setUserInfo(value);
              }
            }}
            renderInput={(params) => (
              <OutlinedTxtField
                {...params}
                name="emailId"
                value={userEmailInput}
                fullWidth
                onChange={handelUserEmailIdOnChange}
                placeholder={t(
                  "interpreterAdd.typeTheEmailIDAndSelectAnInterpreterFromTheList"
                )}
                InputProps={{
                  ...params.InputProps,
                  style: { padding: 3 },
                }}
                inputProps={{
                  ...params.inputProps,
                  style: { paddingLeft: 10 },
                }}
              />
            )}
          />

          <SubTitle>{t("interpreterAdd.meetingCorporate")}</SubTitle>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={meetingList}
            onChange={(event, value) => {
              setMeetingInfo([...value]);
            }}
            getOptionLabel={(option) =>
              `${option.acceptorName} / ${option.applicantName} / ${option.startTime} - ${option.endTime}`
            }
            getOptionSelected={(option, value) => {
              return option.id === value.id;
            }}
            renderOption={(option, { selected }) => {
              return (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.acceptorName} / {option.applicantName} /{" "}
                  {option.startTime} - {option.endTime}
                </React.Fragment>
              );
            }}
            renderInput={(params) => (
              <OutlinedTxtField
                {...params}
                name="meetingId"
                value={meetingInput}
                fullWidth
                onChange={handelMeetingInfoOnChange}
                placeholder={
                  meetingInfo.length === 0
                    ? t(
                        "interpreterAdd.typeTheCorporateNameAndSelectAMeetingForInterpreter"
                      )
                    : ""
                }
                InputProps={{
                  ...params.InputProps,
                  style: { padding: 3 },
                }}
                inputProps={{
                  ...params.inputProps,
                  style: { paddingLeft: 10 },
                }}
              />
            )}
          />

          <SubTitle>{t("common.name")}</SubTitle>
          <OutlinedTxtField
            name="name"
            value={userInfo.name}
            fullWidth
            onChange={handleOnChange}
            placeholder={t("interpreterAdd.typeTheName")}
          />

          <SubTitle>{t("common.phone")}</SubTitle>
          <OutlinedTxtField
            name="phoneNumber"
            value={userInfo.phoneNumber}
            fullWidth
            onChange={handleOnChange}
            placeholder={t("interpreterAdd.typeTheCellularPhoneNo")}
          />

          <SubTitle>{t("common.email")}</SubTitle>
          <OutlinedTxtField
            name="email"
            value={userInfo.email}
            fullWidth
            onChange={handleOnChange}
            placeholder={t("interpreterAdd.typeTheEmailAddress")}
          />

          <Grid
            container
            alignContent="space-between"
            spacing={1}
            style={{ justifyContent: "flex-end", marginTop: "32px" }}
          >
            <Grid item>
              <PxButton backgroundColor="purple" type="submit">
                <ButtonTypo>{t("common.register")}</ButtonTypo>
              </PxButton>
            </Grid>
            <Grid item>
              <PxButton
                backgroundColor="grey"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ButtonTypo>{t("common.cancel")}</ButtonTypo>
              </PxButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default InterpreterAdd;
