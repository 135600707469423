import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import { PresenterType } from "../../../../types/models/Webinar";
import { countryPhoneNumberCode } from "../../../../utils/constants";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import OutlinedTxtField from "../../../../components/Inputs/OutlinedTxtField";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import { SubTitle } from "./WebinarAdd";
import { makeStyles } from "@material-ui/core/styles";
import PxRadioGroup, {
  RadioData,
} from "../../../../components/Radios/PxRadioGroup";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  countryNumSelectBox: {
    width: "15%",
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
    "& div": {
      padding: 11,
    },
  },
  phoneNumTxtField: {
    width: "85%",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
  },
}));

interface WebinarPresenterProps {
  setPresenterInfo: React.Dispatch<React.SetStateAction<PresenterType>>;
  presenterInfo: PresenterType;
  userEmailInput: string;
  setUserEmailInput: React.Dispatch<React.SetStateAction<string>>;
  userList: PresenterType[];
  setUserList: React.Dispatch<React.SetStateAction<PresenterType[]>>;
  handleOnChange: (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => void;
  disabled?: boolean;
  setIsNew?: React.Dispatch<React.SetStateAction<boolean>>;
}

const WebinarPresenter = ({
  setPresenterInfo,
  presenterInfo,
  userEmailInput,
  setUserEmailInput,
  userList,
  handleOnChange,
  disabled,
  setIsNew,
}: WebinarPresenterProps) => {
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const classes = useStyles();
  const radioLabelData: RadioData[] = [
    { value: "exist", label: t("webinarAdd.existingUser") },
    { value: "new", label: t("webinarAdd.newUser") },
  ];
  const [userRadio, setUserRadio] = useState<string>("exist");

  const handelUserEmailIdOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();
    setUserEmailInput(e.target.value as string);
  };

  const userRadioOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    e.preventDefault();
    // 기존유저, 새로운 유저 선택 변경 시 presenterInfo state 초기화
    setPresenterInfo({
      userId: "",
      emailId: "",
      company: t("webinarAdd.presenter"),
      phoneNumber: "",
      countryNumber: "82",
      email: "",
      name: "",
    });
    if (setIsNew) {
      setIsNew(value === "new" ? true : false);
    }
    setUserRadio(value);
  };

  return (
    <>
      <PxRadioGroup
        titleComp={
          <SubTitle>{disabled ? "등록한 발표자" : " 발표자 등록 "}</SubTitle>
        }
        data={radioLabelData}
        value={userRadio}
        onchange={userRadioOnChange}
        radiogroupname="userExist"
        row
        disabled={disabled}
      />
      {userRadio === "exist" && (
        <div>
          <SubTitle margin="0 0 8px 0">{t("webinarAdd.email(id)")}</SubTitle>
          <Autocomplete
            options={userList}
            disabled={disabled}
            getOptionLabel={(option) => option.emailId}
            getOptionSelected={(option, value) => {
              return option.emailId === value.emailId;
            }}
            onChange={(_, value) => {
              if (value !== null) {
                setPresenterInfo(value);
              }
            }}
            renderInput={(params) => (
              <OutlinedTxtField
                {...params}
                name="emailId"
                value={userEmailInput}
                fullWidth
                onChange={handelUserEmailIdOnChange}
                placeholder={t(
                  "webinarAdd.typeTheEmailIDAndSelectAPresenterFromTheList"
                )}
                InputProps={{
                  ...params.InputProps,
                  style: { padding: 3 },
                }}
                inputProps={{
                  ...params.inputProps,
                  style: { paddingLeft: 10 },
                }}
              />
            )}
          />
        </div>
      )}

      <div>
        <SubTitle fontweight="bold" margin="10px 0 8px 0">
          {t("common.name")}
        </SubTitle>
        <OutlinedTxtField
          name="name"
          value={presenterInfo.name}
          fullWidth
          onChange={handleOnChange}
          placeholder={t("webinarAdd.typeTheName")}
          disabled={disabled}
        />
        <SubTitle fontweight="bold" margin="10px 0 8px 0">
          {t("common.email")}
        </SubTitle>
        <OutlinedTxtField
          name="email"
          value={presenterInfo.email}
          fullWidth
          onChange={handleOnChange}
          placeholder={t("webinarAdd.typeTheEmailAddress")}
          disabled={userRadio === "exist" || disabled}
        />
        <SubTitle fontweight="bold" margin="10px 0 8px 0">
          {t("common.phone")}
        </SubTitle>
        <PxGridContainer direction="row">
          <PxSelectBox
            variant="outlined"
            className={classes.countryNumSelectBox}
            displayEmpty
            disabled={disabled}
            name="countryNumber"
            onChange={handleOnChange}
            defaultValue={presenterInfo.countryNumber}
            value={presenterInfo.countryNumber}
          >
            <MenuItem value="" disabled>
              {t("webinarAdd.countryCode")}
            </MenuItem>
            {countryPhoneNumberCode.map((countryCode) => (
              <MenuItem value={countryCode.code}>
                {" "}
                {i18n.language === "ko"
                  ? countryCode.country
                  : countryCode.countryEn}
              </MenuItem>
            ))}
          </PxSelectBox>
          <OutlinedTxtField
            className={classes.phoneNumTxtField}
            name="phoneNumber"
            value={presenterInfo.phoneNumber}
            fullWidth
            onChange={handleOnChange}
            placeholder={t("webinarAdd.typeThePhoneNo")}
            disabled={disabled}
          />
        </PxGridContainer>
      </div>
    </>
  );
};

export default WebinarPresenter;
