import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import { blue } from "@material-ui/core/colors";
import {
  MyMeetingContext,
  MeetingApplicantDataInterface,
} from "./MeetingDetail";
import { confirmAlert } from "react-confirm-alert";
import Typography from "@material-ui/core/Typography";
import Filter from "./Filter";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store";
import {
  applicationMeeting,
  getMeetingTargets,
  getMyMeetings,
  meetingTargetsActionStateToInitial,
} from "../../../actions/meeting";
import { setProgressAction } from "../../../actions/progresses";
import { checkBatchimEnding } from "../../../utils/utils";
import { confirmModalOptions } from "../../../utils/confirmModalOptions";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

// props interface
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  date: string;
  startTime: string;
  endTime: string;
}

interface AcceptorListType {
  id: string;
  name: string;
  manager: string; // 담당자 이름
  info: ApplicantListInfoType[];
}
interface ApplicantListInfoType {
  title: string;
  content: string;
}
const MeetingTargetsDialog: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const param: { id: string; secondId: string } = useParams();
  const { open, setOpen, date, startTime, endTime } = props;
  const { myMeetingCheckList, myMeetingSearch } = useContext(MyMeetingContext);
  const [acceptorList, setAcceptorList] = useState<AcceptorListType[]>([]);
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  let applyingForAMeetingAt = false;

  const application = useSelector(
    (state: AppState) => state.applications.application
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const meetingTargets = useSelector(
    (state: AppState) => state.meetings.meetingTargets
  );

  // 목록 조회
  useEffect(() => {
    if (
      open === true &&
      application !== undefined &&
      application.id !== undefined
    ) {
      dispatch(
        getMeetingTargets(
          props.id,
          param.id,
          application.id,
          application.type!,
          myMeetingSearch.name,
          myMeetingSearch.keyword,
          myMeetingSearch.category
        )
      );
    }
  }, [open, myMeetingSearch]);

  // 조회 목록 가공
  useEffect(() => {
    // 신청가능 기업 목록 조회
    if (meetingTargets !== undefined && meetingTargets.length > 0) {
      let meetingTargetsSetting: AcceptorListType[] = [];
      meetingTargets.map((meetingTarget) => {
        // 버튼을 숨기더라도 볼 수 있을까봐 미연에 방지
        // 해당시간에 미팅을 '신청' 을 했던지 '받은 내역'이 없으면 출력
        if (myMeetingCheckList[props.id] === undefined) {
          let info: ApplicantListInfoType[] = [];

          meetingTarget.applicationFormAnswers!.map((answer) => {
            if (
              answer?.type! !== undefined &&
              answer?.repeatInfo === "N" &&
              answer?.content !== undefined
            ) {
              let content = "";
              content = answer!.content!;
              let infoData: ApplicantListInfoType = {
                title: answer!.title!,
                content: content!,
              };
              info.push(infoData);
            }
          });

          meetingTargetsSetting.push({
            id: meetingTarget.id as string,
            name: meetingTarget.name,
            manager: meetingTarget.applicants
              ? meetingTarget.applicants[0].businessCard.name
              : "",
            info: info,
          });
        }
      });
      setAcceptorList(meetingTargetsSetting);
    } else {
      setAcceptorList([]);
    }
  }, [meetingTargets]);

  useEffect(() => {
    return () => {
      dispatch(meetingTargetsActionStateToInitial());
    };
  }, []);

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleListItemClick = async (applicationId: string) => {
    dispatch(setProgressAction({ open: "true" }));
    const mailPostToggleAt: string =
      localStorage.getItem("mailPostToggleAt") === null
        ? "false"
        : localStorage.getItem("mailPostToggleAt")!.toString();
    // 미팅 신청 데이터
    const meetingApplicantData: MeetingApplicantDataInterface = {
      subEventId: param.id, // 서브 이벤트
      applicant: applicationId, // 신청자(application id)
      acceptor: application!.id as string, // 수락자(application id)
      meetingId: props.id, // 미팅시간 key;
      status: "waiting", // 상태 [대기: waiting, 동의: agree, 비동의: disagree]
      date: date,
      startTime: startTime,
      endTime: endTime,
      mailPostToggleAt: mailPostToggleAt,
    };
    if (applyingForAMeetingAt === true) {
      alert(t("meetingDetail.alreadyApplying"));
      return;
    }

    applyingForAMeetingAt = true;

    const result: any = await dispatch(
      applicationMeeting(meetingApplicantData)
    );
    dispatch(setProgressAction({ open: "false" }));
    applyingForAMeetingAt = false;
    if (result === true) {
      dispatch(getMyMeetings(application!.id!));
      setOpen(false);
    }
  };

  const messageComponent = (info: ApplicantListInfoType[]) => {
    return (
      <>
        <Typography color="textSecondary" style={{ padding: 0 }}>
          {info.map((inData: ApplicantListInfoType) => (
            <div>
              {inData.title.trim() === "기업명"
                ? t("filter.corporate")
                : inData.title.trim() === "카테고리"
                ? t("filter.category")
                : inData.title.trim() === "키워드"
                ? t("filter.keyword")
                : inData.title.trim() === "관심 카테고리"
                ? t("filter.interestedCategory")
                : inData.title}
              : {inData.content}
            </div>
          ))}
        </Typography>
      </>
    );
  };

  return application !== undefined &&
    meetingTargets !== undefined &&
    subEvent !== undefined ? (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          {i18n.language === "ko"
            ? `
          미팅 신청할${" "}
          ${
            application.type === "buyer"
              ? /*셀러 : 바이어*/
                `${subEvent.sgroupName}
                ${
                  checkBatchimEnding(subEvent.sgroupName) === false
                    ? "를 "
                    : "을 "
                }
              `
              : `${subEvent.bgroupName}
                ${
                  checkBatchimEnding(subEvent.bgroupName) === false
                    ? "를 "
                    : "을 "
                }`
          }
          선택해 주세요`
            : `
          select a ${
            application.type === "buyer"
              ? subEvent.sgroupName
              : subEvent.bgroupName
          } to request a meeting
          `}
        </DialogTitle>
        <div style={{ padding: "0 24px" }}>
          <Filter />
        </div>
        <List>
          {acceptorList.length > 0 ? (
            acceptorList.map(
              (meetingTarget, targetIndex) =>
                myMeetingCheckList[props.id] === undefined && (
                  <ListItem
                    button
                    onClick={() => {
                      confirmAlert(
                        confirmModalOptions({
                          title:
                            i18n.language === "ko"
                              ? `'${meetingTarget.name}'에게 미팅 신청하시겠습니까?`
                              : `Would you like to request a meeting with '${meetingTarget.name}'`,
                          message: messageComponent(meetingTarget.info),
                          click: () =>
                            handleListItemClick(meetingTarget.id as string),
                        })
                      );
                    }}
                    key={`meeting-target-${targetIndex}`}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${meetingTarget.name} (${meetingTarget.manager})`}
                    />
                  </ListItem>
                )
            )
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t("meetingDetail.thereIsNoMeetingAvailableAtThatTime")}
              />
            </ListItem>
          )}
        </List>
      </Dialog>
    </>
  ) : null;
};

export default MeetingTargetsDialog;
