import { Advertising } from "../types/models/Advertising";
import { AppActions, AppState } from "../store";
import { postRequest, getRequest } from "../utils/utils";

export const createAdvertisingAction = (
  advertising: Advertising
): AppActions => ({
  type: "CREATE_ADVERTISING",
  advertising: advertising,
});

export const getAdvertisingsAction = (
  advertisings: Advertising[]
): AppActions => ({
  type: "GET_ADVERTISINGS",
  advertisings: advertisings,
});

export const modfiyAdvertisingOpenAction = (
  advertising: Advertising
): AppActions => ({
  type: "MODIFY_ADVERTISING_OPEN",
  advertising: advertising,
});

export const createAdvertising = (eventId: string, type: string) => {
  return postRequest({ eventId: eventId, type: type }, "/api/advertising");
};

export const getAdvertisings = () => {
  return getRequest(null, "/api/advertising", getAdvertisingsAction, {});
};

export const modifyAdvertisingOpen = (id: string) => {
  return getRequest(
    null,
    "/api/advertising/advertisingOpen",
    modfiyAdvertisingOpenAction,
    { id: id }
  );
};
