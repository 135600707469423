import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  MenuItem,
  InputLabel,
  Grid,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanel,
} from "@material-ui/core";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store";
import { getForm } from "../../../actions/forms";
import PxOutlinedTxtField from "../../../components/Inputs/PxOutlinedTxtField";
import PxOutlinedFormControl from "../../../components/Forms/PxOutlinedFormControl";
import PxSelectBox from "../../../components/SelectBox/PxSelectBox";
import PxButton from "../../../components/Buttons/PxButton";
import ButtonTypo from "../../../components/Typography/ButtonTypo";
import { locationParamData } from "../../../utils/utils";

const ExpansionPanelForm = styled(ExpansionPanel)`
  padding: 0;
  margin: 0;
  &::before {
    border: 0;
    height: 0;
  }
`;
const Filter = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const applicationType = useSelector(
    (state: AppState) => state.applications!.application!.type
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const formContent: string = useSelector(
    (state: AppState) => state.forms.content!
  );
  const [t, i18n] = useTranslation("lang", { useSuspense: false });

  const [categorySearchOption, setCategorySearchOption] = useState<string[]>(
    []
  );

  // PartiForm
  const [filter, setFilter] = useState<{ [key: string]: string }>({
    name: "",
    keyword: "",
    category: "",
  });

  useEffect(() => {
    const locationSearchData = locationParamData(location.search);
    if (locationSearchData !== {}) {
      setFilter(locationSearchData as { [key: string]: string });
    }

    let formId: string = "";
    if (applicationType === "buyer") {
      formId = subEvent!.sellerPartiForm!.toString();
    } else {
      formId = subEvent!.buyerPartiForm!.toString();
    }
    dispatch(getForm(formId));
  }, [location.search]);

  useEffect(() => {
    let categorySearchList: string[] = [];
    if (formContent !== "") {
      const formContentParsing = JSON.parse(formContent);
      const categoryKey = formContentParsing.formIds[1];
      const categoryData =
        formContentParsing.formData[categoryKey].selectContent;
      categoryData.formIds.map((formKey: string) => {
        categorySearchList.push(categoryData.formData[formKey].explain);
      });
      setCategorySearchOption(categorySearchList);
    }
  }, [formContent]);

  const handleInputOnChange = (
    event: React.ChangeEvent<{ value: unknown; name: string }>
  ) => {
    setFilter({
      ...filter,
      [event.target.name as string]: event.target.value as string,
    });
  };

  const onSubmit = () => {
    let submitpath = location.pathname;
    if (Object.keys(filter).length > 0) {
      submitpath += `?name=`;
      submitpath += filter.name !== undefined ? filter.name : "";
      submitpath += `&keyword=`;
      submitpath += filter.keyword !== undefined ? filter.keyword : "";
      submitpath += `&category=`;
      submitpath += filter.category !== undefined ? filter.category : "";
      submitpath += ``;
    }
    history.push(submitpath);
  };

  // 미팅 신청 대상 필터 입력값이 있는지 체크
  function objectValueCheck(obj: any) {
    return Object.keys(obj).reduce(
      (sum, key) => sum + parseFloat(obj[key] ? "1" : "0"),
      0
    );
  }

  return categorySearchOption.length > 0 ? (
    <div>
      <ExpansionPanelForm
        elevation={0}
        style={{ marginBottom: "30px" }}
        defaultExpanded={true} //{objectValueCheck(filter) > 0}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
          style={{ padding: 0, margin: "0" }}
        >
          {t("filter.filtering")}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <PxOutlinedTxtField
                fullWidth
                name="name"
                value={filter.name || ""}
                rows={2}
                label={t("filter.corporate")}
                placeholder={t("filter.typeCorporate")}
                fontsize={14}
                onChange={handleInputOnChange}
                onKeyDown={(e) => e.keyCode == 13 && onSubmit()}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <PxOutlinedTxtField
                fullWidth
                name="keyword"
                value={filter.keyword || ""}
                rows={2}
                label={t("filter.keyword")}
                placeholder={t("filter.TypeKeyword")}
                fontsize={14}
                onChange={handleInputOnChange}
                onKeyDown={(e) => e.keyCode == 13 && onSubmit()}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <PxOutlinedFormControl fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("filter.category")}
                </InputLabel>
                <PxSelectBox
                  name="category"
                  label={t("filter.typeCategory")}
                  value={filter.category || ""}
                  onChange={(e: any) => handleInputOnChange(e)}
                  displayEmpty
                >
                  {categorySearchOption.map((categoryText) => (
                    <MenuItem value={categoryText}>{categoryText}</MenuItem>
                  ))}
                </PxSelectBox>
              </PxOutlinedFormControl>
            </Grid>
            <Grid item xs={12} md={3} style={{ margin: "auto" }}>
              <PxButton fullWidth backgroundColor="purple" onClick={onSubmit}>
                <ButtonTypo className="font-weight-bold">
                  {t("filter.search")}
                </ButtonTypo>
              </PxButton>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanelForm>
    </div>
  ) : null;
};

export default Filter;
