import InputAdornment from "@material-ui/core/InputAdornment";
import React, { useEffect, useState } from "react";
import HeadlineTypo from "../../components/Typography/HeadlineTypo";
import TitleTypo from "../../components/Typography/TitleTypo";
import CloseIcon from "@material-ui/icons/CloseRounded";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import PxButton from "../../components/Buttons/PxButton";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxIconButton from "../../components/Buttons/PxIconButton";
import { useHistory } from "react-router";
import { getApplicantByPhoneNum, printLabel } from "../../actions/deskPrint";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { useParams } from "react-router-dom";
import { removeAlertAction, setAlertAction } from "../../actions/alerts";
import { setPrinterLoadingAction } from "../../actions/printers";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    top: "15%",
    textAlign: "center",
    alignSelf: "center",
  },
  textField: {
    backgroundColor: "#f5f5f5",
    width: 300,
  },
  numpadRoot: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  numBtn: {
    marginTop: 5,
    "&:disabled": {
      backgroundColor: "transparent",
    },
  },
}));

const PrinterFindUser = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ subEventId: string }>();
  const [number, setNumber] = useState<string>("");
  const { loading, alert, printer, subEvent } = useSelector(
    (state: AppState) => state.printers
  );

  useEffect(() => {
    if (loading === false && alert) {
      dispatch(setAlertAction(alert!));
      setTimeout(() => {
        dispatch(removeAlertAction(alert!.id));
        dispatch(setPrinterLoadingAction(false, undefined));
      });
      if (alert.id === "printerSuccess") {
        history.goBack();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, alert, dispatch]);

  const onSubmit = async () => {
    const applicant: any = await dispatch(
      getApplicantByPhoneNum(params.subEventId, number)
    );

    if (applicant && printer && subEvent) {
      printLabel({
        applicantId: applicant.id,
        eventName: applicant.eventName,
        companyName: applicant.company,
        position: applicant.position,
        name: applicant.name,
        type:
          applicant.type === "seller"
            ? subEvent.sgroupName
            : subEvent.bgroupName,
        dispatch,
        ipAddress: printer.ipAddress,
        printerName: printer.name,
      });
    }
  };

  return (
    <div className={classes.root}>
      <HeadlineTypo>참가신청 조회</HeadlineTypo>
      <TitleTypo fontWeight={500}>휴대폰번호를 입력해주십시오</TitleTypo>
      <Box height={20} />
      <TextField
        className={classes.textField}
        id="text"
        type="text"
        value={number}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PxIconButton onClick={() => setNumber("")}>
                <CloseIcon />
              </PxIconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box height={20} />
      <div className={classes.numpadRoot}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, "", 0, ""].map((num) => (
          <PxButton
            padding="15px 15px"
            className={classes.numBtn}
            disabled={num === "" ? true : false}
            onClick={() => {
              setNumber((prevState) => {
                return prevState!.toString() + num.toString();
              });
            }}
          >
            {num}
          </PxButton>
        ))}
      </div>
      <Box height={20} />
      <PxGridContainer>
        <PxButton
          noBorderRadius
          padding="10px 60px"
          onClick={() => history.goBack()}
        >
          <TitleTypo txtcolor="secondary">취소</TitleTypo>
        </PxButton>
        <PxButton
          backgroundColor="purple"
          noBorderRadius
          padding="10px 60px"
          onClick={onSubmit}
        >
          <TitleTypo>완료</TitleTypo>
        </PxButton>
      </PxGridContainer>
    </div>
  );
};

export default PrinterFindUser;
