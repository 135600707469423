import PrinterSubEventActionTypes from "../types/actions/PrinterAction";
import Alert from "../types/models/Alert";
import { Printer, PrinterSubEvent } from "../types/models/Printer";

interface PrinterReducerType {
  subEvents?: PrinterSubEvent[];
  subEvent?: PrinterSubEvent;
  printers?: Printer[];
  printer?: Printer;
  loading: boolean;
  alert?: Alert;
  onSiteRegit: "seller" | "buyer" | "none";
}

const printerDefaultState: PrinterReducerType = {
  loading: false,
  onSiteRegit: "none",
};

const printerReducer = (
  state = printerDefaultState,
  action: PrinterSubEventActionTypes
): PrinterReducerType => {
  switch (action.type) {
    case "GET_PRINTER_SUB_EVENTS":
      return { ...state, subEvents: action.subEvents };
    case "GET_PRINTER_SUB_EVENT":
      return { ...state, subEvent: action.subEvent };
    case "SET_PRINTER_LOADING":
      return {
        ...state,
        loading: action.loading,
        alert: action.alert,
      };
    case "SET_ONSITE_REGIT":
      return {
        ...state,
        onSiteRegit: action.onSiteRegit,
      };
    case "GET_PRINTERS":
      return {
        ...state,
        printers: action.printers,
      };
    case "GET_PRINTER":
      return {
        ...state,
        printer: action.printer,
      };
    case "RESET_PRINTER":
      return {
        ...state,
        printer: undefined,
      };
    default:
      return state;
  }
};

export default printerReducer;
