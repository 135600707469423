import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// material comp
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import ListItem from "@material-ui/core/ListItem";

// maeterial icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { makeStyles } from "@material-ui/core/styles";
import SharpWarningIcon from "../../../assets/icons/1-sharp-warning.svg";
import SharpDoneIcon from "../../../assets/icons/1-sharp-done.svg";
import SharpDoneGrayIcon from "../../../assets/icons/1-sharp-done-gray.svg";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";

const StyledListItem = styled<any>(ListItem)`
  padding: 0;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  & span {
    color: ${(props) =>
      props.backgroundColor === "white" ? "black" : "white"};
    font-size: 14px;
    font-weight: 500;
  }
  :hover {
    background-color: ${(props) =>
      props.backgroundColor === "white" ? "white" : "#3b2a85"};
  }
`;

interface MakeFormComponentProps {
  title: string;
  onClick: () => void;
}

const useStyles = makeStyles(() => ({
  iconParent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 48,
    height: 48,
    // backgroundColor: "#311b92",
    marginRight: 20,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sharpWarningIcon: {
    width: 24,
    height: 24,
    objectFit: "contain",
  },
  requiredText: {
    paddingLeft: 5,
    fontWeight: "normal",
  },
  icon: {},
}));

interface MakeFormComponentProps {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  required?: boolean;
  done?: boolean;
}

const MakeFormComponent = ({
  title,
  onClick,
  disabled,
  required,
  done,
}: MakeFormComponentProps) => {
  const classes = useStyles();
  const [t] = useTranslation("lang", { useSuspense: false });

  const setIcon = () => {
    if (required && done) {
      return (
        <img src={SharpDoneIcon} className={classes.sharpWarningIcon} alt="" />
      );
    } else if (required) {
      return (
        <img
          src={SharpWarningIcon}
          className={classes.sharpWarningIcon}
          alt=""
        />
      );
    } else if (done) {
      return (
        <img
          src={SharpDoneGrayIcon}
          className={classes.sharpWarningIcon}
          alt=""
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <PxGridContainer direction="column">
      <List>
        <StyledListItem
          button
          backgroundColor={required ? "#512da8" : "white"}
          onClick={onClick}
        >
          <div
            className={classes.iconParent}
            style={{ backgroundColor: required ? "#311b92" : "#fafcfe" }}
          >
            {setIcon()}
          </div>
          <ListItemText
            primary={
              <>
                {title}{" "}
                {required ? (
                  <span
                    className={classes.requiredText}
                    style={{ color: "#f9a825" }}
                  >
                    {t("form.required")}
                  </span>
                ) : (
                  <></>
                )}
              </>
            }
          />
          <div style={{ display: "flex", paddingRight: 16 }}>
            {!done && <ListItemText primary={t("form.noForm")} />}
            <ArrowForwardIcon
              style={{
                width: 18,
                height: 18,
                alignSelf: "center",
                color: required ? "white" : "black",
              }}
            />
          </div>
        </StyledListItem>
      </List>
      {/* <PxGridItem>
        <SubHeadingTypo fontweight="bold">{title}</SubHeadingTypo>
      </PxGridItem>
      <PxGridItem>
        <SubHeadingTypo txtcolor="secondary">
          {disabled ? "업데이트 예정" : t("form.makeAForm")}
        </SubHeadingTypo>
      </PxGridItem>
      <PxGridItem>
        <PxButton
          backgroundcolor="grey"
          disabled={disabled}
          endIcon={<ArrowForwardIcon style={{ fontSize: 12 }} />}
          onClick={onClick}
        >
          <ButtonTypo>{t("form.form")}</ButtonTypo>
        </PxButton>
      </PxGridItem> */}
    </PxGridContainer>
  );
};

export default MakeFormComponent;
