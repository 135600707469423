import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Body1Typo from "../Typography/Body1Typo";
import Body3Typo from "../Typography/Body3Typo";

export default () => {
  const { t } = useTranslation("lang", { useSuspense: false });
  return (
    <Container>
      <NoResultEmoji>:(</NoResultEmoji>
      <Body3Typo txtcolor="black0.6">{t("noResult.noResultsFound")}</Body3Typo>
      <Body1Typo txtcolor="black0.6">{t("noResult.weCan'tFind")} </Body1Typo>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px 0px;
`;

const NoResultEmoji = styled.div`
  font-size: 3.75rem;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 12px;
`;
