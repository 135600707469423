import User from "../types/models/User";
import UserActionTypes from "../types/actions/UserActions";

const userDefaultState: User = {
  name: "",
  email: "",
  company: "",
  countryNumber: "",
  phoneNumber: "",
  createDate:"",
  position:"",
  applicantCount:0,
  createdCount:0,
  isAuthenticated: false,
  users: [],
  hosters: [],
  getUsers:[],
};

const userReducer = (
  state = userDefaultState,
  action: UserActionTypes
): User => {
  switch (action.type) {
    case "CREATE_USER":
      return action.user;
    case "GET_USER":
      return { ...state, ...action.user, isAuthenticated: true };
    case "GET_AUTH_TOKEN":
      return { ...state, isAuthenticated: true };
    case "REMOVE_USER":
      return userDefaultState;
    case "CREATE_USERS":
      return { ...state, users: action.users };
    case "GET_USERS":
      return {...state, getUsers: action.getUsers};
    case "GET_HOSTERS":
      return{...state, hosters: action.hosters};
    case "UPDATE_USERINFO" : 
      return{
        ...state,
        getUsers:state.getUsers?.map(data => {if(data.id === action.user.id){
          data.name = action.user.name;
          data.company = action.user.company;
          data.position = action.user.position;
          data.phoneNumber = action.user.phoneNumber;
          data.countryNumber = action.user.countryNumber;
          return data;
        }else{return data}})
      }
    default:
      return state;
  }
};

export default userReducer;
