import React from "react";
import styled, { css } from "styled-components";

// material ui comp
import Typography, { TypographyProps } from "@material-ui/core/Typography";

interface Body1TypoProps {
  fontSize?: string;
  fontWeight?: string;
  txtcolor?:
    | "rgba(0,0,0,0.87)"
    | "secondary"
    | "purple"
    | "black0.6"
    | "purple700"
    | "black0.38"
    | "white"
    | "red";
  marginLeft?: string;
}

type Prop = Body1TypoProps & TypographyProps;

const Body1Typo: React.FC<Prop> = styled((props: Prop) => {
  const { fontSize, txtcolor, ...rest } = props;
  return <Typography {...rest} />;
})`
  font-size: ${(props) => props.fontSize ?? "13px"};
  font-family: NotoSansCJKkr;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.4px;
  margin-left: ${(props) => props.marginLeft ?? "0px"};
  color: ${(props) => {
    if (props.txtcolor === "purple") {
      return "rgba(95, 75, 139, 1.0)";
    } else if (props.txtcolor === "secondary") {
      return "#6d7278";
    } else if (props.txtcolor === "black0.6") return "rgba(0,0,0,0.6)";
    else if (props.txtcolor === "purple700") {
      return "#512da8";
    } else if (props.txtcolor === "black0.38") {
      return "rgba(0, 0, 0, 0.38)";
    } else if (props.txtcolor === "white") return "#ffffff";
    else if (props.txtcolor === "red") return "#FF0000";

    return props.txtcolor;
  }};
  ${(props) => {
    if (props.fontWeight !== undefined) {
      return css`
        font-weight: ${props.fontWeight};
      `;
    }
  }}
`;

Body1Typo.defaultProps = {
  txtcolor: "rgba(0,0,0,0.87)",
};

export default Body1Typo;
