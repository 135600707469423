import React from "react";
import { Redirect, Route, RouteProps } from "react-router";

export interface PrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  let redirectPath = "/login";

  if (props.isAuthenticated) {
    redirectPath = props.path;
  }

  if (props.isAuthenticated) {
    return <Route {...props} />;
  } else {
    return (
      <Route
        {...props}
        component={() => <Redirect to={{ pathname: redirectPath }} />}
        render={undefined}
      />
    );
  }
};

export default PrivateRoute;
