import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const FullScreen = styled.div`
  position: fixed;
  margin: 0 auto;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.8);
  z-index: 2000;
`;

const PrograssCenter = styled.div`
  position: fixed;
  margin: 0 auto;
  left: calc(50% - 171px);
  top: calc(50% - 45px);
  z-index: 2000;
`;

const ProgressText = styled.div`
  font-weight: bold;
  color: white;
  font-size: 20px;
  margin-top: 15px;
`;

const PxProgress: any = () => {
  const classes = useStyles();

  const [open, setOpen] = useState("false");

  const openAt = useSelector((state: AppState) => state.progresses.open);

  useEffect(() => {
    setOpen(openAt);
  }, [openAt]);

  return (
    open === "true" && (
      <FullScreen>
        <PrograssCenter>
          <div className={classes.root}>
            <CircularProgress />
            <ProgressText>
              작업에 따라 5분이상 소요 될 수 있습니다.
            </ProgressText>
            {/* <CircularProgress color="secondary" /> */}
          </div>
        </PrograssCenter>
      </FullScreen>
    )
  );
};

export default PxProgress;
