import { AppActions } from "../store";
import { OnSiteUser } from "../types/models/Printer";
import { postRequest } from "../utils/utils";

export const createOnSiteUsersAction = (
  onSiteUsers: OnSiteUser[]
): AppActions => ({
  type: "SET_ONSITE_USERS",
  onSiteUsers: onSiteUsers,
});

export const createOnSiteUsers = (onSiteUsers: OnSiteUser[]) => {
  return postRequest(
    onSiteUsers,
    "/api/onSiteRegit/createOnSiteRegits",
    createOnSiteUsersAction
  );
};
