import React from "react";

// material
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import ButtonTypo from "../Typography/ButtonTypo";

interface PxRadioButtonProps {
  value?: string;
  label: string | JSX.Element;
  disabled?: boolean;
}

const PxRadioButton: React.FC<PxRadioButtonProps> = (props) => {
  const { value, label, disabled } = props;
  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={<ButtonTypo>{label}</ButtonTypo>}
      disabled={disabled}
    />
  );
};

export default PxRadioButton;
