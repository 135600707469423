import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column, MTableToolbar } from "material-table";

// actions
import { getMeetingHistories } from "../../../../actions/meetingHistory";

// custom ui comp

import moment from "moment";
import { useTranslation } from "react-i18next";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import PxTable from "../../../../components/Tables/PxTable";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";

interface RowType {
  meetingHistoryId: string;
  meetingHistoryApplicantId: string;
  meetingHistoryAcceptorId: string;
  meetingHistoryApplicantName: string;
  meetingHistoryAcceptorName: string;
  meetingHistoryMeetingId: string;
  meetingHistoryPrevStatus: string;
  meetingHistoryNextStatus: string;
  meetingHistoryStatus: string;
  executionPoint: string;
  meetingHistoryDate: string;
  meetingHistoryStartTime: string;
  meetingHistoryEndTime: string;
  meetingHistoryCreatedDate: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const MeetingHistoryList = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", { useSuspense: false });
  const param: { id: string } = useParams();
  const meetingHistories = useSelector(
    (state: AppState) => state.meetingHistories.meetingHistories
  );
  const [state, setState] = React.useState<TableStateType>({
    columns: [
      // {
      //   title: "제목",
      //   field: "meetingHistoryTitle",
      //   cellStyle: {
      //     width: "calc(100%-600px)",
      //     paddingRight: 0,
      //     maxWidth: "500px",
      //     whiteSpace: "nowrap",
      //     overflow: "hidden",
      //     textOverflow: "ellipsis",
      //   },
      // },
      {
        title: t("meetingHistoryList.applicant"),
        field: "meetingHistoryApplicantName",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("meetingHistoryList.acceptor"),
        field: "meetingHistoryAcceptorName",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("meetingHistoryList.beforeMatch"),
        field: "meetingHistoryPrevStatus",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("meetingHistoryList.afterMatch"),
        field: "meetingHistoryNextStatus",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("meetingHistoryList.status"),
        field: "meetingHistoryStatus",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("meetingHistoryList.matchingWay"),
        field: "executionPoint",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("meetingHistoryList.date"),
        field: "meetingHistoryDate",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("meetingHistoryList.start"),
        field: "meetingHistoryStartTime",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("meetingHistoryList.end"),
        field: "meetingHistoryEndTime",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("meetingHistoryList.registerDate"),
        field: "meetingHistoryCreatedDate",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
    ],
    data: [],
  });

  const callGetMeetingHistories = useCallback(async () => {
    await dispatch(getMeetingHistories(param.id));
    if (!meetingHistories || meetingHistories.content.length <= 0)
      dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  useEffect(() => {
    callGetMeetingHistories();
  }, [callGetMeetingHistories]);

  useEffect(() => {
    let tableData: RowType[] = [];
    if (
      Array.isArray(meetingHistories?.content) &&
      meetingHistories &&
      meetingHistories?.content.length > 0
    ) {
      meetingHistories?.content.map((meetingHistory) => {
        const rowData: RowType = {
          meetingHistoryId: meetingHistory.id,
          meetingHistoryApplicantId: meetingHistory.applicantId,
          meetingHistoryAcceptorId: meetingHistory.acceptorId,
          meetingHistoryApplicantName: meetingHistory.applicantName,
          meetingHistoryAcceptorName: meetingHistory.acceptorName,
          meetingHistoryMeetingId: meetingHistory.meetingId,
          meetingHistoryPrevStatus: meetingHistory.prevStatus,
          meetingHistoryNextStatus: meetingHistory.nextStatus,
          meetingHistoryStatus: meetingHistory.status,
          executionPoint:
            meetingHistory.executionPoint === "autoMatching"
              ? t("meetingHistoryList.auto")
              : t("meetingHistoryList.manual"),
          meetingHistoryDate: meetingHistory.date,
          meetingHistoryStartTime: meetingHistory.startTime,
          meetingHistoryEndTime: meetingHistory.endTime,
          meetingHistoryCreatedDate: moment(meetingHistory.createdDate).format(
            "YYYY-MM-DD"
          ),
        };
        tableData.push(rowData);
      });
      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: tableData };
      });
      dispatch(setTableInfoLoadingAction(false));
    }
  }, [meetingHistories]);

  return (
    <>
      <PxTable<RowType>
        components={{
          Toolbar: (props) => {
            return (
              <PxGridContainer direction="column">
                {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        actionData={param.id}
        getAction={getMeetingHistories}
        totalPage={meetingHistories?.totalPages}
        columns={state.columns}
        data={state.data}
        // onRowClick={(evt, selectedRow) => {
        //   history.push(`${match.url}/${selectedRow?.meetingHistoryId}`);
        // }}
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
    </>
  );
};

export default MeetingHistoryList;
