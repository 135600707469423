import React from "react";
import styled from "styled-components";

// material ui comp
import TextField, { OutlinedTextFieldProps } from "@material-ui/core/TextField";

// Partial <> 안의 모든 props들을 optional로 바꿈
interface OutlinedTxtFieldProps extends Partial<OutlinedTextFieldProps> {
  padding?: string;
  propPadding?: string;
}

const OutlinedTxtField: React.FC<OutlinedTxtFieldProps> = styled(
  (props: OutlinedTxtFieldProps) => {
    const { padding, propPadding, ...rest } = props;
    return (
      <TextField
        variant="outlined"
        inputProps={{
          style: {
            padding: props.propPadding ? props.propPadding : "10.5px 14px",
          },
        }}
        {...rest}
      />
    );
  }
)`
  padding: ${(props) => (props.padding == null ? "0" : props.padding)};
`;

export default OutlinedTxtField;
