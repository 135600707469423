import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getCountry } from "../../actions/country";
import { AppState } from "../../store";

// types
import SubHeadingTypo from "../Typography/SubHeadingTypo";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import { TypographyProps } from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import OutlinedTxtField from "../Inputs/OutlinedTxtField";

import styled from "styled-components";
import PxButton from "../Buttons/PxButton";
import ButtonTypo from "../Typography/ButtonTypo";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const CountryDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const param: { id: string } = useParams();
  const country = useSelector((state: AppState) => state.countries.country);

  useEffect(() => {
    dispatch(getCountry(param.id));
  }, []);

  return country !== undefined && country.id !== undefined ? (
    <Grid container>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px", width: "100%" }}>
        <SubTitle>제목</SubTitle>
        <OutlinedTxtField value={country.code} fullWidth disabled />
        <SubTitle>국가명(국문)</SubTitle>
        <OutlinedTxtField value={country.koName} fullWidth disabled />
        <SubTitle>국가명(영문)</SubTitle>
        <OutlinedTxtField value={country.enName} fullWidth disabled />
        <SubTitle>타임존</SubTitle>
        <OutlinedTxtField value={country.timezone} fullWidth disabled />
        <SubTitle>국가(번역)</SubTitle>
        <OutlinedTxtField value={country.i18code} fullWidth disabled />
        <PxButton
          backgroundColor="purple"
          onClick={() => history.push(`/country/modify/${country.id}`)}
          style={{ margin: "32px 0 8px 0" }}
        >
          <ButtonTypo>국가 수정</ButtonTypo>
        </PxButton>
      </Grid>
    </Grid>
  ) : null;
};

export default CountryDetail;
