import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AppActions, AppState } from "../../store";
import { ThunkDispatch } from "redux-thunk";

// actions
import { createUser, updateUserInfo, updateUserPwd } from "../../actions/users";

// types
import User from "../../types/models/User";

// custom ui comp
import OutlinedTxtField from "../../components/Inputs/PxOutlinedTxtField";
import PxSelectBox from "../../components/SelectBox/PxSelectBox";
import PxOutlinedFormControl from "../../components/Forms/PxOutlinedFormControl";
import PxButton from "../../components/Buttons/PxButton";

// mateiral ui comp
import MenuItem from "@material-ui/core/MenuItem";
import Container from "@material-ui/core/Container";
import { countryPhoneNumberCode } from "../../utils/constants";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { fileStateToInitial, getSignedRequest } from "../../actions/files";

import { setAlertMessage } from "../../actions/alerts";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import ShowFile from "../../components/File/ShowFile";
import Grid from "@material-ui/core/Grid";
import { removeLoading, setLoading } from "../../actions/loading";

const Title = styled.div`
  height: 24px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #5f4b8b;
`;
const InputForm = styled.div`
  width: 960px;
  height: 100%;
  min-height: 56px;
  box-shadow: ${(props: { top?: boolean }) =>
    props.top ? "inset 0 1px 0 0 #bdbdbd" : "inset 0 1px 0 0 #e0e0e0"};
  background-color: #fafafa;

  display: flex;
  flex-direction: row;
`;
const InputTitle = styled.div`
  width: 100%;
  max-width: 246px;
  align-self: center;
  padding-left: 16px;

  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.38);
`;
const InputContent = styled.div`
  width: 100%;
  align-self: center;
  padding-right: 10px;
`;

export default ({ user, setUser }: { user: User; setUser: any }) => {
  const dispatch = useDispatch();

  const [businessCheck, setBusinessCheck] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const files = useSelector((state: AppState) => state.files);

  const handleOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>
  ) => {
    setUser({
      ...user,
      [event.target.name as string]: event.target.value as string,
    });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(setLoading());
    if (!user.company) {
      dispatch(setAlertMessage("회사명을 입력해주세요"));
    } else if (!user.name) {
      dispatch(setAlertMessage("이름을 입력해주세요"));
    } else if (!user.phoneNumber) {
      dispatch(setAlertMessage("전화번호를 입력해주세요"));
    } else if (!user.id || !user.email) {
      dispatch(setAlertMessage("새로고침 후 다시 시도해 주세요"));
    } else {
      const result: any = await dispatch(updateUserInfo(user));
      if (result === true) {
        dispatch(setAlertMessage("변경되었습니다.", "success"));
      } else {
        dispatch(setAlertMessage("다시 시도해 주세요."));
      }
    }
    dispatch(removeLoading());
  };

  // 파일업로드 결과
  useEffect(() => {
    if (files.id !== "") {
      setUser({ ...user, businessImage: files, businessImageId: files.id });
    }

    // 리덕스 초기화
    dispatch(fileStateToInitial());
  }, [files]);

  // 파일 등록
  const handleBusinessImageAdd = async (
    e: React.ChangeEvent<{
      files: any;
      name?: string | undefined;
    }>
  ) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileParts = file.name.split(".");
    const originalFileName = fileParts[0];
    const timestamp = new Date().getTime();
    const fileType = file.type.split("/")[1];
    const fileName = originalFileName + "&&" + timestamp + "." + fileType;
    const folderPath = "auth/";
    const fileSize = file.size;
    const gubun = "mainImage"; // 여러파일등록시 어떤 파일을 등록했는지 구분
    dispatch(
      getSignedRequest(file, fileName, fileType, folderPath, fileSize, gubun)
    );
  };

  const handlePasswordChange = async (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(setLoading());

    if (!user.password) {
      dispatch(setAlertMessage("비밀번호를 입력해주세요"));
    } else if (!user.id) {
      dispatch(setAlertMessage("새로고침 후 다시 시도해 주세요"));
    } else {
      const result: any = await dispatch(
        updateUserPwd({ id: user.id, newPassword: user.password })
      );
      if (result === true) {
        dispatch(setAlertMessage("비밀번호가 변경되었습니다.", "success"));
      } else {
        dispatch(setAlertMessage("다시 시도해 주세요"));
      }
    }
    dispatch(removeLoading());
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Container style={{ width: "100%", maxWidth: "960px", marginBottom: 50 }}>
        <form onSubmit={onSubmit}>
          <Title>기본정보</Title>

          <InputForm top>
            <InputTitle>이메일</InputTitle>
            <InputContent>
              <OutlinedTxtField
                name="email"
                fullWidth
                value={user.email}
                disabled
                InputProps={{
                  style: {
                    backgroundColor: "whtie",
                    fontFamily: "NotoSansCJKkr",
                    fontSize: "14px",
                    fontWeight: "bold",
                    lineHeight: "1.5",
                    height: "36px",
                  },
                }}
              />
            </InputContent>
          </InputForm>

          <InputForm>
            <InputTitle>회사명</InputTitle>
            <InputContent>
              <OutlinedTxtField
                name="company"
                fullWidth
                onChange={handleOnChange}
                value={user.company}
                style={{ backgroundColor: "white" }}
                InputProps={{
                  style: {
                    fontFamily: "NotoSansCJKkr",
                    fontSize: "14px",
                    fontWeight: "bold",
                    lineHeight: "1.5",
                    height: "36px",
                  },
                }}
              />
            </InputContent>
          </InputForm>

          <InputForm>
            <InputTitle>이름</InputTitle>
            <InputContent>
              <OutlinedTxtField
                name="name"
                fullWidth
                onChange={handleOnChange}
                value={user.name}
                style={{ backgroundColor: "white" }}
                InputProps={{
                  style: {
                    fontFamily: "NotoSansCJKkr",
                    fontSize: "14px",
                    fontWeight: "bold",
                    lineHeight: "1.5",
                    height: "36px",
                  },
                }}
              />
            </InputContent>
          </InputForm>

          <InputForm>
            <InputTitle>비밀번호</InputTitle>
            <InputContent
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <OutlinedInput
                id="outlined-adornment-password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={user.password}
                style={{
                  width: "79%",
                  justifyContent: "center",
                  backgroundColor: "white",
                }}
                onChange={handleOnChange}
                inputProps={{
                  style: {
                    fontFamily: "NotoSansCJKkr",
                    fontSize: "14px",
                    fontWeight: "bold",
                    height: "36px",
                    padding: "0 10px",
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                      style={{ padding: 0, margin: 0 }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Button
                variant="contained"
                component="label"
                style={{
                  width: "19%",
                  boxShadow: "none",
                  backgroundColor: "#eee",
                  padding: "9px 16px",
                }}
                onClick={handlePasswordChange}
              >
                비밀번호 변경
              </Button>
            </InputContent>
          </InputForm>

          <InputForm>
            <InputTitle>전화번호</InputTitle>
            <InputContent
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <OutlinedTxtField
                style={{
                  justifyContent: "center",
                  width: "49%",
                  backgroundColor: "white",
                }}
                name="phoneNumber"
                fullWidth
                value={user.phoneNumber}
                onChange={handleOnChange}
                InputProps={{
                  style: {
                    fontFamily: "NotoSansCJKkr",
                    fontSize: "14px",
                    fontWeight: "bold",
                    lineHeight: "1.5",
                    height: "36px",
                  },
                }}
              />
              <InputContent style={{ width: "49%" }}>
                <PxOutlinedFormControl fullWidth>
                  <PxSelectBox
                    style={{
                      justifyContent: "center",
                      backgroundColor: "white",
                    }}
                    value={user.countryNumber}
                    onChange={handleOnChange}
                    name="countryNumber"
                    displayEmpty
                    defaultValue={user.countryNumber}
                    input={<OutlinedInput margin="dense" />}
                  >
                    <MenuItem value="" disabled>
                      국가번호
                    </MenuItem>
                    {countryPhoneNumberCode.map((countryCode) => (
                      <MenuItem value={countryCode.code}>
                        {countryCode.country}
                      </MenuItem>
                    ))}
                  </PxSelectBox>
                </PxOutlinedFormControl>
              </InputContent>
            </InputContent>
          </InputForm>

          <Title style={{ marginTop: "32px" }}>추가정보</Title>
          {businessCheck && (
            <>
              <InputForm top>
                <InputTitle>세금계산서 이메일</InputTitle>
                <InputContent
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <OutlinedTxtField
                    name="taxInvoiceMail"
                    fullWidth
                    onChange={handleOnChange}
                    disabled={true}
                    value={user.taxInvoiceMail}
                    style={{
                      justifyContent: "center",
                      width: "49%",
                    }}
                    InputProps={{
                      style: {
                        fontFamily: "NotoSansCJKkr",
                        fontSize: "14px",
                        fontWeight: "bold",
                        lineHeight: "1.5",
                        height: "36px",
                      },
                    }}
                  />
                </InputContent>
              </InputForm>

              <InputForm>
                <InputTitle>사업자등록증</InputTitle>
                <InputContent>
                  <InputContent style={{ width: "60%" }}>
                    {user.businessImageId &&
                    parseInt(user.businessImageId) > 0 ? (
                      <Grid style={{ marginTop: 10 }}>
                        <ShowFile
                          fileId={parseInt(user.businessImageId)}
                          marginBottom={0}
                        />
                        <Button
                          variant="contained"
                          component="label"
                          fullWidth
                          style={{
                            boxShadow: "none",
                            backgroundColor: "#eee",
                            padding: "4.5px 8px",
                            margin: "5px 0px",
                          }}
                        >
                          교체하기
                          <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleBusinessImageAdd}
                          />
                        </Button>
                      </Grid>
                    ) : (
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          boxShadow: "none",
                          backgroundColor: "#eee",
                          padding: "9px 16px",
                        }}
                      >
                        추가하기
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleBusinessImageAdd}
                        />
                      </Button>
                    )}
                  </InputContent>
                </InputContent>
              </InputForm>
            </>
          )}
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <PxButton
              className="my-4"
              style={{ padding: "12px 48px" }}
              backgroundColor="bluePurple"
              txtColor="white"
              type="submit"
            >
              <ButtonTypo txtcolor="white">변경하기</ButtonTypo>
            </PxButton>
          </div>
        </form>
      </Container>
    </>
  );
};

interface DispatchProps {
  createUser: (user: User) => void;
}
