// types
import { Form } from "../types/models/Form";
import FormActionTypes from "../types/actions/FormAction";

const formDefaultState: Form = {
  id: undefined,
  type: undefined,
  subEventId: undefined,
  formData: { formData: {}, formIds: [] },
  additionalFormData: { formData: {}, formIds: [] },
  content: "",
  createdDate: undefined,
  modifiedDate: undefined,
};

const formReducer = (
  state = formDefaultState,
  action: FormActionTypes
): Form => {
  switch (action.type) {
    case "GET_FORMS":
    case "SET_FORMS":
    case "SET_ADD_FORMS":
      return { ...state, ...action.forms };
    case "GET_FORM":
      return { ...state, ...action.form };
    case "REMOVE_FORMS":
      return {
        ...formDefaultState,
        additionalFormData: { formData: {}, formIds: [] },
      };
    default:
      return state;
  }
};

export default formReducer;
