import React from "react";
import BaseTemplate, { EventContent, LinkButton, MailContent } from "./BaseTemplate";
import styled from "styled-components";

interface MeetingMailProp {
  language: "kr" | "en";
  userName: string;
  title: string;
  mailContent: string;
  eventName: string;
  subEventName: string;
  eventStart: string;
  eventEnd: string;
  applicant: string;
  acceptor: string;
  meetingStart: string;
  meetingEnd: string;
  link: string;
  googleCalenderUrl: string;
}

const GoogleAdd = styled.a`
  display: block;
  margin-top: 20px;
  background-color: #5f6368;
  padding: 13px;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
`;

export default ({
  language,
  userName,
  title,
  mailContent,
  eventName,
  subEventName,
  eventStart,
  eventEnd,
  applicant,
  acceptor,
  meetingStart,
  meetingEnd,
  link,
  googleCalenderUrl,
}: MeetingMailProp) => {
  return (
    <BaseTemplate title={title} language={language}>
      {language === "en" ? (
        <>
          <MailContent>
            {" "}
            Hello, {userName}
            <br />
            {mailContent}
          </MailContent>
          <EventContent>
            Event Name:&nbsp; {eventName}&nbsp;({subEventName})<br />
            Period:&nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
            <br />
            <br />
            Applicant:&nbsp;{applicant}
            <br />
            Accepotr:&nbsp;{acceptor}
            <br />
            Meeting time:&nbsp;{meetingStart}(GMT+9) ~&nbsp;{meetingEnd}(GMT+9)
            <br />
          </EventContent>
          <LinkButton target="_blank" href={link}>
            Go to event{" "}
          </LinkButton>
          <GoogleAdd target="_blank" href={googleCalenderUrl}>
            Add to google calender
          </GoogleAdd>
        </>
      ) : (
        <>
          <MailContent>
            {" "}
            {userName}님 안녕하세요 {userName}
          </MailContent>
          <EventContent>
            이벤트 명:&nbsp; {eventName}&nbsp;({subEventName})<br />
            이벤트 기간:
            <br />
            &nbsp;{eventStart}(GMT+9) ~&nbsp;{eventEnd}(GMT+9)
            <br />
            요청자:&nbsp;{applicant}
            <br />
            대상자:&nbsp;{acceptor}
            <br />
            미팅일시:&nbsp;{meetingStart}(GMT+9) ~&nbsp;{meetingEnd}(GMT+9)
            <br />
          </EventContent>
          <LinkButton target="_blank" href={link}>
            이벤트 바로가기
          </LinkButton>
          <GoogleAdd target="_blank" href={googleCalenderUrl}>
            구글캘린더 일정 추가
          </GoogleAdd>
        </>
      )}
    </BaseTemplate>
  );
};
