import OnSiteUserActionTypes from "../types/actions/OnSiteUserAction";
import { OnSiteUser } from "../types/models/Printer";

interface OnSiteUserReduxType {
  onSiteUsers?: OnSiteUser[];
}

const onSiteUserDefaultState: OnSiteUserReduxType = {
  onSiteUsers: [],
};

const onSiteUserReducer = (
  state = onSiteUserDefaultState,
  action: OnSiteUserActionTypes
) => {
  switch (action.type) {
    case "SET_ONSITE_USERS":
      return { ...state, onSiteUsers: action.onSiteUsers };
    default:
      return state;
  }
};

export default onSiteUserReducer;
