import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../store";
import { v4 } from "uuid";
import { useHistory, useParams } from "react-router-dom";

// types
import { FormData } from "../../../types/models/AutonoForm";

// component
import AutonomousForm from "../autonomous/AutonomousForm";
import PxAutonoForm from "../autonomous/PxAutonomousForm";
// material comp
import PxButton from "../../../components/Buttons/PxButton";

// material icons
import AddIcon from "@material-ui/icons/Add";
import { Form } from "../../../types/models/Form";
import PxGridContainer from "../../../components/Grid/PxGridContainer";

import { setAddFormsAction, createForm } from "../../../actions/forms";
import SubHeadingTypo from "../../../components/Typography/SubHeadingTypo";
import ButtonTypo from "../../../components/Typography/ButtonTypo";

const AddtionalForm = () => {
  const dispatch = useDispatch();
  const { additionalFormData, formData, content } = useSelector(
    (state: AppState) => state.forms
  );
  const params: { id: string } = useParams(); // id: subEventId
  const [initData, setInitialData] = React.useState<FormData>({
    formData: {},
    formIds: [],
  });
  const history = useHistory();
  const forms = useSelector((state: AppState) => state.forms);

  useEffect(() => {
    if (additionalFormData !== undefined) {
      setInitialData(additionalFormData);
    }
  }, [additionalFormData]);

  // 양식추가하기 버튼 onClick function
  const formAddButtonOnClick = () => {
    // 기본 자율양식 form 데이터
    const newFormId = `form-${v4()}`;
    const newForm = {
      [newFormId]: {
        id: newFormId,
        index: 0,
        type: "single",
        title: "",
        explain: "",
        secretCheck: "N",
        explainCheck: "N",
        requireCheck: "N",
        selectContent: {
          formData: {
            [newFormId]: {
              id: newFormId,
              explain: "",
              index: 0,
            },
          },
          formIds: [newFormId],
        },
      },
    };
    setInitialData((prevState) => {
      const formData = Object.assign(prevState!.formData, newForm);
      const formIds = prevState!.formIds;
      formIds.push(newFormId);
      return { ...prevState, formData, formIds };
    });
  };

  // 양식 복사 onClick fucntion
  const formCopyButtonOnClick = (formId: string, index: number) => {
    const newFormId = `form-${v4()}`;
    // 복사할 폼과 같은 폼 생성
    const newForm = {
      [newFormId]: {
        ...initData!.formData[formId],
        id: newFormId,
        selectContent: {
          formData: {},
          formIds: [] as string[],
        },
      },
    };

    // 단일 및 복수 선택지 내용을 복사하기 위한 변수
    const selectContent: FormData = {
      formData: {},
      formIds: [],
    };
    // 전체 form에 selectForm의 key값과 id 값만 변경해서 넣어줌
    for (let [, value] of Object.entries(
      initData!.formData[formId].selectContent!.formData
    )) {
      const selectFormId = `form-${v4()}`;
      Object.assign(selectContent.formData, {
        [selectFormId]: {
          id: selectFormId,
          explain: value.explain,
          index: value.index,
        },
      });
    }

    // select Form의 index값에 따라 정렬 후 formIds 값에 넣어줌
    const selectFormOrderByIndex = Object.values(selectContent.formData).sort(
      (a, b) => a.index! - b.index!
    );
    const formIds = selectFormOrderByIndex.map((value) => {
      return value.id;
    });
    selectContent.formIds = formIds;

    // select content object값 갱신
    Object.assign(newForm[newFormId].selectContent, selectContent);

    // 생성된 폼을 formData에 추가하고 formIds 배열에 기존폼 index 옆에 추가
    setInitialData((prevState) => {
      const formData = Object.assign(initData!.formData, newForm);
      const formIds = prevState!.formIds;
      formIds.splice(index + 1, 0, newFormId);
      return { ...prevState, formData, formIds };
    });
  };

  // 양식 삭제 onClick function
  const formDeleteButtonOnClick = (selectId: string) => {
    // const newData = initData.formData.filter((value) => value.id !== selectId);
    delete initData!.formData[selectId]; // delete object property
    setInitialData((prevState) => {
      // delete formId in formIds array
      const formIds = prevState!.formIds.filter((value) => value !== selectId);
      return { ...prevState, formIds };
    });
  };

  // 복수/단수 선택 양식 추가 onClick function
  const selectContentFormAdd = (formId: string) => {
    const newFormId = `form-${v4()}`;
    const newSelectForm = {
      [newFormId]: {
        id: newFormId,
        explain: "",
        index: initData!.formData[formId].selectContent?.formIds.length,
      },
    };

    //@ts-ignore
    setInitialData((prevState) => {
      const formData = prevState!.formData;
      Object.assign(formData[formId].selectContent?.formData, newSelectForm);
      formData[formId].selectContent?.formIds.push(newFormId);
      return { ...prevState, formData };
    });
  };
  // 복수/단수 선택 폼 삭제 메소드
  const selectContentFormDelete = (formId: string, selectFormId: string) => {
    //@ts-ignore
    setInitialData((prevState) => {
      const formData = prevState!.formData;
      delete formData[formId].selectContent?.formData[selectFormId];
      const formIds = formData[formId].selectContent?.formIds.filter(
        (value) => value !== selectFormId
      );
      formData[formId].selectContent!.formIds = formIds!;
      return { ...prevState, formData };
    });
  };

  const backButtonOnClick = () => {
    const form: Form = {
      content: content,
      additionalFormData: initData,
    };

    dispatch(setAddFormsAction(form));
    history.goBack();
  };

  // 미리보기 버튼 onclick function, redux에 forms data 저장 후 미리보기 화면이동
  const previewOnClick = () => {
    const form: Form = {
      additionalFormData: initData,
    };

    dispatch(setAddFormsAction(form));
    history.push("/form/add/preview");
  };

  // 완료 버튼 onClick function
  const onSubmit = async () => {
    const form: Form = {
      id: forms.id,
      type: "",
      subEventId: params.id,
      content: JSON.stringify(formData),
      additional: JSON.stringify(initData),
    };

    if (history.location.pathname.includes("addBuyerPartiForm")) {
      form["type"] = "BYP";
    } else if (history.location.pathname.includes("addSellerPartiForm")) {
      form["type"] = "SP";
    }

    // console.log(formData, "form");

    const result: any = await dispatch(createForm(form));
    if (result === true) {
      history.push(`/form/subEvent/${params.id}/match`);
    }
  };

  return (
    <div>
      <SubHeadingTypo fontweight="bold">추가정보</SubHeadingTypo>
      <SubHeadingTypo txtcolor="secondary">
        상품명, 가격, 최소 구매수량과 같은 반복정보 양식추가
      </SubHeadingTypo>
      {initData !== undefined && (
        <AutonomousForm
          colunmId="columId-1"
          initialData={initData}
          setInitialData={setInitialData}
          draggableComp={
            <PxAutonoForm
              id=""
              index={0}
              elevation={0}
              setSelected={() => {}}
              draghandle={undefined}
              formCopy={formCopyButtonOnClick}
              formDelete={formDeleteButtonOnClick}
              initialData={initData!}
              setInitialData={setInitialData}
              selectContentFormAdd={selectContentFormAdd}
              selectContentFormDelete={selectContentFormDelete}
            />
          }
        />
      )}
      <PxButton
        fullWidth
        backgroundColor="grey"
        startIcon={<AddIcon />}
        className="mt-3"
        onClick={formAddButtonOnClick}
      >
        <ButtonTypo>양식 추가하기</ButtonTypo>
      </PxButton>
      <PxGridContainer justify="space-between" style={{ marginTop: 100 }}>
        <PxButton padding="10px 60px 10px 60px" onClick={backButtonOnClick}>
          <ButtonTypo>이전</ButtonTypo>
        </PxButton>
        {/* 완성된 양식 미리보기 버튼 */}
        <PxButton
          backgroundColor="grey"
          onClick={previewOnClick}
          padding="10px 50px 10px 50px"
        >
          <ButtonTypo>미리보기</ButtonTypo>
        </PxButton>

        <PxButton
          backgroundColor="purple"
          padding="10px 60px 10px 60px"
          onClick={onSubmit}
        >
          <ButtonTypo>완료</ButtonTypo>
        </PxButton>
      </PxGridContainer>
    </div>
  );
};

export default AddtionalForm;
