import React from "react";

// types
import { DraggableItemComp } from "../../../types/models/AutonoForm";

// material comp
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../components/Grid/PxGridItem";
import PxOutlinedTxtField from "../../../components/Inputs/PxOutlinedTxtField";
import PxIconButton from "../../../components/Buttons/PxIconButton";

// material icons
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

// 단수 or 복수 자율양식 선택 시 콘텐츠폼
const PxSelectFormContent: React.FC<Partial<DraggableItemComp>> = (props) => {
  const {
    draghandle,
    id,
    formId,
    initialData,
    selectContentFormDelete,
    setInitialData,
  } = props;
  // id : select form drag&drop item id
  // formId: parent drag&drop item id
  // selectContentFormDelete: select content form 삭제 메소드
  // setInitialData: parent form data setState 메소드
  // initialData: select form data

  // 단일 및 복수 선택지의 explain 값을 변경하면 전체 form data에서 해당하는 formData 값의 selectContent formData의 explain 값을 변경
  const handleOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>
  ) => {
    const value = event.target.value as string;
    setInitialData !== undefined &&
      setInitialData((prevState) => {
        const formData = prevState.formData;
        formData[formId!].selectContent!.formData[id!].explain! = value;
        return { ...prevState, formData };
      });
  };

  return (
    <PxGridContainer className="pt-2" direction="row" alignItems="center">
      <PxGridItem className="pr-3" {...draghandle}>
        <MenuIcon fontSize="small" />
      </PxGridItem>
      <PxGridItem md={10} xs={9}>
        <PxOutlinedTxtField
          fullWidth
          name="explain"
          value={
            initialData === undefined ? "" : initialData.formData[id!].explain
          }
          inputpadding={8}
          placeholder="선택할 각 항목을 입력해주세요."
          fontsize={14}
          onChange={handleOnChange}
        />
      </PxGridItem>
      <PxIconButton
        onClick={() => {
          selectContentFormDelete !== undefined &&
            selectContentFormDelete(formId!, id!);
        }}
      >
        <CloseIcon fontSize="small" />
      </PxIconButton>
    </PxGridContainer>
  );
};

export default PxSelectFormContent;
