import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";

import styled from "styled-components";

const CusComboboxInput = styled(ComboboxInput)`
  width: 100%;
  border: 1px solid #ccc;
  padding: 10.5px 14px;
  border-radius: 3px;
`;
const CusComboboxOption = styled(ComboboxOption)`
  background-color: white;
  padding: 10px;
`;

interface Props {
  setPlace: any;
}
const GooglePlaces: React.FC<Props> = (props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete();

  const handleInput = (e: any) => {
    setValue(e.target.value);
  };

  const handleSelect = (val: any) => {
    setValue(val, false);

    getGeocode({ address: val })
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        const { lat, lng } = latLng;
        props.setPlace(val as string, lat as number, lng as number);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  return (
    <Combobox onSelect={handleSelect} aria-labelledby="demo">
      <CusComboboxInput
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="주소를 입력해주세요"
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ id, description }) => (
              <CusComboboxOption key={id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};

export default GooglePlaces;
// https://github.com/wellyshen/use-places-autocomplete
