import Dialog from "../types/models/Dialog";
import DialogActionTypes from "../types/actions/DialogAction";

const dialogDefaultState: Dialog = {
  materialDialog: {
    open: "false",
    title: "",
    content: undefined,
  },
  pxDialog: {
    open: false,
    width: "",
    isBackgroundColor: true,
    children: "",
    footerJustifyContent: "flex-end",
    footer: "",
  },
};

const dialogReducer = (
  state = dialogDefaultState,
  action: DialogActionTypes
): Dialog => {
  switch (action.type) {
    case "SET_DIALOG":
      return { ...state, materialDialog: action.dialog };
    case "SET_PXDIALOG":
      return { ...state, pxDialog: action.dialog };
    default:
      return state;
  }
};

export default dialogReducer;
