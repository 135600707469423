import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, RouteComponentProps } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column, MTableToolbar } from "material-table";

// custom ui comp
import PxTable from "../../../../components/Tables/PxTable";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typography/ButtonTypo";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";

import moment from "moment";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import { getSmss } from "../../../../actions/smss";
import { SmsListItem } from "../../../../types/models/Sms";
import { useTranslation } from "react-i18next";

interface TableStateType {
  columns: Array<Column<SmsListItem>>;
  data: SmsListItem[];
}

const SmsList: React.FC<RouteComponentProps> = ({ match }) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string } = useParams();
  const smss = useSelector((state: AppState) => state.smss.smss);

  // const callSmsList = useCallback(async () => {
  //   await dispatch(getSmss(param.id));
  //   if (!smss || smss.content.length <= 0)
  //     dispatch(setTableInfoLoadingAction(false));
  // }, [dispatch, param.id]);

  // useEffect(() => {
  //   callSmsList();
  // }, [callSmsList]);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("smsList.recipient"),
        field: "toName",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("smsList.corporateName"),
        field: "toCompany",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("smsList.phoneNo"),
        field: "toSms",
        cellStyle: {
          paddingRight: 0,
          maxWidth: "calc(100% - 500px)",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("common.content"),
        field: "content",
        cellStyle: {
          paddingRight: 0,
          maxWidth: "300px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("smsList.sendDate"),
        field: "createdDate",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
    ],
    data: [],
  });

  useEffect(() => {
    if (smss !== undefined && smss.content.length > 0) {
      const smsList: SmsListItem[] = smss.content.map((sms) => ({
        id: sms.id,
        toName: sms.toName,
        toCompany: sms.toCompany,
        content: sms.content,
        toSms: sms.toSms,
        status: sms.status,
        createdDate: moment(sms.createdDate).format("YYYY-MM-DD"),
      }));

      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: smsList };
      });
      dispatch(setTableInfoLoadingAction(false));
    }
  }, [smss]);

  return (
    <>
      <div style={{ textAlign: "right", margin: "10px" }}>
        <PxButton
          backgroundColor="purple"
          onClick={() => history.push(`${match.url}/add`)}
        >
          <ButtonTypo>{t("smsList.send")}</ButtonTypo>
        </PxButton>
      </div>
      <PxTable<SmsListItem>
        components={{
          Toolbar: (props) => {
            return (
              <PxGridContainer direction="column">
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        onRowClick={(evt, selectedRow) => {
          history.push(`${match.url}/${selectedRow?.id}`);
        }}
        title=""
        columns={state.columns}
        data={state.data}
        actionData={param.id}
        getAction={getSmss}
        totalPage={smss?.totalPages}
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
    </>
  );
};

export default SmsList;
