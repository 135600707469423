import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import PxTable from "../../components/Tables/PxTable";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "material-table";
import { getPrinterSubEventsByDate } from "../../actions/printers";
import { AppState } from "../../store";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
import PxGridItem from "../../components/Grid/PxGridItem";
import CaptionTypo from "../../components/Typography/CaptionTypo";
import { useHistory } from "react-router";

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

interface RowType {
  subEventId: string;
  date: string;
  name: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flexGrow: 1,
      padding: "15px 40px",
      backgroundColor: "#f5f5f5",
      border: "1px solid",
      borderColor: "#bdbdbd",
      marginBottom: 15,
    },
  })
);

const EventListByDate = () => {
  const classes = useStyles();
  const history = useHistory();
  const nowDate = moment().format("YYYY-MM-DD");
  const dispatch = useDispatch();

  const subEvents = useSelector((state: AppState) => state.printers.subEvents);

  const [tableState, setTableState] = useState<TableStateType>();

  useEffect(() => {
    dispatch(getPrinterSubEventsByDate(nowDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = async (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    dispatch(getPrinterSubEventsByDate(e.target.value as string));
  };

  useEffect(() => {
    if (subEvents) {
      const tableData: RowType[] = subEvents.map((subEvent) => ({
        subEventId: subEvent.id,
        name: subEvent.mainEvent.name,
        date: `${moment(subEvent.subEventStartDate).format(
          "YYYY.MM.DD"
        )} - ${moment(subEvent.subEventEndDate).format("YYYY.MM.DD")}`,
      }));

      setTableState({
        columns: [
          {
            field: "name",
            render: (rowData) => (
              <PxGridContainer direction="column">
                <SubHeadingTypo fontweight="bold">
                  {rowData.name}
                </SubHeadingTypo>
                <PxGridContainer direction="row" spacing={1}>
                  <PxGridItem>
                    <CaptionTypo txtcolor="grey">{rowData.date}</CaptionTypo>
                  </PxGridItem>
                </PxGridContainer>
              </PxGridContainer>
            ),
          },
        ],
        data: tableData,
      });
    }
  }, [subEvents]);

  return (
    <>
      <form className={classes.container} noValidate>
        <TextField
          id="date"
          type="date"
          defaultValue={nowDate}
          variant="outlined"
          fullWidth
          onChange={handleDateChange}
        />
      </form>
      <PxTable<RowType>
        columns={tableState?.columns ?? []}
        data={tableState?.data ?? []}
        onRowClick={(e, rowData) => {
          history.push(`/printer/${rowData?.subEventId}/test`);
        }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          header: false,
          defaultExpanded: false,
          search: false,
          toolbar: false,
          rowStyle: {
            borderBottom: "none",
          },
        }}
      />
    </>
  );
};

export default EventListByDate;
