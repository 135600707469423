import React from "react";
import Banner from "./Banner";
import DoneIcon from "@material-ui/icons/Done";
import { SubEventInfo } from "../../../../types/models/SubEvent";
import Body1Typo from "../../../../components/Typography/Body1Typo";
import moment from "moment";
export default ({ subEvent }: { subEvent: SubEventInfo }) => {
  return (
    <Banner
      bgColor="#34c759"
      IconNode={<DoneIcon style={{ width: 40, height: 40 }} />}
      estimateLink={`/estimate/subEvent/${subEvent.id}/consulting`}
      actionBtnTypo="최종견적"
      quoationLink={`/estimate/subEvent/${subEvent.id}/consultingQuoation`}
      subEvent={subEvent}
      title={
        <>
          상담회 <span style={{ color: "#512da8" }}>유료 플랜</span> 이용중 &nbsp;
          <span>
            <Body1Typo display="inline" txtcolor="black0.6" fontSize="15px">{`(신청일:${moment(
              subEvent.paymentApplicationDate
            ).format("yyyy-MM-DD HH:mm")} / 전환일:${moment(subEvent.paymentApprovedDate).format(
              "yyyy-MM-DD HH:mm"
            )})`}</Body1Typo>
          </span>
        </>
      }
      body={
        subEvent.mainEvent &&
        subEvent.mainEvent.managers && (
          <table style={{ marginTop: 18 }}>
            <tr>
              <td>담당자</td>
              <td>{subEvent.mainEvent.managers[0].name}</td>{" "}
            </tr>
            <tr>
              <td style={{ paddingRight: 17 }}>전화번호</td>
              <td>{`(${subEvent.mainEvent.managers[0].countryNumber}) ${subEvent.mainEvent.managers[0].phone}`}</td>{" "}
            </tr>
            <tr>
              <td>이메일</td>
              <td>{subEvent.mainEvent.managers[0].email}</td>{" "}
            </tr>
          </table>
        )
      }
    />
  );
};
