import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, RouteComponentProps } from "react-router-dom";
import { AppState } from "../../../../store";
import { Column, MTableToolbar } from "material-table";

// actions
import {
  getApplications,
  changeApplicationState,
  updateApplicationsZoomLink,
  changeApplicationNote,
} from "../../../../actions/application";
import { setProgressAction } from "../../../../actions/progresses";

// custom ui comp

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import moment from "moment";
import Button from "@material-ui/core/Button";
import { confirmModalOptions } from "../../../../utils/confirmModalOptions";
import { confirmAlert } from "react-confirm-alert";

import { MenuItem } from "@material-ui/core";
import { categoryAndCheckboxSelectTextToLinkAndText } from "../../../../utils/utils";

import { makeStyles } from "@material-ui/core/styles";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  removeLoadingAction,
  setLoadingAction,
} from "../../../../actions/loading";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import { setAlertAction } from "../../../../actions/alerts";

import Box from "@material-ui/core/Box";
import AddApplicationDialog from "./AddApplicationDialog";
import PxTable from "../../../../components/Tables/PxTable";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import PxOutlinedFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import PxOutlinedTxtField from "../../../../components/Inputs/PxOutlinedTxtField";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typography/ButtonTypo";
import { setPxDialogAction } from "../../../../actions/dialogs";
import WarningRemove from "../../../../components/Dialog/WaringRemove";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    minWidth: 320,
  },
  tableTitle: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
  },
  gubun: { fontSize: 14, paddingRight: 10 },
  outlinedFormControl: { minWidth: 120 },
  stateChangeBtnText: { fontSize: "14px" },
  addAplcBtn: {
    float: "right",
    marginTop: 10,
  },
}));

interface RowType {
  applicationApplicantId: string;
  applicationApproval: string;
  applicationCategory: string;
  applicationId: string;
  applicationKeyword: string;
  applicationMethod: string;
  applicationName: string;
  applicationType: string;
  applicationCreatedDate: string;
  type: "seller" | "buyer";
  zoomLink: boolean;
  note?: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const PurpleSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "rgb(81, 45, 168)",
    },
    "&$checked + $track": {
      backgroundColor: "rgb(81, 45, 168)",
    },
  },
  checked: {},
  track: {},
})(Switch);

const ApplicationList: React.FC<RouteComponentProps> = ({ match }) => {
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const param: { id: string } = useParams();
  const applications = useSelector(
    (state: AppState) => state.applications.applications
  );
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [type, setType] = useState("");
  const [getActionParam, setGetActionParam] = useState({
    subEventId: param.id,
    type,
  });
  const [columnDefDone, setColumnDefDone] = useState<boolean>(false);
  const [noteText, setnoteText] = useState<{
    applicationId: string;
    text: string;
  }>();
  const [addAplcDialogOpen, setAddAplcDialogOpen] = useState<boolean>(false);

  const approvalChangeActionsList: any = {
    cancel: {
      title: t(""),
      prevState: ["approval", "reject"],
    },
    approval: {
      title: t("common.approval"),
      prevState: ["waiting"],
    },
    reject: {
      title: t("common.reject"),
      prevState: ["waiting"],
    },
    delete: {
      title: t("common.delete"),
      prevState: ["waiting", "waiting", "reject", "approval"],
    },
  };

  useEffect(() => {
    const callGetApplications = async () => {
      setGetActionParam({ subEventId: param.id, type });
      await dispatch(getApplications({ subEventId: param.id, type }));
      dispatch(setTableInfoLoadingAction(false));

      if (!applications || applications.content.length <= 0)
        dispatch(setTableInfoLoadingAction(false));
    };

    if (!addAplcDialogOpen) {
      callGetApplications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAplcDialogOpen]);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("applicationList.group"),
        field: "applicationType",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
          maxWidth: 140,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("applicationList.corporateName"),
        field: "applicationName",
        cellStyle: {
          paddingRight: 0,
          width: "cal(100%-800px)",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("applicationList.manager"),
        field: "applicationManager",
        cellStyle: {
          paddingRight: 0,
          width: "100px",
          maxWidth: "100px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("applicationList.registerDate"),
        field: "applicationCreatedDate",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("applicationList.status"),
        field: "applicationApproval",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: "Zoom",
        field: "zoomLink",
        cellStyle: { width: "50px", paddingRight: 0 },
        render: (rowData) => {
          if (rowData.type === "buyer") {
            return (
              <FormControlLabel
                control={
                  <PurpleSwitch
                    checked={rowData.zoomLink}
                    onClick={async (e) => {
                      e.stopPropagation();
                      dispatch(setLoadingAction());
                      await dispatch(
                        updateApplicationsZoomLink([rowData.applicationId])
                      );
                      dispatch(removeLoadingAction());
                    }}
                    name="zoomSwitch"
                  />
                }
                label=""
                style={{ margin: 0 }}
              />
            );
          } else {
            return <></>;
          }
        },
      },
      {
        title: t("applicationList.note"),
        field: "note",
        cellStyle: {
          width: "100px",
          textAlign: "inherit",
          paddingRight: 0,
        },
        render: (rowData) => (
          <PxOutlinedTxtField
            id={rowData.applicationId}
            inputpadding={5}
            defaultValue={rowData.note ?? ""}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
            onBlur={() => {
              // note text update api call
            }}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                const inputElem: any = document.getElementById(
                  rowData.applicationId
                );
                if (inputElem) {
                  setnoteText({
                    applicationId: rowData.applicationId,
                    text: inputElem.value,
                  });
                }
              }
            }}
          />
        ),
      },
      {
        title: "",
        field: "",
        cellStyle: {
          width: "250px",
          textAlign: "inherit",
          paddingRight: 0,
        },
        render: (rowData) =>
          ["approval", "reject", "cancel", "delete"].map((action) =>
            statusChangeButtonForm(rowData.applicationApproval, rowData, action)
          ),
      },
    ],
    data: [],
  });

  // 참가자 노트 작성 시
  useEffect(() => {
    if (noteText) {
      const newData = [...state.data];

      const selectedDataIndex = newData.findIndex(
        (row) => row.applicationId === noteText.applicationId
      );

      if (selectedDataIndex !== -1) {
        newData[selectedDataIndex].note = noteText.text;
      }

      const updateApplicationNote = async () => {
        const result: any = await dispatch(
          changeApplicationNote({
            id: newData[selectedDataIndex].applicationId,
            note: noteText.text,
          })
        );
        if (result) {
          setState((prevState) => ({
            ...prevState,
            data: newData,
          }));

          // 다음 노트 input 있을 시 focus 이동
          if (newData[selectedDataIndex + 1]) {
            const nextInputElem: any = document.getElementById(
              newData[selectedDataIndex + 1].applicationId
            );
            if (nextInputElem) {
              nextInputElem.focus();
            }
          }

          dispatch(
            setAlertAction({
              id: newData[selectedDataIndex].applicationName,
              msg: `${newData[selectedDataIndex].applicationName} ${t(
                "applicationList.saved"
              )}`,
              alertType: "success",
            })
          );
        }
      };

      updateApplicationNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteText]);

  // 회의(presentation)경우 테이블의 컬럼명과 테이블 컬럼 구성이 바뀜
  // 한번 테이블이 렌더링되면 column을 바꿔도 테이블이 다시 렌더링 되지않기 때문에 columnDef state로 테이블의 컬럼이 결정되면 테이블이 렌더링되도록 함
  useEffect(() => {
    if (subEvent && subEvent.category === "presentation") {
      const columns = [...state.columns];
      columns[1].title = t("common.name");
      columns.splice(-3, 1); // zoom링크를 끄고 켜는 기능이 없음
      setState((prevState) => ({
        ...prevState,
        columns: columns,
      }));
      setColumnDefDone(true);
    } else if (subEvent?.category === "consulting") {
      setColumnDefDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subEvent]);

  useEffect(() => {
    let tableData: any = [];

    if (
      applications &&
      Array.isArray(applications.content) &&
      applications.content.length > 0
    ) {
      dispatch(setTableInfoLoadingAction(true));

      applications.content.forEach((application) => {
        const rowData = {
          applicationApplicantId: application.applicantId,
          applicationApproval: application.approval,
          applicationCategory: application.category
            ? categoryAndCheckboxSelectTextToLinkAndText(
                application.category!,
                false
              )
            : "",
          applicationId: application.id,
          applicationKeyword: application.keyword,
          // applicationMethod:
          //   application.method === "pre"
          //     ? t("applicationList.preRegister")
          //     : t("applicationList.onsiteRegister"),
          applicationName: application.name,
          applicationManager: application.applicants
            ? application.applicants[0].businessCardId?.name
            : "",
          applicationType:
            application.type === "seller"
              ? subEvent?.sgroupName
              : subEvent?.bgroupName,
          applicationCreatedDate: moment(application.createdDate).format(
            "YYYY-MM-DD"
          ),
          note: application.note,
          type: application.type,
          zoomLink: application.zoomLinkId ?? false,
        };
        tableData.push(rowData);
      });
      dispatch(setTableInfoLoadingAction(false));
    }

    setState((prevCreateDate) => {
      return { ...prevCreateDate, data: tableData };
    });
  }, [applications, subEvent]);

  // 상태 변경
  const approvalChangeFunc = async (
    changeData: any,
    approval: string,
    message?: string
  ) => {
    dispatch(setProgressAction({ open: "true" }));

    if (subEvent === undefined) {
      return;
    }

    await Promise.all(
      changeData.map(async (actionTargetInData: any) => {
        const approvalChangeParam = {
          id: actionTargetInData.applicationId,
          subEventId: param.id,
          prevApproval: actionTargetInData.applicationApproval,
          action: approval,
          message: message!,
        };

        await dispatch(changeApplicationState(approvalChangeParam));
      })
    );

    dispatch(setProgressAction({ open: "false" }));
    dispatch(getApplications({ subEventId: param.id, type }));
  };

  // 선택 상태 변경 여러명 (승인, 거절, 취소, 삭제)
  const approvalChangeMultiFunc = (action: string, data: any) => {
    let title: string = approvalChangeActionsList[action].title;
    let prevState: string[] = approvalChangeActionsList[action].prevState;

    const actionTargetList: string[] = data.filter((inData: any) =>
      prevState.includes(inData.applicationApproval)
    );

    if (actionTargetList.length === 0) {
      confirmAlert(
        confirmModalOptions({ title: `${title}가능한 대상이 없습니다` })
      ); // 번역 noPossible
      return;
    }

    handleClickModalOpen(title, action, actionTargetList);
  };

  // 상태값 변경 버튼
  const statusChangeButtonForm = (
    status: string,
    rowData: any,
    action: string
  ) => {
    let title = "";
    let buttonBackgroundColor = "#512da8";
    let buttonTypoColor = "white";

    if (status === "waiting" && action === "approval") {
      title = t("applicationList.approval");
      buttonBackgroundColor = "#512da8";
    } else if (status === "waiting" && action === "reject") {
      title = t("applicationList.reject");
      buttonBackgroundColor = "#e4d7f7";
      buttonTypoColor = "black";
    } else if (
      (status === "approval" || status === "reject") &&
      action === "cancel"
    ) {
      buttonBackgroundColor = "#f1f2f5";
      buttonTypoColor = "black";
      title = t("applicationList.cancel");
    } else if (action === "delete") {
      title = t("applicationList.delete");
      buttonBackgroundColor = "white";
      buttonTypoColor = "black";
    }

    return (
      <>
        {title !== "" && (
          <>
            <Button
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.stopPropagation();
                handleClickModalOpen(title, action, [rowData]);
              }}
              style={{
                backgroundColor: buttonBackgroundColor,
                fontWeight: 500,
                color: buttonTypoColor,
                margin: "3px",
                border: action === "delete" ? "1px solid silver" : "none",
              }}
              size="small"
            >
              {title}
            </Button>
          </>
        )}
      </>
    );
  };

  const [approvalModalData, setApprovalModalData] = React.useState({
    open: false,
    title: "",
    action: "",
    rowData: [],
    message: "",
  });

  const handleClickModalOpen = useCallback(
    (title: string, action: string, rowData: any) => {
      setApprovalModalData({
        open: true,
        title: title,
        action: action,
        rowData: rowData,
        message: "",
      });
    },
    []
  );

  const handleModalClose = useCallback(() => {
    setApprovalModalData({
      open: false,
      title: "",
      action: "",
      rowData: [],
      message: "",
    });
  }, []);

  const handleModalMessageChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setApprovalModalData({
        ...approvalModalData,
        message: e.target.value,
      });
    },
    [approvalModalData]
  );

  // 선택된 buyer 전부 zoom link 를 넣어주거나 없애줌
  const onClickSelectedApplicationSetZoomLink = async (evt: any, data: any) => {
    dispatch(setLoadingAction());
    const applicationIds = data
      .filter((application: RowType) => application.type === "buyer")
      .map((application: RowType) => application.applicationId);

    await dispatch(updateApplicationsZoomLink(applicationIds));

    dispatch(removeLoadingAction());
  };

  const onChangeSearchParam = useCallback(
    (
      e: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      setType(e.target.value as string);
      setGetActionParam({
        subEventId: getActionParam.subEventId,
        type: e.target.value as string,
      });
    },
    []
  );

  return (
    <>
      {/* 참가자 가져오기 dialog */}
      <AddApplicationDialog
        open={addAplcDialogOpen}
        setOpen={setAddAplcDialogOpen}
      />
      {/* 참가자 가져오기 버튼 */}
      <div className={classes.addAplcBtn}>
        <PxButton
          backgroundColor="purple"
          onClick={() => {
            setAddAplcDialogOpen(true);
          }}
        >
          <ButtonTypo>참가자 가져오기</ButtonTypo>
        </PxButton>
      </div>
      {columnDefDone && (
        <PxTable<RowType>
          components={{
            Toolbar: (props) => {
              return (
                <PxGridContainer direction="column">
                  {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                  <MTableToolbar {...props} />
                </PxGridContainer>
              );
            },
          }}
          title={
            <div className={classes.tableTitle}>
              <PxOutlinedFormControl className={classes.outlinedFormControl}>
                <PxSelectBox
                  value={type}
                  onChange={(e) => {
                    onChangeSearchParam(e);
                  }}
                  displayEmpty
                  input={<OutlinedInput margin="dense" />}
                >
                  <MenuItem value={""}>{t("applicationList.all")}</MenuItem>
                  <MenuItem value={"seller"}>{subEvent?.sgroupName}</MenuItem>
                  <MenuItem value={"buyer"}>{subEvent?.bgroupName}</MenuItem>
                </PxSelectBox>
              </PxOutlinedFormControl>
              <Box width={10} />
            </div>
          }
          getAction={getApplications}
          totalPage={applications?.totalPages}
          actionData={getActionParam}
          columns={state.columns}
          data={state.data}
          onRowClick={(evt, selectedRow) => {
            history.push(`${match.url}/${selectedRow?.applicationId}`);
          }}
          // material-table 속성: https://material-table.com/#/docs/features/search
          options={{
            toolbar: true,
            actionsColumnIndex: -1,
            showTitle: true,
            defaultExpanded: false,
            search: true,
            selection: true,
            searchFieldAlignment: "right",
            exportButton: false, // csv 다운
          }}
          actions={[
            {
              tooltip: "",
              icon: () => (
                <span className={classes.stateChangeBtnText}>Zoom</span>
              ),
              onClick: onClickSelectedApplicationSetZoomLink,
            },
            {
              tooltip: "",
              icon: () => (
                <span className={classes.stateChangeBtnText}>
                  {t("applicationList.approval")}
                </span>
              ),
              onClick: (evt, data: any) =>
                approvalChangeMultiFunc("approval", data),
            },
            {
              tooltip: "",
              icon: () => (
                <span className={classes.stateChangeBtnText}>
                  {t("applicationList.reject")}
                </span>
              ),
              onClick: (evt, data: any) =>
                approvalChangeMultiFunc("reject", data),
            },
            {
              tooltip: "",
              icon: () => (
                <span className={classes.stateChangeBtnText}>
                  {t("applicationList.cancel")}
                </span>
              ),
              onClick: (evt, data: any) =>
                approvalChangeMultiFunc("cancel", data),
            },
            {
              tooltip: "",
              icon: () => (
                <span className={classes.stateChangeBtnText}>
                  {t("applicationList.delete")}
                </span>
              ),
              onClick: (evt, data: any) =>
                approvalChangeMultiFunc("delete", data),
            },
          ]}
        />
      )}
      <Dialog
        open={approvalModalData.open}
        onClose={handleModalClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          {approvalModalData.rowData.length > 0 && i18n.language === "ko"
            ? `${approvalModalData.title}가능한 대상이 ${approvalModalData.rowData.length}명이 있습니다 ${approvalModalData.title}하시겠습니까?`
            : approvalModalData.rowData.length &&
              `there is ${approvalModalData.rowData.length} that can be ${
                translate(approvalModalData.title)[0]
              },would you like to ${translate(approvalModalData.title)[1]}?`}
          {/* 번역 */}
        </DialogTitle>
        <DialogContent>
          {approvalModalData.rowData.length > 0 &&
            approvalModalData.rowData.map(
              (actionTargetindata: any, index: number) => (
                <div
                  key={`state-change-info-${index}`}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{`${index + 1}. ${
                    actionTargetindata.applicationName
                  }`}</div>
                  <div>{`${t("application")}: ${
                    actionTargetindata.applicationCreatedDate
                  }`}</div>
                </div>
              )
            )}
          {approvalModalData.action === "reject" && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label={t("applicationList.resonForRejection")}
                placeholder={t("typeTheResonForRejection")}
                type="text"
                value={approvalModalData.message}
                onChange={handleModalMessageChange}
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            {t("common.close")}
          </Button>
          <Button
            onClick={async () => {
              console.log(subEvent?.mainEvent, "subEvent?.mainEvent");
              console.log(approvalModalData.action, "approvalModalData.action");
              handleModalClose();
              subEvent?.mainEvent && approvalModalData.action === "delete"
                ? await approvalChangeFunc(
                    approvalModalData.rowData,
                    approvalModalData.action,
                    approvalModalData.message
                  )
                : await approvalChangeFunc(
                    approvalModalData.rowData,
                    approvalModalData.action,
                    approvalModalData.message
                  );
            }}
            color="primary"
          >
            {t("common.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApplicationList;

function translate(title: string) {
  const transTitle =
    title === "approval"
      ? ["approved", "approve"]
      : title === "cancel"
      ? ["cancelled", "cancel"]
      : title === "delete"
      ? ["deleted", "delete"]
      : ["rejected", "reject"];

  return transTitle;
}
