import Route from "./types/models/Route";
import Event from "./layouts/Event";
import EventDetail from "./views/Event/EventDetail";
import EventModify from "./views/Event/EventModify";
import Advertising from "./layouts/Advertising";
import MeetingFormSetting from "./views/form/main/MeetingFormSetting";
import FormPreview from "./views/form/preview/FormPreview";
import SellerPartiForm from "./views/form/setting/exportMeeting/SellerPartiForm";
import SellerCounselForm from "./views/form/setting/exportMeeting/SellerCounselForm";
import SellerSurveyForm from "./views/form/setting/exportMeeting/SellerSurveyForm";
import BuyerPartiForm from "./views/form/setting/exportMeeting/BuyerPartiForm";
import BuyerCounselForm from "./views/form/setting/exportMeeting/BuyerCounselForm";
import BuyerSurveyForm from "./views/form/setting/exportMeeting/BuyerSurveyForm";
import AutonoForm from "./layouts/AutonoForm";
import AddtionalForm from "./views/form/additional/AdditionalForm";
import SubEventModify from "./views/Event/SubEventModify";
import CountryList from "./components/Country/CountryList";
import CountryAdd from "./components/Country/CountryAdd";
import CountryDetail from "./components/Country/CountryDetail";
import CountryModify from "./components/Country/CountryModify";
import UserRegit from "./layouts/UserRegit";
import UserList from "./views/MemeberList/UserList";
import HostList from "./views/MemeberList/HostList";
import MemberList from "./layouts/MemberList";
import UserDetail from "./views/MemeberList/UserDetail";
import ZoomList from "./views/Zoom/ZoomList";
import ZoomAdd from "./views/Zoom/ZoomAdd";
import ZoomModify from "./views/Zoom/ZoomModify";
import HostDetail from "./views/MemeberList/HostDetail";
import PrinterList from "./views/Printers/PrinterList";
import PrinterAdd from "./views/Printers/PrinterAdd";
import PrinterModify from "./views/Printers/PrinterModify";
import ConsultingEstimate from "./views/Event/Payment/ConsultingEstimate";
import ConferenceEstimate from "./views/Event/Payment/ConferenceEstimate";
import Estimate from "./layouts/Estimate";
import ConsultingQuoation from "./views/Event/Payment/ConsultingQuoation";
import ConferenceQuoation from "./views/Event/Payment/ConferenceQuoation";
import FileConversion from "./layouts/FileConversion";
import OnSiteRegit from "./layouts/OnSiteRegit";
import NoMeetingFormSetting from "./views/form/main/NoMeetingFormSetting";
import AttPartiForm from "./views/form/setting/presentation/AttPartiForm";
import AttSurveyForm from "./views/form/setting/presentation/AttSurveyForm";
import PrePartiForm from "./views/form/setting/presentation/PrePartiForm";
import PreSurveyForm from "./views/form/setting/presentation/PreSurveyForm";
import SubEvent from "./layouts/SubEvent";
import ApplicationList from "./views/SubEvent/InsideMenu/Application/ApplicationList";
import ApplicationDetail from "./views/SubEvent/InsideMenu/Application/ApplicationDetail";
import MeetingHistory from "./views/SubEvent/InsideMenu/MeetingHistory/MeetingHistory";
import NoticeAdd from "./views/SubEvent/InsideMenu/Notice/NoticeAdd";
import NoticeList from "./views/SubEvent/InsideMenu/Notice/NoticeList";
import NoticeModify from "./views/SubEvent/InsideMenu/Notice/NoticeModify";
import NoticeDetail from "./views/SubEvent/InsideMenu/Notice/NoticeDetail";
import MailTab from "./views/SubEvent/InsideMenu/Mail/MailTab";
import MailDetail from "./views/SubEvent/InsideMenu/Mail/MailDetail";
import MailList from "./views/SubEvent/InsideMenu/Mail/MailList";
import MailAdd from "./views/SubEvent/InsideMenu/Mail/MailAdd";
import SmsAdd from "./views/SubEvent/InsideMenu/Sms/SmsAdd";
import SmsDetail from "./views/SubEvent/InsideMenu/Sms/SmsDetail";
import SmsList from "./views/SubEvent/InsideMenu/Sms/SmsList";
import Excel from "./views/SubEvent/InsideMenu/Excel/Excel";
import WebinarList from "./views/SubEvent/InsideMenu/Webinar/WebinarList";
import WebinarAdd from "./views/SubEvent/InsideMenu/Webinar/WebinarAdd";
import WebinarDetail from "./views/SubEvent/InsideMenu/Webinar/WebinarDetail";
import WebinarModify from "./views/SubEvent/InsideMenu/Webinar/WebinarModify";
import MeetingList from "./views/SubEvent/Meeting/MeetingList";
import MeetingDetail from "./views/SubEvent/Meeting/MeetingDetail";
import SendMail from "./components/SendMail/SendMail";
import InterpreterList from "./views/SubEvent/InsideMenu/Interpreter/InterPreterList";
import InterpreterAdd from "./views/SubEvent/InsideMenu/Interpreter/InterpreterAdd";
import InterpreterModify from "./views/SubEvent/InsideMenu/Interpreter/InterpreterModify";
import KeywordDetail from "./views/keyword/keywordDetail";

export const mainRoutes: Route[] = [
  { path: "", name: "EventPage", component: Event, layout: "/event" },
  { path: "", name: "MemberList", component: MemberList, layout: "/member" },
  {
    path: "/detail/:id",
    name: "EventDetailPage",
    component: EventDetail,
    layout: "/event",
  },
  {
    path: "/modify/:id",
    name: "EventModifyPage",
    component: EventModify,
    layout: "/event",
  },
  {
    path: "",
    name: "FormPage",
    component: AutonoForm,
    layout: "/form",
  },
  {
    path: "",
    name: "AdvertisingPage",
    component: Advertising,
    layout: "/advertising",
  },
  {
    path: "",
    name: "SendMail",
    component: SendMail,
    layout: "/sendMail",
  },
  {
    path: "",
    name: "OnSiteRegisterPage",
    component: OnSiteRegit,
    layout: "/onSiteRegit",
  },
  {
    path: "",
    name: "UserRegisterPage",
    component: UserRegit,
    layout: "/userRegit",
  },
  {
    path: "/detail/:id/subEventModify/:secondId",
    name: "SubEventModifyPage",
    component: SubEventModify,
    layout: "/event",
  },
  {
    path: "",
    name: "CountryListPage",
    component: CountryList,
    layout: "/country",
  },
  {
    path: "/add",
    name: "CountryAddPage",
    component: CountryAdd,
    layout: "/country",
  },
  {
    path: "/:id",
    name: "CountryDetailPage",
    component: CountryDetail,
    layout: "/country",
  },
  {
    path: "/modify/:id",
    name: "CountryModifyPage",
    component: CountryModify,
    layout: "/country",
  },
  {
    path: "",
    name: "ZoomListPage",
    component: ZoomList,
    layout: "/zoom",
  },
  {
    path: "/add",
    name: "ZoomAddPage",
    component: ZoomAdd,
    layout: "/zoom",
  },
  {
    path: "/modify/:id",
    name: "ZoomModifyPage",
    component: ZoomModify,
    layout: "/zoom",
  },
  {
    path: "",
    name: "PrinterListPage",
    component: PrinterList,
    layout: "/printers",
  },
  {
    path: "/add",
    name: "PrinterAddPage",
    component: PrinterAdd,
    layout: "/printers",
  },
  {
    path: "/modify/:id",
    name: "PrinterModifyPage",
    component: PrinterModify,
    layout: "/printers",
  },
  {
    path: "",
    name: "fileConversionPage",
    component: FileConversion,
    layout: "/fileConversion",
  },
  { path: "", name: "Estimate", component: Estimate, layout: "/estimate" },
  { path: "", name: "SubEventPage", component: SubEvent, layout: "/subEvent" },
  // {path: "",
  // name: "MemberList",
  // component: MemberList,
  // layout: "",},
  {
    path: "/state",
    name: "keywordAdministration",
    component: KeywordDetail,
    layout: "/keyword"
  }
];

export const memberRoutes: Route[] = [
  {
    path: "/users",
    name: "UserList",
    component: UserList,
    layout: "/member",
  },
  {
    path: "/users/:id",
    name: "UserDetail",
    component: UserDetail,
    layout: "/member",
  },
  {
    path: "/hosts",
    name: "HostList",
    component: HostList,
    layout: "/member",
  },
  {
    path: "/hosts/:id",
    name: "HostDetail",
    component: HostDetail,
    layout: "/member",
  },
];

export const formRoutes: Route[] = [
  {
    path: "/subEvent/:id/match",
    name: "MeetingFormSettingPage",
    component: MeetingFormSetting,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch",
    name: "ExhibitionFormSettingPage",
    component: NoMeetingFormSetting,
    layout: "/form",
  },
  {
    path: "/:from/preview",
    name: "FormPreview",
    component: FormPreview,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/addBuyerPartiForm",
    name: "AddtionalBuyerForm",
    component: AddtionalForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/addSellerPartiForm",
    name: "AddtionalSellerForm",
    component: AddtionalForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/sellerPartiForm",
    name: "SellerPartiForm",
    component: SellerPartiForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/sellerCounselForm",
    name: "SellerCounselForm",
    component: SellerCounselForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/sellerSurveyForm",
    name: "SellerSurveyForm",
    component: SellerSurveyForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/buyerPartiForm",
    name: "BuyerPartiForm",
    component: BuyerPartiForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/buyerCounselForm",
    name: "BuyerCounselForm",
    component: BuyerCounselForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/match/buyerSurveyForm",
    name: "BuyerSurveyForm",
    component: BuyerSurveyForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/attPartiForm",
    name: "PresentationAttendeeParticipationForm",
    component: AttPartiForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/attCounselForm",
    name: "PresentationAttendeeSurveyForm",
    component: AttSurveyForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/prePartiForm",
    name: "PresentationPresenterParticipationForm",
    component: PrePartiForm,
    layout: "/form",
  },
  {
    path: "/subEvent/:id/noMatch/preCounselForm",
    name: "PresentationPresenterSurveyForm",
    component: PreSurveyForm,
    layout: "/form",
  },
  {
    path: "/country",
    name: "CountryListPage",
    component: CountryList,
    layout: "/event",
  },
];

export const EstimatesRoutes: Route[] = [
  {
    path: "/subEvent/:subEventId/consulting",
    name: "estimatePage",
    component: ConsultingEstimate,
    layout: "/estimate",
  },

  {
    path: "/subEvent/:subEventId/consultingQuoation",
    name: "estimatePage",
    component: ConsultingQuoation,
    layout: "/estimate",
  },
  {
    path: "/subEvent/:subEventId/conference",
    name: "estimatePage",
    component: ConferenceEstimate,
    layout: "/estimate",
  },
  {
    path: "/subEvent/:subEventId/conferenceQuoation",
    name: "estimatePage",
    component: ConferenceQuoation,
    layout: "/estimate",
  },
];

export const keywordRoutes: Route[] = [
  {
    path: "/state",
    name: "keywordAdministration",
    component: KeywordDetail,
    layout: "/keyword"
  }
]

export const subEventRoutes: Route[] = [
  {
    path: "/:id/application",
    name: "ApplicationListPage",
    component: ApplicationList,
    layout: "/subEvent",
  },
  {
    path: "/:id/application/:secondId",
    name: "ApplicationDetailPage",
    component: ApplicationDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/webinar",
    name: "WebinarListPage",
    component: WebinarList,
    layout: "/subEvent",
  },
  {
    path: "/:id/webinar/add",
    name: "WebinarAddPage",
    component: WebinarAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/webinar/:secondId",
    name: "WebinarDetailPage",
    component: WebinarDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/webinar/:secondId/modify",
    name: "WebinarModifyPage",
    component: WebinarModify,
    layout: "/subEvent",
  },
  {
    path: "/:id/meeting",
    name: "MeetingListPage",
    component: MeetingList,
    layout: "/subEvent",
  },
  {
    path: "/:id/meeting/:secondId",
    name: "MeetingDetailPage",
    component: MeetingDetail,
    layout: "/subEvent",
  },
  // {
  //   path: "/:id/counsel",
  //   name: "CounselListPage",
  //   component: CounseList,
  //   layout: "/subEvent",
  // },
  // {
  //   path: "/:id/counsel/counselExcel",
  //   name: "CounselExcelPage",
  //   component: CounselExcel,
  //   layout: "/subEvent",
  // },
  {
    path: "/:id/meetingHistory",
    name: "MeetingHistoryPage",
    component: MeetingHistory,
    layout: "/subEvent",
  },
  {
    path: "/:id/interpreter",
    name: "InterpreterListPage",
    component: InterpreterList,
    layout: "/subEvent",
  },
  {
    path: "/:id/interpreter/add",
    name: "InterpreterAddPage",
    component: InterpreterAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/interpreter/:secondId",
    name: "InterpreterModifyPage",
    component: InterpreterModify,
    layout: "subEvent",
  },
  {
    path: "/:id/notice",
    name: "NoticeListPage",
    component: NoticeList,
    layout: "/subEvent",
  },
  {
    path: "/:id/notice/add",
    name: "NoticeAddPage",
    component: NoticeAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/notice/:secondId/modify",
    name: "NoticeModifyPage",
    component: NoticeModify,
    layout: "/subEvent",
  },
  {
    path: "/:id/notice/:secondId",
    name: "NoticeDetailPage",
    component: NoticeDetail,
    layout: "subEvent",
  },
  {
    path: "/:id/mail",
    name: "MailPage",
    component: MailTab,
    layout: "/subEvent",
  },
  {
    path: "/:id/mail/add",
    name: "MailAddPage",
    component: MailAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/mail/:secondId",
    name: "MailDetailPage",
    component: MailDetail,
    layout: "/subEvent",
  },
  {
    path: "/:id/sms",
    name: "SMSPage",
    component: SmsList,
    layout: "/subEvent",
  },
  {
    path: "/:id/sms/add",
    name: "SmsAddPage",
    component: SmsAdd,
    layout: "/subEvent",
  },
  {
    path: "/:id/sms/:secondId",
    name: "SmsDetailPage",
    component: SmsDetail,
    layout: "/subEvent",
  },
  // {
  //   path: "/:id/monitoring",
  //   name: "MonitoringPage",
  //   component: Monitoring,
  //   layout: "/subEvent",
  // },
  {
    path: "/:id/excel",
    name: "ExcelPage",
    component: Excel,
    layout: "/subEvent",
  },
  // {
  //   path: "/:id/regitDesk",
  //   name: "RegistrationDeskPage",
  //   component: RegistrationDesk,
  //   layout: "/subEvent",
  // },
  // {
  //   path: "/:id/regitDesk/:secondId",
  //   name: "applicantModify",
  //   component: ApplicantModify,
  //   layout: "/subEvent",
  // },
  // {
  //   path: "/:id/qrScanner",
  //   name: "QRScanner",
  //   component: QRreader,
  //   layout: "/subEvent",
  // },
  // {
  //   path: "/:id/onSiteRegit",
  //   name: "OnSiteRegit",
  //   component: OnSiteRegistration,
  //   layout: "/subEvent",
  // },
  // {
  //   path: "/:id/onSiteRegit/printer",
  //   name: "OnSiteRegit",
  //   component: PrinterOnSite,
  //   layout: "/subEvent",
  // },
  // // {
  // //   path: "/:id",
  // //   name: "SubEventListPage",
  // //   component: ProgramList,
  // //   layout: "/subEvent",
  // // },
  // {
  //   path: "/:id/regitApplicationAdd",
  //   name: "RegitApplicationAddPage",
  //   component: RegitApplicationAdd,
  //   layout: "/subEvent",
  // },
  // {
  //   path: "/:id/regitApplicationList",
  //   name: "RegitApplicationListPage",
  //   component: RegitApplicationList,
  //   layout: "/subEvent",
  // },
  // {
  //   path: "/:id/regitApplicationDetail/:secondId",
  //   name: "RegitApplicationModifyPage",
  //   component: RegitApplicationModify,
  //   layout: "/subEvent",
  // },
];
