import React, { useState } from "react";
import EventInfo from "./EventInfo";
import SubEventList from "./SubEventList";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

function a11yProps(index: any) {
  return {
    id: `event-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  eventTitleRoot: {
    textAlign: "center",
    margin: "60px 0",
  },
  contentRoot: {
    flexGrow: 1,
  },
  tabIndicator: {
    backgroundColor: "#5f4b8b",
  },
}));

const EventRoot = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  return (
    <>
      {/* 탭 */}
      <div className={classes.contentRoot}>
        <AppBar position="static" color="default" elevation={0}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
            TabIndicatorProps={{ className: classes.tabIndicator }}
          >
            <Tab label="하위이벤트 목록" {...a11yProps(0)} />
            <Tab label="상세내용" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </div>

      {tabValue === 0 && <SubEventList />}
      {tabValue === 1 && <EventInfo />}
    </>
  );
};

export default EventRoot;
