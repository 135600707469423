import React, { useState, useEffect } from "react";
import PxContainer from "../../components/Containers/PxContainer";
import PxOutlinedTxtField from "../../components/Inputs/PxOutlinedTxtField";
import { Link, Redirect } from "react-router-dom";
import PexpoLogo from "../../assets/images/logo.png";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { getUser, getAuthToken } from "../../actions/users";
interface LoginForm {
  email: string;
  password: string;
}

const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.users);

  const [loginForm, setLoginForm] = useState<LoginForm>({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (user.isAuthenticated === true && user.userId === undefined) {
      dispatch(getUser(localStorage.userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getUser]);

  const handleOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string | undefined }>
  ) => {
    setLoginForm({
      ...loginForm,
      [event.target.name as string]: event.target.value as string,
    });
  };

  // user 로그인 성공 시 Redirect
  // if (user.userId) {
  //   return (
  //     <Redirect
  //       to={lastLocation?.pathname === undefined ? "/" : lastLocation.pathname}
  //     />
  //   );
  // }

  // user 로그인 성공 시 Redirect
  if (user.userId) {
    return <Redirect to="/" />;
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(getAuthToken(loginForm));
    // props.getAuthToken(loginForm);
  };

  return (
    <PxContainer maxWidth="xs" style={{ textAlign: "center", paddingTop: 50 }}>
      <div style={{ padding: 20 }}>
        <Link to="/">
          <img
            src={PexpoLogo}
            width="100"
            height="33"
            alt="Pexpo logo"
            style={{ marginBottom: 30 }}
          />
        </Link>
        <form onSubmit={onSubmit}>
          <PxOutlinedTxtField
            fullWidth
            label="Email"
            name="email"
            padding="0px 0px 20px 0px"
            onChange={handleOnChange}
          />
          <PxOutlinedTxtField
            fullWidth
            label="Password"
            name="password"
            padding="0px 0px 30px 0px"
            onChange={handleOnChange}
          />
          <PxButton
            backgroundColor="purple"
            fullWidth
            padding="10px 0px 10px 0px"
            type="submit"
          >
            <ButtonTypo>로그인</ButtonTypo>
          </PxButton>
        </form>
      </div>
    </PxContainer>
  );
};

export default Login;
