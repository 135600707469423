import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../store";
import { RouteProps, useHistory, useParams } from "react-router-dom";

// types
import FormPreviewItem from "./FormPreviewItem";
import { Form } from "../../../types/models/Form";

// component
import PxButton from "../../../components/Buttons/PxButton";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import ButtonTypo from "../../../components/Typography/ButtonTypo";
import Box from "@material-ui/core/Box";

type Props = ReduxStateProps & RouteProps;

const FormPreview: React.FC<Props> = (props) => {
  const { forms } = props;
  const params: { from: string } = useParams(); // parti: 참가신청양식 미리보기, add: 추가양식 미리보기
  const history = useHistory();

  return (
    <>
      {params.from === "parti" &&
        forms?.formData!.formIds.length !== 0 &&
        forms?.formData!.formIds.map((formId, index) => (
          <>
            <FormPreviewItem
              key={index}
              title={forms?.formData!.formData[formId].title}
              type={forms?.formData!.formData[formId].type}
              id={forms?.formData!.formData[formId].id}
              explainCheck={forms?.formData!.formData[formId].explainCheck}
              explain={forms?.formData!.formData[formId].explain}
              selectContent={forms?.formData!.formData[formId].selectContent}
            />
            <Box height={20} />
          </>
        ))}
      {params.from === "add" &&
        forms?.additionalFormData!.formIds.length !== 0 &&
        forms?.additionalFormData!.formIds.map((formId, index) => (
          <>
            <FormPreviewItem
              key={index}
              title={forms?.additionalFormData!.formData[formId].title}
              type={forms?.additionalFormData!.formData[formId].type}
              id={forms?.additionalFormData!.formData[formId].id}
              explainCheck={
                forms?.additionalFormData!.formData[formId].explainCheck
              }
              explain={forms?.additionalFormData!.formData[formId].explain}
              selectContent={
                forms?.additionalFormData!.formData[formId].selectContent
              }
            />
            <Box height={20} />
          </>
        ))}
      <PxGridContainer justify="center" style={{ marginTop: 50 }}>
        <PxButton
          backgroundColor="purple"
          padding="10px 60px 10px 60px"
          onClick={() => {
            history.goBack();
          }}
        >
          <ButtonTypo>뒤로가기</ButtonTypo>
        </PxButton>
      </PxGridContainer>
    </>
  );
};

interface ReduxStateProps {
  forms?: Form;
}

const mapStateToProps = (state: AppState) => ({
  forms: state.forms,
});

export default connect(mapStateToProps, null)(FormPreview);
