import User, { UpdatePwd } from "../types/models/User";
import { AppActions, AppState } from "../store";
import { postRequest, getRequest, setAlert, putRequest } from "../utils/utils";
import { Dispatch } from "redux";
import axios, { AxiosResponse } from "axios";
import Alert from "../types/models/Alert";
import { removeAlertAction, setAlertAction } from "./alerts";

export const createUserAction = (user: User): AppActions => ({
  type: "CREATE_USER",
  user,
});

export const getUserAction = (user: User): AppActions => ({
  type: "GET_USER",
  user,
});

export const updateUserInfoAction = (user:User) : AppActions => ({
  type: "UPDATE_USERINFO",
  user
})

export const getAuthTokenAction = (user: User): AppActions => ({
  type: "GET_AUTH_TOKEN",
  user,
});

export const createUsersAction = (users: User[]): AppActions => ({
  type: "CREATE_USERS",
  users,
});

export const getUsersAction = (getUsers: User[]): AppActions => ({
  type: "GET_USERS",
  getUsers
})

export const getHostersAction = (hosters: User[]): AppActions => ({
  type: "GET_HOSTERS",
  hosters
})

export const createUser = (user: User) => {
  return postRequest(user, "/api/users", createUserAction);
};

export const getUser = (userId: string) => {
  return getRequest(userId, `/api/users/admin/${userId}`, getUserAction);
};

export const removeUser = (): AppActions => ({
  type: "REMOVE_USER",
});

export const createUsers = (users: User[]) => {
  return postRequest(users, "/api/users/createUsers", createUsersAction);
};

export interface LoginInfo {
  email: string;
  password: string;
}
export const getAuthToken = (loginInfo: LoginInfo) => {
  return postRequest(loginInfo, "/api/users/login", getAuthTokenAction);
};     

export const getMember = (userType: "USER"|"HOST" ="USER") =>{
  return getRequest(null, `/api/users/userList/${userType}`, userType ==="USER" ? getUsersAction : getHostersAction);
}


export const getUserApplicants = (id : string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/applicants/userApprovedEventList/${id}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    return res.data;
  } catch (err) {
    const alert: Alert = setAlert(err.response.status, err.response.data, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
}

export const updateUserPwd = (data : UpdatePwd) => {
  return putRequest(data, "/api/users/update/password/admin");
}

export const updateUserInfo = (user:User) => {
  return putRequest(user,"/api/users/update/admin",updateUserInfoAction);
}