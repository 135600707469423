import SubEventActionTypes from "../types/actions/SubEventAction";
import { SubEventInfo } from "../types/models/SubEvent";

interface SubEVentReducerType {
  subEvent?: SubEventInfo;
  subEvents: SubEventInfo[];
}

const subEventDefaultState: SubEVentReducerType = {
  subEvent: undefined,
  subEvents: [],
};

const subEventReducer = (
  state = subEventDefaultState,
  action: SubEventActionTypes
): SubEVentReducerType => {
  switch (action.type) {
    case "GET_SUB_EVENT":
      return { ...state, subEvent: action.subEvent };
    case "GET_SUB_EVENTS":
      return { ...state, subEvents: action.subEvents };
    case "REMOVE_SUB_EVENT":
      return subEventDefaultState;
    default:
      return state;
  }
};

export default subEventReducer;
