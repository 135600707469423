import React, {useEffect} from "react";
import styled from "styled-components";
import {Switch, useLocation, useHistory} from "react-router-dom";

// router
import {formRoutes, mainRoutes, keywordRoutes} from "../router";

// custom components
import MainNavbar from "../components/Navbars/MainNavbar";

// types
import RouteType from "../types/models/Route";
import Container from "@material-ui/core/Container";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../store";
import FormProvider from "../components/FormProvider/FormProvider";

const StyledContainer = styled(Container)`
  height: 100%;
  padding: 0 20 0 20;
  max-width: 1680px;
  /* max-width: 1366px; */
`;

const StyleDiv = styled.div`
  min-height: 100%;
  position: relative;
  background-color: white;
`;

const FormRootDiv = styled.div`
  max-width: 640px;
  margin: auto;
  margin-top: 30px;
`;

interface MainProps {}
const Main: React.FC<MainProps> = props => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {isAuthenticated} = useSelector((state: AppState) => state.users);

  useEffect(() => {
    if (history.location.pathname === "/") {
      history.push("/event");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // useEffect(() => {
  //   dispatch(setCountry());
  // }, []);
  // const country = useSelector((state: AppState) => state.countries);

  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      switch (prop.layout) {
        case "/member":
        case "/estimate":
        case "/subEvent":
        case "/keyword":
          return <PrivateRoute path={prop.layout} component={prop.component} key={key} isAuthenticated={isAuthenticated!} />;
        default:
          return <PrivateRoute exact path={prop.layout + prop.path} component={prop.component} key={key} isAuthenticated={isAuthenticated!} />;
      }
    });
  };

  return (
    <StyleDiv>
      <MainNavbar />
      <StyledContainer>
        <Switch>{getRoutes(mainRoutes)}</Switch>
        <FormRootDiv>
          <FormProvider>{getRoutes(formRoutes)}</FormProvider>
        </FormRootDiv>
      </StyledContainer>
    </StyleDiv>
  );
};

export default Main;
