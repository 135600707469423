import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getEvent,
  modifyEventApprovalAction,
  modifyEventShowLogo,
} from "../../actions/events";
import moment from "moment";
import { AppState } from "../../store";

// custom material ui comp
import PxGridContainer from "../../components/Grid/PxGridContainer";
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
import HeadlineTypo from "../../components/Typography/HeadlineTypo";

// icons
import { makeStyles } from "@material-ui/core/styles";
import EventRoot from "./EventRoot";
import { getSubEvents, removeSubEventAction } from "../../actions/subEvent";
import ConsultingPaidEstimateBanner from "./Payment/Banner/ConsultingPaidEstimateBanner";
import ConsultingPaidApplyEstimateBanner from "./Payment/Banner/ConsultingPaidApplyEstimateBanner";
import ConsultingFreeEstimateBanner from "./Payment/Banner/ConsultingFreeEstimateBanner";
import ConferenceEstimateBanner from "./Payment/Banner/ConferenceEstimateBanner";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { removeLoadingAction, setLoadingAction } from "../../actions/loading";

const useStyles = makeStyles((theme) => ({
  eventTitleRoot: {
    textAlign: "center",
    margin: "60px 0",
  },
  contentRoot: {
    flexGrow: 1,
  },
  tabIndicator: {
    backgroundColor: "#5f4b8b",
  },
}));

const PurpleSwitch = withStyles({
  root: {
    alignSelf: "center",
  },
  switchBase: {
    "&$checked": {
      color: "rgb(81, 45, 168)",
    },
    "&$checked + $track": {
      backgroundColor: "rgb(81, 45, 168)",
    },
  },
  checked: {},
  track: {},
})(Switch);

const EventDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const param: { id: string } = useParams();
  const subEvents = useSelector((state: AppState) => state.subEvents.subEvents);
  const eventDetail = useSelector((state: AppState) => state.events.event);

  useEffect(() => {
    dispatch(getEvent(param.id));
    dispatch(getSubEvents(param.id));

    return () => {
      dispatch(removeSubEventAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickLogoOffSwitch = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    e.stopPropagation();
    dispatch(setLoadingAction());
    const result: any = await dispatch(modifyEventShowLogo(id));
    dispatch(removeLoadingAction());
    if (result === true) {
      const changedEventDetail = { ...eventDetail! };
      changedEventDetail.showLogo =
        changedEventDetail.showLogo === "Y" ? "N" : "Y";
      dispatch(modifyEventApprovalAction(changedEventDetail));
    }
  };

  return eventDetail !== undefined ? (
    <>
      <PxGridContainer
        direction="column"
        alignContent="center"
        className={classes.eventTitleRoot}
      >
        <HeadlineTypo>{eventDetail.name}</HeadlineTypo>
        <SubHeadingTypo>
          {`${moment(eventDetail.startDate).format("yyyy-MM-DD")} - ${moment(
            eventDetail.endDate
          ).format("yyyy-MM-DD")}`}
        </SubHeadingTypo>
        <PurpleSwitch
          checked={eventDetail.showLogo === "Y" ? true : false}
          onClick={(e) => {
            onClickLogoOffSwitch(e, eventDetail.id!);
          }}
          name="paymentSwitch"
        />
      </PxGridContainer>
      {subEvents?.map((data) => {
        if (data.category === "consulting") {
          return data.payment === "Y" ? (
            <ConsultingPaidEstimateBanner subEvent={data} />
          ) : data.payment === "W" ? (
            <ConsultingPaidApplyEstimateBanner subEvent={data} />
          ) : (
            <ConsultingFreeEstimateBanner subEvent={data} />
          );
        } else return <ConferenceEstimateBanner subEvent={data} />;
      })}
      <EventRoot />
    </>
  ) : null;
};

export default EventDetail;
