import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { memberRoutes } from "../router";
import RouteType from "../types/models/Route";

const Layout = styled.div`
  width: 100vw;
  max-width: 100%;
  padding: 10px 67px;
`;

export default () => {
  const getRoutes = (routes: RouteType[]) => {
    return routes.map((prop, key) => {
      switch (prop.path) {
        case "/users":
        case "/hosts":
        case "/users/:id":
        case "/hosts/:id":
          return (
            <Route exact path={`${prop.layout}${prop.path}`} component={prop.component} key={key} />
          );
        default:
          return <Redirect to="/" />;
      }
    });
  };
  return (
    <Layout>
      <Switch>{getRoutes(memberRoutes)}</Switch>
    </Layout>
  );
};
