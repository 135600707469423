import React, { useEffect } from "react";
import { Column } from "material-table";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PxTable from "../../components/Tables/PxTable";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import { deleteZooms, getZooms, unUseZooms } from "../../actions/zooms";
import { AppState } from "../../store";
import moment from "moment";
import { removeLoadingAction, setLoadingAction } from "../../actions/loading";
import { setTableInfoLoadingAction } from "../../actions/tableInfo";

interface RowType {
  id?: string;
  useCheck: string;
  applicationId: string;
  zoomRoomId: string;
  zoomEmail: string;
  modifiedDate?: string;
  createdDate?: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const StyledDiv = styled.div`
  width: 100%;
  & th.MuiTableCell-root,
  td.MuiTableCell-root {
    padding: 0px 0px;
  }
  & td.MuiTableCell-root {
    color: rgba(0, 0, 0, 0.6) !important;
  }
`;

const ZoomList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const zooms = useSelector((state: AppState) => state.zooms.zooms);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: "ID",
        field: "id",
        cellStyle: {
          width: "50px",
        },
      },
      {
        title: "Zoom Link",
        field: "zoomLink",
        cellStyle: {
          width: "calc(100%-650px)",
        },
      },
      {
        title: "Zoom Room ID",
        field: "zoomRoomId",
        cellStyle: {
          width: "150px",
        },
      },
      {
        title: "등록 Email",
        field: "zoomEmail",
        cellStyle: {
          width: "200px",
        },
      },
      {
        title: "사용여부",
        field: "useCheck",
        cellStyle: {
          width: "100px",
          paddingRight: 0,
        },
      },
      {
        title: "만든날짜",
        field: "createdDate",
        cellStyle: {
          width: "150px",
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    dispatch(getZooms());
  }, [dispatch]);

  useEffect(() => {
    if (zooms) {
      const tableData = zooms.map((zoom) => ({
        id: zoom.id!,
        useCheck: zoom.useCheck!,
        applicationId: zoom.applicationId!,
        zoomLink: zoom.link,
        zoomRoomId: zoom.zoomRoomId,
        zoomEmail: zoom.zoomEmail,
        createdDate: moment(zoom.createdDate)
          .format("YYYY-MM-DD HH:mm")
          .toString(),
      }));

      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: tableData };
      });

      dispatch(setTableInfoLoadingAction(false));
    }
  }, [zooms]);

  const onClickSelectedZoomLinkDelete = async (evt: any, data: any) => {
    dispatch(setLoadingAction());
    const zoomLinkIds = data.map((zoomLink: RowType) => zoomLink.id);

    await deleteZooms(zoomLinkIds);

    await dispatch(getZooms());

    dispatch(removeLoadingAction());
  };

  const onClickSelectedZoomLinkUnUse = async (evt: any, data: any) => {
    dispatch(setLoadingAction());
    const zoomLinkIds = data.map((zoomLink: RowType) => zoomLink.id);

    const result = await unUseZooms(zoomLinkIds);

    if (result) {
      await dispatch(getZooms());
    }

    dispatch(removeLoadingAction());
  };

  return (
    <>
      <div style={{ textAlign: "right", marginTop: "10px" }}>
        <PxButton
          backgroundColor="purple"
          onClick={() => {
            history.push("/zoom/add");
          }}
        >
          <ButtonTypo>추가</ButtonTypo>
        </PxButton>
      </div>
      <StyledDiv>
        <PxTable<RowType>
          columns={state.columns}
          data={state.data}
          options={{
            selection: true,
            actionsColumnIndex: 0,
            pageSize: 10,
            showTitle: true,
            defaultExpanded: false,
            search: true,
          }}
          onRowClick={(event, rowData) => {
            history.push(`/zoom/modify/${rowData!.id}`);
          }}
          actions={[
            {
              tooltip: "",
              icon: () => (
                <span style={{ fontSize: 14, color: "red" }}>삭제</span>
              ),
              onClick: onClickSelectedZoomLinkDelete,
            },
            {
              tooltip: "",
              icon: () => (
                <span style={{ fontSize: 14, color: "blue" }}>사용중지</span>
              ),
              onClick: onClickSelectedZoomLinkUnUse,
            },
          ]}
        />
      </StyledDiv>
    </>
  );
};

export default ZoomList;
