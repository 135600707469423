import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";

import styled from "styled-components";

interface impossibleCardProps {
  impossibleAt: string;
  disabledClick?: boolean;
}
const ImpossibleCard = styled(Card)`
  border: 1px solid #ccc;
  width: 135px;
  height: 70px;
  margin-bottom: 10px;
  outline: none;
  &:focus {
    outline: none !important;
  }
  ${(props: impossibleCardProps) => {
    if (props.impossibleAt === "Y") {
      return `border: 1px solid red;`;
    }
  }}
`;

const TimeCardActions = styled(CardActions)`
  justify-content: center;
  padding: 0 0 8px 0;
  ${(props: impossibleCardProps) => {
    if (props.impossibleAt !== "Y" && !props.disabledClick) {
      return `padding-bottom: 38px;`;
    }
  }}
  & > p {
    font-size: 16px;
    font-weight: bold;
  }
`;

const ImpossibleCardActions = styled(CardActions)`
  border: 1px solid red;
  background-color: red;
  color: white;
  padding: 0;
  & > p {
    width: 100%;
    background-color: red;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
`;

const DisabledClickCardActions = styled(CardActions)`
  background-color: #ccc;
  border: 1px solid #ccc;
  color: white;
  padding: 0;
  & > p {
    width: 100%;
    color: #fff;
    background-color: #ccc;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
`;

interface Props {
  startTime: string;
  endTime: string;
  impossibleAt: "Y" | "N";
  disabledClick?: boolean;
  dateIndex: number;
  timeIndex: number;
  onChangeMeetingTime?: any;
}

const ImpossibleTimeCard: React.FC<Props> = props => {
  const {startTime, endTime, impossibleAt, dateIndex, timeIndex, disabledClick, onChangeMeetingTime} = props;
  return (
    <ImpossibleCard impossibleAt={impossibleAt} onClick={() => !disabledClick && onChangeMeetingTime(startTime, impossibleAt)}>
      <CardActionArea>
        <TimeCardActions disabledClick={disabledClick} impossibleAt={impossibleAt}>
          <Typography gutterBottom>
            {startTime} - {endTime}
          </Typography>
        </TimeCardActions>
        {disabledClick && (
          <DisabledClickCardActions>
            <Typography gutterBottom>선택불가</Typography>
          </DisabledClickCardActions>
        )}

        {impossibleAt === "Y" && (
          <ImpossibleCardActions>
            <Typography gutterBottom>미팅불가</Typography>
          </ImpossibleCardActions>
        )}
      </CardActionArea>
    </ImpossibleCard>
  );
};

export default ImpossibleTimeCard;
