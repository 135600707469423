import React, { useEffect } from "react";
import { Column } from "material-table";
import { AppState } from "../../store";

// material icons
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PxTable from "../../components/Tables/PxTable";
import PxButton from "../../components/Buttons/PxButton";
import { getMember } from "../../actions/users";
import moment from "moment";
import { removeLoading, setLoading } from "../../actions/loading";
import { debounce } from "lodash";
import styled from "styled-components";
import { setTableInfoLoadingAction } from "../../actions/tableInfo";

interface RowType {
  id: string;
  createDate: string;
  name: string;
  position: string;
  company: string;
  email: string;
  createdCount: number;
  payment: number;
  status: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}
const StyledDiv = styled.div`
  width: 100%;
  & th.MuiTableCell-root,
  td.MuiTableCell-root {
    padding: 0px 0px;
  }
  & td.MuiTableCell-root {
    color: rgba(0, 0, 0, 0.6) !important;
  }
`;

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state: AppState) => state.users.hosters);
  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: "가입일시",
        field: "createDate",
        cellStyle: {
          width: "140px",
        },
      },
      {
        title: "이름",
        field: "name",
        cellStyle: {
          width: "140px",
        },
      },
      {
        title: "직함",
        field: "position",
        cellStyle: {
          width: "120px",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "소속",
        field: "company",
        cellStyle: {
          width: "300px",
          paddingRight: 0,
        },
      },
      {
        title: "이메일",
        field: "email",
        cellStyle: {
          width: "calc(100%-540px)",
          paddingRight: 0,
        },
      },
      {
        title: "개설",
        field: "createdCount",
        cellStyle: {
          width: "60px",
        },
      },
      {
        title: "결제",
        field: "payment",
        cellStyle: {
          width: "60px",
        },
      },
      {
        title: "상태",
        field: "status",
        cellStyle: {
          width: "80px",
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    const result: any = dispatch(getMember("HOST"));
    if (!result) {
      dispatch(removeLoading());
    }
  }, [dispatch]);

  const loadData = debounce(() => {
    const tableData: RowType[] = users!.map((data) => {
      const rowData: RowType = {
        name: data.name,
        createDate: moment(data.createDate)
          .format("YYYY-MM-DD HH:mm")
          .toString(),
        position: data.position!,
        company: data.company,
        email: data.email,
        createdCount: data.createdCount ?? 0,
        payment: 0,
        status: "활동중",
        id: data.id!,
      };
      return rowData;
    });
    setState({ ...state, data: tableData });
    dispatch(setTableInfoLoadingAction(false));
  }, 500);

  useEffect(() => {
    if (users !== undefined && users.length !== 0) {
      loadData();
    }
  }, [users]);

  return (
    <StyledDiv>
      <PxTable<RowType>
        columns={state.columns}
        data={state.data}
        title={
          <>
            <PxButton
              txtColor="purple"
              padding="7px 24px"
              border={true}
              borderColor="grey"
              style={{ marginRight: 4 }}
            >
              활성화
            </PxButton>
            <PxButton
              backgroundColor="grey"
              padding="7px 24px"
              txtColor="black60"
            >
              탈퇴처리
            </PxButton>
          </>
        }
        initPageSize={5}
        options={{
          selection: true,
          actionsColumnIndex: 0,
          showTitle: true,
          defaultExpanded: false,
          search: true,
        }}
        onRowClick={(event, rowData) => {
          history.push(`/member/hosts/${rowData!.id}`);
        }}
        actions={[
          {
            tooltip: "활성화",
            icon: () => (
              <PxButton
                txtColor="purple"
                padding="7px 24px"
                border={true}
                borderColor="grey"
              >
                활성화
              </PxButton>
            ),
            onClick: (evt, data: any) => {},
          },
          {
            tooltip: "활성화",
            icon: () => (
              <PxButton
                backgroundColor="grey"
                padding="7px 24px"
                txtColor="black60"
              >
                탈퇴처리
              </PxButton>
            ),
            onClick: (evt, data: any) => {
              // confirmAlert(
              //   confirmModalOptions({
              //     title: "선택 자동매칭을 하시겠습니까?",
              //     message: "카테고리와 키워드 모두 맞는 상대를 매칭합니다",
              //     click: () => choiceMatchingHandler(data, "tight"),
              //   })
              // );
            },
          },
        ]}
      />
    </StyledDiv>
  );
};
