import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useParams } from "react-router-dom";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { MyMeetingContext } from "./MeetingDetail";

import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { confirmAlert } from "react-confirm-alert";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import styled from "styled-components";
import { AppState } from "../../../store";
import {
  changeMeetingState,
  getMyMeetings,
  getWaitingAcceptMeetingTargets,
  waitingAcceptMeetingTargetsActionStateToInitial,
} from "../../../actions/meeting";
import { ChangeMeetingStateInterface } from "../../../types/models/Meeting";
import { confirmModalOptions } from "../../../utils/confirmModalOptions";
const ExpansionPanelForm = styled(ExpansionPanel)`
  padding: 0;
  margin: 0;
  &::before {
    border: 0;
    height: 0;
  }
`;

// props interface
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  date: string;
  startTime: string;
  endTime: string;
}

interface ApplicantListType {
  id: string;
  name: string;
  info: ApplicantListInfoType[];
  managerName?: string;
}
interface ApplicantListInfoType {
  title: string;
  content: string;
}
const WaitingAcceptMeetingTarget: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const param: { id: string; secondId: string } = useParams();
  const { open, setOpen, date, startTime, endTime } = props;
  const { myMeetingCheckList } = useContext(MyMeetingContext);
  const [applicantList, setApplicantList] = useState<ApplicantListType[]>([]);
  const [meetingsId, setMeetingsId] = React.useState<string>("");
  const radioGroupRef = useRef<HTMLElement>(null);

  const application = useSelector(
    (state: AppState) => state.applications.application
  );
  const waitingAcceptMeetingTargets = useSelector(
    (state: AppState) => state.meetings.waitingAcceptMeetingTargets
  );

  // 목록 조회
  useEffect(() => {
    if (
      open === true &&
      application !== undefined &&
      application.id !== undefined
    ) {
      dispatch(getWaitingAcceptMeetingTargets(props.id, application.id));
    }
  }, [open]);

  useEffect(() => {
    return () => {
      dispatch(waitingAcceptMeetingTargetsActionStateToInitial());
    };
  }, []);

  // 조회 목록 가공
  useEffect(() => {
    // 신청가능 기업 목록 조회
    if (
      waitingAcceptMeetingTargets !== undefined &&
      waitingAcceptMeetingTargets.length > 0
    ) {
      let waitingAcceptMeetingTargetsSetting: ApplicantListType[] = [];

      waitingAcceptMeetingTargets.map((waitingAcceptMeetingTarget) => {
        let info: ApplicantListInfoType[] = [];

        // 질문별 답변 컬럼다름 질문추가하고 답변 변경 할것
        waitingAcceptMeetingTarget.applicationFormAnswers!.map(
          (answer: any, index) => {
            if (
              answer!.type! !== null &&
              answer!.repeatInfo === "N" &&
              answer.content !== undefined
            ) {
              let infoData: ApplicantListInfoType = {
                title: answer!.title!,
                content: answer!.content!,
              };
              info.push(infoData);
            }
          }
        );

        waitingAcceptMeetingTargetsSetting.push({
          id: waitingAcceptMeetingTarget.id as string,
          name: waitingAcceptMeetingTarget.applicantName as string,
          info: info,
          managerName: waitingAcceptMeetingTarget.applicantManagerName,
        });
      });

      setApplicantList(waitingAcceptMeetingTargetsSetting);
    }
  }, [waitingAcceptMeetingTargets]);

  // 미팅요청자 리스트 라디오박스 선택
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeetingsId((event.target as HTMLInputElement).value);
  };

  const onStateChange = async (status: string) => {
    if (meetingsId === "") {
      alert("대상자를 선택해주세요.");
      return;
    }

    const mailPostToggleAt =
      localStorage.getItem("mailPostToggleAt") === null
        ? "false"
        : localStorage.getItem("mailPostToggleAt")!.toString();

    // 미팅 신청 데이터
    const changeMeetingStateData: ChangeMeetingStateInterface = {
      subEventId: param.id,
      acceptorId: param.secondId, // 기업 pk;
      meetingId: props.id, // 미팅시간 key;
      meetingsId: meetingsId, // 미팅 pk;
      status: status, // 상태 [대기: waiting, 동의: agree, 비동의: disagree]
      mailPostToggleAt: mailPostToggleAt,
    };

    const result: any = await dispatch(
      changeMeetingState(changeMeetingStateData)
    );
    if (result === true) {
      dispatch(getMyMeetings(param.secondId));
      setOpen(false);
    }
  };

  return application !== undefined &&
    waitingAcceptMeetingTargets !== undefined ? (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      fullWidth
      onEntering={() => console.log("handleEntering")}
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        미팅 수락대기자 리스트
      </DialogTitle>
      {applicantList.length > 0 ? (
        <DialogContent dividers>
          <RadioGroup
            ref={radioGroupRef}
            aria-label="ringtone"
            value={meetingsId}
            onChange={handleChange}
          >
            {applicantList.map((applicant: ApplicantListType, targetIndex) => (
              <ExpansionPanelForm elevation={0}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                  style={{ padding: 0, margin: "0" }}
                >
                  <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={<Radio />}
                    label={applicant.name + `(${applicant.managerName})`}
                    value={applicant.id.toString()}
                    style={{ padding: 0, margin: "0", height: "10px" }}
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0 }}>
                  <Typography color="textSecondary" style={{ paddingLeft: 40 }}>
                    {applicant!.info!.map((inData: ApplicantListInfoType) => (
                      <div>
                        {inData.title}: {inData.content}
                      </div>
                    ))}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanelForm>
            ))}
          </RadioGroup>
        </DialogContent>
      ) : (
        <ListItem>
          <ListItemText primary="해당시간에는 미팅신청자가 없습니다." />
        </ListItem>
      )}

      <DialogActions>
        <Button autoFocus onClick={() => setOpen(false)} color="primary">
          닫기
        </Button>
        {applicantList.length > 0 && (
          <>
            <Button
              onClick={() => {
                confirmAlert(
                  confirmModalOptions({
                    title: `거절하시겠습니까?`,
                    click: () => onStateChange("disagree"),
                  })
                );
              }}
              color="primary"
            >
              거절
            </Button>
            <Button
              onClick={() => {
                confirmAlert(
                  confirmModalOptions({
                    title: `수락하시겠습니까?`,
                    click: () => onStateChange("agree"),
                  })
                );
              }}
              color="primary"
            >
              수락
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  ) : null;
};

export default WaitingAcceptMeetingTarget;
