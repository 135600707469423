import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import PrinterNavbar from "../views/Printer/printerNavbar";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PrinterDetail from "../views/Printer/printerDetail";
import PrinterEvent from "../views/Printer/printerEvent";
import PrinterFindUser from "../views/Printer/printerFindUser";
import PrinterEventTest from "../views/Printer/printerEventTest";
import { Backdrop } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppState } from "../store";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrinterSelect from "../views/Printer/PrinterSelect";
import PrinterOnSite from "../views/Printer/printerOnSite";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const Printer = () => {
  const match = useRouteMatch();
  const classes = useStyles();
  const loading = useSelector((state: AppState) => state.printers.loading);

  return (
    <div className={classes.root}>
      <Backdrop open={loading} style={{ zIndex: 1000, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <PrinterNavbar />
      <Container className={classes.root} maxWidth="md">
        <Switch>
          <Route exact path={match.path} component={PrinterSelect} />
          <Route
            exact
            path={`${match.path}/eventList`}
            component={PrinterDetail}
          />
          <Route
            exact
            path={`${match.path}/:subEventId/test`}
            component={PrinterEventTest}
          />
          <Route
            exact
            path={`${match.path}/:subEventId`}
            component={PrinterEvent}
          />
          <Route
            exact
            path={`${match.path}/:subEventId/user`}
            component={PrinterFindUser}
          />
          <Route
            exact
            path={`${match.path}/:subEventId/onSiteUser`}
            component={PrinterOnSite}
          />
        </Switch>
      </Container>
    </div>
  );
};

export default Printer;
