import React, {useCallback} from "react";
import {useHistory} from "react-router-dom";

import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import PexpoLogo from "../../assets/images/logo.png";
import PxButton from "../Buttons/PxButton";
import ButtonTypo from "../Typography/ButtonTypo";
import {useSelector, useDispatch} from "react-redux";
import {AppState} from "../../store";
import {removeUser} from "../../actions/users";
import {deleteAuthToken} from "../../utils/setAuthToken";
import TitleTypo from "../Typography/TitleTypo";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: "center",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      cursor: "pointer",
    },
  })
);

export default function MainNavbar() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.users);
  const event = useSelector((state: AppState) => state.events.event);
  const loading = useSelector((state: AppState) => state.loadings.loading);

  const setNavbarContent = useCallback(() => {
    if (history.location.pathname.includes("/form") || history.location.pathname.includes("SubEventModify")) {
      return (
        <>
          <div className={classes.root}>
            <TitleTypo>{event?.name}</TitleTypo>
          </div>
          <PxButton
            backgroundColor="purple"
            onClick={() => {
              history.push(`/event/detail/${event?.id}`);
            }}
          >
            <ButtonTypo>취소</ButtonTypo>
          </PxButton>
        </>
      );
    } else {
      return (
        <>
          <div className={classes.root} />
          <PxButton
            backgroundColor={history.location.pathname.includes("/event") ? "purple" : "default"}
            onClick={() => {
              history.push("/event");
            }}
          >
            <ButtonTypo>이벤트 목록</ButtonTypo>
          </PxButton>
          <PxButton
            backgroundColor={history.location.pathname.includes("/member/hosts") ? "purple" : "default"}
            onClick={() => {
              history.push("/member/hosts");
            }}
          >
            <ButtonTypo>주최자 목록</ButtonTypo>
          </PxButton>
          <PxButton
            backgroundColor={history.location.pathname.includes("/member/users") ? "purple" : "default"}
            onClick={() => {
              history.push("/member/users");
            }}
          >
            <ButtonTypo>유저 목록</ButtonTypo>
          </PxButton>
          <PxButton
            backgroundColor={history.location.pathname.includes("/country") ? "purple" : "default"}
            onClick={() => {
              history.push("/country");
            }}
          >
            <ButtonTypo>국가 목록</ButtonTypo>
          </PxButton>
          <PxButton
            backgroundColor={history.location.pathname.includes("/keyword") ? "purple" : "default"}
            onClick={() => {
              history.push("/keyword/state");
            }}
          >
            <ButtonTypo>키워드 관리</ButtonTypo>
          </PxButton>
          {/* <PxButton
            backgroundColor={
              history.location.pathname.includes("/advertising") ? "purple" : "default"
            }
            onClick={() => {
              history.push("/advertising");
            }}
          >
            <ButtonTypo>광고</ButtonTypo>
          </PxButton> */}
          <PxButton
            backgroundColor={history.location.pathname.includes("/sendMail") ? "purple" : "default"}
            onClick={() => {
              history.push("/sendMail");
            }}
          >
            <ButtonTypo>메일 보내기</ButtonTypo>
          </PxButton>

          <PxButton
            backgroundColor={history.location.pathname.includes("/userRegit") ? "purple" : "default"}
            onClick={() => {
              history.push("/userRegit");
            }}
          >
            <ButtonTypo>유저등록</ButtonTypo>
          </PxButton>

          <PxButton
            backgroundColor={history.location.pathname.includes("/onSiteRegit") ? "purple" : "default"}
            onClick={() => {
              history.push("/onSiteRegit");
            }}
          >
            <ButtonTypo>현장등록</ButtonTypo>
          </PxButton>

          <PxButton
            backgroundColor={history.location.pathname.includes("/zoom") ? "purple" : "default"}
            onClick={() => {
              history.push("/zoom");
            }}
          >
            <ButtonTypo>Zoom</ButtonTypo>
          </PxButton>

          <PxButton
            backgroundColor={history.location.pathname.includes("/printers") ? "purple" : "default"}
            onClick={() => {
              history.push("/printers");
            }}
          >
            <ButtonTypo>Printers</ButtonTypo>
          </PxButton>

          <PxButton
            backgroundColor={history.location.pathname.includes("/fileConversion") ? "purple" : "default"}
            onClick={() => {
              history.push("/fileConversion");
            }}
          >
            <ButtonTypo>File convertor</ButtonTypo>
          </PxButton>

          {/* <PxButton
            backgroundColor={
              history.location.pathname.includes("/printer")
                ? "purple"
                : "default"
            }
            onClick={() => {
              window.open("/printer", "_blank");
            }}
          >
            <ButtonTypo>Printer</ButtonTypo>
          </PxButton> */}

          <PxButton
            backgroundColor={history.location.pathname.includes("/login") ? "purple" : "default"}
            onClick={() => {
              // 로그인 or 로그아웃
              if (user.userId === undefined) {
                history.push("/login");
              } else {
                deleteAuthToken();
                dispatch(removeUser());
              }
            }}
          >
            <ButtonTypo>{user.userId === undefined ? "로그인" : "로그아웃"}</ButtonTypo>
          </PxButton>
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, event]);

  return (
    <>
      <AppBar position="sticky" style={{backgroundColor: "white"}} elevation={0}>
        <Toolbar>
          <div className={classes.logo}>
            <img
              src={PexpoLogo}
              width="79"
              height="26"
              className="d-inline-block align-top"
              alt="Pexpo logo"
              onClick={() => {
                history.push("/event");
              }}
            />
          </div>
          {setNavbarContent()}
        </Toolbar>
      </AppBar>
      {loading === true && <LinearProgress />}
    </>
  );
}
