import React, { useState, useEffect, createContext, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";

// @material-ui/core
import Grid from "@material-ui/core/Grid";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// custom ui comp
import MeetingTimeForm from "./MeetingTimeForm";

import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store";
import {
  applicationActionStateToInitial,
  getApplication,
} from "../../../actions/application";
import {
  choiceMatching,
  choiceMatchingCancel,
  getMyMeetings,
  matchingMailSend,
  meetingsActionStateToInitial,
} from "../../../actions/meeting";
import { locationParamData } from "../../../utils/utils";
import { setDialog } from "../../../actions/dialogs";
import { convertEtcToGmt } from "../../../utils/momentTz";
import { choiceCountry } from "../../../actions/country";
import { confirmAlert } from "react-confirm-alert";
import { confirmModalOptions } from "../../../utils/confirmModalOptions";
import PxOutlinedFormControl from "../../../components/Forms/PxOutlinedFormControl";
import InputLabel from "@material-ui/core/InputLabel";
import PxSelectBox from "../../../components/SelectBox/PxSelectBox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import PxCalendar from "../../../components/PxCalendar/PxCalendar";
import PxButton from "../../../components/Buttons/PxButton";
import ButtonTypo from "../../../components/Typography/ButtonTypo";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const MeetingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const TitleForm = styled.div`
  display: flex;
  flex-direction: column;
`;
const CompanyName = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -1px;
  color: rgba(0, 0, 0, 0.87);

  @media only screen and (max-width: 920px) {
    flex-direction: column;
  }
`;
const CompanyType = styled.div`
  height: 24px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.6);
`;
const MatchingBtnForm = styled.div`
  display: inline-block;
  margin-bottom: 32px;
`;
const ToggleBtnForm = styled.div`
  float: right;
`;

const MailSendBtn = styled.button`
  transition: all 300ms ease;
  outline: none;
  padding: 5px 10px;
  text-align: center;
  line-height: 33px;
  background-color: #e9ecef;
  border: none;
  border-radius: 5px;
  margin: 10px;
  color: #383838;
  &:hover {
    background-color: #5f4b8b;
    color: white;
  }
`;

// 미팅신청 param interface
export interface MeetingApplicantDataInterface {
  subEventId: string;
  applicant: string;
  acceptor: string;
  meetingId: string;
  status: "waiting" | "agree" | "disagree" | "impossible"; // 상태 [대기: waiting, 동의: agree, 비동의: disagree]
  date: string;
  startTime: string;
  endTime: string;
  mailPostToggleAt: string; // 메일 발송여부
}

// createContext 타입
interface MyMeetingContextType {
  myMeetingCheckList: MyMeetingCheckListKeyType;
  myMeetingSearch: MyMeetingSearchType;
}

// 미팅 데이터 가공([key]: date), 목록에서 내가 신청했는지 파악하기 위해 필요
interface MyMeetingCheckListKeyType {
  [key: string]: MyMeetingCheckListType[];
}

// 미팅 데이터 가공 데이터 타입([key]: data) => data
export interface MyMeetingCheckListType {
  id: string;
  date: string;
  acceptor: string;
  acceptorName: string;
  applicant: string;
  applicantName: string;
  status: string;
}
export interface MyMeetingSearchType {
  name: string;
  keyword: string;
  category: string;
}
export const MyMeetingContext = createContext<MyMeetingContextType>(null!);

const MeetingDetail = () => {
  const [t, i18n] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const param: { id: string; secondId: string } = useParams();
  const location = useLocation();
  const application = useSelector(
    (state: AppState) => state.applications.application
  );
  const meetings = useSelector((state: AppState) => state.meetings.meetings);
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const { country, countries, getCountry, getCountries } = useSelector(
    (state: AppState) => state.countries!
  );
  const [date, setDate] = useState<moment.Moment>(() => moment());
  // 메일발송 여부
  const getMailPostToggleAt =
    localStorage.getItem("mailPostToggleAt") === null
      ? "false"
      : localStorage.getItem("mailPostToggleAt")!.toString();
  const [mailPostToggleAt, setMailPostToggleAt] =
    React.useState(getMailPostToggleAt);

  // 미팅목록별 카드 ui변경을 위함
  const [myMeetingCheckList, setMyMeetingCheckList] = useState({});

  // 검색 변수
  const [searchData, setSearchData] = useState<MyMeetingSearchType>({
    name: "",
    keyword: "",
    category: "",
  });

  useEffect(() => {
    if (subEvent) {
      !date.isBetween(
        subEvent.subEventStartDate,
        subEvent.subEventEndDate,
        undefined,
        "[]"
      ) &&
        setDate(
          moment(
            `${subEvent.subEventStartDate.split("T")[0]}T${
              subEvent.matchStartTime
            }`
          )
        );
    }
  }, [subEvent]);

  // 기업정보, 미팅 리스트 조회
  useEffect(() => {
    dispatch(getApplication(param.secondId));
    dispatch(getMyMeetings(param.secondId));

    return () => {
      dispatch(applicationActionStateToInitial);
      dispatch(meetingsActionStateToInitial);
    };
  }, []);

  // 검색 필터
  useEffect(() => {
    const locationSearchData = locationParamData(location.search);
    if (Object.keys(locationSearchData).length !== 0) {
      setSearchData(locationSearchData as any);
    }
  }, [location]);

  // 내 미팅목록 가공(미팅 카드, 리스트별 구분을 위함)
  useEffect(() => {
    let myMeetingCardSetting: MyMeetingCheckListKeyType = {};
    if (meetings !== undefined && meetings.length > 0) {
      meetings.map((meeting) => {
        let MyMeetingContextData: MyMeetingCheckListType = {
          id: meeting.id as string,
          date: meeting.date as string,
          acceptor: meeting.acceptor as string,
          acceptorName: meeting.acceptorName as string,
          applicant: meeting.applicant as string,
          applicantName: meeting.applicantName as string,
          status: meeting.status as string,
        };
        const key = `${meeting.date}${meeting.startTime}${meeting.endTime}`;
        if (
          Object.keys(myMeetingCardSetting).length !== 0 &&
          myMeetingCardSetting[key] !== undefined
        ) {
          // 등록 내역이 있을경우
          myMeetingCardSetting[key].push(MyMeetingContextData);
        } else {
          // 첫 등록일 경우
          Object.assign(myMeetingCardSetting, {
            [key]: [MyMeetingContextData],
          });
        }
      });
    }
    setMyMeetingCheckList(myMeetingCardSetting);
  }, [meetings]);

  // 자동 매칭 결과 확인창 문구
  const matchDialogMessage = (matchData: any) => {
    let returnData: string[] = [];
    if (matchData.length > 0) {
      matchData.map((rowData: any) => {
        returnData.push(
          `[${rowData.date} ${rowData.startTime} ~ ${rowData.endTime}] ${rowData.acceptorName}, ${rowData.applicantName}`
        );
      });
    }
    return returnData;
  };

  // 매칭
  const choiceMatchingHandler = async (tightOrNot: string) => {
    const mailPostToggleAt =
      localStorage.getItem("mailPostToggleAt") === null
        ? "false"
        : localStorage.getItem("mailPostToggleAt")!.toString();

    const matchingParam: any = {
      subEventId: param.id,
      applicationIds: [param.secondId],
      tightOrNot: tightOrNot,
      mailPostToggleAt: mailPostToggleAt,
    };

    const result: any = await dispatch(choiceMatching(matchingParam));
    dispatch(
      setDialog(`총 ${result.length}건 매칭 완료`, matchDialogMessage(result))
    );
    dispatch(getApplication(param.secondId));
    dispatch(getMyMeetings(param.secondId));
  };

  // 매칭 취소
  const choiceMatchingCancelHandler = async () => {
    const mailPostToggleAt =
      localStorage.getItem("mailPostToggleAt") === null
        ? "false"
        : localStorage.getItem("mailPostToggleAt")!.toString();

    const matchingParam: any = {
      subEventId: param.id,
      applicationIds: [param.secondId],
      mailPostToggleAt: mailPostToggleAt,
    };

    const result: any = await dispatch(choiceMatchingCancel(matchingParam));
    if (result === "success") {
      dispatch(getApplication(param.secondId));
      dispatch(getMyMeetings(param.secondId));
    }
  };

  // 노출 기준 시간
  const exposureTime = () => {
    const thisCountryTime = getCountries![getCountry!.countryCode!].timezone;
    return thisCountryTime.indexOf("Etc/GMT") > -1
      ? "GMT" + convertEtcToGmt(thisCountryTime)
      : thisCountryTime;
  };

  // 국가변경
  const changeCountry = async (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    const key: string = e.target.value as string;
    const getCountry = getCountries![key];

    await dispatch(choiceCountry(key, getCountry.koName, getCountry.timezone));
  };

  // 메일발송 여부 변경
  const handleMailPostToggleAtChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const clicked = event.target.checked.toString();
    setMailPostToggleAt(clicked);
    localStorage.setItem("mailPostToggleAt", clicked);
  };

  // 전체 메일 발송
  const matchingMailSendAll = () => {
    if (application === undefined) return;

    const matchingMailSendParam: any = {
      subEventId: param.id,
      mode: "choice",
      targetIds: [application.id],
    };

    confirmAlert(
      confirmModalOptions({
        title: "전체 체결 메일을 발송 하시겠습니까?",
        click: async () => {
          const result: any = await dispatch(
            matchingMailSend(matchingMailSendParam)
          );

          if (result === true) dispatch(setDialog(`메일발송되었습니다.`, []));
        },
      })
    );
  };

  return application !== undefined && subEvent !== undefined ? (
    <Grid container>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px", width: "100%" }}>
        <MeetingHeader>
          <TitleForm>
            <CompanyName>
              <div style={{ marginRight: 20 }}>
                <div>
                  {application.name}{" "}
                  <span style={{ fontSize: "small" }}>
                    {t("meetingDetail.globalTime")}: {getCountry!.country} (
                    {exposureTime()})
                  </span>
                </div>
                <CompanyType>
                  {application.type === "seller"
                    ? subEvent.sgroupName
                    : subEvent.bgroupName}
                </CompanyType>
              </div>
              {/* 접속국가 globalTime */}
              <PxOutlinedFormControl style={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("meetingDetail.globalTime")}
                </InputLabel>
                <PxSelectBox
                  value={getCountry?.countryCode}
                  onChange={changeCountry}
                  displayEmpty
                  input={<OutlinedInput margin="dense" />}
                >
                  {Object.keys(getCountries!).map((countryMap) => (
                    <MenuItem value={countryMap}>
                      {i18n.language === "ko"
                        ? getCountries![countryMap].koName
                        : getCountries![countryMap].enName}
                    </MenuItem>
                  ))}
                </PxSelectBox>
              </PxOutlinedFormControl>
              <PxCalendar date={date} setDate={setDate} meetings={meetings} />
            </CompanyName>
          </TitleForm>
          <MatchingBtnForm>
            <div style={{ display: "inline-block", paddingTop: 10 }}>
              {/* <PxButton
                backgroundcolor="purple"
                onClick={() => {
                  confirmAlert(
                    confirmModalOptions({
                      title: "자동 매칭을 하시겠습니까?",
                      message: "카테고리와 키워드 모두 맞는 상대를 매칭합니다",
                      click: () => choiceMatchingHandler("tight"),
                    })
                  );
                }}
              >
                <ButtonTypo>{t("meetingDetail.matching(Tight)")}</ButtonTypo>
              </PxButton>{" "}
              <PxButton
                backgroundcolor="purple"
                onClick={() => {
                  confirmAlert(
                    confirmModalOptions({
                      title: "자동 매칭을 하시겠습니까?",
                      message: "카테고리가 맞는 상대를 매칭합니다",
                      click: () => choiceMatchingHandler(""),
                    })
                  );
                }}
              >
                <ButtonTypo>{t("meetingDetail.matching")}</ButtonTypo>
              </PxButton>{" "} */}
              <PxButton
                backgroundColor="purple"
                onClick={() => {
                  confirmAlert(
                    confirmModalOptions({
                      title: "매칭을 취소 하시겠습니까?",
                      message: "대상자와 관련된 매칭을 취소하시겠습니까?",
                      click: choiceMatchingCancelHandler,
                    })
                  );
                }}
              >
                <ButtonTypo>{t("meetingDetail.cancelMatching")}</ButtonTypo>
              </PxButton>
            </div>
            <ToggleBtnForm>
              <span style={{ fontWeight: "bold", marginRight: 10 }}>
                {t("meetingList.mailSwitch")}
              </span>
              <FormControlLabel
                control={
                  <Switch
                    checked={mailPostToggleAt === "true" ? true : false}
                    onChange={handleMailPostToggleAtChange}
                    color="primary"
                  />
                }
                label={mailPostToggleAt === "true" ? "on" : "off"}
              />
              <MailSendBtn
                style={{
                  outline: "none",
                }}
                onClick={matchingMailSendAll}
              >
                {t("meetingList.mailSending")}
              </MailSendBtn>
            </ToggleBtnForm>
          </MatchingBtnForm>
        </MeetingHeader>

        {/* <Filter /> */}
        <MyMeetingContext.Provider
          value={{
            myMeetingCheckList: myMeetingCheckList,
            myMeetingSearch: searchData,
          }}
        >
          <MeetingTimeForm date={date} />
        </MyMeetingContext.Provider>
      </Grid>
    </Grid>
  ) : null;
};

export default MeetingDetail;
