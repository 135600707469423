import PrinterSubEventActionTypes from "../types/actions/PrinterAction";
import Alert from "../types/models/Alert";
import { OnSiteUser, Printer, PrinterSubEvent } from "../types/models/Printer";
import { getRequest, postRequest, setAlert } from "../utils/utils";
import axios from "axios";
import { Dispatch } from "redux";
import { AppActions } from "../store";
import { removeAlertAction, setAlertAction } from "./alerts";

export const getPrinterSubEventsAction = (
  subEvents: PrinterSubEvent[]
): PrinterSubEventActionTypes => ({
  type: "GET_PRINTER_SUB_EVENTS",
  subEvents: subEvents,
});

export const getPrinterSubEventAction = (
  subEvent: PrinterSubEvent
): PrinterSubEventActionTypes => ({
  type: "GET_PRINTER_SUB_EVENT",
  subEvent: subEvent,
});

export const setPrinterLoadingAction = (
  loading: boolean,
  alert?: Alert
): PrinterSubEventActionTypes => ({
  type: "SET_PRINTER_LOADING",
  alert: alert,
  loading: loading,
});

export const setOnSiteRegitAction = (
  regit: "seller" | "buyer" | "none"
): PrinterSubEventActionTypes => ({
  type: "SET_ONSITE_REGIT",
  onSiteRegit: regit,
});

export const getPrintersction = (
  printers: Printer[]
): PrinterSubEventActionTypes => ({
  type: "GET_PRINTERS",
  printers: printers,
});

export const getPrinterAction = (
  printer: Printer
): PrinterSubEventActionTypes => ({
  type: "GET_PRINTER",
  printer: printer,
});

export const resetPrinterAction = (): PrinterSubEventActionTypes => ({
  type: "RESET_PRINTER",
});

export const getPrinterSubEventsByDate = (date: string) => {
  return getRequest(
    null,
    "/api/subEvents/dateAndApproval",
    getPrinterSubEventsAction,
    {
      date: date,
    }
  );
};

export const getPrinterSubEventsByName = (name: string) => {
  return getRequest(
    null,
    "/api/subEvents/nameAndApproval",
    getPrinterSubEventsAction,
    {
      name: name,
    }
  );
};

export const getPrinterSubEvent = (subEventId: string) => {
  return getRequest(null, `/api/subEvents/detail`, getPrinterSubEventAction, {
    id: subEventId,
  });
};

export const createPrinter = (printer: Printer) => {
  return postRequest(printer, "/api/printer");
};

export const getPrinters = () => {
  return getRequest(null, "/api/printer", getPrintersction);
};

export const getPrinter = (id: string) => {
  return getRequest(null, `/api/printer/${id}`, getPrinterAction);
};

export const deletePrinters = async (printerIds: string[]) => {
  try {
    const body = JSON.stringify(printerIds);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: body, // get 호출시 필요
    };

    const res = await axios.delete("/api/printer", config);

    return res.data;
  } catch (err) {
    return false;
  }
};

export const createOnSiteRegitUser = (userInfo: OnSiteUser) => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: null,
    };

    const body = JSON.stringify(userInfo);

    const res = await axios.post("/api/onSiteRegit", body, config);

    return res.data;
  } catch (err) {
    const error: any = err.response.data;
    const alert: Alert = setAlert(
      err.response.status,
      error,
      "onSiteRegitError"
    );
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};
