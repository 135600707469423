import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import "./i18n/i18n";

// material ui global theme
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import store from "./store";
import { SnackbarProvider } from "notistack";

import PxAlert from "./components/Alerts/PxAlert";
import PxBackDrop from "./components/BackDrop/PxBackDrop";
import PxProgress from "./components/Progresses/PxProgress";

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: green,
  },
  typography: {
    fontFamily: "Noto Sans KR",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <SnackbarProvider
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            maxSnack={3}
          >
            <PxAlert /> <PxProgress />
          </SnackbarProvider>
          <PxBackDrop />
          <App />
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
