import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeFormAction } from "../../../actions/forms";

import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

// custom comp
import MakeFormComponent from "./MakeFormComponent";

import { AppState } from "../../../store";
import { setAlert } from "../../../actions/alerts";

import Box from "@material-ui/core/Box";
import useFormContext from "../../../components/FormProvider/useFormContext";
import { getSubEvent } from "../../../actions/subEvent";
import SubHeadingTypo from "../../../components/Typography/SubHeadingTypo";

const MeetingFormSetting: React.FC = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const { setInitialLoad } = useFormContext(); // 처음 자율양식 페이지에 들어왔을때, 자율양식을 불러오는 api 호출 yes or no 판단
  const params = useParams<{ id: string }>(); // for getting 서브 이벤트 id
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const event = useSelector((state: AppState) => state.events.event);

  useEffect(() => {
    dispatch(getSubEvent(parseInt(params.id)));
    setInitialLoad(true);
    dispatch(removeFormAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.push(`/event/detail/${event!.id}`);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <SubHeadingTypo fontweight="bold" gutterBottom>
        {subEvent?.sgroupName}
      </SubHeadingTypo>
      {/* 셀러 참가신청 */}
      <MakeFormComponent
        title={`${subEvent?.sgroupName} ${t("form.registration")}`}
        onClick={() => {
          history.push(`/form/subEvent/${params.id}/match/sellerPartiForm`);
        }}
        required={true}
        done={subEvent?.sellerPartiForm === 0 ? false : true}
      />
      {/* 셀러 상담일지 */}
      <MakeFormComponent
        title={`${subEvent?.sgroupName} ${t("form.meetingNote")}`} // 셀러
        onClick={() => {
          history.push(`/form/subEvent/${params.id}/match/sellerCounselForm`);
        }}
        done={subEvent?.sellerCounselForm === 0 ? false : true}
      />
      {/* 셀러 사후설문 */}
      <MakeFormComponent
        title={`${subEvent?.sgroupName} ${t("form.postSurvey")}`} // 셀러
        onClick={() => {
          history.push(`/form/subEvent/${params.id}/match/sellerSurveyForm`);
        }}
        done={subEvent?.sellerSurveyForm === 0 ? false : true}
      />

      <Box paddingY={5} />
      <SubHeadingTypo fontweight="bold" gutterBottom>
        {subEvent?.bgroupName}
      </SubHeadingTypo>
      {/* 바이어 참가신청 */}
      <MakeFormComponent
        title={`${subEvent?.bgroupName} ${t("form.registration")}`} // 바이어
        onClick={() => {
          if (subEvent?.sellerPartiForm === 0) {
            dispatch(
              setAlert({
                id: v4(),
                msg: `${subEvent.sgroupName} 참가신청양식을 먼저 만들어주세요.`, // 셀러
                alertType: "warning",
              })
            );
          } else {
            history.push(`/form/subEvent/${params.id}/match/buyerPartiForm`);
          }
        }}
        required={true}
        done={subEvent?.buyerPartiForm === 0 ? false : true}
      />
      {/* 바이어 상담일지 */}
      <MakeFormComponent
        title={`${subEvent?.bgroupName} ${t("form.meetingNote")}`} // 바이어
        onClick={() => {
          history.push(`/form/subEvent/${params.id}/match/buyerCounselForm`);
        }}
        done={subEvent?.buyerCounselForm === 0 ? false : true}
      />
      {/* 바이어 사후설문 */}
      <MakeFormComponent
        title={`${subEvent?.bgroupName} ${t("form.postSurvey")}`}
        onClick={() => {
          history.push(`/form/subEvent/${params.id}/match/buyerSurveyForm`);
        }}
        done={subEvent?.buyerSurveyForm === 0 ? false : true}
      />
    </>
  );
};

export default MeetingFormSetting;
