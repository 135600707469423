import React from "react";
import styled, { css } from "styled-components";

import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";

interface PxInputBase {
  fontsize?: number;
  fontweight?: "bold" | "normal";
  inputPadding?: string;
}

type Prop = PxInputBase & InputBaseProps;

const PxInputBase: React.FC<Prop> = styled((props: Prop) => {
  const { fontsize, ...rest } = props;
  return <InputBase {...rest} />;
})`
  ${(props) => {
    if (props.inputPadding !== undefined) {
      return css`
        .MuiInputBase-input {
          padding: ${props.inputPadding};
        }
      `;
    }
  }}  
  font-size: ${(props) => props.fontsize + "px"};
  font-family: NotoSansCJKkr;
  font-weight: ${(props) => props.fontweight ?? "normal"};
`;

PxInputBase.defaultProps = {
  fontsize: 16,
};

export default PxInputBase;
