import React from "react";
import { AppState } from "../../../../../store";
import { useSelector } from "react-redux";
import Card from "@material-ui/core/Card";
import { makeStyles, CardContent } from "@material-ui/core";
import PxGridContainer from "../../../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../../../components/Grid/PxGridItem";
import SubHeadingTypo from "../../../../../components/Typography/SubHeadingTypo";
import TitleTypo from "../../../../../components/Typography/TitleTypo";
import ButtonTypo from "../../../../../components/Typography/ButtonTypo";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cardRoot: {
    padding: 20,
    marginTop: 20,
    border: "1px solid #ddd",
    width: 450,
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0px 40px 0px",
      padding: 5,
      width: "100%",
    },
  },
}));

const CompanyManagerInfo = () => {
  const classes = useStyles();

  const application = useSelector(
    (state: AppState) => state.applications.application
  );

  return application !== undefined ? (
    <>
      {application!.applicants!.map((applicant, index) => {
        return (
          applicant.businessCard !== undefined && (
            <Card className={classes.cardRoot} key={`manager-${index}`}>
              <CardContent>
                <PxGridContainer direction="column">
                  <PxGridItem>
                    <SubHeadingTypo>
                      {applicant.businessCard.company}
                    </SubHeadingTypo>
                  </PxGridItem>
                  <PxGridItem className="mt-4">
                    <TitleTypo>{applicant.businessCard.name}</TitleTypo>
                  </PxGridItem>
                  <PxGridItem className="mt-4">
                    <ButtonTypo>{`${applicant.businessCard.email}`}</ButtonTypo>
                  </PxGridItem>
                  <PxGridItem>
                    <ButtonTypo>
                      {`(${applicant.businessCard.countryNumber})${applicant.businessCard.phoneNumber}`}
                    </ButtonTypo>
                  </PxGridItem>
                </PxGridContainer>
              </CardContent>
            </Card>
          )
        );
      })}
    </>
  ) : null;
};
export default CompanyManagerInfo;
