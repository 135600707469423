import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { AxiosResponse } from "axios";
import { Column } from "material-table";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setDialog } from "../../actions/dialogs";
import { removeLoadingAction, setLoadingAction } from "../../actions/loading";
import { createMail, getUserListByEventCategories } from "../../actions/mail";
import { setTableInfoLoadingAction } from "../../actions/tableInfo";
import { ApplicationInfo } from "../../types/models/Application";
import { MailInfo, MailRecipient } from "../../types/models/Mail";
import { confirmModalOptions } from "../../utils/confirmModalOptions";
import { TypographyProps } from "@material-ui/core/Typography";
import EventCategory from "../../views/Event/EventCategory";
import PxButton from "../Buttons/PxButton";
import PxEditor from "../Editor/PxEditor";
import PxOutlinedFormControl from "../Forms/PxOutlinedFormControl";
import OutlinedTxtField from "../Inputs/OutlinedTxtField";
import PxSelectBox from "../SelectBox/PxSelectBox";
import PxTable from "../Tables/PxTable";
import Body1Typo from "../Typography/Body1Typo";
import ButtonTypo from "../Typography/ButtonTypo";
import SubHeadingTypo from "../Typography/SubHeadingTypo";
interface RowType {
  applicantId: string;
  company: string;
  email: string;
  name: string;
  type: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}
// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const SendMail = () => {
  const dispatch = useDispatch();
  const [selectParam, setSelectParam] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [mail, setMail] = useState<MailInfo>({
    senderName: "KINTERCH",
    recipient: [],
    title: "",
    content: "",
  });

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: "그룹",
        field: "type",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: "이메일",
        field: "email",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: "회사명",
        field: "company",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: "이름",
        field: "name",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
    ],
    data: [],
  });
  useEffect(() => {
    getUserList();
  }, [categories, selectParam]);
  const getUserList = async () => {
    const returnValue: any = await dispatch(
      getUserListByEventCategories(
        selectParam,
        categories[0] ?? "",
        categories[1] ?? "",
        categories[2] ?? ""
      )
    );
    let tableData: RowType[] = [];
    if (Array.isArray(returnValue) && returnValue.length > 0) {
      returnValue
        .filter((data) => data.type?.includes(selectParam))
        .map((mailTarget: ApplicationInfo) => {
          const rowData: RowType = {
            company: mailTarget.name || "",
            email: mailTarget.applicants![0].businessCardId?.email || "",
            name: mailTarget.name || "",
            type: mailTarget.type === "seller" ? "셀러" : "바이어",
            applicantId: mailTarget.applicantId || "",
          };
          tableData.push(rowData);
        });
    }

    setState({ columns: state.columns, data: tableData });
    dispatch(setTableInfoLoadingAction(false));
  };
  // 메일 대상 선택
  const changeTarget = (changeData: RowType[]) => {
    let targetArray: MailRecipient[] = [];
    changeData.map((data: RowType) => {
      targetArray.push({
        email: data.email,
        company: data.company,
        name: data.name,
        recipientId: data.applicantId,
      });
    });
    setMail({ ...mail, recipient: targetArray });
  };

  // 메일 내용 변경
  const handleOnChange = (
    e: React.ChangeEvent<{
      value: unknown;
      name?: string | undefined;
    }>
  ) => {
    setMail({
      ...mail,
      [e.target.name as string]: e.target.value as string,
    });
  };

  // 이벤트 컨텐츠 내용(에디터) 변경
  const handleContentChange = (value: string) => {
    //태그 넣어보기
    setMail({
      ...mail,
      content: value as string,
    });
  };
  // 메일 발송
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMail({ ...mail, senderName: mail.senderName?.trim() });
    confirmAlert(
      confirmModalOptions({
        title: "메일을 발송하시겠습니까??",
        click: async () => {
          dispatch(setLoadingAction());
          const mailRes = await dispatch(createMail(mail));
          dispatch(removeLoadingAction());
          if (mailRes.toString() === "success") {
            dispatch(setDialog(`메일 발송`, ["메일이 발송되었습니다."]));
          }
        },
      })
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <SubTitle>메일 보내기</SubTitle>
      <EventCategory categories={categories} setCategory={setCategories} limit={3} />
      <div
        style={{
          width: "100%",

          margin: 5,
        }}
      >
        <PxOutlinedFormControl>
          <PxSelectBox
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px",
            }}
            value={selectParam}
            onChange={(e) => setSelectParam(e.target.value as string)}
            displayEmpty
            input={<OutlinedInput margin="dense" />}
          >
            <MenuItem value={""}>전체</MenuItem>
            <MenuItem value={"seller"}>SELLER</MenuItem>
            <MenuItem value={"buyer"}>BUYER</MenuItem>
          </PxSelectBox>
        </PxOutlinedFormControl>
      </div>
      <Grid
        container
        direction="row"
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          minHeight: "60px",
        }}
      >
        <PxTable<RowType>
          // components={{
          //   Toolbar: (props) => {
          //     return (
          //       <PxGridContainer direction="column">
          //         {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
          //         <MTableToolbar {...props} />
          //       </PxGridContainer>
          //     );
          //   },
          // }}
          title=""
          columns={state.columns}
          data={state.data}
          // material-table 속성: https://material-table.com/#/docs/features/search
          options={{
            toolbar: true,
            actionsColumnIndex: -1,
            paging: false,
            headerStyle: { position: "sticky", top: 0 },
            maxBodyHeight: "300px",
            // pageSize: 10,
            showTitle: true,
            defaultExpanded: false,
            search: false,
            selection: true,
            searchFieldAlignment: "right",
            exportButton: false, // csv 다운
          }}
          onSelectionChange={(rows) => changeTarget(rows)}
        />
      </Grid>

      <SubTitle>발신자 </SubTitle>

      <Grid container direction="row">
        <OutlinedTxtField
          fullWidth
          onChange={handleOnChange}
          name="senderName"
          value={mail.senderName}
          placeholder={"발신자명을 입력해주세요"}
        />
      </Grid>
      <SubTitle>수신자</SubTitle>
      <Grid
        container
        direction="row"
        style={{
          padding: "10px",
          minHeight: "60px",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        }}
      >
        {mail.recipient !== undefined && mail.recipient.length > 0 ? (
          mail.recipient.map((targetId: any, idx) => (
            <Chip
              key={`email-${idx}`}
              // avatar={<Avatar>M</Avatar>}
              label={targetId.email}
              // clickable
              color="primary"
              //onDelete={handleDelete}
              // deleteIcon={<DoneIcon />}
              variant="outlined"
              style={{ margin: "3px 10px 3px 0" }}
            />
          ))
        ) : (
          <div style={{ alignSelf: "center", color: "#888" }}>{}</div>
        )}
      </Grid>
      <SubTitle> 제목</SubTitle>
      <Grid container direction="row">
        <OutlinedTxtField
          fullWidth
          onChange={handleOnChange}
          name="title"
          value={mail.title}
          placeholder={"제목을 입력해주세요"}
        />
      </Grid>
      <SubTitle> {"내용"}</SubTitle>
      {/* <Body1Typo>{t("mailAdd.contentTip")}</Body1Typo> */}
      <PxEditor
        value={mail.content}
        onChange={handleContentChange}
        folderPath="mail/"
        placeholder="메일 내용을 입력하세요."
      />
      <Grid container alignContent="space-between" spacing={1}>
        <Grid item>
          <PxButton type="submit">
            <ButtonTypo txtcolor="white">메일 보내기</ButtonTypo>
          </PxButton>
        </Grid>
        <Grid item>
          <PxButton>
            <ButtonTypo>취소 </ButtonTypo>
          </PxButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SendMail;
