import Country from "../types/models/Country";
import CountryActionTypes from "../types/actions/CountryAction";

const countryDefaultState: Country = {
  country: {
    code: "KR",
    koName: "대한민국",
    enName: "Korean",
    timezone: "Asia/Seoul",
    i18code: "ko",
  },
  countries: [],
  getCountry: {
    businessName: "",
    businessWebsite: "",
    city: "Seoul",
    continent: "Asia",
    country: "South Korea",
    countryCode: "KR",
    ipName: "",
    ipType: "",
    isp: "",
    lat: "",
    lon: "",
    org: "",
    query: "",
    region: "",
    status: "",
    thisCountryTz: "Asia/Seoul",
  },
  getCountries: {
    KR: {
      koName: "대한민국",
      enName: "Korean",
      timezone: "Asia/Seoul",
      i18code: "ko",
    },
  },
};

const countries = (
  state = countryDefaultState,
  action: CountryActionTypes
): Country => {
  switch (action.type) {
    case "SET_COUNTRY":
      return { ...state, getCountry: action.getCountry };
    case "REMOVE_COUNTRY":
      return { ...state, getCountry: state.getCountry };
    case "CREATE_COUNTRY":
    case "GET_COUNTRY":
      return { ...state, country: action.country };
    case "GET_COUNTRIES":
      return { ...state, countries: action.countries };
    default:
      return state;
  }
};

export default countries;
