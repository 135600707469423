import React, { SetStateAction, useCallback } from "react";
import { Grid, Input } from "react-spreadsheet-grid";
import set from "lodash/set";

export interface RowType {
  email: string;
  name: string;
  taxInvoiceMail: string;
  password: string;
  company: string;
  phoneNumber: string;
  position: string;
  countryNumber: string;
}

interface SpreadSheetProps {
  rowData: RowType[];
  setRowData: React.Dispatch<SetStateAction<RowType[]>>;
}

const SpreadSheet = ({ rowData, setRowData }: SpreadSheetProps) => {
  const onFieldChange = useCallback(
    (rowId: string, field: string) => (value: string) => {
      //   Find the row that is being changed
      const row: any = rowData.find(({ email }) => email === rowId);

      //   Change a value of a field
      set(row, field, value);

      setRowData(([] as RowType[]).concat(rowData));
    },
    [rowData, setRowData]
  );

  const initColumns = useCallback(
    () => [
      {
        id: "name",
        title: () => "이름(name)",
        width: 10,
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.name}
              focus={focus}
              onChange={onFieldChange(row.email, "name")}
            />
          );
        },
      },
      {
        id: "email",
        title: () => "이메일(email)",
        width: 10,
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.email}
              focus={focus}
              onChange={onFieldChange(row.email, "email")}
            />
          );
        },
      },
      {
        id: "password",
        title: () => "비밀번호(password)",
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.password}
              focus={focus}
              onChange={onFieldChange(row.email, "password")}
            />
          );
        },
        width: 10,
      },
      {
        id: "company",
        title: () => "회사(company)",
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.company}
              focus={focus}
              onChange={onFieldChange(row.email, "company")}
            />
          );
        },
      },
      {
        id: "countryNumber",
        title: () => "국가번호(countryNumber)",
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.countryNumber}
              focus={focus}
              onChange={onFieldChange(row.email, "countryNumber")}
            />
          );
        },
        width: 12,
      },
      {
        id: "phoneNumber",
        title: () => "전화번호(phoneNumber)",
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.phoneNumber}
              focus={focus}
              onChange={onFieldChange(row.email, "phoneNumber")}
            />
          );
        },
      },
      {
        id: "position",
        title: () => "직책(position)",
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.position}
              focus={focus}
              onChange={onFieldChange(row.email, "taxInvoiceMail")}
            />
          );
        },
        width: 10,
      },
      {
        id: "taxInvoiceMail",
        title: () => "세금계산서메일(taxInvoiceMail)",
        value: (row: RowType, { focus }: any) => {
          return (
            <Input
              value={row.taxInvoiceMail}
              focus={focus}
              onChange={onFieldChange(row.email, "taxInvoiceMail")}
            />
          );
        },
      },
    ],
    [onFieldChange]
  );

  return (
    <>
      <Grid
        columns={initColumns()}
        rows={rowData}
        getRowKey={(row: RowType) => row.email}
      />
    </>
  );
};

export default SpreadSheet;
