import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PxGridContainer from "../../components/Grid/PxGridContainer";
import PxGridItem from "../../components/Grid/PxGridItem";
import { AppState } from "../../store";
import styled from "styled-components";

import { TypographyProps } from "@material-ui/core/Typography";
import PhoneIcon from "@material-ui/icons/Phone";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import { makeStyles } from "@material-ui/core/styles";
import PxButton from "../../components/Buttons/PxButton";
import ButtonTypo from "../../components/Typography/ButtonTypo";
import SubHeadingTypo from "../../components/Typography/SubHeadingTypo";
import { useTranslation } from "react-i18next";
import ShowFile from "../../components/File/ShowFile";
import PxGoogleMap from "../../components/Map/PxGoogleMap";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import PxOutlinedTxtField from "../../components/Inputs/PxOutlinedTxtField";
import { parallaxCalculation } from "../../utils/momentTz";

// subtitle style
type TypoProp = TypographyProps;
const SubTitle: React.FC<TypoProp> = styled((props: TypoProp) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;
const EventContentDiv = styled.div`
  img {
    max-width: 100%;
  }
`;

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginTop: 15,
  },
  eventImage: {
    width: "100%",
    maxHeight: 356,
  },
  contentRoot: {
    flexGrow: 1,
  },
  tabIndicator: {
    backgroundColor: "purple",
  },
}));

const EventInfo = () => {
  const classes = useStyles();
  const history = useHistory();
  const [t] = useTranslation("lang", { useSuspense: false });
  const eventDetail = useSelector((state: AppState) => state.events.event);
  const { getCountries } = useSelector((state: AppState) => state.countries!);

  const timeset = (date: string, countryCode: string) => {
    return parallaxCalculation(
      date,
      "",
      getCountries![countryCode] as any,
      "YYYY-MM-DD HH:mm"
    );
  };

  return eventDetail !== undefined ? (
    <PxGridContainer
      spacing={2}
      direction="column"
      md={8}
      style={{ margin: "auto" }}
    >
      <PxGridItem md={12} fullWidth>
        <SubTitle>주최지</SubTitle>
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <PxOutlinedTxtField
            fullWidth
            padding="0px 0px 8px 0px"
            disabled
            value={
              getCountries![eventDetail.countryCode!] !== undefined
                ? getCountries![eventDetail.countryCode!].koName
                : ""
            }
          />
        </Grid>

        <SubTitle>기간</SubTitle>
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <PxOutlinedTxtField
            fullWidth
            padding="0px 0px 8px 0px"
            disabled
            value={`${timeset(
              eventDetail.startDate,
              eventDetail.countryCode! as string
            )} ~ ${timeset(
              eventDetail.endDate,
              eventDetail.countryCode! as string
            )}`}
          />
        </Grid>

        {eventDetail.location !== "" && eventDetail.locationDetail !== "" && (
          <>
            <SubTitle>장소</SubTitle>
            {/* 지도 */}
            <PxGridItem className="mb-1">
              {eventDetail.lat !== "" && eventDetail.lng !== "" && (
                <PxGoogleMap
                  containerElement={<div style={{ height: `240px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  lat={Number(eventDetail.lat)}
                  lng={Number(eventDetail.lng)}
                  marker={true}
                />
              )}
            </PxGridItem>
            <Grid container direction="row">
              <Grid sm={12} md={12} lg={12}>
                <PxOutlinedTxtField
                  fullWidth
                  padding="0px 0px 8px 0px"
                  disabled
                  value={eventDetail.location}
                />
              </Grid>
              <Grid sm={12} md={12} lg={12}>
                <PxOutlinedTxtField
                  fullWidth
                  disabled
                  value={eventDetail.locationDetail}
                />
              </Grid>
            </Grid>
          </>
        )}

        <SubTitle>개요</SubTitle>
        <Grid
          sm={12}
          md={12}
          lg={12}
          style={{
            border: "1px solid #ccc",
            padding: "10.5px 14px",
            overflow: "scroll",
            maxHeight: "500px",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: eventDetail.content }} />
        </Grid>

        {eventDetail.homePage !== "" && (
          <>
            <SubTitle>웹사이트</SubTitle>
            <Grid container direction="row">
              <Grid sm={12} md={12} lg={12}>
                <PxOutlinedTxtField
                  disabled
                  value={eventDetail.homePage}
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        )}

        {(eventDetail.atchFile1 !== -1 ||
          eventDetail.atchFile2 !== -1 ||
          eventDetail.atchFile3 !== -1 ||
          eventDetail.atchFile4 !== -1 ||
          eventDetail.atchFile5 !== -1) && (
          <>
            <SubTitle>첨부파일</SubTitle>
            <Grid container direction="row">
              <Grid sm={12} md={12} lg={12}>
                {eventDetail.atchFile1 !== -1 && (
                  <ShowFile fileId={eventDetail.atchFile1 as number} />
                )}
                {eventDetail.atchFile2 !== -1 && (
                  <ShowFile fileId={eventDetail.atchFile2 as number} />
                )}
                {eventDetail.atchFile3 !== -1 && (
                  <ShowFile fileId={eventDetail.atchFile3 as number} />
                )}
                {eventDetail.atchFile4 !== -1 && (
                  <ShowFile fileId={eventDetail.atchFile4 as number} />
                )}
                {eventDetail.atchFile5 !== -1 && (
                  <ShowFile fileId={eventDetail.atchFile5 as number} />
                )}
              </Grid>
            </Grid>
          </>
        )}

        {/* 대표 이미지 */}
        <SubTitle>대표이미지</SubTitle>
        <Grid container direction="row">
          <Grid sm={12} md={12} lg={12}>
            <img
              src={
                eventDetail.mainImageFile !== null
                  ? eventDetail.mainImageFile.filePath
                  : eventDetail.image
              }
              alt={
                eventDetail.mainImageFile !== null
                  ? eventDetail.mainImageFile.filePath
                  : eventDetail.image
              }
              style={{ width: "100%", maxWidth: "200px" }}
            />
            <br />
            {eventDetail.mainImageFile !== null &&
              eventDetail.mainImageFile.fileName}
          </Grid>
        </Grid>

        {/* 배너 이미지 */}
        {eventDetail.bannerImageFile !== undefined &&
          eventDetail.bannerImageFile !== null && (
            <>
              <SubTitle>배너이미지</SubTitle>
              <Grid container direction="row">
                <Grid sm={12} md={12} lg={12}>
                  <img
                    src={eventDetail.bannerImageFile.filePath}
                    alt={eventDetail.bannerImageFile.fileName}
                    style={{ width: "100%", maxWidth: "200px" }}
                  />
                  <br />
                  {eventDetail.bannerImageFile.fileName}
                </Grid>
              </Grid>
            </>
          )}

        {/* 담당자 */}
        <Grid container direction="row">
          {Array.isArray(eventDetail.managers) &&
          eventDetail.managers.length > 0
            ? eventDetail.managers.map((evManagers: any, idx: number) => (
                <Fragment key={`manager-${idx}`}>
                  <Grid
                    container
                    alignContent="space-between"
                    spacing={1}
                    style={{
                      margin: "32px 0 0 0",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <Grid>
                      <SubTitle>담당자</SubTitle>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid sm={12} md={12} lg={12}>
                      <PxOutlinedTxtField
                        fullWidth
                        padding="0px 0px 8px 0px"
                        // propPadding="10.5px 0"
                        value={evManagers.name}
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon
                                style={{
                                  color: "#ccc",
                                  width: "18px",
                                  height: "18px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid sm={12} md={12} lg={12}>
                      <PxOutlinedTxtField
                        fullWidth
                        padding="0px 0px 8px 0px"
                        // propPadding="10.5px 0"
                        value={evManagers.email}
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon
                                style={{
                                  color: "#ccc",
                                  width: "18px",
                                  height: "18px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid container alignContent="space-between" spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                        <PxOutlinedTxtField
                          fullWidth
                          padding="0px 0px 8px 0px"
                          // propPadding="10.5px 0"
                          value={evManagers.phone}
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon
                                  style={{
                                    color: "#ccc",
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                        <PxOutlinedTxtField
                          fullWidth
                          padding="0px 0px 8px 0px"
                          // propPadding="10.5px 0"
                          value={evManagers.countryNumber}
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon
                                  style={{
                                    color: "#ccc",
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              ))
            : null}
        </Grid>
      </PxGridItem>
      {eventDetail?.approval !== "D" && (
        <>
          <PxButton
            backgroundColor="purple"
            onClick={() => history.push(`/event/modify/${eventDetail.id}`)}
          >
            <ButtonTypo>이벤트 수정</ButtonTypo>
          </PxButton>{" "}
        </>
      )}
    </PxGridContainer>
  ) : null;
};

export default EventInfo;
