import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MailList from "./MailList";
import SystemMailList from "./SystemMailList";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typography/ButtonTypo";
import { useTranslation } from "react-i18next";

const MailTab: React.FC<RouteComponentProps> = ({ match }) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const [tapNumber, setTapNumber] = React.useState(
    JSON.parse(window.localStorage.getItem("mailTab") || "1")
  );
  const handleChange = (event: React.ChangeEvent<{}>, newTapNumber: number) => {
    setTapNumber(newTapNumber);
    window.localStorage.setItem("mailTab", JSON.stringify(newTapNumber));
  };

  return (
    <>
      <Paper square style={{ margin: "20px 0 0" }}>
        <Tabs
          value={tapNumber}
          TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="mail" value={1} />
          <Tab label="system mail" value={2} />

          <div
            style={{
              position: "absolute",
              right: 4,
              top: 4,
              textAlign: "right",
            }}
          >
            <PxButton
              backgroundColor="purple"
              onClick={() => history.push(`${match.url}/add`)}
            >
              <ButtonTypo>{t("mailList.send")}</ButtonTypo>
            </PxButton>
          </div>
        </Tabs>
      </Paper>
      {tapNumber === 1 ? (
        <MailList match={match} />
      ) : (
        <SystemMailList match={match} />
      )}
    </>
  );
};
export default MailTab;
