import React from "react";
import styled, { css } from "styled-components";

// material ui comp
import Button, { ButtonProps } from "@material-ui/core/Button";

interface PxButtonProps {
  padding?: string;
  backgroundColor?:
    | "purple"
    | "grey"
    | "bluePurple"
    | "sapphire"
    | "default"
    | "blueGrey"
    | "#512da8"
    | "#f1f2f5"
    | "black.87"
    | "transparent"
    | "pale-grey";
  boxshadow?: boolean;
  hover?: boolean;
  txtColor?: "purple" | "white" | "black60" | "bluePurple" | "warning";
  fontWeight?: number;
  border?: boolean;
  borderColor?: "grey";
  noBorderRadius?: boolean;
  margin?: string;
  outline?: boolean;
}

type Prop = PxButtonProps & ButtonProps;

const PxButton: React.FC<Prop> = styled((props: Prop) => {
  const {
    margin,
    backgroundColor,
    boxshadow,
    padding,
    fontWeight,
    noBorderRadius,
    ...rest
  } = props;
  return <Button variant="contained" {...rest} />;
})`
  padding: ${(props) => props.padding ?? "10px 24px 10px 24px"};
  margin: ${(props) => props.margin ?? "0"};
  outline: none !important;
  background-color: ${(props) => {
    if (props.backgroundColor === "purple") return "#5f4b8b";
    else if (props.backgroundColor === "bluePurple") return "rgb(81, 45, 168)";
    else if (props.backgroundColor === "grey") return "#eeeeee";
    else if (props.backgroundColor === "sapphire")
      return "rgba(69, 39, 160,0.08)";
    else if (props.backgroundColor === "blueGrey") return "#637381";
    else if (props.backgroundColor === "#512da8") return "#512da8";
    else if (props.backgroundColor === "#f1f2f5") return "#f1f2f5";
    else if (props.backgroundColor === "black.87") return "rgba(0, 0, 0, 0.87)";
    else if (props.backgroundColor === "transparent") return "transparent";
    else if (props.backgroundColor === "pale-grey") return "#f6f7fa";
    return "white";
  }};
  font-weight: ${(props) => {
    if (props.fontWeight) {
      return props.fontWeight;
    }
    return "normal";
  }};

  color: ${(props) => {
    if (props.txtColor === "purple") return "rgb(81, 45, 168)";
    else if (props.txtColor === "black60") return "rgba(0, 0, 0, 0.6)";
    else if (props.txtColor === "white") return "#ffffffff";
    else if (props.txtColor === "bluePurple") return "rgb(81, 45, 168)";
    else if (props.txtColor === "warning") return "#d32f2f";
    return "black";
  }};
  border: ${(props) => {
    if (props.border) return "solid 1px";
    return "none";
  }};
  border-color: ${(props) => {
    if (props.borderColor === "grey") return "#eeeeee";
    return "none";
  }};
  border-radius: ${(props) => {
    if (props.noBorderRadius) return "0";
    return "4px";
  }};
  &:hover {
    ${(props) => {
      if (props.hover === false) {
        return css`
          background-color: transparent;
          box-shadow: none;
        `;
      }
    }}
  }
  &:focus {
    outline: ${(props) => (props.outline === false ? "none" : "")};
  }
  ${(props) => {
    if (
      props.backgroundColor === "purple" ||
      props.backgroundColor === "blueGrey"
    ) {
      return css`
        & p {
          color: white;
        }
      `;
    }
    if (props.boxshadow === false) {
      return css`
        box-shadow: none;
      `;
    }
  }};
`;

PxButton.defaultProps = {
  boxshadow: false,
  backgroundColor: "default",
};

export default PxButton;
