import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { Column, MTableToolbar } from "material-table";

// actions
import { AppState } from "../../../../store";

// custom ui comp
import PxTable from "../../../../components/Tables/PxTable";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typography/ButtonTypo";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import { getWebinars } from "../../../../actions/webinar";

import { useTranslation } from "react-i18next";
interface RowType {
  title: string;
  date: string;
  presenters: string;
  createdDate: string;
  modifiedDate: string;
  id: string;
}

interface TableStateType {
  columns: Array<Column<RowType>>;
  data: RowType[];
}

const WebinarList: React.FC<RouteComponentProps> = ({ match }) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const history = useHistory();
  const dispatch = useDispatch();
  const param: { id: string } = useParams();
  const webinars = useSelector((state: AppState) => state.webinars.webinars);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("webinarAdd.session"),
        field: "title",
        cellStyle: {
          width: "calc(100%-600px)",
          paddingRight: 0,
          maxWidth: "500px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: t("webinarAdd.date"),
        field: "date",
        cellStyle: {
          width: "200px",
        },
      },
      {
        title: t("webinarAdd.presenter"),
        field: "presenters",
        cellStyle: { width: "120px" },
      },
      {
        title: t("common.registerDate"),
        field: "createdDate",
        cellStyle: { width: "110px" },
      },
      {
        title: t("common.reviseDate"),
        field: "modifiedDate",
        cellStyle: { width: "110px" },
      },
    ],
    data: [],
  });

  const callGetWebinars = useCallback(async () => {
    await dispatch(getWebinars(param.id));
    dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  useEffect(() => {
    callGetWebinars();
  }, [callGetWebinars]);

  useEffect(() => {
    if (webinars.length > 0) {
      const tableData: RowType[] = webinars.map((webinar) => ({
        title: webinar.title,
        date: `${moment(webinar.startDate).format("YYYY-MM-DD")} ~ ${moment(
          webinar.endDate
        ).format("YYYY-MM-DD")}`,
        presenters: webinar.presenters ? webinar.presenters[0].name : "",
        createdDate: moment(webinar.createdDate).format("YYYY-MM-DD"),
        modifiedDate: webinar.modifiedDate
          ? moment(webinar.createdDate).format("YYYY-MM-DD")
          : "",
        id: webinar.id!,
      }));

      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: tableData };
      });
    }
  }, [webinars]);

  return (
    <>
      <div style={{ textAlign: "right", margin: "10px" }}>
        <PxButton
          backgroundColor="purple"
          onClick={() => history.push(`${match.url}/add`)}
        >
          <ButtonTypo>{t("common.add")}</ButtonTypo>
        </PxButton>
      </div>

      <PxTable<RowType>
        components={{
          Toolbar: (props) => {
            return (
              <PxGridContainer direction="column">
                {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                <MTableToolbar {...props} />
              </PxGridContainer>
            );
          },
        }}
        columns={state.columns}
        data={state.data}
        onRowClick={(evt, selectedRow) => {
          history.push(`${match.url}/${selectedRow?.id}`);
        }}
        title=""
        // material-table 속성: https://material-table.com/#/docs/features/search
        options={{
          toolbar: true,
          actionsColumnIndex: -1,
          pageSize: 10,
          showTitle: false,
          defaultExpanded: false,
          search: true,
          selection: false,
          searchFieldAlignment: "right",
          exportButton: false, // csv 다운
        }}
      />
    </>
  );
};

export default WebinarList;
