import LoadingActionTypes from "../types/actions/LoadingAction";

interface loadingReduxStateType {
  loading: boolean;
  backdropLoading: boolean;
}

const loadingDefaultState: loadingReduxStateType = {
  loading: false,
  backdropLoading: false,
};

const userReducer = (
  state = loadingDefaultState,
  action: LoadingActionTypes
): loadingReduxStateType => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: true };
    case "REMOVE_LOADING":
      return { ...state, loading: false };
    case "SET_BACKDROP_LOADING":
      return { ...state, backdropLoading: true };
    case "REMOVE_BACKDROP_LOADING":
      return { ...state, backdropLoading: false };
    default:
      return state;
  }
};

export default userReducer;
