import React from "react";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../components/Grid/PxGridItem";
import PxInputBase from "../../../components/Inputs/PxInputBase";
import PxOutlinedTxtField from "../../../components/Inputs/PxOutlinedTxtField";

// types
import { AutonoForm } from "../../../types/models/AutonoForm";

// material comp
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import PxRadioButton from "../../../components/Radios/PxRadioButton";
import PxCheckBox from "../../../components/Checkboxes/PxCheckBox";
import PxButton from "../../../components/Buttons/PxButton";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";

const FormPreviewItem: React.FC<AutonoForm> = (props) => {
  const {
    // id,
    type,
    title,
    explain,
    explainCheck,
    // secretCheck,
    // requireCheck,
    // index,
    selectContent,
  } = props;

  return (
    <PxGridContainer className="py-3 pl-3" direction="column">
      <PxGridItem>
        <PxInputBase
          name="title"
          value={title === "" ? "제목을 입력해주세요." : `${title}`}
          fullWidth
          fontsize={15}
          readOnly={true}
          fontweight="bold"
          autoComplete="off"
        />
      </PxGridItem>
      {explainCheck === "Y" && (
        <PxGridItem>
          <PxInputBase
            name="explain"
            value={explain}
            fullWidth
            placeholder="설명을 입력해주세요."
            fontsize={14}
            fontweight="bold"
            readOnly
          />
        </PxGridItem>
      )}
      {(type === "long" ||
        type === "short" ||
        type === "url" ||
        type === "keyword") && (
        <PxGridItem>
          <PxOutlinedTxtField
            inputpadding={type === "long" ? 0 : 10}
            multiline={type === "long" ? true : false}
            rows={2}
            fullWidth
            placeholder="입력해주세요."
            fontsize={14}
          />
        </PxGridItem>
      )}
      {(type === "single" || type === "category") && (
        <PxGridItem>
          <FormControl>
            <RadioGroup>
              {selectContent!.formIds.map((formId, index) => (
                <PxRadioButton
                  value={
                    selectContent!.formData[formId].explain! === ""
                      ? "선택할 각 항목을 입력해주세요."
                      : selectContent!.formData[formId].explain!
                  }
                  label={
                    selectContent!.formData[formId].explain! === ""
                      ? "선택할 각 항목을 입력해주세요."
                      : selectContent!.formData[formId].explain!
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </PxGridItem>
      )}
      {type === "many" && (
        <PxGridItem>
          <FormControl>
            <RadioGroup>
              {selectContent!.formIds.map((formId, index) => (
                <PxCheckBox
                  label={
                    selectContent!.formData[formId].explain! === ""
                      ? "선택할 각 항목을 입력해주세요."
                      : selectContent!.formData[formId].explain!
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </PxGridItem>
      )}
      {type === "file" && (
        <PxGridItem>
          <PxButton backgroundColor="grey">추가하기</PxButton>
        </PxGridItem>
      )}
      {type === "star" && (
        <PxGridItem>
          <Box
            borderColor="grey.500"
            borderRadius={5}
            border={1}
            style={{ textAlign: "center" }}
          >
            <Rating size="large" className="mt-1" />
          </Box>
        </PxGridItem>
      )}
    </PxGridContainer>
  );
};

export default FormPreviewItem;

// const setRadioGroupData = () => {
//   const radioData: RadioData[] = selectContent!.formIds.map(
//     (formId, index) => {
//       return {
//         value:
//           selectContent!.formData[formId].explain! === ""
//             ? "선택할 각 항목을 입력해주세요."
//             : selectContent!.formData[formId].explain!,
//         label:
//           selectContent!.formData[formId].explain! === ""
//             ? "선택할 각 항목을 입력해주세요."
//             : selectContent!.formData[formId].explain!,
//       };
//     }
//   );
//   return <PxRadioGroup value={""} data={radioData} radiogroupname={title!} />;
// };
