import Chip from "@material-ui/core/Chip";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { eventCategoryArray } from "../../types/models/Event";

interface EventCategoryProps {
  categories: string[];
  setCategory: React.Dispatch<React.SetStateAction<string[]>>;
  limit?: number;
}

const EventCategory = ({ categories, setCategory, limit }: EventCategoryProps) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const clickCategory = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const category = event.currentTarget.id;
      if (categories.some((ca) => ca === category)) {
        setCategory(categories.filter((ca) => ca !== category));
      } else if (!limit || limit > categories.length) {
        console.log("dfs");
        setCategory([...categories, category]);
      }
    },
    [categories]
  );
  return (
    <div>
      {eventCategoryArray.map((category: string) => (
        <Chip
          id={category}
          style={
            categories.some((ca) => ca === category)
              ? {
                  backgroundColor: "rgb(95, 75, 139)",
                  color: "white",
                  margin: "4px",
                  fontSize: "14px",
                }
              : { margin: "4px", fontSize: "14px" }
          }
          label={category}
          onClick={clickCategory}
        />
      ))}
    </div>
  );
};

export default EventCategory;
