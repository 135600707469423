import React, { useCallback, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import SpreadSheet, { RowType } from "../views/UserRegit/SpreadSheet";
import Button from "@material-ui/core/Button";
import xlsx from "xlsx";
import { makeStyles } from "@material-ui/core/styles";
import TitleTypo from "../components/Typography/TitleTypo";
import PxGridContainer from "../components/Grid/PxGridContainer";
import CaptionTypo from "../components/Typography/CaptionTypo";
import PxGridItem from "../components/Grid/PxGridItem";
import { createUsers } from "../actions/users";
import { useDispatch, useSelector } from "react-redux";
import User from "../types/models/User";
import {
  removeBackDropLoadingAction,
  setBackDropLoadingAction,
} from "../actions/loading";
import PxBackDrop from "../components/BackDrop/PxBackDrop";
import { AppState } from "../store";
import PxDialog from "../components/Dialog/PxDialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import SubHeadingTypo from "../components/Typography/SubHeadingTypo";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
  },
  uploadButton: {
    boxShadow: "none",
    backgroundColor: "#eee",
    padding: "5px 10px",
  },
  regitButton: {
    boxShadow: "none",
    backgroundColor: "#5f4b8b",
    padding: "5px 10px",
    color: "white",
    marginTop: 10,
  },
  input: {
    display: "none",
  },
  spanTitle: {
    color: "blue",
  },
  spanImportant: {
    color: "red",
  },
  resultDialog: {
    minWidth: 150,
  },
  failedUserList: {
    maxHeight: 300,
    overflow: "scroll",
  },
}));

const UserRegit = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const users = useSelector((state: AppState) => state.users.users);

  const [rowData, setRowData] = useState<RowType[]>([
    {
      email: "",
      name: "",
      taxInvoiceMail: "",
      password: "",
      company: "",
      phoneNumber: "",
      position: "",
      countryNumber: "",
    },
  ]);
  const [userData, setUserData] = useState<User[]>([]);
  const [resultDialogOpen, setResultDialogOpen] = React.useState(false);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target.files;
    if (files !== null) {
      const f = files![0];
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = e.target!.result;
        const readedData = xlsx.read(data, { type: "binary" });
        const sheetName = readedData.SheetNames[0];
        const sheetData = readedData.Sheets[sheetName];

        /* Convert array to json*/
        const dataParse = xlsx.utils.sheet_to_json(sheetData);
        setRowData(dataParse as RowType[]);
      };
      reader.readAsBinaryString(f);
    }
  };

  const onClickRegiterUsers = async () => {
    const users: User[] = rowData.map((row) => ({
      email: row.email,
      password: row.password,
      name: row.name,
      company: row.company,
      position: row.position,
      countryNumber: row.countryNumber,
      phoneNumber: row.phoneNumber,
    }));
    setUserData(users);
    dispatch(setBackDropLoadingAction());
    await dispatch(createUsers(users));
    dispatch(removeBackDropLoadingAction());
    setResultDialogOpen(true);
  };

  const resultDialogClose = () => {
    setResultDialogOpen(false);
  };

  const setDialogContent = useCallback(() => {
    // userData: 전체유저
    // users: 성공한 유저
    // 실패한 데이터
    const failedData = userData
      .map((user) => {
        if (users?.find(({ email }) => user.email === email) === undefined) {
          return user;
        }
        return undefined;
      })
      .filter((data) => data !== undefined);

    return (
      <div className={classes.resultDialog}>
        <SubHeadingTypo>성공: {users!.length}</SubHeadingTypo>
        <SubHeadingTypo>실패: {failedData.length}</SubHeadingTypo>
        <div className={classes.failedUserList}>
          {failedData.map((failedUser) => (
            <CaptionTypo>{failedUser?.email}</CaptionTypo>
          ))}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, userData]);

  return (
    <Container className={classes.container}>
      <PxBackDrop />
      <PxDialog title="등록결과" open={resultDialogOpen} onClose={() => {}}>
        <DialogContent>{setDialogContent()}</DialogContent>
        <DialogActions>
          <Button onClick={resultDialogClose} color="primary" autoFocus>
            확인
          </Button>
        </DialogActions>
      </PxDialog>
      <PxGridContainer justify="space-between">
        <TitleTypo>유저등록</TitleTypo>
        <Button
          variant="contained"
          component="label"
          className={classes.uploadButton}
        >
          엑셀파일 불러오기
          <input
            type="file"
            name="file1"
            onChange={handleUpload}
            className={classes.input}
          />
        </Button>
      </PxGridContainer>
      <PxGridContainer direction="row" justify="space-between">
        <PxGridItem>
          <CaptionTypo>
            <span className={classes.spanTitle}>파일형식:</span>{" "}
            <span className={classes.spanImportant}>xlsx(엑셀)</span> 로 해야함.
            - 다른 형식으로 불러올 경우 한글이 깨짐
          </CaptionTypo>
          <CaptionTypo>
            <span className={classes.spanTitle}>엑셀파일 구조:</span>{" "}
            <span className={classes.spanImportant}>첫행</span>은 무조건 아래
            보이는 1행의 가로안에 있는 영문(name, email, password ...)로
            입력하고 그에 맞게 값을 채워줘야함.
          </CaptionTypo>
          <CaptionTypo>
            <span className={classes.spanTitle}>회원가입</span> 시 필요한 형식을
            똑같이 맞춰야함. ex)패스워드 대소문자 포함 6자이상
          </CaptionTypo>
        </PxGridItem>
        <PxGridItem>
          <Button className={classes.regitButton} onClick={onClickRegiterUsers}>
            등록하기
          </Button>
        </PxGridItem>
      </PxGridContainer>
      <SpreadSheet rowData={rowData} setRowData={setRowData} />
    </Container>
  );
};

export default UserRegit;
