import React from "react";
import { RouteComponentProps } from "react-router-dom";

import MeetingHistoryList from "./MeetingHistoryList";

const MeetingHistory: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <MeetingHistoryList />
    </>
  );
};
export default MeetingHistory;
