import React from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../store";

export default ({
  sGroupName,
  bGroupName,
}: {
  sGroupName: string;
  bGroupName: string;
}) => {
  const meetingTotalCount = useSelector(
    (state: AppState) => state.meetings.meetingTotalCount
  );
  const [t] = useTranslation("lang", { useSuspense: false });

  return meetingTotalCount ? (
    <Table>
      <tr>
        <Th></Th>
        <Th>{t("meetingDetail.requesting")}</Th>
        <Th>{t("meetingDetail.awaiting")}</Th>
        <Th>{t("meetingDetail.setUnavailable")}</Th>
        <Th>{t("meetingDetail.conclusioned")}</Th>
      </tr>
      <tr>
        {/* 바이어 */}
        <Th>{bGroupName}</Th>
        <Td>{meetingTotalCount.bgroupApplicantCount}</Td>
        <Td>{meetingTotalCount.bgroupWaitingAcceptorCount}</Td>
        <Td>{meetingTotalCount.bgroupImpossibleCount}</Td>
        <Td rowSpan={2}>{meetingTotalCount.totalAgreeCount / 2}</Td>
      </tr>
      <tr>
        {/* 셀러 */}
        <Th style={{ padding: "10px 5px 20px 5px" }}>{sGroupName}</Th>
        <LastTd>{meetingTotalCount.sgroupApplicantCount}</LastTd>
        <LastTd>{meetingTotalCount.sgroupWaitingAcceptorCount}</LastTd>
        <LastTd>{meetingTotalCount.sgroupImpossibleCount}</LastTd>
      </tr>
    </Table>
  ) : null;
};

const Table = styled.table`
  width: 100%;
  border: solid 2px #e9eaed;
  text-align: center;
  border-collapse: collapse;
  overflow: hidden;
  padding: 20px;
`;

const Th = styled.th`
  &:not(:first-child) {
    border-left: solid 2px #e9eaed;
    padding: 20px 0px 10px;
  }
  &:first-child {
    width: 155px;
  }
`;

const Td = styled.td`
  &:not(:first-child) {
    border-left: solid 2px #e9eaed;
  }
  padding: 10px 5px;
`;

const LastTd = styled.td`
  &:not(:first-child) {
    border-left: solid 2px #e9eaed;
  }
  padding: 10px 5px 20px 5px;
`;
