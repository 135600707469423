import moment from "moment";
import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PxButton from "../../../../components/Buttons/PxButton";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import ButtonTypo from "../../../../components/Typography/ButtonTypo";
import CaptionTypo from "../../../../components/Typography/Caption2Typo";
import { SubEventInfo } from "../../../../types/models/SubEvent";

interface Props {
  bgColor: string;
  body: ReactNode;
  title: ReactNode;
  IconNode: ReactNode;
  estimateLink: string;
  quoationLink?: string;
  actionBtnTypo?: string;
  subEvent: SubEventInfo;
}

export default (props: Props) => {
  const {
    bgColor,
    title,
    body,
    IconNode,
    quoationLink,
    estimateLink,
    actionBtnTypo,
    subEvent,
  } = props;
  const history = useHistory();

  return (
    <Container>
      <Content>
        {
          <div>
            <Title>{title}</Title>
            <Body>{body}</Body>
          </div>
        }
      </Content>
      <ButtonContainer>
        <PxGridContainer justify="center" alignItems="center">
          <CaptionTypo>
            {subEvent.estimates?.some((data) => data.type === "estimate")
              ? moment(
                  subEvent.estimates?.find((data) => data.type === "estimate")?.modifiedDate
                ).format("yyyy-MM-DD HH:mm")
              : "없음"}
          </CaptionTypo>

          <PxButton backgroundColor="#f1f2f5" onClick={() => history.push(estimateLink)} fullWidth>
            <ButtonTypo txtcolor="black0.6">예상견적</ButtonTypo>
          </PxButton>
        </PxGridContainer>
        <PxGridContainer justify="center">
          <CaptionTypo>
            {subEvent.estimates?.some((data) => data.type === "quoation")
              ? moment(
                  subEvent.estimates?.find((data) => data.type === "quoation")?.modifiedDate
                ).format("yyyy-MM-DD HH:mm")
              : "없음"}
          </CaptionTypo>
          {quoationLink && (
            <PxButton
              className="last"
              backgroundColor="black.87"
              onClick={() => history.push(quoationLink)}
              fullWidth
              txtColor="white"
            >
              <ButtonTypo txtcolor="white">{actionBtnTypo}</ButtonTypo>
            </PxButton>
          )}
        </PxGridContainer>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 26px 40px 26px 48px;
  border-radius: 3px;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.12);
  margin-bottom: 32px;
  &:not(:first-child) {
    margin-bottom: 8px !important;
  }
  &:not(:first-child) {
    margin-top: 32px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-width: 339px;
  & .last {
    margin-left: 8px;
  }
`;

const Title = styled.div`
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.87);
`;
const Body = styled.span`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
`;
