import { Dispatch } from "react";
import axios, { AxiosResponse } from "axios";
import { AppActions, AppState } from "../store";
import { setAlertAction, removeAlertAction } from "./alerts";
import Mail, { MailInfo, MailTargetInfo, PaginationMail, SendGridMailInfo } from "../types/models/Mail";
import Alert from "../types/models/Alert";
import Error from "../types/models/Error";
import { getRequest, setAlert } from "../utils/utils";
import { ApplicationInfo } from "../types/models/Application";

export const createMailAction = (mail: MailInfo): AppActions => ({
  type: "CREATE_MAIL",
  mail,
});

export const getMailsAction = (mails: PaginationMail): AppActions => ({
  type: "GET_MAILS",
  mails,
});

export const getMailAction = (mail: MailInfo): AppActions => ({
  type: "GET_MAIL",
  mail,
});

export const getMailTargetAction = (
  mailTargets: MailTargetInfo[]
): AppActions => ({
  type: "GET_MAIL_TARGETS",
  mailTargets,
});

export const getSendGridMailsAction= (
  sendGridMails : Mail['sendGridMails']
):AppActions => ({
  type:"GET_SENDGRID_MAILS",
  sendGridMails,
})

export const createMail = (mail: MailInfo) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
  };
  const body = JSON.stringify(mail);

  const path = `/api/mails`;
  try {
    var res: AxiosResponse<Event> = await axios.post(path, body, config);

    return "success";
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
    return "error";
  }
};

export const getMails = <Mail>(subEventId: string,page :number = 0, pageSize:number =10,searchWord:string = "") => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/mails?subEventId=${subEventId}&page=${page}&size=${pageSize}&searchWord=${searchWord}`;
  const sendGrid = getState().mails.sendGridMails;
  const sendGridConfig = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_SENDGRID}`
    },}
  
  try {
    if (sendGrid && ( sendGrid.content.length === 0 || page  - sendGrid.page > 9 )){
      const sendGridpage = page === 0 ? 0 :Math.floor(( page - sendGrid.page) / 10)
      const sendGridResult = await axios.get(`https://api.sendgrid.com/v3/messages?limit=${pageSize * 10}&offset=${sendGridpage}&query=(Contains(categories%2C%22sbId%26%26${subEventId}%22))`,sendGridConfig);
      
      dispatch(getSendGridMailsAction({content: sendGridpage ===0 ? sendGridResult.data.messages : [...sendGrid.content,sendGridResult.data.messages], page:page}))
    }  
    const res: AxiosResponse<PaginationMail> = await axios.get(path, config) ;
    dispatch(getMailsAction(res.data));
    return res.data;
  } catch (err) {
    console.log(err)
    const error: Error = err.response;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const getSystemMails = <Mail>(subEventId: string,page :number = 0,pageSize=10, searchWord:string = "") => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/mails/getSystemMails?subEventId=${subEventId}&page=${page}&size=${pageSize}&searchWord=${searchWord}`;
  try {
    let res: AxiosResponse<PaginationMail> = await axios.get(path, config) ;
    dispatch(getMailsAction(res.data));
    return res.data
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const getMail = <Mail>(mailId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/mails/detail?mailId=${mailId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getMailAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const getMailTargets = <Mail>(subEventId: string) => async (
  dispatch: Dispatch<AppActions>,
  getState: () => AppState
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "ko",
    },
    data: {}, // get 호출시 필요
  };
  const path = `/api/mails/targetList?subEventId=${subEventId}`;
  try {
    var res: AxiosResponse = await axios.get(path, config);
    dispatch(getMailTargetAction(res.data));
  } catch (err) {
    const error: Error = err.response.data;
    const alert: Alert = setAlert(err.response.status, error, path);
    dispatch(setAlertAction(alert));
    setTimeout(() => {
      dispatch(removeAlertAction(alert.id));
    });
  }
};

export const getUserListByEventCategories = (g:string ="", category1:string="",category2:string="",category3:string="") => {
  return getRequest<ApplicationInfo[]>(undefined,`/api/applications/getEventCategoriesUserList`,undefined,{g,category1,category2,category3})
}