import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCounselsAnswers } from "../../../../actions/counsels";
import { ExportCSV } from "../../../../components/ExportCSV/ExportCSV";
import HeadingTypo from "../../../../components/Typography/HeadingTypo";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { categoryAndCheckboxSelectTextToLinkAndText } from "../../../../utils/utils";
import { CircularProgress } from "@material-ui/core";
import { CounselInfo, MeetingSurvey } from "../../../../types/models/Counsel";
import { useTranslation } from "react-i18next";
import axios from "axios";

interface MeetingSurveyExcelRefinedData {
  name?: string;
  managerName?: string;
  modifiedDate?: string;
  otherCompanyName?: string;
  otherCompanyManagerName?: string;
  survey?: MeetingSurvey[];
  type?: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  status?: string;
}

const MeetingSurveyExcel = ({
  classes,
}: {
  classes: Record<"root" | "companyDiv" | "radioForm" | "meetingDiv", string>;
}) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const params: { id: string } = useParams(); // subEventId
  const [data, setData] = useState<{
    // 엑셀 export data
    data: any[];
    fileName: string;
  }>(null!);
  const [filteredData, setFilteredData] = useState<{
    // 라디오 or 체크박스 값에 따라 필터된 excel로 export할 데이터
    buyer: any[];
    seller: any[];
    fileName: string;
  }>(null!);
  const [groupNames, setGroupNames] = useState<{
    // 전체 데이터
    buyer: string;
    seller: string;
  }>();
  // const [radioValue, setRadioValue] = useState<string>("seller"); // 구분 radio value

  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  // 전체 미팅을 가져옴 -> 미팅에 걸린 상담일지와 같이 데이터 가공 후 excel로 export 할 data 생성
  const getMeetingSurveys = useCallback(async () => {
    const result: CounselInfo[] = await getCounselsAnswers(params.id);
    const acceptorMeetings: MeetingSurveyExcelRefinedData[] = []; // 미팅 수락자 미팅데이터와 상담일지 데이터
    const applicantMeetings: MeetingSurveyExcelRefinedData[] = []; // 미팅 신청자 미팅데이터와 상담일지 데이터
    const meetingsWithSurveys: MeetingSurveyExcelRefinedData[] = []; // 전체 미팅데이터와 상담일지 데이터

    // excel data로 만들기 위한 data 1차 가공
    result.forEach((counsel) => {
      const acceptor: MeetingSurveyExcelRefinedData = {};
      const applicant: MeetingSurveyExcelRefinedData = {};
      acceptor["survey"] = counsel.acceptorMeetingSurvey;
      acceptor["status"] =
        counsel.acceptorMeetingSurvey.length === 0 ? "미작성" : "작성완료";
      acceptor["name"] = counsel.acceptorName;
      acceptor["managerName"] = counsel.meetingAcceptor?.applicants
        ? counsel.meetingAcceptor?.applicants[0].businessCardId!.name
        : "";
      acceptor["type"] = counsel.meetingAcceptor?.type;
      acceptor["otherCompanyName"] = counsel.applicantName;
      acceptor["otherCompanyManagerName"] = counsel.meetingApplicant?.applicants
        ? counsel.meetingApplicant?.applicants[0].businessCardId!.name
        : "";
      acceptor["date"] = counsel.date;
      acceptor["startTime"] = counsel.startTime;
      acceptor["endTime"] = counsel.endTime;
      acceptorMeetings.push(acceptor);

      applicant["survey"] = counsel.applicantMeetingSurvey;
      applicant["status"] =
        counsel.applicantMeetingSurvey.length === 0 ? "미작성" : "작성완료";
      applicant["name"] = counsel.applicantName;
      applicant["managerName"] = counsel.meetingApplicant?.applicants
        ? counsel.meetingApplicant?.applicants[0].businessCardId!.name
        : "";
      applicant["type"] = counsel.meetingApplicant?.type;
      applicant["otherCompanyName"] = counsel.acceptorName;
      applicant["otherCompanyManagerName"] = counsel.meetingAcceptor?.applicants
        ? counsel.meetingAcceptor?.applicants[0].businessCardId!.name
        : "";
      applicant["date"] = counsel.date;
      applicant["startTime"] = counsel.startTime;
      applicant["endTime"] = counsel.endTime;
      applicantMeetings.push(applicant);
    });

    meetingsWithSurveys.push(...applicantMeetings, ...acceptorMeetings);

    // excel로 export 최종 data
    const resultData = meetingsWithSurveys.map((meetingWithSurvey) => {
      const refinedData: any = {}; // 최종 엑셀 데이터
      refinedData["구분"] =
        meetingWithSurvey.type === "seller"
          ? subEvent?.sgroupName
          : subEvent?.bgroupName;
      refinedData["기업명"] = meetingWithSurvey.name;
      refinedData["담당자"] = meetingWithSurvey.managerName;
      refinedData["상대기업"] = meetingWithSurvey.otherCompanyName;
      refinedData["상대기업 담당자"] =
        meetingWithSurvey.otherCompanyManagerName;
      refinedData["미팅날짜"] = meetingWithSurvey.date;
      refinedData["시작시간"] = meetingWithSurvey.startTime;
      refinedData["끝시간"] = meetingWithSurvey.endTime;
      refinedData["작성여부"] = meetingWithSurvey.status;

      meetingWithSurvey.survey!.forEach(async (answer: any) => {
        // 복수선택 답변 일때는 같은 key 값의 value에 해당 답변값(content)를 , 로 붙여줌
        if (
          answer.type === "many" &&
          refinedData[answer.title] !== undefined // 복수선택 값이 처음 들어올때 예외처리
        ) {
          refinedData[answer.title] =
            refinedData[answer.title] + "," + answer.content;
        } else if (answer.type === "file") {
          const filePath =
            answer.content && (await getFile(parseInt(answer.content)));
          refinedData[answer.title] = answer.content ? filePath : "";
        } else if (answer.type === "category") {
          // 카테고리 답변에 url 이 있을 시 url 링크 텍스트 제거
          refinedData[answer.title] =
            categoryAndCheckboxSelectTextToLinkAndText(answer.content!, false);
        } else {
          refinedData[answer.title] = answer.content;
        }
      });

      return refinedData;
    });

    setData({ data: resultData, fileName: "상담일지" });
  }, [params.id, subEvent]);

  useEffect(() => {
    if (subEvent !== undefined) {
      // subEvent에서 설정한 seller 및 buyer naming 변경
      setGroupNames({
        seller: subEvent.sgroupName,
        buyer: subEvent.bgroupName,
      });
      getMeetingSurveys();
    }
  }, [subEvent, getMeetingSurveys]);

  useEffect(() => {
    if (data !== null && subEvent !== undefined) {
      setFilteredData(null!);
      const seller: any[] = data.data.filter(
        (d) => d["구분"] === subEvent.sgroupName
      );
      const buyer: any[] = data.data.filter(
        (d) => d["구분"] === subEvent.bgroupName
      );
      let fileName = "상담일지";
      setFilteredData({ seller, buyer, fileName: fileName });
    }
  }, [data, subEvent]);

  const getFile = async (id: number) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    try {
      const res = await axios.get(`/api/fileS3?id=${id}`, config);
      return res.data.filePath;
    } catch (err) {
      return "";
    }
  };

  return (
    <div className={classes.meetingDiv}>
      <HeadingTypo fontweight="bold">{t("excel.meetingNote")}</HeadingTypo>
      {/* 셀러, 바이어 구분 해서 엑셀 출력 (subEvent에 따라 seller와 buyer의 문구가 다름) */}
      {/* { groupNames !== undefined && (
        <div className={classes.radioForm}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gubun"
              name="gubunRadio"
              row
              value={radioValue}
              onChange={handleChange}
            >
              {Object.entries(groupNames).map(([key, value]) => {
                return <FormControlLabel key={key} value={key} control={<Radio />} label={value} />;
              })}
            </RadioGroup>
          </FormControl>
        </div>
      )} */}

      {filteredData !== null ? (
        <ExportCSV
          sellerData={filteredData.seller}
          buyerData={filteredData.buyer}
          sellerName={groupNames!.seller}
          buyerName={groupNames!.buyer}
          fileName={filteredData.fileName}
        />
      ) : (
        <CircularProgress size={30} />
      )}
    </div>
  );
};

export default MeetingSurveyExcel;
