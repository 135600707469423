import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, RouteComponentProps } from "react-router-dom";
import { Column, MTableToolbar } from "material-table";
import Chip from "@material-ui/core/Chip";
import styled from "styled-components";
import { confirmAlert } from "react-confirm-alert";

// actions
import { getSmsTargets, sendSms } from "../../../../actions/smss";
import { AppState } from "../../../../store";
import { setProgressAction } from "../../../../actions/progresses";

// types
import { SmsTarget } from "../../../../types/models/Sms";
import { confirmModalOptions } from "../../../../utils/confirmModalOptions";

// custom ui comp
import PxTable from "../../../../components/Tables/PxTable";
import PxGridContainer from "../../../../components/Grid/PxGridContainer";
import SubHeadingTypo from "../../../../components/Typography/SubHeadingTypo";
import OutlinedTxtField from "../../../../components/Inputs/OutlinedTxtField";
import PxButton from "../../../../components//Buttons/PxButton";
import ButtonTypo from "../../../../components//Typography/ButtonTypo";
import PxGridItem from "../../../../components/Grid/PxGridItem";
import { setAlertAction } from "../../../../actions/alerts";
import Alert from "../../../../types/models/Alert";
import { setTableInfoLoadingAction } from "../../../../actions/tableInfo";
import PxOutlinedFormControl from "../../../../components/Forms/PxOutlinedFormControl";
import { MenuItem, OutlinedInput } from "@material-ui/core";
import PxSelectBox from "../../../../components/SelectBox/PxSelectBox";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CaptionTypo from "../../../../components/Typography/CaptionTypo";
import { useTranslation } from "react-i18next";
import Body1Typo from "../../../../components/Typography/Body1Typo";

const SubTitle = styled(SubHeadingTypo)`
  margin: 32px 0 0px 0;
  font-weight: bold;
`;

const Folder = styled.div<{ open: boolean }>`
  overflow: hidden;
  display: ${(props) => (props.open ? "none" : "block")};
  /* transition: all 0.3s; */
`;

const FolderBtn = styled.span`
  float: right;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
`;

interface SmsInfo {
  subEventId: string;
  emails: string[];
  companyNames: string[];
  phoneNumbers: string[];
  names: string[];
  content: string;
}

interface TableStateType {
  columns: Array<Column<SmsTarget>>;
  data: SmsTarget[];
}

const SmsAdd: React.FC<RouteComponentProps> = () => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const dispatch = useDispatch();
  const history = useHistory();
  const param: { id: string } = useParams();
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const smsTargets = useSelector((state: AppState) => state.smss.smsTargets);
  const [folder, setfolder] = useState<boolean>(false);
  const [selectParam, setSelectParam] = useState("");

  const [sms, setSms] = useState<SmsInfo>({
    subEventId: param.id,
    emails: [],
    phoneNumbers: [],
    companyNames: [],
    names: [],
    content: "",
  });

  useEffect(() => {
    callgetSmsTargets();
  }, []);

  const callgetSmsTargets = useCallback(async () => {
    await dispatch(getSmsTargets(param.id));
    dispatch(setTableInfoLoadingAction(false));
  }, [dispatch, param.id]);

  const [state, setState] = React.useState<TableStateType>({
    columns: [
      {
        title: t("smsAdd.corporate"),
        field: "companyName",
        cellStyle: { paddingRight: 0 },
      },
      // {
      //   title: "이메일",
      //   field: "email",
      //   cellStyle: { width: "100px", paddingRight: 0 },
      // },
      {
        title: t("common.name"),
        field: "name",
        cellStyle: { width: "70px", maxWidth: "70px", paddingRight: 0 },
      },
      {
        title: t("smsAdd.group"),
        field: "type",
        cellStyle: { width: "70px", paddingRight: 0 },
      },
      // {
      //   title: "직책",
      //   field: "position",
      //   cellStyle: { width: "100px", paddingRight: 0 },
      // },
      {
        title: t("smsAdd.countryCode"),
        field: "countryNum",
        cellStyle: { width: "70px", paddingRight: 0 },
      },
      {
        title: t("common.phone"),
        field: "phoneNum",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
      {
        title: t("smsAdd.approvalStatus"),
        field: "approval",
        cellStyle: { width: "100px", paddingRight: 0 },
      },
    ],
    data: [],
  });

  // 참가자리스트(Sms 발송대상 리스트) 테이블 데이터 state set
  useEffect(() => {
    if (subEvent !== undefined) {
      const smsTargetData: SmsTarget[] = smsTargets.map((smsTarget) => {
        return {
          companyName: smsTarget.companyName,
          name: smsTarget.name,
          email: smsTarget.email,
          type:
            smsTarget.type === "seller"
              ? subEvent.sgroupName
              : subEvent.bgroupName,
          countryNum: smsTarget.countryNum,
          phoneNum: smsTarget.phoneNum,
          position: smsTarget.position,
          approval: smsTarget.approval,
        };
      });

      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: smsTargetData };
      });
    }
  }, [subEvent, smsTargets]);

  //셀렉터 변경시
  useEffect(() => {
    if (subEvent !== undefined) {
      const smsTargetData: SmsTarget[] = smsTargets
        .filter((data) => data.type?.includes(selectParam))
        .map((smsTarget) => {
          return {
            companyName: smsTarget.companyName,
            name: smsTarget.name,
            email: smsTarget.email,
            type:
              smsTarget.type === "seller"
                ? subEvent.sgroupName
                : subEvent.bgroupName,
            countryNum: smsTarget.countryNum,
            phoneNum: smsTarget.phoneNum,
            position: smsTarget.position,
            approval: smsTarget.approval,
          };
        });
      setState((prevCreateDate) => {
        return { ...prevCreateDate, data: smsTargetData };
      });
    }
  }, [selectParam]);

  // sms 발송 대상 선택
  const changeTarget = (changeData: SmsTarget[]) => {
    const targetEmails: string[] = [];
    const targetCompanyNames: string[] = [];
    const targetPhoneNumbers: string[] = [];
    const targetNames: string[] = [];
    changeData.forEach((data) => {
      // 한국번호 '0'이 앞에 붙을 경우 '0'을 지우고 국가번호를 붙여줘야함
      let phoneNum;
      if (data.countryNum === 82) {
        if (data.phoneNum[0] === "0") {
          phoneNum = `+${data.countryNum}` + data.phoneNum.replace("0", "");
        } else {
          phoneNum = `+${data.countryNum}` + data.phoneNum;
        }
      } else {
        phoneNum = `+${data.countryNum}` + data.phoneNum;
      }
      targetPhoneNumbers.push(phoneNum);
      targetEmails.push(data.email);
      targetCompanyNames.push(data.companyName);
      targetNames.push(data.name);
    });
    setSms({
      ...sms,
      emails: targetEmails,
      companyNames: targetCompanyNames,
      phoneNumbers: targetPhoneNumbers,
      names: targetNames,
    });
  };

  // Sms 내용 변경
  const handleOnChange = useCallback(
    (
      e: React.ChangeEvent<{
        value: unknown;
        name?: string | undefined;
      }>
    ) => {
      e.preventDefault();

      setSms({
        ...sms,
        [e.target.name as string]: e.target.value as string,
      });
    },
    [sms]
  );

  // 내용 byte 계산
  const getByteLengthOfString = (text: string) => {
    if (text && text !== "") {
      let byte = 0;
      for (var i = 0; i < text.length; ++i) {
        // 기본 한글 2바이트 처리
        text.charCodeAt(i) > 127 ? (byte += 2) : byte++;
      }
      return byte;
    } else {
      return 0;
    }
  };

  // 메일 발송
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // validation
    let validationAlert: Alert | undefined = undefined;
    // 발송대상 1명 이상 선택필요
    if (sms.emails.length === 0) {
      validationAlert = {
        id: "smsSendErrorAlert",
        msg: "SMS 발송대상을 선택해주세요.",
        alertType: "warning",
      };
    } else if (sms.content.length === 0) {
      validationAlert = {
        id: "smsSendErrorAlert",
        msg: "SMS 내용을 입력해주세요.",
        alertType: "warning",
      };
    }

    if (validationAlert !== undefined) {
      return dispatch(setAlertAction(validationAlert));
    }

    confirmAlert(
      confirmModalOptions({
        title: "SMS를 발송하시겠습니까??",
        click: async () => {
          if (subEvent !== undefined) {
            dispatch(dispatch(setProgressAction({ open: "true" })));

            const refinedData = sms.phoneNumbers.map((phoneNum, index) => ({
              [phoneNum]: {
                name: sms.names[index],
                companyName: sms.companyNames[index],
              },
            }));

            const bodyData = {
              subEventId: subEvent.id,
              content: sms.content,
              users: refinedData,
            };

            const result = await dispatch(sendSms(bodyData));

            // sms전송 성공 시 발송대상 등 초기화
            // table의 체크 value -> false
            if (result !== undefined) {
              setSms({
                ...sms,
                emails: [],
                phoneNumbers: [],
                companyNames: [],
                names: [],
              });

              const tableData: SmsTarget[] = state.data.map((data) => {
                return {
                  ...data,
                  tableData: { id: data.tableData!.id, checked: false },
                };
              });

              setState({ ...state, data: tableData });

              const alert: Alert = {
                id: "smsSendAlertId",
                msg: "SMS를 전송했습니다.",
                alertType: "success",
              };
              dispatch(setAlertAction(alert));
            }

            dispatch(dispatch(setProgressAction({ open: "false" })));
          }
        },
      })
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <SubTitle>
        {t("smsAdd.list")}{" "}
        <FolderBtn onClick={() => setfolder(!folder)}>
          {folder ? (
            <>
              {t("smsAdd.open")} <ArrowDropDownIcon />
            </>
          ) : (
            <>
              {t("smsAdd.fold")} <ArrowDropUpIcon />
            </>
          )}
        </FolderBtn>
      </SubTitle>
      <Folder open={folder}>
        <PxGridContainer
          direction="row"
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            minHeight: "300px",
          }}
        >
          <PxTable<SmsTarget>
            components={{
              Toolbar: (props) => {
                return (
                  <PxGridContainer direction="column">
                    {/* <TitleTypo style={{ paddingLeft: 10 }}>title</TitleTypo> */}
                    <MTableToolbar {...props} />
                  </PxGridContainer>
                );
              },
            }}
            title={
              <div
                style={{
                  width: "100%",

                  margin: 5,
                }}
              >
                <PxOutlinedFormControl>
                  <PxSelectBox
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px",
                    }}
                    value={selectParam}
                    onChange={(e) => setSelectParam(e.target.value as string)}
                    displayEmpty
                    input={<OutlinedInput margin="dense" />}
                  >
                    <MenuItem value={""}>{t("common.all")}</MenuItem>
                    <MenuItem value={"seller"}>
                      {subEvent ? subEvent.sgroupName : "SELLER"}
                    </MenuItem>
                    <MenuItem value={"buyer"}>
                      {subEvent ? subEvent.bgroupName : "BUYER"}
                    </MenuItem>
                  </PxSelectBox>
                </PxOutlinedFormControl>
              </div>
            }
            columns={state.columns}
            data={state.data}
            // material-table 속성: https://material-table.com/#/docs/features/search
            options={{
              toolbar: true,
              actionsColumnIndex: -1,
              paging: false,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "300px",
              showTitle: true,
              defaultExpanded: false,
              search: true,
              selection: true,
              searchFieldAlignment: "right",
              exportButton: false,
            }}
            onSelectionChange={(rows) => changeTarget(rows)}
          />
        </PxGridContainer>
      </Folder>

      <SubTitle>{t("smsAdd.recipient")}</SubTitle>
      <PxGridContainer
        direction="row"
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          minHeight: "60px",
        }}
      >
        {sms.companyNames.length > 0 ? (
          sms.companyNames.map((name, idx) => (
            <Chip
              key={`email-${idx}`}
              label={name}
              color="primary"
              variant="outlined"
              style={{ margin: "3px 10px 3px 0" }}
            />
          ))
        ) : (
          <div style={{ alignSelf: "center", color: "#888" }}>
            {t("smsAdd.selectItFromTheList")}
          </div>
        )}
      </PxGridContainer>

      <SubTitle>{t("common.content")}</SubTitle>
      <Body1Typo>
        각 발송대상의 이름 또는 회사명을 입력하려면, #name, #company를
        이용하세요.
      </Body1Typo>
      <PxGridContainer direction="row" justify="space-between">
        <CaptionTypo>{t("smsAdd.smsAlert")}</CaptionTypo>
        <CaptionTypo>{getByteLengthOfString(sms.content)} byte</CaptionTypo>
      </PxGridContainer>
      <OutlinedTxtField
        fullWidth
        onChange={handleOnChange}
        name="content"
        multiline={true}
        rows={10}
        value={sms.content}
        placeholder="ex) 안녕하세요. 홍길동님.  =>  안녕하세요. #name님." //t("smsAdd.typeTheContent")
      />
      <PxGridContainer
        alignContent="space-between"
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <PxGridItem>
          <PxButton backgroundColor="purple" type="submit">
            <ButtonTypo>{t("smsList.send")}</ButtonTypo>
          </PxButton>
        </PxGridItem>
        <PxGridItem>
          <PxButton backgroundColor="grey" onClick={() => history.goBack()}>
            <ButtonTypo>{t("common.cancel")}</ButtonTypo>
          </PxButton>
        </PxGridItem>
      </PxGridContainer>
    </form>
  );
};

export default SmsAdd;
