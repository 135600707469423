import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import SubHeadingTypo from "../../../../components/Typography/SubHeadingTypo";
import { TypographyProps } from "@material-ui/core/Typography";
import styled from "styled-components";
import OutlinedTxtField from "../../../../components/Inputs/OutlinedTxtField";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSms } from "../../../../actions/smss";
import { SmsListItem } from "../../../../types/models/Sms";
import moment from "moment";

const SubTitle: React.FC<TypographyProps> = styled((props: TypographyProps) => {
  const { ...rest } = props;
  return <SubHeadingTypo {...rest} />;
})`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

const SmsDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", { useSuspense: false });
  const [smsDetail, setSmsDetail] = useState<SmsListItem>();

  const param: { secondId: string } = useParams();

  const getSmsDetail = async () => {
    const result: any = await dispatch(getSms(param.secondId));

    if (result) {
      setSmsDetail(result);
    }
  };

  useEffect(() => {
    getSmsDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Grid container>
      <Grid item>
        <ArrowBackIcon
          style={{
            cursor: "pointer",
            margin: "23px",
            width: "18px",
            height: "18px",
            objectFit: "contain",
          }}
          onClick={() => history.goBack()}
        />
      </Grid>
      <Grid item style={{ padding: "0 0 0 64px", width: "100%" }}>
        <SubTitle>{t("smsDetail.recipient")}</SubTitle>
        <OutlinedTxtField value={smsDetail?.toCompany} fullWidth disabled />
        <SubTitle>{t("smsDetail.phone")}</SubTitle>
        <OutlinedTxtField value={smsDetail?.toSms} fullWidth disabled />
        <SubTitle>{t("smsDetail.sendDate")}</SubTitle>
        <OutlinedTxtField
          value={`${moment(smsDetail?.createdDate).format("YYYY-MM-DD")}`}
          fullWidth
          disabled
        />
        <SubTitle>{t("smsDetail.content")}</SubTitle>
        <Grid
          style={{
            border: "1px solid #ccc",
            padding: "10.5px 14px",
            overflow: "scroll",
          }}
        >
          {smsDetail?.content}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SmsDetail;
