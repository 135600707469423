import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import Main from "./layouts/Main";
import Login from "./views/auth/Login";
import { getUser } from "./actions/users";
import { useDispatch } from "react-redux";
import setAuthToken from "./utils/setAuthToken";
import Printer from "./layouts/Printer";

if (localStorage.token) {
  setAuthToken(localStorage.token, localStorage.userId);
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.token) {
      dispatch(getUser(localStorage.userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/printer" component={Printer} />
        <Route path="/" render={(props) => <Main {...props} />} />
        {/* <Redirect from="/" to="/" /> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
