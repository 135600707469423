import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFormAnswers } from "../../../../actions/application";
import { ApplicationFormAnswers } from "../../../../types/models/Application";
import { ExportCSV } from "../../../../components/ExportCSV/ExportCSV";
import HeadingTypo from "../../../../components/Typography/HeadingTypo";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { categoryAndCheckboxSelectTextToLinkAndText } from "../../../../utils/utils";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import axios from "axios";
const groupBy = require("json-groupby");

const CompanyExcel = ({
  classes,
}: {
  classes: Record<"root" | "companyDiv" | "radioForm" | "meetingDiv", string>;
}) => {
  const [t] = useTranslation("lang", { useSuspense: false });
  const params: { id: string } = useParams(); // subEventId
  const [data, setData] = useState<{
    // 엑셀 export data
    data: any[];
    fileName: string;
  }>(null!);
  const [filteredData, setFilteredData] = useState<{
    // 라디오 or 체크박스 값에 따라 필터된 excel로 export할 데이터
    buyer: any[];
    seller: any[];
    fileName: string;
  }>(null!);
  const [groupNames, setGroupNames] = useState<{
    // 전체 데이터
    buyer: string;
    seller: string;
  }>();

  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);

  // 전체 참가기업 신청서 리스트를 가져온 후 excel로 export 할 data 생성
  const getSubEventApplications = useCallback(async () => {
    const result = await getFormAnswers(params.id);

    const groupedApplicationAnswers: {
      [key: string]: ApplicationFormAnswers[];
    } = groupBy(result, ["applicationId"]);

    const resultData = Object.values(groupedApplicationAnswers).map(
      (answers) => {
        const refinedAnswer: any = {};
        // 답변자 구분
        refinedAnswer["구분"] =
          answers[0].applicationType === "seller"
            ? subEvent?.sgroupName
            : subEvent?.bgroupName;
        // 답변자 승인여부 상태
        const state = answers[0].approval;
        if (state === "approval") {
          refinedAnswer["상태"] = "승인";
        } else if (state === "waiting") {
          refinedAnswer["상태"] = "대기";
        } else {
          refinedAnswer["상태"] = "삭제";
        }

        refinedAnswer["이메일"] =
          answers[0].businessCards !== undefined
            ? answers[0].businessCards[0].email
            : "";

        answers.forEach(async (answer) => {
          // 복수선택 답변 일때는 같은 key 값의 value에 해당 답변값(content)를 , 로 붙여줌
          if (
            answer.type === "many" &&
            refinedAnswer[answer.title] !== undefined // 복수선택 값이 처음 들어올때 예외처리
          ) {
            refinedAnswer[answer.title] =
              refinedAnswer[answer.title] + "," + answer.content;
          } else if (answer.type === "file") {
            const filePath =
              answer.content && (await getFile(parseInt(answer.content)));
            refinedAnswer[answer.title] = answer.content ? filePath : "";
          } else if (answer.type === "category") {
            // 카테고리 답변에 url 이 있을 시 url 링크 텍스트 제거
            refinedAnswer[answer.title] =
              categoryAndCheckboxSelectTextToLinkAndText(
                answer.content!,
                false
              );
          } else {
            refinedAnswer[answer.title] = answer.content;
          }
        });
        // 담당자 추가
        answers[0].businessCards?.forEach((businessCard, index) => {
          refinedAnswer[`담당자${index + 1} 이름`] = businessCard.name;
          refinedAnswer[`담당자${index + 1} 전화번호`] =
            businessCard.phoneNumber;
          refinedAnswer[`담당자${index + 1} 직책`] = businessCard.position;
        });
        return refinedAnswer;
      }
    );

    // resultData object 형식 = [{ 컬럼이름1: '컬럼내용1', 컬럼이름2: '컬럼내용2', ... }]
    setData({ data: resultData, fileName: "참가기업 리스트" });
  }, [params.id, subEvent]);

  useEffect(() => {
    if (subEvent !== undefined) {
      setGroupNames({
        seller: subEvent.sgroupName,
        buyer: subEvent.bgroupName,
      });
      getSubEventApplications();
    }
  }, [subEvent, getSubEventApplications]);

  useEffect(() => {
    if (data !== null && subEvent !== undefined) {
      setFilteredData(null!);
      const seller: any[] = data.data.filter(
        (d) => d["구분"] === subEvent.sgroupName
      );
      const buyer: any[] = data.data.filter(
        (d) => d["구분"] === subEvent.bgroupName
      );
      const fileName = "참가그룹";

      setFilteredData({ seller, buyer, fileName: fileName });
    }
  }, [data, subEvent]);

  const getFile = async (id: number) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      data: {}, // get 호출시 필요
    };
    try {
      const res = await axios.get(`/api/fileS3?id=${id}`, config);
      return res.data.filePath;
    } catch (err) {
      return "";
    }
  };

  return (
    <div className={classes.companyDiv}>
      <HeadingTypo fontweight="bold">
        {t("excel.participatingCorporate")}
      </HeadingTypo>

      {filteredData !== null ? (
        <ExportCSV
          sellerData={filteredData.seller}
          buyerData={filteredData.buyer}
          sellerName={groupNames!.seller}
          buyerName={groupNames!.buyer}
          fileName={filteredData.fileName}
        />
      ) : (
        <CircularProgress size={30} />
      )}
    </div>
  );
};

export default CompanyExcel;
