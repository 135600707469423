import { AppActions } from "../store";
import { PaginationSMS, SmsTarget } from "../types/models/Sms";
import { getRequest, postRequestAndReturn } from "../utils/utils";

export const getSmsTargetsAction = (smsTargets: SmsTarget[]): AppActions => ({
  type: "GET_SMS_TARGETS",
  smsTargets: smsTargets,
});

export const getSmsListAction = (smss: PaginationSMS): AppActions => ({
  type: "GET_SMS_LIST",
  smss: smss,
});

export const getSmsTargets = (subEventId: string) => {
  return getRequest(null, "/api/applicants/subEvent", getSmsTargetsAction, {
    subEventId: subEventId,
  });
};

export const sendSms = (data: any) => {
  const url =
    process.env.NODE_ENV === "production"
      ? "/sms/send"
      : "http://localhost:3001/sms/send";
  return postRequestAndReturn(url, data);
};

export const getSmss = (
  subEventId: string,
  page: number = 0,
  pageSize: number = 10,
  searchWord: string = ""
) => {
  return getRequest(null, `/api/smss`, getSmsListAction, {
    subEventId: subEventId,
    page,
    size: pageSize,
    searchWord,
  });
};

export const getSms = (id: string) => {
  return getRequest(null, `/api/smss/detail?id=${id}`);
};
